import { type ColumnOptions, type RowInstance } from "table";

import { useMemo } from "react";
import { useIntl } from "react-intl";
import { type CloudTypes } from "requests";
import { TruncateText } from "ui";
import { RouterLink } from "../../../../../../../components/Navigation/RouterLink/RouterLink";
import { settingsCloudAccountsSidecarRoute } from "../../../../../../settings/cloudAccounts/Sidecar/settingsCloudAccountsSidecarRoute";
import { type CloudAccount } from "../../../../../types";

export const useCloudAccountsModalTableColumns = ({
  cloudType,
}: {
  cloudType?: CloudTypes;
}) => {
  const intl = useIntl();

  return useMemo(() => {
    return [
      {
        accessor: "name",
        Cell: ({
          value,
          row,
        }: {
          value: string;
          row: Pick<RowInstance<CloudAccount>, "original">;
        }) => {
          return (
            <TruncateText>
              <RouterLink
                to={settingsCloudAccountsSidecarRoute.id}
                params={{
                  cloudAccountId: row.original.id,
                  cloudType: row.original?.cloudType ?? cloudType,
                }}
              >
                {value}
              </RouterLink>
            </TruncateText>
          );
        },
        Header: intl.formatMessage({
          defaultMessage: "Name",
          id: "Hik/zF",
          description: "Name header",
        }),
        width: 375,
      },
    ] as ColumnOptions<CloudAccount>[];
  }, [intl, cloudType]);
};
