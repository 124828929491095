import { LineColumnsIcon, RedoIcon } from "icons";
import { useCallback, useState, type ReactElement } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
} from "ui";

import { FormattedMessage } from "react-intl";
import { type ColumnInstance, type Data } from "../../types";
import { ColumnList } from "./ColumnList";

export interface ConfigureColumnsProps<D extends Data> {
  /**
   * All the columns in the table.
   */
  columns: ColumnInstance<D>[];
  /**
   * The `id`s of the columns, in current order.
   */
  columnOrder: string[];
  /**
   * Disables changing the order of the columns.
   */
  disableColumnOrder?: boolean;
  /**
   * Update the column order. Pass an array of column `id`s representing the new order.
   */
  setColumnOrder: (columnIds: string[]) => void;
  /**
   * Set the hidden columns.
   */
  setHiddenColumns: (columnIds: string[]) => void;
  /**
   * Triggered on close, provided with the visible columns in the new order.
   */
  onModalClose?: (columnIds: string[]) => void;
  /**
   * If provided, the banner will be rendered at the top of the modal.
   */
  banner?: ReactElement;
  /**
   * If true, The modal wont be able to be closed.
   */
  disableModalClosing?: boolean;
}

export function ConfigureColumns<D extends Data>({
  columns,
  columnOrder,
  disableColumnOrder = false,
  setColumnOrder,
  setHiddenColumns,
  onModalClose,
  banner,
  disableModalClosing,
}: ConfigureColumnsProps<D>): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    if (onModalClose) {
      const nextVisibleColumns = columns
        .filter((c) => c.isVisible)
        .map((c) => c.id);
      onModalClose(nextVisibleColumns);
    }
  }, [columns, onModalClose]);

  const resetColumns = useCallback(() => {
    const nextColumnOrder = columns.map((c) => c.id);
    const nextHiddenColumns = columns
      .filter((c) => c.defaultHidden)
      .map((c) => c.id);
    setColumnOrder(nextColumnOrder);
    setHiddenColumns(nextHiddenColumns);
  }, [columns, setColumnOrder, setHiddenColumns]);

  return (
    <>
      <Tooltip label="Hide/show columns and set their order">
        <Button
          aria-label="Configure Columns"
          appearance="tertiary"
          icon={<LineColumnsIcon />}
          onClick={openModal}
        />
      </Tooltip>
      <Modal
        isOpen={isOpen}
        onClose={disableModalClosing ? undefined : closeModal}
        eventsToClose={
          disableModalClosing
            ? { enableClickOutside: false, enableEscapeKey: false }
            : undefined
        }
      >
        <ModalHeader
          title="Configure Columns"
          enableClose={!disableModalClosing}
        />
        {banner}
        <ModalBody overflow>
          <div
            className="flex flex-col"
            style={{ maxHeight: Modal.bodyMeasurements.md.maxHeight }}
          >
            <div className="flex flex-none items-center justify-between border-b pb-2 text-sm dark:border-blue-steel-850">
              <p>
                <FormattedMessage
                  defaultMessage="Select which columns to display and drag to reorder."
                  id="DmuScN"
                  description="Column configuration"
                />
              </p>
              <Button
                appearance="clear"
                data-autofocus
                icon={<RedoIcon />}
                onClick={resetColumns}
                size="sm"
              >
                <FormattedMessage
                  defaultMessage="Reset to Default"
                  id="LmAwjr"
                  description="Button to reset a configuration to default"
                />
              </Button>
            </div>
            <ColumnList
              columns={columns}
              columnOrder={columnOrder}
              disableColumnOrder={disableColumnOrder}
              setColumnOrder={setColumnOrder}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={closeModal}
            appearance="primary"
            disabled={disableModalClosing}
          >
            <FormattedMessage
              defaultMessage="Done"
              id="D0u+V6"
              description="Button to finish a configuration task"
            />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
