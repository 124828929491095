import { Wizard, type WizardStepState } from "../../../../components/Wizard";
import {
  ACCOUNT,
  ACCOUNT_ID,
  ACCOUNT_TYPE,
  ADD_DETAILS,
  AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE,
  AGENTLESS_DEFAULT_MEMBER_STATE,
  AGENTLESS_SCAN,
  AWS_OU_SELECTED_NODES_INITIAL,
  CUSTOM_CONFIGURATION,
  ENABLED,
  ERRORS,
  NAME,
  ORGANIZATION,
  ROLE_ARN,
  ROOT_ID_FROM_API,
  SERVERLESS_DEFAULT_MEMBER_STATE,
  SUMMARY,
  VALUE,
  WIZARD_HEIGHT,
} from "../../../constants";

import { stepTitle } from "../../../messages/commonMessages";

import { isChina } from "environment";
import { fromJS } from "immutable";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIsBusinessLicenseType } from "prisma";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import LoadingModal from "../../../components/LoadingModal";
import {
  useGetAwsPartitionItems,
  useGetDefaultAccountGroupId,
} from "../../../hooks";
import { type OnboardingProps } from "../../../types";
import { AwsSelectMemberAccountsContextProvider } from "../../Onboarding/AwsOnboarding/context/AwsSelectMemberAccountsContext";
import { type AwsSelectMemberAccountsInitialStateType } from "../../Onboarding/AwsOnboarding/context/AwsSelectMemberAccountsContext/state/initialState";
import AddDetails from "./AddDetails";
import CustomConfiguration from "./CustomConfiguration";
import Summary from "./Summary";

export function AwsOnboarding({
  closeModal,
  isOpen,
  fromFreemium = false,
}: OnboardingProps) {
  const intl = useIntl();
  const { agentlessApiDiscovery, cloudScanMode } = useFlags();
  const [isCustomConfigEnabled, setCustomConfigEnabled] = useState(false);
  const [isCftDownloadedOrCreated, setCftDownloadedOrCreated] = useState(false);
  const accountType = isChina() ? ACCOUNT : ORGANIZATION;

  const { isBusinessLicenseType, isLoading: isLicenseTypeLoading } =
    useIsBusinessLicenseType();

  const { awsPartitionItems, isLoading: isAwsPartitionLoading } =
    useGetAwsPartitionItems();

  const partitionType = useMemo(
    () => awsPartitionItems[0]?.value,
    [awsPartitionItems],
  );

  const {
    defaultAccountGroupId: newDefaultAccountGroupId = [],
    isLoading: isAccountGroupItemsLoading,
  } = useGetDefaultAccountGroupId();

  const customConfigurationStep = useMemo(
    () => [
      {
        Component: CustomConfiguration,
        disabled: true,
        key: CUSTOM_CONFIGURATION,
        label: intl.formatMessage(stepTitle[CUSTOM_CONFIGURATION]),
        hasBeenSubmitted: false,
        values: {
          scanOption: "Custom",
          cloudTrailTopic: "",
          securityCapabilities: { [AGENTLESS_SCAN]: !isBusinessLicenseType },
          orgSecurityCapabilities: {
            [AGENTLESS_DEFAULT_MEMBER_STATE]: !isBusinessLicenseType,
            [SERVERLESS_DEFAULT_MEMBER_STATE]: !isBusinessLicenseType,
            ...(agentlessApiDiscovery && {
              [AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE]:
                !isBusinessLicenseType,
            }),
          },
          ...(cloudScanMode && { mode: "cloud-scan" }),
          roleArn: "",
          accountId: "",
        },
      },
    ],
    [agentlessApiDiscovery, cloudScanMode, intl, isBusinessLicenseType],
  );

  const steps = useMemo((): WizardStepState[] => {
    const wizardSteps: WizardStepState[] = [
      {
        Component: AddDetails,
        disabled: false,
        key: ADD_DETAILS,
        label: intl.formatMessage(stepTitle[ADD_DETAILS]),
        hasBeenSubmitted: false,
        values: {
          accountType,
          partitionType,
          onboardType: "automated",
          name: `AWS Cloud Account ${Math.floor(Date.now() / 1000)}`,
          groupIds: newDefaultAccountGroupId[0]?.key,
        },
      },
      {
        Component: Summary,
        disabled: true,
        key: SUMMARY,
        label: intl.formatMessage(stepTitle[SUMMARY]),
        hasBeenSubmitted: false,
      },
    ];
    return wizardSteps;
  }, [accountType, intl, newDefaultAccountGroupId, partitionType]);

  const title = intl.formatMessage({
    defaultMessage: "Connect Provider",
    id: "FDjT8F",
    description: "Modal title",
  });

  const selectMemberAccountInitialState = useMemo(() => {
    /* c8 ignore next */
    let rootIdFromAPI;

    return fromJS({
      [ACCOUNT_ID]: {
        [VALUE]: "",
        [ERRORS]: [],
      },
      [ACCOUNT_TYPE]: {
        [VALUE]: accountType,
        [ERRORS]: [],
      },
      [ENABLED]: {
        [VALUE]: true,
        [ERRORS]: [],
      },
      [NAME]: {
        [VALUE]: "",
        [ERRORS]: [],
      },
      [ROLE_ARN]: {
        [VALUE]: "",
        [ERRORS]: [],
      },
      [AWS_OU_SELECTED_NODES_INITIAL]: {
        [VALUE]: [],
        [ERRORS]: [],
      },
      [ROOT_ID_FROM_API]: { [VALUE]: rootIdFromAPI },
    }) as AwsSelectMemberAccountsInitialStateType;
  }, [accountType]);

  if (
    isAwsPartitionLoading ||
    isAccountGroupItemsLoading ||
    isLicenseTypeLoading
  ) {
    return <LoadingModal title={title} />;
  }

  return (
    <AwsSelectMemberAccountsContextProvider
      initialState={selectMemberAccountInitialState}
    >
      <Wizard
        context={{
          closeModal,
          fromFreemium,
          enabled: true,
          isCustomConfigEnabled,
          setCustomConfigEnabled,
          customConfigurationStep,
          isCftDownloadedOrCreated,
          setCftDownloadedOrCreated,
        }}
        initialActiveIndex={0}
        isOpen={isOpen}
        onClose={closeModal}
        size="xl"
        steps={steps}
        style={{ minHeight: WIZARD_HEIGHT }}
        title={title}
      />
    </AwsSelectMemberAccountsContextProvider>
  );
}
