import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const AmazonRdsIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Amazon RDS",
    id: "uXC1h0",

    description: "Amazon RDS icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M67.5127 35.1739C67.5127 30.5357 58.4123 28.3926 49.9197 28.3926C41.4271 28.3926 32.3268 30.5357 32.3268 35.1739C32.3198 35.4058 32.3467 35.6375 32.4067 35.8616V64.73C32.3268 69.5281 41.4431 71.5753 49.9997 71.5753C58.5563 71.5753 67.5927 69.4642 67.5927 64.826V35.1739H67.5127ZM49.9517 31.6073C59.276 31.6073 64.346 34.1503 64.346 35.1899C64.346 36.2294 59.308 38.7724 49.9517 38.7724C40.5955 38.7724 35.5575 36.2454 35.5575 35.1899C35.5575 34.1343 40.6274 31.6073 49.9517 31.6073ZM64.346 64.842C64.346 65.8656 59.292 68.3926 49.9517 68.3926C40.6114 68.3926 35.5575 65.8656 35.5575 64.842V59.788C38.9321 61.6273 44.5939 62.5069 49.9517 62.5069C55.3096 62.5069 60.9233 61.6433 64.346 59.836V64.842ZM64.346 55.7416C64.346 56.7652 59.308 59.3242 49.9517 59.3242C40.5955 59.3242 35.5575 56.7652 35.5575 55.7416H35.6054V49.6321C38.9801 51.4554 44.6418 52.335 49.9997 52.335C55.3575 52.335 60.9713 51.4713 64.3939 49.6641L64.346 55.7416ZM64.346 45.5537C64.346 46.5933 59.308 49.1523 49.9517 49.1523C40.5955 49.1523 35.5575 46.5933 35.5575 45.5537H35.6054V39.3162C39.0121 41.1235 44.6098 41.9552 49.9997 41.9552C55.3895 41.9552 61.0513 41.0915 64.3939 39.2522L64.346 45.5537Z"
        className="fill-[url(#paint0_linear_6619_101790)] dark:fill-white"
      />
      <path
        d="M15.4694 86.8014H26.0092V90.0002H11.615C11.1908 90.0002 10.784 89.8317 10.4841 89.5317C10.1841 89.2318 10.0156 88.825 10.0156 88.4008V74.0066H13.2143V84.5144L25.2095 72.5352L27.4487 74.7903L15.4694 86.8014Z"
        className="fill-[url(#paint1_linear_6619_101790)] dark:fill-white"
      />
      <path
        d="M89.984 74.0064V88.4007C89.984 88.8248 89.8155 89.2316 89.5156 89.5316C89.2156 89.8315 88.8088 90 88.3847 90H73.9904V86.8013H84.5302L72.7109 74.95L74.966 72.7109L86.7853 84.5142V74.0064H89.984Z"
        className="fill-[url(#paint2_linear_6619_101790)] dark:fill-white"
      />
      <path
        d="M89.984 11.6316V26.0258H86.7853V15.4701L74.966 27.2893L72.7109 25.0342L84.5142 13.2309H73.9904V10.0322H88.3847C88.8088 10.0322 89.2156 10.2007 89.5156 10.5007C89.8155 10.8006 89.984 11.2074 89.984 11.6316Z"
        className="fill-[url(#paint3_linear_6619_101790)] dark:fill-white"
      />
      <path
        d="M27.4487 25.2099L25.2095 27.449L13.2143 15.4698V25.9936H10.0156V11.5994C10.0156 11.1752 10.1841 10.7684 10.4841 10.4684C10.784 10.1685 11.1908 10 11.615 10H26.0092V13.1987H15.4694L27.4487 25.2099Z"
        className="fill-[url(#paint4_linear_6619_101790)] dark:fill-white"
      />
      <path
        d="M25.4651 64.3303C15.693 60.8117 10.0952 55.4379 10.0952 49.6002C10.0952 43.7626 15.693 38.4047 25.4651 34.8701L26.5526 37.8769C18.252 40.8677 13.2939 45.25 13.2939 49.6002C13.2939 53.9505 18.252 58.3327 26.5526 61.3235L25.4651 64.3303Z"
        className="fill-[url(#paint5_linear_6619_101790)] dark:fill-white"
      />
      <path
        d="M73.6378 64.682L72.6143 61.6432C81.4907 58.6524 86.7846 54.1422 86.7846 49.6C86.7846 45.0579 81.4907 40.5477 72.6143 37.5409L73.6378 34.5181C84.0177 38.0047 89.9833 43.5385 89.9833 49.6C89.9833 55.6616 84.0177 61.1634 73.6378 64.682Z"
        className="fill-[url(#paint6_linear_6619_101790)] dark:fill-white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6619_101790"
          x1={32.3257}
          y1={28.3926}
          x2={74.638}
          y2={62.9487}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6619_101790"
          x1={10.0156}
          y1={72.5352}
          x2={27.4806}
          y2={89.9682}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6619_101790"
          x1={72.7109}
          y1={72.7109}
          x2={90}
          y2={89.984}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6619_101790"
          x1={72.7109}
          y1={10.0322}
          x2={89.968}
          y2={27.3053}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6619_101790"
          x1={10.0156}
          y1={10}
          x2={27.4646}
          y2={27.433}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6619_101790"
          x1={10.0952}
          y1={34.8701}
          x2={35.1814}
          y2={48.8841}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_6619_101790"
          x1={72.6143}
          y1={34.5181}
          x2={98.7023}
          y2={49.5401}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
