import { useIsBusinessLicenseType } from "prisma";
import {
  type SecurityCapabilitiesType,
  type SecurityCapabilityValuesType,
} from "requests";
import { Wizard, type WizardStepState } from "../../../../components/Wizard";
import {
  ACCOUNT,
  ACCOUNT_ID,
  ACCOUNT_TYPE,
  AGENTLESS_API_DISCOVERY,
  AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE,
  AGENTLESS_DEFAULT_MEMBER_STATE,
  AGENTLESS_SCAN,
  AUTO_PROTECT,
  AWS,
  AWS_OU_SELECTED_NODES_INITIAL,
  CONFIGURE_ACCOUNT,
  CONFIGURE_DATA_SECURITY,
  CONFIGURE_FW,
  DATA_SECURITY,
  DEFINE_FW,
  ENABLED,
  ERRORS,
  GET_STARTED,
  IS_EDIT,
  NAME,
  ORGANIZATION,
  REVIEW_STATUS,
  ROLE_ARN,
  ROOT_ID_FROM_API,
  SERVERLESS_DEFAULT_MEMBER_STATE,
  SERVERLESS_SCAN,
  VALUE,
  WIZARD_HEIGHT,
} from "../../../constants";
import {
  useGetAwsCloudAccountsCas,
  useGetAwsPartitionItems,
  useGetDefaultAccountGroupId,
} from "../../../hooks";
import { stepTitle, wizardTitle } from "../../../messages/commonMessages";

import { isChina } from "environment";
import { fromJS } from "immutable";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import LoadingModal from "../../../components/LoadingModal";
import { type OnboardingProps } from "../../../types";
import { ActiveStepTitle } from "../components";
import ConfigureAccount from "./ConfigureAccount";
import ConfigureDataSecurity from "./ConfigureDataSecurity";
import ConfigureFwScan from "./ConfigureFwScan";
import DefineFwScan from "./DefineFwScan";
import GetStarted from "./GetStarted";
import ReviewStatus from "./ReviewStatus";
import { AwsSelectMemberAccountsContextProvider } from "./context/AwsSelectMemberAccountsContext";
import { type AwsSelectMemberAccountsInitialStateType } from "./context/AwsSelectMemberAccountsContext/state/initialState";

export const featureMap: Record<
  string,
  Exclude<
    SecurityCapabilitiesType,
    | "Cloud Visibility Compliance and Governance"
    | "Identity Security"
    | "Threat Detection"
    | "Remediation"
  >
> = {
  "compute-agentless": AGENTLESS_SCAN,
  "compute-auto-protect": AUTO_PROTECT,
  "compute-serverless-scan": SERVERLESS_SCAN,
  "data-security": DATA_SECURITY,
  "agentless-api-discovery": AGENTLESS_API_DISCOVERY,
};

export function AwsOnboarding({
  accountId,
  closeModal,
  isOpen,
  isEdit = false,
  isEditingDLP = false,
  fromFreemium = false,
  fromProvidersPage,
}: OnboardingProps) {
  const intl = useIntl();
  const { agentlessApiDiscovery, cloudScanMode } = useFlags();
  const { isBusinessLicenseType, isLoading: isLicenseTypeLoading } =
    useIsBusinessLicenseType();

  const { data: awsData, isLoading } = useGetAwsCloudAccountsCas({
    accountId,
    options: { enabled: isEdit },
  });

  const {
    cloudAccount: {
      accountType = isChina() ? ACCOUNT : ORGANIZATION,
      deploymentTypeDescription = "",
      enabled = true,
      name = "AWS Cloud Account",
      features = [],
    } = {},
    externalId = "",
    defaultAccountGroupId = "",
    groupIds = [],
    hierarchySelection: awsHierarchySelection = [],
    roleArn = "",
    storageScanConfig: { scanOption = "Custom", snsTopicArn = "" } = {},
    customMemberRoleNameEnabled = false,
    memberRoleName = "",
    skipOverrideMemberRoleName = false,
    useTenantExternalId = false,
    unifiedCftDisabled = false,
  } = awsData || {};

  const mode = useMemo(() => {
    const agentlessFeature = features.filter(
      ({ featureName }) => featureMap[featureName] === AGENTLESS_SCAN,
    );
    const featureMode = agentlessFeature[0]?.featureMode ?? "";
    return ["target-scan", "cloud-scan"].includes(featureMode)
      ? featureMode
      : "cloud-scan";
  }, [features]);

  const { awsPartitionItems, isLoading: isAwsPartitionLoading } =
    useGetAwsPartitionItems();

  const partitionType = useMemo(
    () =>
      isEdit
        ? awsPartitionItems.find(
            (deployment) => deployment.title === deploymentTypeDescription,
          )?.value
        : awsPartitionItems[0]?.value,
    [awsPartitionItems, deploymentTypeDescription, isEdit],
  );

  const securityCapabilitiesValues = useMemo(
    () =>
      features.reduce(
        (
          values: Record<SecurityCapabilitiesType, boolean>,
          { featureName, featureState },
        ) => {
          /* c8 ignore next */
          if (featureMap[featureName])
            values[featureMap[featureName]] = featureState === "enabled";
          return values;
        },
        {} as Record<SecurityCapabilitiesType, boolean>,
      ),
    [features],
  );

  const orgSecurityCapabilitiesValues = useMemo(
    () =>
      features
        .filter(({ featureName }) => {
          const feature = featureMap[featureName];
          return (
            feature === AGENTLESS_SCAN ||
            feature === SERVERLESS_SCAN ||
            (agentlessApiDiscovery && feature === AGENTLESS_API_DISCOVERY)
          );
        })
        .reduce(
          (
            values: Record<SecurityCapabilityValuesType, boolean>,
            { featureName, defaultMemberState },
          ) => {
            featureMap[featureName] === AGENTLESS_SCAN
              ? (values[AGENTLESS_DEFAULT_MEMBER_STATE] = Boolean(
                  defaultMemberState === ENABLED ||
                    (!securityCapabilitiesValues[AGENTLESS_SCAN] &&
                      !isBusinessLicenseType),
                ))
              : agentlessApiDiscovery &&
                  featureMap[featureName] === AGENTLESS_API_DISCOVERY
                ? (values[AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE] =
                    Boolean(
                      defaultMemberState === ENABLED ||
                        (!securityCapabilitiesValues[AGENTLESS_API_DISCOVERY] &&
                          !isBusinessLicenseType),
                    ))
                : (values[SERVERLESS_DEFAULT_MEMBER_STATE] = Boolean(
                    defaultMemberState === ENABLED ||
                      (!securityCapabilitiesValues[SERVERLESS_SCAN] &&
                        !isBusinessLicenseType),
                  ));

            return values;
          },
          {} as Record<SecurityCapabilityValuesType, boolean>,
        ),
    [
      features,
      isBusinessLicenseType,
      securityCapabilitiesValues,
      agentlessApiDiscovery,
    ],
  );

  const remediation = useMemo(
    () =>
      /* c8 ignore next */
      features.filter(({ featureName }) => featureName === "remediation")[0]
        ?.featureState === "enabled",
    [features],
  );

  const selectMemberAccountInitialState = useMemo(() => {
    /* c8 ignore next */
    if (isEdit && accountType !== ORGANIZATION)
      return fromJS({}) as AwsSelectMemberAccountsInitialStateType;

    let rootIdFromAPI;
    const hierarchySelection =
      awsHierarchySelection?.map((item) => {
        /* c8 ignore next */
        if (item && item.displayName === "Root" && item.nodeType === "ORG") {
          // Reset the root Id from previous selection
          rootIdFromAPI = item.resourceId;
          return { ...item, resourceId: "root" };
        }
        return item;
      }) ?? [];

    return fromJS({
      [ACCOUNT_ID]: {
        [VALUE]: accountId,
        [ERRORS]: [],
      },
      [ACCOUNT_TYPE]: {
        [VALUE]: accountType,
        [ERRORS]: [],
      },
      [ENABLED]: {
        [VALUE]: enabled,
        [ERRORS]: [],
      },
      [NAME]: {
        [VALUE]: name,
        [ERRORS]: [],
      },
      [ROLE_ARN]: {
        [VALUE]: roleArn,
        [ERRORS]: [],
      },
      [IS_EDIT]: { [VALUE]: isEdit },
      [AWS_OU_SELECTED_NODES_INITIAL]: {
        [VALUE]: hierarchySelection,
        [ERRORS]: [],
      },
      [ROOT_ID_FROM_API]: { [VALUE]: rootIdFromAPI },
    }) as AwsSelectMemberAccountsInitialStateType;
  }, [
    accountType,
    accountId,
    awsHierarchySelection,
    enabled,
    isEdit,
    name,
    roleArn,
  ]);

  const {
    defaultAccountGroupId: newDefaultAccountGroupId = [],
    isLoading: isAccountGroupItemsLoading,
  } = useGetDefaultAccountGroupId();

  const accountGroupDefaultValue = useMemo(() => {
    if (isEdit) {
      return accountType === ORGANIZATION ? defaultAccountGroupId : groupIds;
    } else {
      return newDefaultAccountGroupId[0]?.key;
    }
  }, [
    accountType,
    defaultAccountGroupId,
    groupIds,
    isEdit,
    newDefaultAccountGroupId,
  ]);

  const status = isEdit ? "valid" : "pending";

  const dlpSteps = useMemo(
    () => [
      {
        Component: ConfigureDataSecurity,
        disabled: !isEdit,
        hasBeenSubmitted: isEdit,
        status,
        key: CONFIGURE_DATA_SECURITY,
        label: intl.formatMessage({
          defaultMessage: "Configure Data Security",
          id: "rmpD2C",
          description: "Cloud Account Wizard page heading",
        }),
        values: {
          scanOption,
        },
      },
      {
        Component: DefineFwScan,
        disabled: !isEdit,
        hasBeenSubmitted: isEdit,
        status,
        key: DEFINE_FW,
        label: intl.formatMessage({
          defaultMessage: "Define Forward Scan",
          id: "/0Aclu",
          description: "Cloud Account Wizard page heading",
        }),
        values: {
          cloudTrailTopic: snsTopicArn,
        },
      },
      {
        Component: ConfigureFwScan,
        disabled: !isEdit,
        hasBeenSubmitted: isEdit,
        status,
        key: CONFIGURE_FW,
        label: intl.formatMessage({
          defaultMessage: "Configure Forward Scan",
          id: "Ufg/5/",
          description: "Cloud Account Wizard page heading",
        }),
      },
    ],
    [intl, isEdit, scanOption, snsTopicArn, status],
  );

  const steps = useMemo((): WizardStepState[] => {
    const wizardSteps: WizardStepState[] = [
      {
        Component: GetStarted,
        disabled: false,
        key: GET_STARTED,
        label: intl.formatMessage(stepTitle[GET_STARTED]),
        hasBeenSubmitted: isEdit,
        status,
        values: {
          accountType,
          partitionType,
          securityCapabilities: isEdit
            ? { ...securityCapabilitiesValues }
            : { [AGENTLESS_SCAN]: !isBusinessLicenseType },
          orgSecurityCapabilities: isEdit
            ? { ...orgSecurityCapabilitiesValues }
            : {
                [AGENTLESS_DEFAULT_MEMBER_STATE]: !isBusinessLicenseType,
                [SERVERLESS_DEFAULT_MEMBER_STATE]: !isBusinessLicenseType,
                ...(agentlessApiDiscovery && {
                  [AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE]:
                    !isBusinessLicenseType,
                }),
              },
          ...(cloudScanMode && { mode }),
        },
      },
      {
        Component: ConfigureAccount,
        disabled: !isEdit,
        key: CONFIGURE_ACCOUNT,
        label: intl.formatMessage(stepTitle[CONFIGURE_ACCOUNT]),
        hasBeenSubmitted: isEdit,
        status,
        values: {
          accountId,
          externalId,
          name,
          roleArn,
          ...(accountType === ORGANIZATION && {
            hierarchySelection: awsHierarchySelection,
          }),
          remediation,
          groupIds: accountGroupDefaultValue,
          customMemberRoleNameEnabled,
          memberRoleName,
          skipOverrideMemberRoleName,
          unifiedCftDisabled,
          useTenantExternalId,
        },
      },
      {
        Component: ReviewStatus,
        disabled: !isEdit,
        key: REVIEW_STATUS,
        label: intl.formatMessage(stepTitle[REVIEW_STATUS]),
        hasBeenSubmitted: isEdit,
        status,
      },
    ];
    return isEdit && securityCapabilitiesValues?.[DATA_SECURITY]
      ? ([
          ...wizardSteps.slice(0, 2),
          ...dlpSteps,
          ...wizardSteps.slice(2),
        ] as WizardStepState[])
      : wizardSteps;
  }, [
    intl,
    isEdit,
    status,
    mode,
    accountType,
    partitionType,
    securityCapabilitiesValues,
    isBusinessLicenseType,
    accountId,
    externalId,
    name,
    roleArn,
    awsHierarchySelection,
    remediation,
    accountGroupDefaultValue,
    dlpSteps,
    customMemberRoleNameEnabled,
    memberRoleName,
    skipOverrideMemberRoleName,
    useTenantExternalId,
    unifiedCftDisabled,
    orgSecurityCapabilitiesValues,
    agentlessApiDiscovery,
    cloudScanMode,
  ]);

  const title = (
    <ActiveStepTitle
      label={intl.formatMessage(isEdit ? wizardTitle.edit : wizardTitle.add)}
      cloudType={AWS}
      iconSize={"lg"}
    />
  );

  if (
    isLoading ||
    isAccountGroupItemsLoading ||
    isAwsPartitionLoading ||
    isLicenseTypeLoading
  ) {
    return <LoadingModal title={title} />;
  }

  return (
    <AwsSelectMemberAccountsContextProvider
      initialState={selectMemberAccountInitialState}
    >
      <Wizard
        context={{
          accountId,
          closeModal,
          enabled,
          isEdit,
          fromFreemium,
          initUnifiedCftDisabled: unifiedCftDisabled,
          fromProvidersPage,
        }}
        initialActiveIndex={isEditingDLP ? 2 : 0}
        isOpen={isOpen}
        onClose={closeModal}
        size="xl"
        steps={steps}
        style={{ minHeight: WIZARD_HEIGHT }}
        title={title}
      />
    </AwsSelectMemberAccountsContextProvider>
  );
}
