import {
  autoUpdate,
  flip,
  limitShift,
  offset,
  shift,
} from "@floating-ui/react";
import { useCombobox, type UseComboboxStateChange } from "downshift";
import {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ReactNode,
} from "react";
import { classNames, noop } from "utils";
import { mergeRefs, useFloating, usePrevious } from "../../utils";
import { Button } from "../Button";
import { listDefaultMinWidth } from "../List";
import { Portal } from "../Portal";
import { type SelectItem, type SelectWithoutSearchProps } from "../Select";
import { SelectList, type ListWidthType } from "./SelectList";
import { useSelectAll } from "./useSelectAll";
import {
  getSelectAll,
  isDomElement,
  onSelectedItemChange,
  selectedItemLabel,
  sortBySelected,
} from "./utils";

export type ComboboxChanges = Partial<UseComboboxStateChange<SelectItem>> & {
  selectedItems: SelectItem[];
  selectedItem?: SelectItem;
};

export type ComboboxProps = Omit<SelectWithoutSearchProps, "onChange"> & {
  /**
   * Invoked on changes to the selection, providing an individual selected item and all of the selected items.
   */
  onChange?: (changes: ComboboxChanges) => void;
  /**
   * Use to pass what the label ID is. This will be used to set the appropriate aria attributes
   * on the button and menu.
   *
   * Your label should have an `htmlFor` attribute that equals the `toggleButtonId`.
   *
   * Be sure to also set `toggleButtonId` nad `inputId`.
   */
  labelId?: string;
  /**
   * Use to pass what the button ID should be. This will be used to set the appropriate aria attributes
   * on the button and menu.
   *
   * Your label should have an `htmlFor` attribute that equals the `toggleButtonId`.
   *
   * Be sure to also set `labelID` and `inputId`.
   */
  toggleButtonId?: string;
  /**
   * Use to pass what the input ID should be. This will be used to set the appropriate aria attributes
   * on the input and menu.
   *
   * Your label should have an `htmlFor` attribute that equals the `inputId`.
   *
   * Be sure to also set `labelID` and `toggleButtonId`.
   */
  inputId?: string;
  input: (props: {
    getInputProps: ReturnType<typeof useCombobox>["getInputProps"];
    closeMenu: ReturnType<typeof useCombobox>["closeMenu"];
    highlightedIndex: ReturnType<typeof useCombobox>["highlightedIndex"];
  }) => ReactNode;
  /**
   * Use to control searching. This overrides the default behavior of `enableSearch`.
   *
   * The provided function will receive the input value when it changes, and expect you to filter and
   * provide an updated list of items.
   */
  onSearch?: (inputValue: string) => void;
  /**
   * A function that maps an item to a string. This is used for filtering as well as accessibility.
   *
   * Must return a string.
   *
   * When needing to customize a filter, it is preferred to provide this function over
   * `filterFn` if possible.
   */
  itemToString?: (item: SelectItem | null) => string;
  /**
   * Provide a custom function to use for filtering items.
   *
   * This is for the uncontrolled filter pattern. It will receive the filter and value from `itemToString`
   * and should return a boolean of whether it matches or not.
   */
  filterFn?: (filter: string, value: string) => boolean;
  /**
   * This is to control when the menu is open with custom logic. There is a particular use case in which
   * some selects are opened when mounted. Here, this boolean can be used to open the menu based on custom logic.
   */
  isOpen?: boolean;
  /**
   * This is to add placeholder text in the Select if no items are present.
   */
  placeholder?: string;
  isLoading?: boolean;
  customListItem?: { listHeight?: string; listWidth?: ListWidthType };
};

const emptyItems: SelectItem[] = [];
const defaultFilterFn = (_filter: string, _value: string) => true;

const defaultItemToString = (item: SelectItem | null): string => {
  return item ? String(item.value) : "";
};
/**
 * This component is a `Base Component` used by Selects that use useCombobox and have an input in the dropdown..
 *
 * ### Import Guide
 *
 * ```jsx
 * import { SelectWithSearch } from "ui";
 * ```
 */
export function Combobox({
  button,
  input,
  buttonRef,
  "data-autofocus": dataAutofocus,
  fullWidth = false,
  disabled,
  items = emptyItems,
  filterFn = defaultFilterFn,
  itemToString = defaultItemToString,
  enableMultiSelect = false,
  enableSelectAll = false,
  isSelectAllDisabled = false,
  labelId,
  inputId,
  darkMode,
  menuStyle = {},
  onChange = noop,
  onClose = noop,
  onSearch,
  selectedItem,
  toggleButtonId,
  dataSelector,
  defaultPlacement = "bottom-start",
  footer,
  allowClear = false,
  isOpen: willOpenOrCloseMenu = false,
  placeholder = "",
  customListItem,
  selectorRef,
  infiniteScroll,
  isLoading,
  totalCount,
}: ComboboxProps) {
  const [selectMenuStyle, setSelectMenuStyle] = useState(menuStyle);
  const selectedItems = useMemo(
    () => (selectedItem ? [selectedItem].flat() : []),
    [selectedItem],
  );
  const selectedValues = useMemo(
    () => selectedItems.map((item) => item.value),
    [selectedItems],
  );

  const [filteredSortedItems, setFilteredSortedItems] = useState(items);

  const { showSelectAll, selectAllItem, allSelected } = useSelectAll({
    enableSelectAll,
    items: filteredSortedItems,
    selectedValues,
    enableMultiSelect,
    isLoading,
    totalCount,
  });

  const normalizedItems = useMemo(() => {
    return enableSelectAll && showSelectAll
      ? [selectAllItem, ...filteredSortedItems]
      : filteredSortedItems;
  }, [enableSelectAll, filteredSortedItems, selectAllItem, showSelectAll]);

  const {
    setReference,
    setFloating: popContainerRef,
    domReferenceRef: referenceRef,
    floatingStyles,
  } = useFloating({
    placement: defaultPlacement,
    strategy: "fixed",
    middleware: [offset(4), flip(), shift({ limiter: limitShift() })],
    whileElementsMounted: autoUpdate,
  });

  const containerRef = useMemo(
    () => mergeRefs([popContainerRef, selectorRef]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const {
    closeMenu,
    isOpen,
    openMenu,
    getInputProps,
    getToggleButtonProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
  } = useCombobox({
    items: normalizedItems,
    itemToString,
    labelId,
    toggleButtonId,
    inputId,
    onInputValueChange: ({ inputValue }) => {
      let filtered = [...items];
      if (inputValue && !onSearch) {
        filtered = filtered.filter((item) => {
          return filterFn(inputValue, itemToString(item));
        });
      }
      if (enableMultiSelect)
        filtered = filtered.sort(sortBySelected(selectedValues));

      setFilteredSortedItems(filtered);
    },
    onIsOpenChange: ({ isOpen }) => {
      if (isOpen) {
        const buttonWidth = referenceRef?.current?.offsetWidth;

        /* c8 ignore next */
        if (buttonWidth && buttonWidth > listDefaultMinWidth) {
          setSelectMenuStyle({
            minWidth: `${buttonWidth}px`,
            ...menuStyle,
          });
        }
        onSearch?.("");
        setFilteredSortedItems(
          enableMultiSelect
            ? [...items].sort(sortBySelected(selectedValues))
            : items,
        );
      } else {
        onClose();
      }
    },
    onStateChange: (action) => {
      const { type } = action;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (!enableMultiSelect) {
            referenceRef.current?.focus();
          }
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
        case useCombobox.stateChangeTypes.InputBlur:
          referenceRef.current?.focus();
          break;
        default:
          break;
      }
    },
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.ToggleButtonClick:
          // Clear the input when menu opens
          return { ...changes, inputValue: "" };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            // onSelectedItemChange is only called when selectedItem changes.
            //   It is not called when selected and re-selected without selecting another item first.
            //   Add a prop so the item always changes so onSelectedItemChange will be called to
            //   update the state.
            // I believe this may be a bug in downshift 7.0.  It's worth checking on upgrades if
            // overriding selectedItem can be removed.
            selectedItem: changes?.selectedItem?.value
              ? {
                  ...(changes.selectedItem as SelectItem),
                  ensureChange: true,
                }
              : (changes.selectedItem as SelectItem),
            inputValue: state.inputValue, // Don't reset the inputValue to the value of the selected item, which is what it is by default.
            isOpen: enableMultiSelect, // keep menu open after selection.
          };
        default:
          // remove active state from Select/Deselect All except key down events
          if (
            enableSelectAll &&
            changes.highlightedIndex === 0 &&
            type !== useCombobox.stateChangeTypes.InputKeyDownArrowDown &&
            type !== useCombobox.stateChangeTypes.InputKeyDownArrowUp
          ) {
            return {
              ...changes,
              highlightedIndex: -1,
            };
          }

          return changes;
      }
    },
    onSelectedItemChange: (changes) => {
      // This can happen when a footer is clicked
      if (changes.selectedItem?.value === undefined) return;

      onSelectedItemChange({
        changes,
        enableMultiSelect,
        selectedItems,
        onChange,
        selectedValues,
        selectAllItem,
        allSelected,
        itemsForSelectAll: filteredSortedItems,
      });
    },
  });

  const previousItemCount = usePrevious(items.length);

  useEffect(() => {
    willOpenOrCloseMenu ? openMenu() : closeMenu();
  }, [closeMenu, openMenu, willOpenOrCloseMenu]);

  // This is for when items come back from the BE when the menu is already open
  useEffect(() => {
    if (isOpen && items.length !== previousItemCount) {
      setFilteredSortedItems(
        enableMultiSelect
          ? [...items].sort(sortBySelected(selectedValues))
          : items,
      );
    }
  }, [enableMultiSelect, isOpen, items, previousItemCount, selectedValues]);

  const triggerRef = useMemo(
    () => (buttonRef ? mergeRefs([buttonRef, setReference]) : setReference),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const trigger = useMemo(() => {
    const addProps = isDomElement(button?.type.toString()) ? {} : { isOpen }; // This allows the flippy caret to work when a button is passed in.
    const toggleButtonProps = getToggleButtonProps({
      ...addProps,
      ...(button?.props ?? {}),
      // downshift default tab index to -1 to remove the toggle button from the tab order
      // because it's expected that the input is next to the button, not inside the menu.
      // Our design has the input inside the menu.
      // Therefore, it requires overriding tabIndex so it's not skipped when tabbing.
      tabIndex: 0,
      ref: triggerRef,
      disabled,
    });
    const DefaultButton = (
      <Button
        data-autofocus={dataAutofocus}
        data-testid="select-trigger"
        fullWidth={fullWidth}
        isMenu
        isOpen={isOpen}
      >
        {!selectedItem || !selectedItems.length
          ? placeholder
          : selectedItemLabel(selectedItem)}
      </Button>
    );

    return cloneElement(button || DefaultButton, {
      ...toggleButtonProps,
      // useCombobox.getToggleButtonProps does not provide "aria-labelledby" as useCombobox is designed to be used how
      // we have implemented ChipInput.  Adding "aria-labelledby" with the value useSelect.getToggleButtonProps returns.
      "aria-labelledby": `${labelId ?? ""} ${toggleButtonProps.id}`,
    });
  }, [
    button,
    dataAutofocus,
    disabled,
    fullWidth,
    getToggleButtonProps,
    isOpen,
    labelId,
    placeholder,
    selectedItem,
    selectedItems.length,
    triggerRef,
  ]);

  const handleSelectAll = useCallback(
    (action: string) => {
      onChange?.(
        getSelectAll({
          action,
          selectAllItem,
          selectedItems,
          items: filteredSortedItems,
          selectedValues,
        }),
      );
    },
    [
      filteredSortedItems,
      onChange,
      selectAllItem,
      selectedItems,
      selectedValues,
    ],
  );

  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <div className={classNames(darkMode && "dark")}>
      {trigger}
      <Portal>
        <div
          ref={containerRef}
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className={classNames("z-30", darkMode && "dark")}
          style={floatingStyles}
        >
          <SelectList
            isOpen={isOpen}
            menuStyle={selectMenuStyle}
            getMenuProps={getMenuProps}
            handleSelectAll={handleSelectAll}
            items={normalizedItems}
            highlightedIndex={highlightedIndex}
            selectedItems={selectedItems}
            selectAllItem={selectAllItem}
            enableMultiSelect={enableMultiSelect}
            getItemProps={getItemProps}
            showSelectAll={showSelectAll}
            allSelected={allSelected}
            clearSelection={() => {
              onChange?.({
                selectedItem: undefined,
                selectedItems: [],
              });
            }}
            allowClear={allowClear}
            search={input({ getInputProps, closeMenu, highlightedIndex })}
            selectedValues={selectedValues}
            dataSelector={dataSelector}
            footer={footer}
            customListItem={customListItem}
            infiniteScroll={infiniteScroll}
            isSelectAllDisabled={isSelectAllDisabled}
            isLoading={isLoading}
          />
        </div>
      </Portal>
    </div>
  );
}
