import { type ReactNode } from "react";
import { defineMessage } from "react-intl";
import { statusHeaderMap } from "../../../../../messages/statusHeaderMessages";

import { DynamicFormattedMessage, TruncateText } from "ui";
import { classNames, snakeCase } from "utils";

type DetailsProps = {
  isAccountDetails?: boolean;
  children?: ReactNode;
  className?: string;
  description?: ReactNode;
  icon?: ReactNode;
  label: string;
  normalFont?: boolean;
};

export default function Details({
  isAccountDetails = false,
  children,
  className = "mt-5",
  description,
  icon,
  label,
  normalFont = false,
}: DetailsProps) {
  const fallback = defineMessage({
    defaultMessage: "Status not found",
    id: "m6fYMN",
    description: "Status not found message as fallback",
  });
  return (
    <>
      <div
        className={classNames(
          "flex min-h-[1.25rem] items-center space-x-4 text-xs first:mt-0",
          className,
        )}
        data-testid="details-item"
      >
        <div
          className={classNames(
            "flex w-[35%] items-center space-x-2 break-all px-4",
            !icon && !isAccountDetails && "pl-14",
          )}
        >
          {icon}
          <TruncateText>
            <span
              className={classNames(
                "grow text-left",
                normalFont ? "font-normal" : "font-bold",
              )}
            >
              <DynamicFormattedMessage
                fallback={fallback}
                messageMap={statusHeaderMap}
                token={snakeCase(label)}
              />
            </span>
          </TruncateText>
        </div>
        <div className="flex items-center">{children}</div>
      </div>

      {description ? description : null}
    </>
  );
}
