import { useEffect, useMemo, useState } from "react";
import { get, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import { useMutation } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEmpty } from "remeda";
import { type SecurityCapabilitiesType } from "requests";
import { useToastActions } from "stores";
import { regexes } from "utils";
import { useWizardContext } from "../../../../../components/Wizard";
import {
  ACCOUNT_ID,
  ACCOUNT_NAME,
  ACCOUNT_TYPE,
  ADD_DETAILS,
  AGENTLESS_SCAN,
  AWS,
  AWS_PARTITION,
  FEATURES,
  REMEDIATION,
} from "../../../../constants";
import { useGetFeaturesList } from "../../../../hooks";
import { type AwsAddDetailsStepValues } from "../../../../types";
import { parseErrorsFromResponse } from "../../../../utils";
import {
  downloadManagementRoleCft,
  downloadMemberRoleCft,
} from "../../../Onboarding/AwsOnboarding/ConfigureAccount/ManagementOrMemberIAMRoleCft/utils";
import { getWizardStepIndex } from "../../../Onboarding/utils";
import ConfigHelpers from "./ConfigHelpers";

type ManagementOrMemberIAMRoleCftProps = {
  closeModal: () => void;
  setCftDownloadedOrCreated: (val: boolean) => void;
  disabled: boolean;
};

export default function ManagementOrMemberIAMRoleCft({
  closeModal,
  setCftDownloadedOrCreated,
  disabled,
}: ManagementOrMemberIAMRoleCftProps) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const {
    state: { steps },
  } = useWizardContext();
  const { cloudScanMode } = useFlags();
  const { setValue, watch } = useFormContext();
  const getStepIndex = getWizardStepIndex(steps);
  const addDetailsStepIndex = getStepIndex(ADD_DETAILS);

  const [securityCapabilities, mode, accountId] = watch([
    "securityCapabilities",
    "mode",
    "accountId",
  ]);

  const {
    accountType = "account",
    partitionType: awsPartition,
    name: accountName,
    onboardType,
  } = steps[addDetailsStepIndex]?.values as AwsAddDetailsStepValues;

  const { supportedFeaturesList } = useGetFeaturesList({
    cloudType: AWS,
    payload: {
      accountType,
      awsPartition,
    },
  });

  const features = Object.keys(securityCapabilities)
    .filter((key) => securityCapabilities[key as SecurityCapabilitiesType])
    .filter((feature) =>
      supportedFeaturesList.includes(
        feature as Exclude<
          SecurityCapabilitiesType,
          "Cloud Visibility Compliance and Governance"
        >,
      ),
    );

  const remediation = securityCapabilities[REMEDIATION];
  const useTenantExternalId = watch("useTenantExternalId");
  const [isMemberCftDownloaded, setMemberCftDownloaded] = useState(false);
  const [isManagementCftDownloaded, setManagementCftDownloaded] =
    useState(false);

  const featuresList = remediation ? [...features, REMEDIATION] : features;

  const featureWithModes = featuresList.map((feature) => {
    if (feature === AGENTLESS_SCAN) {
      return {
        feature,
        mode,
      };
    }
    return { feature };
  });

  const isAutomatedOnboardType = onboardType === "automated";

  const payload = {
    ...(isAutomatedOnboardType
      ? { [ACCOUNT_NAME]: accountName }
      : { [ACCOUNT_ID]: accountId }),
    [ACCOUNT_TYPE]: accountType,
    [AWS_PARTITION]: awsPartition,
    [FEATURES]: featuresList,
    ...(cloudScanMode && { featureWithModes }),
    customMemberRoleNameEnabled: true,
    useTenantExternalId,
    ...(isAutomatedOnboardType && { automatedFlow: true }),
  };

  useEffect(() => {
    if (isMemberCftDownloaded && isManagementCftDownloaded) {
      setCftDownloadedOrCreated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMemberCftDownloaded, isManagementCftDownloaded]);

  disabled = useMemo(
    () =>
      onboardType === "automated"
        ? disabled
        : isEmpty(accountId)
          ? true
          : !regexes.awsAccountId.test(accountId),
    [accountId, disabled, onboardType],
  );

  const {
    mutateAsync: downloadMemberIAMRoleCft,
    isPending: isDownloadingMemberCft,
  } = useMutation({
    mutationFn: () =>
      downloadMemberRoleCft({ ...payload, cftType: "org_member" }),
    onSuccess: (response) => {
      const externalId = get(response, "external-id", "");
      setValue("externalId", externalId);
      setMemberCftDownloaded(true);
    },
    onError: (error) => {
      toast(parseErrorsFromResponse(intl, error), {
        appearance: "error",
      });
      closeModal();
    },
  });

  const {
    mutateAsync: downloadManagementIAMRoleCft,
    isPending: isDownloadingManagementCft,
  } = useMutation({
    mutationFn: () =>
      downloadManagementRoleCft({ ...payload, cftType: "org_management" }),
    onSuccess: (_response) => {
      setManagementCftDownloaded(true);
    },
    onError: (error) => {
      toast(parseErrorsFromResponse(intl, error), {
        appearance: "error",
      });
      closeModal();
    },
  });

  return (
    <ConfigHelpers
      downloadMemberIAMRoleCft={downloadMemberIAMRoleCft}
      downloadManagementIAMRoleCft={downloadManagementIAMRoleCft}
      isDownloadingMemberCft={isDownloadingMemberCft}
      isDownloadingManagementCft={isDownloadingManagementCft}
      isMemberCftDownloaded={isMemberCftDownloaded}
      isManagementCftDownloaded={isManagementCftDownloaded}
      closeModal={closeModal}
      disabled={disabled}
    />
  );
}
