import {
  CloudAccountNameResponseSchema,
  CloudAccountNameWithOtherResponseSchema,
} from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import { type CloudType } from "../cloudType";

export type CloudAccountNameKeysProps = {
  onlyActive?: "true" | "false";
  includeOthers?: "true" | "false";
  cloudType?: CloudType;
  accountGroupIds?: string[];
  includeNonOnboardedAccounts?: "true" | "false";
};

export const cloudAccountNameKeys = {
  all: [{ scope: "cloudAccountNameAll" }] as const,
  cloudAccountNames: (params?: CloudAccountNameKeysProps) => {
    const { accountGroupIds, ...rest } = params ?? {};

    return [
      {
        ...cloudAccountNameKeys.all[0],
        entity: "cloudAccountName",
        params: {
          ...rest,
          ...(accountGroupIds?.length && {
            accountGroupIds: accountGroupIds?.join(","),
          }),
        } as Omit<CloudAccountNameKeysProps, "accountGroupIds"> & {
          accountGroupIds?: string;
        },
      },
    ] as const;
  },
} as const;

export const getCloudAccountNames = async ({
  queryKey: [{ params }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountNameKeys)["cloudAccountNames"]>
>) => {
  return jsonApi({
    path: "cloud/name",
    params,
    requestSchema: z.void(),
    responseSchema:
      params?.includeOthers === "true"
        ? CloudAccountNameWithOtherResponseSchema
        : CloudAccountNameResponseSchema,
  });
};
