import { z } from "zod";

export const applicationBusinessCriticalities = [
  "High",
  "Medium",
  "Low",
  "Undefined",
] as const;
export type ApplicationBusinessCriticality =
  (typeof applicationBusinessCriticalities)[number];

export const ApplicationBusinessCriticalityEnumSchema = z.enum(
  applicationBusinessCriticalities,
);

export const applicationEnvironment = [
  "Staging",
  "Production",
  "Development",
  "Undefined",
] as const;
export type ApplicationEnvironment = (typeof applicationEnvironment)[number];

export const ApplicationEnvironmentEnumSchema = z.enum(applicationEnvironment);

export const ApplicationsDefinitionRequestSchema = z.object({
  reviewed: z.string().optional(),
});

const ApplicationsDefinitionMetadata = z.object({
  owner: z.array(z.string()),
  reviewed: z.array(z.string()),
  displayName: z.array(z.string()),
  environment: z.array(ApplicationEnvironmentEnumSchema),
  businessCriticality: z.array(ApplicationBusinessCriticalityEnumSchema),
  clusterAssetId: z.array(z.string()),
  namespace: z.array(z.string()),
  type: z.array(z.string()),
});

export type ApplicationsDefinitionMetadataType = z.infer<
  typeof ApplicationsDefinitionMetadata
>;

export const ApplicationDefinition = z.object({
  operands: z.array(
    z.object({
      nodeType: z.string(),
      operation: z.string(),
      key: z.string(),
      values: z.array(z.string()),
    }),
  ),
  nodeType: z.string(),
  operation: z.string(),
});

export type ApplicationsDefinitionType = z.infer<typeof ApplicationDefinition>;

export const ApplicationsDefinitionWithMetadataResponseSchema = z.object({
  id: z.string(),
  metadata: ApplicationsDefinitionMetadata,
  definition: ApplicationDefinition,
  clusterId: z.string(),
  lastSeen: z.number(),
});

export type ApplicationsDefinitionWithMetadata = z.infer<
  typeof ApplicationsDefinitionWithMetadataResponseSchema
>;

export const ApplicationsDefinitionResponseSchema = z.object({
  value: z.array(ApplicationsDefinitionWithMetadataResponseSchema),
  nextPageToken: z.any(),
});

const ReviewApplicationsDefinitionMetadataSchema = z.object({
  displayName: z.array(z.string()).or(z.string()),
  owner: z.array(z.string()).or(z.string()),
  businessCriticality: z.array(z.string()).or(z.string()),
  environment: z.array(z.string()).or(z.string()),
});

export type ReviewApplicationsDefinitionMetadataType = z.infer<
  typeof ReviewApplicationsDefinitionMetadataSchema
>;

export const ReviewApplicationsDefinitionRequestSchema = z.object({
  ids: z.array(z.string()),
  metadata: ReviewApplicationsDefinitionMetadataSchema,
});
