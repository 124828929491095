import { DropdownIcon, LoadingIcon } from "icons";
import {
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  type ButtonHTMLAttributes,
  type ElementType,
  type ReactNode,
} from "react";

import { classNames } from "utils";
import { mergeRefs } from "../../utils";
import isIconOnly from "./isIconOnly";
import validateProps from "./validateProps";

export type ButtonAppearances =
  | "primary"
  | "secondary"
  | "danger"
  | "clear"
  | "tertiary"
  | "tertiary-clear";

export const buttonSizes = {
  lg: "text-base h-10",
  md: "text-xs h-8",
  sm: "text-xs h-6",
};

export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className"> {
  /**
   * Add a className to the button
   */
  addClassName?: string;
  /**
   * Defines the appearance of the button
   */
  appearance?: ButtonAppearances;
  /**
   * Whether or not the text should be centered. Typically with a `fullWidth` button.
   */
  centerText?: boolean;
  /**
   * Whether or not this button is disabled
   */
  disabled?: boolean;
  /**
   * When true, the the button will receive focus when it mounts.
   *
   * This is different from autoFocus in that autoFocus set the focus when
   * a page renders, which may be before the button is mounted.
   */
  focusOnMount?: boolean;
  /**
   * The button should expand to fill the width of its container
   */
  fullWidth?: boolean;
  /**
   * An icon to display on the left side of the button
   */
  icon?: ReactNode;
  /**
   * Loading indicator
   */
  isLoading?: boolean;
  /**
   * If true, renders a menu icon on the right side of the button
   */
  isMenu?: boolean;
  /**
   * The button is in selected state (or not)
   */
  isSelected?: boolean;
  /**
   * Use with `isMenu`, determines the direction of the menu item
   */
  isOpen?: boolean;
  /**
   * The size of the button
   */
  size?: keyof typeof buttonSizes;
  /**
   * Whether or not to truncate internal text
   */
  truncate?: boolean;
  /**
   * Only for buttons wrapped by `ButtonGroup` component. Used for CSS styling of the border of each button in a group.
   * `"left"` is for the left most button, `"right"` is for the right most button,
   * any button in the middle should be `"middle"`.
   */
  buttonGroupPosition?: "left" | "middle" | "right";
  /**
   * The type of element to render.
   *
   * By default, `Button` renders a `button` element, but any element type can be used.
   * This can be helpful if you would like to use the Button's styles, but need to
   * render a different element to be semantically appropriate.
   */
  as?: ElementType;
}

function makeClassName(props: ButtonProps): string {
  const {
    addClassName,
    disabled,
    fullWidth,
    isLoading,
    isSelected,
    size = "md",
    appearance = "secondary",
    buttonGroupPosition,
  } = props;
  const iconOnly = isIconOnly(props);

  const defaultClassNames =
    "box-border rounded focus:outline-none focus-visible:ring flex items-center relative max-w-full";

  const sizeClassName = buttonSizes[size];

  const spacingClassName = {
    lg: iconOnly ? "py-3 px-4" : "py-3 px-2",
    md: iconOnly ? "p-2" : "py-2 px-2",
    sm: iconOnly ? "p-1" : "py-1 px-2",
  }[size];

  const buttonGroupPositionClassName = classNames(
    buttonGroupPosition && isSelected && "z-10",
    buttonGroupPosition === "left"
      ? "rounded-r-none hover:z-20 focus:z-30"
      : buttonGroupPosition === "middle"
        ? "-ml-px rounded-none hover:z-20 focus:z-30"
        : buttonGroupPosition === "right" &&
          "-ml-px rounded-l-none hover:z-20 focus:z-30",
  );

  const typeClassName: Record<ButtonAppearances, string> = {
    primary: classNames(
      "text-dark-bg",
      disabled
        ? "bg-blue-600 opacity-40 dark:bg-blue-400"
        : isLoading
          ? "bg-blue-600 dark:bg-blue-400"
          : isSelected
            ? "bg-blue-800 hover:bg-blue-700 active:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-500 dark:active:bg-blue-600"
            : "bg-blue-600 hover:bg-blue-700 active:bg-blue-800 dark:bg-blue-400 dark:hover:bg-blue-500 dark:active:bg-blue-600",
    ),
    secondary: classNames(
      "border border-gray-300 text-link",
      "dark:border-blue-steel-850 dark:text-dark-bg-link",
      disabled
        ? "opacity-40"
        : !isLoading &&
            "hover:border-blue-700 hover:bg-blue-100 active:bg-blue-200 dark:hover:bg-blue-steel-850 dark:active:bg-blue-steel-830",
      isSelected
        ? "border-blue-800 bg-blue-200 dark:bg-blue-steel-830"
        : "bg-white dark:bg-transparent",
    ),
    clear: classNames(
      "group text-link dark:text-dark-bg-link",
      disabled
        ? "opacity-40"
        : !isLoading &&
            "hover:bg-blue-100 active:bg-blue-200 dark:hover:bg-blue-steel-850 dark:active:bg-blue-steel-830",
      isSelected && "bg-blue-200 dark:bg-blue-steel-830",
    ),
    danger: classNames(
      "border border-gray-300 text-red",
      "dark:border-blue-steel-850 dark:text-dark-bg-red",
      disabled
        ? "opacity-40"
        : !isLoading &&
            "hover:border-red-700 hover:bg-red-100 active:border-red active:bg-red-200 dark:hover:border-red-700 dark:hover:bg-red-400/[.16] dark:active:border-red-800 dark:active:bg-red-400/[.24]",
      isSelected
        ? "border-red-700 bg-red-100 dark:border-red-800 dark:bg-red-400/[.24]"
        : "bg-white dark:bg-transparent",
    ),
    tertiary: classNames(
      "border text-default",
      "dark:border-blue-steel-850 dark:text-dark-bg",
      disabled
        ? "border-gray-300 opacity-40"
        : isLoading
          ? "border-gray-300"
          : "border-gray-300 hover:border-gray-800 hover:bg-gray-100 active:border-gray-900 active:bg-gray-200 dark:hover:bg-blue-steel-850 dark:active:bg-blue-steel-830",
      isSelected
        ? "border-gray-900 bg-gray-300 dark:bg-blue-steel-830"
        : "bg-white dark:bg-blue-steel-950 ",
    ),
    "tertiary-clear": classNames(
      "text-default",
      "dark:border-blue-steel-850 dark:text-dark-bg",
      disabled
        ? "opacity-40"
        : !isLoading &&
            "hover:bg-gray-100 active:bg-gray-200 dark:hover:bg-blue-steel-850 dark:active:bg-blue-steel-830",
      isSelected && "bg-gray-300 dark:bg-blue-steel-830",
    ),
  };

  return classNames(
    addClassName,
    defaultClassNames,
    fullWidth ? "w-full flex-1" : null,
    sizeClassName,
    spacingClassName,
    isLoading
      ? "cursor-wait"
      : disabled
        ? "cursor-not-allowed"
        : "cursor-pointer",
    typeClassName[appearance],
    buttonGroupPositionClassName,
  );
}

/**
 * Lets users trigger an action. All extra props will be passed to the button element.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Button } from "ui";
 * ```
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, userRef) => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
      addClassName,
      appearance = "secondary",
      centerText,
      focusOnMount,
      fullWidth,
      children,
      disabled,
      icon,
      isLoading,
      isMenu = false,
      isOpen,
      isSelected,
      size = "md",
      truncate = true,
      buttonGroupPosition,
      as: Component = "button",
      ...rest
    } = props;
    /* eslint-enable @typescript-eslint/no-unused-vars */

    validateProps(props);
    const className = makeClassName(props);

    const ariaProps = isMenu ? { ["aria-expanded"]: isOpen } : {};
    const buttonRef = useRef<HTMLButtonElement>(null);
    const ref = useMemo(() => mergeRefs([buttonRef, userRef]), [userRef]);

    useEffect(() => {
      if (focusOnMount) buttonRef.current?.focus();
    }, [focusOnMount]);

    return (
      <Component
        ref={ref}
        type="button"
        {...ariaProps}
        {...rest}
        disabled={disabled || isLoading}
        className={className}
      >
        <span
          className={classNames(
            // min-w-0 is necessary for truncate to work.
            "flex min-w-0 flex-1 items-center",
            centerText ? "justify-center" : "",
          )}
        >
          {icon && (
            <span className={isIconOnly(props) ? "" : "mr-1"}>
              {isLoading ? <LoadingIcon /> : icon}
            </span>
          )}
          <span
            className={classNames(
              truncate && "truncate",
              isLoading && !icon && "invisible",
            )}
          >
            {children}
          </span>
        </span>

        {isLoading && !icon && (
          <span className="absolute right-0 top-0 flex h-full w-full items-center justify-center">
            <LoadingIcon />
          </span>
        )}
        {isMenu && (
          <span className="ml-1">
            <DropdownIcon isOpen={isOpen} />
          </span>
        )}
      </Component>
    );
  },
);

Button.displayName = "Button";
