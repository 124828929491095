import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const AwsLambdaIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "AWS Lambda",
    id: "opxZAb",

    description: "AWS Lambda icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M86.8247 90H67.1127C66.8111 89.9991 66.5159 89.9131 66.2611 89.7517C66.0064 89.5904 65.8024 89.3603 65.6727 89.088L37.8007 30.8H26.0087C25.5843 30.8 25.1774 30.6314 24.8773 30.3314C24.5773 30.0313 24.4087 29.6243 24.4087 29.2V11.6C24.4087 11.1757 24.5773 10.7687 24.8773 10.4686C25.1774 10.1686 25.5843 10 26.0087 10H50.6487C50.9503 10.0009 51.2455 10.0869 51.5002 10.2483C51.755 10.4096 51.959 10.6397 52.0887 10.912L79.8327 69.2H86.8247C87.249 69.2 87.656 69.3686 87.9561 69.6686C88.2561 69.9687 88.4247 70.3757 88.4247 70.8V88.4C88.4247 88.8243 88.2561 89.2313 87.9561 89.5314C87.656 89.8314 87.249 90 86.8247 90ZM68.1207 86.8H85.2247V72.4H78.8247C78.5231 72.3991 78.2279 72.3131 77.9731 72.1517C77.7184 71.9904 77.5144 71.7603 77.3847 71.488L49.6407 13.2H27.6247V27.6H38.8247C39.1263 27.6009 39.4215 27.6869 39.6762 27.8483C39.931 28.0096 40.135 28.2397 40.2647 28.512L68.1207 86.8Z"
        className="fill-[url(#paint0_linear_6619_102010)] dark:fill-white"
      />
      <path
        d="M33.9923 89.9998H13.1923C12.9204 90.0003 12.6529 89.9316 12.4149 89.8C12.177 89.6684 11.9765 89.4784 11.8323 89.2478C11.6796 89.0117 11.5917 88.7394 11.5777 88.4585C11.5636 88.1776 11.6239 87.898 11.7523 87.6478L33.5123 42.1918C33.642 41.9195 33.846 41.6894 34.1008 41.5281C34.3556 41.3667 34.6508 41.2806 34.9523 41.2798C35.2522 41.2792 35.5462 41.3629 35.8008 41.5213C36.0554 41.6797 36.2604 41.9065 36.3923 42.1758L46.8083 63.5998C46.9132 63.8168 46.9677 64.0548 46.9677 64.2958C46.9677 64.5368 46.9132 64.7747 46.8083 64.9918L35.4483 88.9918C35.3318 89.2845 35.1315 89.5363 34.8725 89.7156C34.6135 89.8949 34.3073 89.9938 33.9923 89.9998ZM15.7523 86.7998H32.9843L43.5923 64.3998L34.9683 46.5598L15.7523 86.7998Z"
        className="fill-[url(#paint1_linear_6619_102010)] dark:fill-white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6619_102010"
          x1={24.4087}
          y1={10}
          x2={102.462}
          y2={72.4581}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6619_102010"
          x1={11.5757}
          y1={41.2798}
          x2={57.9091}
          y2={74.938}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
