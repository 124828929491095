import { type OperationName } from "prisma";

import { defineMessages, type MessageDescriptor } from "react-intl";

export const operationNames: Record<OperationName, MessageDescriptor> =
  defineMessages({
    READ: {
      defaultMessage: "View",
      id: "n/SqSK",
      description: "Permission group operation view",
    },
    CREATE: {
      defaultMessage: "Create",
      id: "bDlEv7",
      description: "Permission group operation view",
    },
    UPDATE: {
      defaultMessage: "Update",
      id: "5a2ztl",
      description: "Permission group operation view",
    },
    DELETE: {
      defaultMessage: "Delete",
      id: "NV9CSp",
      description: "Permission group operation view",
    },
  });

export const featureNameMap = defineMessages({
  dataSecurityPostureManagement: {
    defaultMessage: "Data Security Posture Management",
    id: "z4XvAN",
    description: "Permission group feature name",
  },
  dlpDataProfile: {
    defaultMessage: "Data Security Profile",
    id: "eeCTnd",
    description: "Permission group feature name",
  },
  dlpDataPattern: {
    defaultMessage: "Data Security Patterns",
    id: "OBsPuH",
    description: "Permission group feature name",
  },
  dlpSnippet: {
    defaultMessage: "Data Security Snippet Masking",
    id: "CGjlpR",
    description: "Permission group feature name",
  },
  dlpResource: {
    defaultMessage: "Data Security Resource",
    id: "SqFqo/",
    description: "Permission group feature name",
  },
  dlpDataDashboard: {
    defaultMessage: "Data Security Dashboard",
    id: "2V6fDX",
    description: "Permission group feature name",
  },
  dlpDataInventory: {
    defaultMessage: "Data Security Inventory",
    id: "/FXG+a",
    description: "Permission group feature name",
  },
  settingsEnterprise: {
    defaultMessage: "Enterprise Settings",
    id: "11W/sU",
    description: "Permission group feature name",
  },
  settingsLoginIpAddresses: {
    defaultMessage: "Trusted Login IP Addresses",
    id: "60pa/s",
    description: "Permission group feature name",
  },
  settingsAlertIpAddresses: {
    defaultMessage: "Trusted Alert IP Addresses",
    id: "1HnQHY",
    description: "Permission group feature name",
  },
  settingsAccessKeys: {
    defaultMessage: "Access Keys",
    id: "zyWlan",
    description: "Permission group feature name",
  },
  settingsUserRole: {
    defaultMessage: "Roles",
    id: "mwLbyT",
    description: "Permission group feature name",
  },
  settingsUsers: {
    defaultMessage: "Users",
    id: "N/ZnN7",
    description: "Permission group feature name",
  },
  settingsSSO: {
    defaultMessage: "SSO",
    id: "c0XB9G",
    description: "Permission group feature name",
  },
  settingsAuditLogs: {
    defaultMessage: "Audit Logs",
    id: "GkOtbc",
    description: "Permission group feature name",
  },
  settingsAccountGroup: {
    defaultMessage: "Account Group",
    id: "7rzldi",
    description: "Permission group feature name",
  },
  settingsAnomalyTrustedList: {
    defaultMessage: "Anomaly Trusted List",
    id: "ICvZMw",
    description: "Permission group feature name",
  },
  settingsAnomalyThreshold: {
    defaultMessage: "Anomaly Threshold",
    id: "cdN4pA",
    description: "Permission group feature name",
  },
  settingsResourceList: {
    defaultMessage: "Resource List",
    id: "mOjj5V",
    description: "Permission group feature name",
  },
  settingsCloudAccounts: {
    defaultMessage: "Cloud Accounts",
    id: "FJOeXs",
    description: "Permission group feature name",
  },
  settingsLicensing: {
    defaultMessage: "Licensing",
    id: "82d/7f",
    description: "Permission group feature name",
  },
  settingsIntegrations: {
    defaultMessage: "Integrations",
    id: "qkjHMn",
    description: "Permission group feature name",
  },
  settingsRepositories: {
    defaultMessage: "Providers",
    id: "z05D3O",
    description: "Permission group feature name",
  },
  settingsCodeSecurity: {
    defaultMessage: "Application Security",
    id: "kCDmLm",
    description: "Permission group feature name",
  },
  computeRadarsCloud: {
    defaultMessage: "Cloud Radar",
    id: "KbAN/R",
    description: "Permission group feature name",
  },
  computeRadarsHosts: {
    defaultMessage: "Hosts Radar",
    id: "gF6+W6",
    description: "Permission group feature name",
  },
  computeRadarsContainers: {
    defaultMessage: "Containers Radar",
    id: "TURaDX",
    description: "Permission group feature name",
  },
  computeRadarsServerless: {
    defaultMessage: "Serverless Radar",
    id: "nhktMj",
    description: "Permission group feature name",
  },
  computePolicyCodeRepos: {
    defaultMessage: "Code Repositories Vulnerability Policies",
    id: "PR+Abs",
    description: "Permission group feature name",
  },
  computePolicyContainers: {
    defaultMessage: "Images/Containers Vulnerabilities & Compliance Policies",
    id: "zt7SPZ",
    description: "Permission group feature name",
  },
  computePolicyHosts: {
    defaultMessage: "Host Vulnerabilities & Compliance Policies",
    id: "yqgubm",
    description: "Permission group feature name",
  },
  computePolicyServerless: {
    defaultMessage: "Serverless & App-Embedded Runtime Policies",
    id: "1QlGZl",
    description: "Permission group feature name",
  },
  computePolicyComplianceCustomRules: {
    defaultMessage: "Custom Compliance Policies",
    id: "xKZkJs",
    description: "Permission group feature name",
  },
  computePolicyRuntimeContainer: {
    defaultMessage: "Container Runtime Policies",
    id: "SHUBG8",
    description: "Permission group feature name",
  },
  computePolicyRuntimeHosts: {
    defaultMessage: "Host Runtime Policies",
    id: "TR6J1K",
    description: "Permission group feature name",
  },
  computePolicyRuntimeServerless: {
    defaultMessage: "Serverless & App-Embedded Runtime Policies",
    id: "1QlGZl",
    description: "Permission group feature name",
  },
  computePolicyWAAS: {
    defaultMessage: "WAAS Policies",
    id: "Ii6P8O",
    description: "Permission group feature name",
  },
  computePolicyCNNF: {
    defaultMessage: "CNNF Policies",
    id: "51+MWs",
    description: "Permission group feature name",
  },
  computePolicyAccessDocker: {
    defaultMessage: "Docker Policies",
    id: "L3gPA1",
    description: "Permission group feature name",
  },
  computePolicyAccessSecrets: {
    defaultMessage: "Secrets Policies",
    id: "EpHVTS",
    description: "Permission group feature name",
  },
  computePolicyAccessKubernetes: {
    defaultMessage: "Kubernetes & Admissions Policies",
    id: "2KHvoT",
    description: "Permission group feature name",
  },
  computePolicyCustomRules: {
    defaultMessage: "Custom Rules",
    id: "j4h6rY",
    description: "Permission group feature name",
  },
  computeMonitorVuln: {
    defaultMessage: "Vulnerabilities Dashboard",
    id: "/Qy5U3",
    description: "Permission group feature name",
  },
  computeMonitorCompliance: {
    defaultMessage: "Compliance Dashboard",
    id: "ddwrGO",
    description: "Permission group feature name",
  },
  computeMonitorCodeRepos: {
    defaultMessage: "Code Repositories Vulnerabilities & Compliance Results",
    id: "yuYvFn",
    description: "Permission group feature name",
  },
  computeMonitorImages: {
    defaultMessage: "Images/Containers Vulnerabilities & Compliance Results",
    id: "rlcRbw",
    description: "Permission group feature name",
  },
  computeMonitorHosts: {
    defaultMessage: "Hosts Vulnerabilities & Compliance Results",
    id: "i3fSRD",
    description: "Permission group feature name",
  },
  computeMonitorServerless: {
    defaultMessage:
      "Serverless & App-Embedded Vulnerabilities & Compliance Results",
    id: "Vh202R",
    description: "Permission group feature name",
  },
  computeMonitorCI: {
    defaultMessage: "CI Results",
    id: "/nRcpW",
    description: "Permission group feature name",
  },
  computeMonitorRuntimeContainers: {
    defaultMessage: "Container Runtime Results",
    id: "DgRwHD",
    description: "Permission group feature name",
  },
  computeMonitorRuntimeHosts: {
    defaultMessage: "Host Runtime Results",
    id: "mkVEqN",
    description: "Permission group feature name",
  },
  computeMonitorRuntimeServerless: {
    defaultMessage: "Serverless & App-Embedded Runtime Results",
    id: "dCroZr",
    description: "Permission group feature name",
  },
  computeMonitorRuntimeIncidents: {
    defaultMessage: "Runtime Dashboards",
    id: "TtWmnj",
    description: "Permission group feature name",
  },
  computeSandbox: {
    defaultMessage: "Image Analysis Sandbox",
    id: "6/uDUO",
    description: "Permission group feature name",
  },
  computeMonitorWAAS: {
    defaultMessage: "WAAS Events",
    id: "o6u5ZH",
    description: "Permission group feature name",
  },
  computeMonitorCNNF: {
    defaultMessage: "CNNF Runtime Results",
    id: "uUp+x9",
    description: "Permission group feature name",
  },
  computeMonitorAccessDocker: {
    defaultMessage: "Docker Runtime Results",
    id: "58H+0l",
    description: "Permission group feature name",
  },
  computeMonitorAccessKubernetes: {
    defaultMessage: "Kubernetes & Admission Runtime Results",
    id: "7EVZ0J",
    description: "Permission group feature name",
  },
  computeUIEventSubscriber: {
    defaultMessage: "Data Updates Pushed to Client Browsers",
    id: "1YZ6PR",
    description: "Permission group feature name",
  },
  computePolicyCloud: {
    defaultMessage: "Cloud Account Policy",
    id: "YjasNT",
    description: "Permission group feature name",
  },
  computeMonitorCloud: {
    defaultMessage: "Cloud Discovery Results",
    id: "2Bsin/",
    description: "Permission group feature name",
  },
  computeSystemLogs: {
    defaultMessage: "Logs",
    id: "b3ViGQ",
    description: "Permission group feature name",
  },
  computeManageDefenders: {
    defaultMessage: "Defenders Management",
    id: "E6/NC4",
    description: "Permission group feature name",
  },
  computeManageAlerts: {
    defaultMessage: "Alerts",
    id: "Q5O7Xw",
    description: "Permission group feature name",
  },
  computeCollections: {
    defaultMessage: "Collections and Tags",
    id: "ZH2jBM",
    description: "Permission group feature name",
  },
  computeManageCreds: {
    defaultMessage: "Credentials Store",
    id: "+VBE70",
    description: "Permission group feature name",
  },
  computeAuth: {
    defaultMessage: "Authentication",
    id: "ssU1Xk",
    description: "Permission group feature name",
  },
  computeSystemOperations: {
    defaultMessage: "System",
    id: "aN+QzC",
    description: "Permission group feature name",
  },
  computePrivilegedOperations: {
    defaultMessage: "System Privileged",
    id: "5T750c",
    description: "Permission group feature name",
  },
  computeDownloads: {
    defaultMessage: "Utilities",
    id: "clh22+",
    description: "Permission group feature name",
  },
  investigateAssetRql: {
    defaultMessage: "Asset",
    id: "GoeSCU",
    description: "Permission group feature name",
  },
  investigateConfigRql: {
    defaultMessage: "Config",
    id: "Sihp3P",
    description: "Permission group feature name",
  },
  investigateEventRql: {
    defaultMessage: "Audit Events",
    id: "ppcOnO",
    description: "Permission group feature name",
  },
  investigateNetworkRql: {
    defaultMessage: "Network",
    id: "0e+yzI",
    description: "Permission group feature name",
  },
  investigateVulnerabilityRql: {
    defaultMessage: "Vulnerability",
    id: "f8v1Cj",
    description: "Permission group feature name",
  },
  savedSearches: {
    defaultMessage: "Saved Searches",
    id: "iDQrRC",
    description: "Permission group feature name",
  },
  alertsOverview: {
    defaultMessage: "Overview",
    id: "AoSC/t",
    description: "Permission group feature name",
  },
  alertsSnoozeDismiss: {
    defaultMessage: "Snooze/Dismiss",
    id: "gLYGnF",
    description: "Permission group feature name",
  },
  alertsRemediation: {
    defaultMessage: "Remediation",
    id: "WqTmto",
    description: "Permission group feature name",
  },
  alertsSavedFilters: {
    defaultMessage: "Saved Filters",
    id: "o+MFs/",
    description: "Permission group feature name",
  },
  alertsAlertRules: {
    defaultMessage: "Rules",
    id: "Xw1I9f",
    description: "Permission group feature name",
  },
  alertsReport: {
    defaultMessage: "Reports",
    id: "COULyA",
    description: "Permission group feature name",
  },
  alertsNotificationTemplates: {
    defaultMessage: "Notification Templates",
    id: "0Mko5a",
    description: "Permission group feature name",
  },
  dashboardSecOps: {
    defaultMessage: "SecOps",
    id: "6GnIMI",
    description: "Permission group feature name",
  },
  assetInventoryOverview: {
    defaultMessage: "Overview",
    id: "AoSC/t",
    description: "Permission group feature name",
  },
  assetInventoryFilters: {
    defaultMessage: "Saved Filters",
    id: "o+MFs/",
    description: "Permission group feature name",
  },
  policies: {
    defaultMessage: "Policies",
    id: "71h5yb",
    description: "Permission group feature name",
  },
  policyComplianceMapping: {
    defaultMessage: "Manage Policies Compliance Mapping",
    id: "K45wOV",
    description: "Permission group feature name",
  },
  complianceStandards: {
    defaultMessage: "Standards",
    id: "gVIYeT",
    description: "Permission group feature name",
  },
  complianceOverview: {
    defaultMessage: "Overview",
    id: "AoSC/t",
    description: "Permission group feature name",
  },
  complianceFilters: {
    defaultMessage: "Saved Filters",
    id: "o+MFs/",
    description: "Permission group feature name",
  },
  complianceReports: {
    defaultMessage: "Reports",
    id: "COULyA",
    description: "Permission group feature name",
  },
  alarmCentre: {
    defaultMessage: "Alarm Centre",
    id: "AP0vp8",
    description: "Permission group feature name",
  },
  alarmCentreSettings: {
    defaultMessage: "Alarm Centre Settings",
    id: "ES/eDP",
    description: "Permission group feature name",
  },
  codeSecurityProjects: {
    defaultMessage: "Projects",
    id: "Wa2rEa",
    description: "Permission group feature name",
  },
  codeSecuritySupplyChain: {
    defaultMessage: "Supply Chain",
    id: "ExYofz",
    description: "Permission group feature name",
  },
  codeSecurityDevPipelinesProjects: {
    defaultMessage: "Projects Tab",
    id: "QTBJ2J",
    description: "Permission group feature name",
  },
  codeSecurityDevPipelinesCodeReviews: {
    defaultMessage: "Code Reviews Tab",
    id: "LgKtjU",
    description: "Permission group feature name",
  },
  codeSecurityDashboard: {
    defaultMessage: "Code Security & Application Security Tabs",
    id: "6tJSn/",
    description: "Permission group feature name",
  },
  vulnerabilityDashboard: {
    defaultMessage: "Vulnerability",
    id: "f8v1Cj",
    description: "Permission group feature name",
  },
  vulnerabilityRemediation: {
    defaultMessage: "Remediate Vulnerabilities",
    id: "2A8HX7",
    description: "Permission group feature name",
  },
  computeAccessUI: {
    defaultMessage: "Compute Access Tab",
    id: "A6yZBM",
    description: "Permission group feature name",
  },
  actionPlanOverview: {
    defaultMessage: "Overview",
    id: "AoSC/t",
    description: "Permission group feature name",
  },
  actionPlanNotificationTemplates: {
    defaultMessage: "Notification Templates",
    id: "0Mko5a",
    description: "Permission group feature name",
  },
  actionPlanStatusAndAssignment: {
    defaultMessage: "Status and Assignment",
    id: "2DF8YV",
    description: "Permission group feature name",
  },
  actionPlanRemediation: {
    defaultMessage: "Remediation & Delegation",
    id: "H3nuxT",
    description: "Permission group feature name",
  },
});

export const subtitleMap = defineMessages({
  computeRadarsCloud: {
    defaultMessage:
      "Roles with access to cloud radar require permissions for Cloud Discovery Results permissions",
    id: "1wE9Hh",
    description: "Subtitle for feature row",
  },
  computeUIEventSubscriber: {
    defaultMessage:
      "Control access to sensitive information used to populate views in the UI that flows over the connection from the Console to client browsers that could be intercepted by users",
    id: "+TqCEf",
    description: "Subtitle for feature row",
  },
  computePolicyCloud: {
    defaultMessage:
      "Roles with access to cloud policies requires permissions for Credentials store",
    id: "SyuTMy",
    description: "Subtitle for feature row",
  },
  computeCollections: {
    defaultMessage:
      "Roles that access policies typically require permissions for collections",
    id: "pTsS6K",
    description: "Subtitle for feature row",
  },
  computeManageCreds: {
    defaultMessage:
      "Roles that access policies typically require permissions for collections",
    id: "pTsS6K",
    description: "Subtitle for feature row",
  },
  computePrivilegedOperations: {
    defaultMessage:
      "Grants access to extremely sensitive UI controls and API routes, including backup management, project management, and /api/v1/certs/*. Users with this permission could gain access to any other permission, even if not explicitly granted.",
    id: "cMGfF3",
    description: "Subtitle for feature row",
  },
});
