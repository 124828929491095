import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  ACTIVE_DIRECTORY,
  AGENTLESS_API_DISCOVERY,
  AGENTLESS_SCAN,
  ALIBABA_CLOUD,
  AWS,
  AZURE,
  DATA_SECURITY,
  IDENTITY_SECURITY,
  OCI,
  SERVERLESS_SCAN,
  THREAT_DETECTION,
} from "../../../../constants";
import {
  useGetSupportedFeaturesList,
  useIdentitySubscription,
} from "../../../../hooks";
import FeatureGroup from "../FeatureGroup";

import { Title } from "ui";
import { useDLPFeature } from "../../../../../../../utils/useDLPFeatures";
import { type AccountType, type CloudType } from "../../../../types";

import { useQuery } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  getDlpStatus,
  tenantKeys,
  type SecurityCapabilitiesType,
} from "requests";
import { type FeatureItemType } from "../FeatureGroup/FeatureGroup";
import { getSecurityCapabilitiesItems } from "./items";

interface SecurityCapabilitiesPropTypes {
  cloudType: CloudType;
  isMSA?: boolean;
  licenseType?: string;
  payload: {
    accountType: AccountType;
    awsPartition?: string;
  };
}

export default function SecurityCapabilities({
  cloudType,
  isMSA,
  payload,
}: SecurityCapabilitiesPropTypes) {
  const { getValues } = useFormContext();
  const { agentlessApiDiscovery } = useFlags();
  const intl = useIntl();

  const title = intl.formatMessage({
    defaultMessage: "Security Capabilities and Permissions",
    id: "2Am6a7",
    description: "Title for Security Capabilities and Permissions section",
  });

  const accountType = getValues("accountType");

  const { supportedFeaturesList } = useGetSupportedFeaturesList({
    cloudType,
    payload,
    options: {
      enabled: ![ALIBABA_CLOUD, OCI].includes(cloudType),
    },
  });

  const { isSubscribed } = useIdentitySubscription();

  const dlpHasPermission = useDLPFeature(true);
  const { data: dlpData } = useQuery({
    queryKey: tenantKeys.dlpStatus(),
    queryFn: getDlpStatus,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: dlpHasPermission && cloudType === AWS,
  });

  const provisionStatus = dlpData?.status || "provisionNotStarted";
  const dlpReady = provisionStatus === "provisionSuccessful";

  const data = useMemo(() => {
    const securityCapabilitiesItems = getSecurityCapabilitiesItems(cloudType);

    let features = securityCapabilitiesItems
      .filter((item: FeatureItemType) =>
        supportedFeaturesList.includes(item.value as SecurityCapabilitiesType),
      )
      .map((item: FeatureItemType) => {
        if (item.id === "identitySecurity") {
          return {
            ...item,
            tag: isSubscribed ? "subscribed" : "not_subscribed",
          };
        }
        return item;
      });

    if (cloudType === AWS && !agentlessApiDiscovery) {
      features = features.filter(
        ({ value }) => value !== AGENTLESS_API_DISCOVERY,
      );
    }

    const dsIndex = features.findIndex(
      (feature: FeatureItemType) => feature.value === DATA_SECURITY,
    );

    if (!dlpReady && dsIndex !== -1) {
      return [...features.slice(0, dsIndex), ...features.slice(dsIndex + 1)];
    }

    // AZURE Feature Filter
    if (cloudType === AZURE) {
      features = features.filter(
        ({ value }) =>
          !(
            [THREAT_DETECTION, IDENTITY_SECURITY].includes(value) &&
            ACTIVE_DIRECTORY === accountType
          ),
      );
    }
    // GCP Master Service Account don't have Identity Security
    if (isMSA) {
      features = features.filter(({ value }) => value !== IDENTITY_SECURITY);
    }

    //TODO: remove this default return after API support
    if ([ALIBABA_CLOUD, OCI].includes(cloudType)) {
      features = securityCapabilitiesItems;
    }

    return features;
  }, [
    accountType,
    cloudType,
    dlpReady,
    isMSA,
    isSubscribed,
    supportedFeaturesList,
    agentlessApiDiscovery,
  ]);

  const isComputeWorkloadSupported =
    supportedFeaturesList.includes(AGENTLESS_SCAN) ||
    supportedFeaturesList.includes(SERVERLESS_SCAN);

  return (
    <>
      {data.length ? (
        <section className="space-y-2">
          <Title level={5} size="xxs">
            {title}
          </Title>
          <FeatureGroup
            cloudType={cloudType}
            items={data}
            name="securityCapabilities"
            accountType={accountType}
            isComputeWorkloadSupported={isComputeWorkloadSupported}
          />
        </section>
      ) : null}
    </>
  );
}
