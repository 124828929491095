import { type CasSource } from "../types";

export const sourceToDisplayNameMap: Record<CasSource, string> = {
  Github: "GitHub",
  Gitlab: "GitLab",
  Bitbucket: "Bitbucket",
  cli: "cli",
  githubEnterprise: "GitHub Server",
  gitlabEnterprise: "GitLab Self-managed",
  bitbucketEnterprise: "Bitbucket Server",
  terraformCloud: "Terraform Cloud (Sentinel)",
  githubActions: "GitHub Actions",
  tfcRunTasks: "Terraform Cloud (Run Tasks)",
  tfeRunTasks: "Terraform Enterprise (Run Tasks)",
  admissionController: "Kubernetes Admission Controller",
  terraformEnterprise: "Terraform Enterprise (Sentinel)",
  AzureRepos: "Azure Repos",
  Kubernetes: "Kubernetes",
  circleci: "CircleCi",
  codebuild: "AWS Code Build",
  jenkins: "Jenkins",
  kubernetesWorkloads: "Kubernetes Workload",
  okta: "Okta",
  adfs: "adfs",
  onelogin: "onelogin",
  azuread: "azuread",
  gitlabCi: "GitLab CI",
  azurePipelines: "Azure Pipelines",
  awsCodeCommit: "AWS CodeCommit",
  checkmarx: "Checkmarx",
};

export const sourceToDisplayName = (source: CasSource): string => {
  return sourceToDisplayNameMap[source] ?? source;
};
