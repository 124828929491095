import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const ContainerImgIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox={"0 0 18 20"}>
      <path
        fillRule="evenodd"
        d="M1.01738 0.175049H16.983C17.3221 0.175049 17.5971 0.450001 17.5971 0.789111V12.4317C17.5971 13.7828 16.4917 14.888 15.1408 14.888H13.3109C12.9664 14.888 12.6846 15.1698 12.6846 15.5143V17.3688C12.6846 18.7199 11.5792 19.825 10.2283 19.825H1.01738C0.678273 19.825 0.40332 19.5501 0.40332 19.211V0.789111C0.40332 0.450001 0.678273 0.175049 1.01738 0.175049ZM8.83473 3.90251L5.27333 5.9085C5.0572 6.03023 5.05718 6.33097 5.27332 6.45271L8.83471 8.45885C8.93688 8.5164 9.06335 8.5164 9.16552 8.45885L12.7269 6.45271C12.9431 6.33097 12.9431 6.03024 12.7269 5.9085L9.16552 3.90251C9.06336 3.84497 8.93689 3.84497 8.83473 3.90251ZM9.71014 13.3686V9.91577C9.71014 9.8038 9.77184 9.70024 9.87223 9.64368L13.4197 7.64521C13.638 7.52221 13.9126 7.67379 13.9126 7.91731V11.3705C13.9126 11.4825 13.8509 11.586 13.7505 11.6426L10.203 13.6407C9.9847 13.7637 9.71014 13.6121 9.71014 13.3686ZM8.12803 9.64368L4.58053 7.64521C4.36221 7.52221 4.08763 7.67379 4.08763 7.91731V11.3705C4.08763 11.4825 4.14933 11.586 4.24974 11.6426L7.79722 13.6407C8.01555 13.7637 8.29011 13.6121 8.29011 13.3686V9.91577C8.29011 9.8038 8.22842 9.70023 8.12803 9.64368Z"
      />
    </SvgIcon>
  );
};
