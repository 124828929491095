import { useWizardContext } from "../../../../../../components/Wizard";

import {
  ACCOUNT,
  ACTIVE_DIRECTORY,
  AZURE,
  REMEDIATION,
  TENANT,
} from "../../../../../constants";

import { accountGroupsMultiSelect } from "../../../utils";

import { isChina } from "environment";
import { useFlags } from "launchdarkly-react-client-sdk";
import { AccountGroupSelect, useIsBusinessLicenseType } from "prisma";
import { useMemo } from "react";
import { useIsSystemAdmin } from "requests";
import { useGetSupportedFeaturesList } from "../../../../../hooks";
import {
  type AzureConfigureAccountStepValuesType,
  type AzureGetStartedStepValuesType,
} from "../../../../../types";
import AccountName from "../../../components/AccountName";
import ApplicationId from "../../../components/ApplicationId";
import ApplicationKey from "../../../components/ApplicationKey";
import MonitorFlowLogs from "../../../components/MonitorFlowLogs";
import RemediationCard from "../../../components/RemediationCard";
import ServicePrincipalId from "../../../components/ServicePrincipalId";
import SubscriptionId from "../../../components/SubscriptionId";
import TenantId from "../../../components/TenantId";
import { useAzureSelectMonitoredProjectContext } from "../../context/AzureSelectMonitoredProjectContext";
import {
  setAccountName,
  setApplicationId,
  setApplicationKey,
  setMonitorFlowLogs,
  setServicePrincipalId,
  setTenantId,
} from "../../context/AzureSelectMonitoredProjectContext/state/actions";
import SelectMonitoredProjects from "../SelectMonitoredProjects";
import AccountGroups from "./AccountGroups/AccountGroups";
import DownloadTerraform from "./DownloadTerraform";

type AccountDetailsProps = {
  closeModal: () => void;
  isEdit: boolean;
};

export default function AccountDetails({
  closeModal,
  isEdit,
}: AccountDetailsProps) {
  const { azureAutoMap } = useFlags();
  const isSystemAdmin = useIsSystemAdmin();
  const {
    state: { steps },
  } = useWizardContext();
  const { isBusinessLicenseType } = useIsBusinessLicenseType();

  const { accountType, environmentType: deploymentType = "" } = steps[0]
    ?.values as AzureGetStartedStepValuesType;
  const isSubscription = accountType === ACCOUNT;
  const isActiveDirectory = accountType === ACTIVE_DIRECTORY;

  const { supportedFeaturesList } = useGetSupportedFeaturesList({
    cloudType: AZURE,
    payload: {
      ...(isActiveDirectory ? { accountType: TENANT } : { accountType }),
      deploymentType,
      ...(accountType === TENANT && { rootSyncEnabled: true }),
    },
  });
  const { groupIds: accountGroupDefaultValue } = steps[1]
    ?.values as AzureConfigureAccountStepValuesType;

  const isRemediationSupported = useMemo(
    () => supportedFeaturesList.some((feature) => feature === REMEDIATION),
    [supportedFeaturesList],
  );

  const { dispatch: selectMonitoredProjectsState } =
    useAzureSelectMonitoredProjectContext();

  const onApplicationKeyBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) =>
    selectMonitoredProjectsState(setApplicationKey(event?.target?.value ?? ""));

  const onApplicationIdBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) =>
    selectMonitoredProjectsState(setApplicationId(event?.target?.value ?? ""));

  const onTenantIdBlur = (event: React.FocusEvent<HTMLInputElement, Element>) =>
    selectMonitoredProjectsState(setTenantId(event?.target?.value ?? ""));

  const onServicePrincipalIdBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) =>
    selectMonitoredProjectsState(
      setServicePrincipalId(event?.target?.value ?? ""),
    );

  const onAccountNameBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) => selectMonitoredProjectsState(setAccountName(event?.target?.value ?? ""));

  const onMonitorFlowLogsChange = (value = false) =>
    selectMonitoredProjectsState(setMonitorFlowLogs(value));

  const accountGroupsMultiSelectProps = {
    accountType,
    selectedCloud: AZURE,
  };

  return (
    <section className="space-y-2">
      <div className="space-y-4">
        <div className="flex w-full space-x-4">
          <div className="w-1/2">
            <TenantId isEdit={isEdit} onBlur={onTenantIdBlur} />
          </div>
          <div className="w-1/2">
            <AccountName onBlur={onAccountNameBlur} />
          </div>
        </div>
        {isSubscription && <SubscriptionId isEdit={isEdit} />}

        {isRemediationSupported && <RemediationCard />}

        {!isChina() && <DownloadTerraform />}

        <div className="flex w-full space-x-4">
          <div className="w-1/2">
            <ApplicationId onBlur={onApplicationIdBlur} />
          </div>
          <div className="w-1/2">
            <ApplicationKey onBlur={onApplicationKeyBlur} />
          </div>
        </div>

        <ServicePrincipalId onBlur={onServicePrincipalIdBlur} />

        {!isActiveDirectory && !isBusinessLicenseType && (
          <MonitorFlowLogs onChange={onMonitorFlowLogsChange} />
        )}

        <SelectMonitoredProjects closeModal={closeModal} />

        {azureAutoMap && isSystemAdmin && accountType === TENANT ? (
          <AccountGroups />
        ) : (
          <AccountGroupSelect
            defaultValue={accountGroupDefaultValue}
            enableMultiSelect={accountGroupsMultiSelect({
              ...accountGroupsMultiSelectProps,
            })}
            itemValue="id"
            name="groupIds"
          />
        )}
      </div>
    </section>
  );
}
