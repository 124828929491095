/* c8 ignore start */
import { useQuery } from "@tanstack/react-query";
import {
  accountGroupNameKeys,
  cloudRegionKeys,
  getAccountGroupNames,
  getCloudAccountNameListItems,
  getCloudRegions,
  listItemKeys,
  type AccountGroupType,
  type CloudAccountName,
  type CloudRegionWithOther,
} from "../../requests";

type UseCloudDataReturnType = {
  cloudRegions: CloudRegionWithOther[];
  cloudNames: CloudAccountName[];
  accountGroups: AccountGroupType[];
  isLoading: boolean;
};

/**
 * Custom hook to fetch cloud data including regions, names, and account groups.
 */
export function useCloudData(): UseCloudDataReturnType {
  const { data: cloudRegions = [], isLoading: isLoadingRegions } = useQuery({
    queryKey: cloudRegionKeys.cloudRegions(),
    queryFn: getCloudRegions,
  });

  const { data: cloudNames = [], isLoading: isLoadingNames } = useQuery({
    queryKey: listItemKeys.cloudAccountNames(),
    queryFn: getCloudAccountNameListItems,
  });

  const { data: accountGroups = [], isLoading: isLoadingGroups } = useQuery({
    queryKey: accountGroupNameKeys.accountGroupNames(),
    queryFn: getAccountGroupNames,
  });
  const isLoading = isLoadingRegions || isLoadingNames || isLoadingGroups;

  return {
    isLoading,
    cloudRegions,
    cloudNames,
    accountGroups,
  };
}
/* c8 ignore end */
