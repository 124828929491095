import { useIntl } from "react-intl";

import { Input } from "form";
import { getValidateAccountName } from "../../utils";

type AccountNameProps = {
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
};

export default function AccountName({ onBlur }: AccountNameProps) {
  const intl = useIntl();

  return (
    <Input
      name="name"
      data-selector="cloud-account-name-input"
      label={intl.formatMessage({
        defaultMessage: "Account Name",
        id: "6SMg+U",
        description:
          "The name of an input field for onboarding a cloud account",
      })}
      onBlur={onBlur}
      placeholder={intl.formatMessage({
        defaultMessage: "Account Name",
        id: "03zf9t",
        description: "Placeholder for Cloud Account Name",
      })}
      register={{
        validate: getValidateAccountName(intl),
        maxLength: {
          message: intl.formatMessage({
            defaultMessage:
              "Account Name cannot be more than 50 characters long",
            id: "4EHnDw",
            description: "First Name field length validation",
          }),
          value: 50,
        },
      }}
    />
  );
}
