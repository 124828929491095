import { useMutation } from "@tanstack/react-query";
import { CheckIcon, DownloadIcon, LoadingIcon } from "icons";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { isNil } from "remeda";
import { type SecurityCapabilitiesType } from "requests";
import { useToastActions } from "stores";
import { Button, dynamicFormatMessage } from "ui";
import {
  Authentication as AuthenticationStatus,
  DeploymentType,
  SecurityCapabilities,
} from "../../../../../components/ReviewStatus/AccountDetails";
import {
  ACCOUNT_NAME,
  ACCOUNT_TYPE,
  AWS_PARTITION,
  CREATE_IAM_ROLE_CFT,
} from "../../../../../constants";
import { accountDetailsLabelMap } from "../../../../../messages/reviewStatusMessages";
import { type AccountType, type Status } from "../../../../../types";
import { parseErrorsFromResponse } from "../../../../../utils";
import { downloadDynamicIAMRoleCft } from "../../../components/CreateOrDownloadIAMRoleCft/utils";
import AwsAccountDetails, {
  AccountType as AwsAccountType,
  Details,
  RoleARN,
} from "../../../components/ReviewStatus/AccountDetails";

type AccountDetailsStatusProps = {
  accountGroups?: string[];
  accountName: string;
  accountType: AccountType;
  authentication?: {
    id: string;
    message: string;
    name: string;
    status: Status;
  };
  roleArn?: string;
  securityCapabilities?: SecurityCapabilitiesType[];
  deploymentType?: string;
  isLoading: boolean;
  displayRoleArn?: boolean;
  isTempEdit?: boolean;
  awsPartition?: string;
  setIsRoleArnSaved?: (val: boolean) => void;
  defaultOpen?: boolean;
  isAccordianOpen?: boolean;
};

type Field = {
  renderComponent: (key: string, classNames?: string) => JSX.Element;
  key: string;
  label: string;
  transform?: (value: string | string[]) => JSX.Element;
  rightIcon?: JSX.Element;
  defaultCount?: number;
  allowCopy?: boolean;
};

type ContentValue =
  | string
  | boolean
  | string[]
  | JSX.Element
  | { id: string; message: string; name: string; status: Status }
  | undefined;

export default function AccountDetailsStatus(props: AccountDetailsStatusProps) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const {
    accountName,
    accountType,
    awsPartition = "",
    securityCapabilities,
    deploymentType = "",
    authentication,
    isLoading,
    setIsRoleArnSaved,
    roleArn = "",
    defaultOpen,
    isAccordianOpen,
  } = props;
  const [iamRoleCftMode, setIAMRoleCftMode] = useState("");
  const [isIAMRoleCftDownloaded, setIAMRoleCftDownloaded] = useState(false);

  const { mutateAsync: downloadIAMRoleCft, isPending: isDownloadingCft } =
    useMutation({
      onMutate: () => setIAMRoleCftMode(CREATE_IAM_ROLE_CFT),
      mutationFn: () =>
        downloadDynamicIAMRoleCft({
          [ACCOUNT_NAME]: accountName,
          [ACCOUNT_TYPE]: accountType as "account" | "organization",
          [AWS_PARTITION]: awsPartition,
          automatedFlow: true,
        }),
      onSuccess: () => {
        setIAMRoleCftDownloaded(true);
      },
      onError: (err) => {
        toast(parseErrorsFromResponse(intl, err), {
          appearance: "error",
        });
      },
    });

  const icon = useMemo(
    () =>
      isDownloadingCft ? (
        <LoadingIcon />
      ) : isIAMRoleCftDownloaded && iamRoleCftMode === CREATE_IAM_ROLE_CFT ? (
        <CheckIcon />
      ) : (
        <DownloadIcon />
      ),
    [isDownloadingCft, isIAMRoleCftDownloaded, iamRoleCftMode],
  );

  const fields = useMemo(() => {
    return [
      {
        renderComponent: (key: string) => (
          <AuthenticationStatus
            isLoading={isLoading}
            key={key}
            data={authentication}
            isCollapsibleDetails={true}
            isAccountDetails
          />
        ),
        key: "authentication",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "authentication",
        ),
      },
      {
        key: "accountType",
        renderComponent: (key: string, classNames: string) => (
          <AwsAccountType
            key={key}
            accountType={accountType}
            classNames={classNames}
          />
        ),
      },
      ...(securityCapabilities?.length
        ? [
            {
              renderComponent: (key: string, classNames: string) => (
                <SecurityCapabilities
                  key={key}
                  defaultCount={2}
                  value={securityCapabilities}
                  classNames={classNames}
                />
              ),
              key: "securityCapabilities",
              label: dynamicFormatMessage(
                intl,
                accountDetailsLabelMap,
                "securityCapabilities",
              ),
            },
          ]
        : []),
      {
        key: "accountName",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "accountName",
        ),
      },
      {
        key: "accountGroups",
        label: dynamicFormatMessage(
          intl,
          accountDetailsLabelMap,
          "accountGroups",
        ),
        transform: (value: string[]) =>
          intl.formatMessage(
            {
              defaultMessage: "{count} Account {label} Selected",
              id: "RyshgG",
              description: "Description for selected Account Group with count",
            },
            {
              count: value?.length,
              label: value?.length > 1 ? "Groups" : "Group",
            },
          ),
      },
      ...(props.isTempEdit
        ? [
            {
              key: "downloadCFT",
              renderComponent: () => (
                <Details
                  label={dynamicFormatMessage(
                    intl,
                    accountDetailsLabelMap,
                    "downloadCFT",
                  )}
                >
                  <Button
                    data-selector="config-helper-download-dynamic-cft-link"
                    appearance="primary"
                    icon={icon}
                    onClick={() => downloadIAMRoleCft()}
                  >
                    <span className="flex items-center text-sm">
                      <FormattedMessage
                        defaultMessage="Download CFT Template"
                        id="RJXp8s"
                        description="AWS cft download link"
                      />
                    </span>
                  </Button>
                </Details>
              ),
            },
          ]
        : []),
      ...(props.displayRoleArn
        ? [
            {
              key: "roleArn",
              label: dynamicFormatMessage(
                intl,
                accountDetailsLabelMap,
                "roleArn",
              ),
              allowCopy: true,
              renderComponent: (key: string) => (
                <RoleARN
                  key={key}
                  accountName={accountName}
                  setIsRoleArnSaved={setIsRoleArnSaved}
                  roleArn={roleArn}
                />
              ),
            },
          ]
        : []),
      {
        renderComponent: (key: string, classNames: string) => (
          <DeploymentType
            key={key}
            value={deploymentType}
            classNames={classNames}
          />
        ),
        key: "deploymentType",
      },
    ].filter((field) => !isNil(field)) as Field[];
  }, [
    accountName,
    accountType,
    authentication,
    deploymentType,
    downloadIAMRoleCft,
    icon,
    intl,
    isLoading,
    props.displayRoleArn,
    props.isTempEdit,
    roleArn,
    securityCapabilities,
    setIsRoleArnSaved,
  ]);

  const getContent = (
    transform: Field["transform"],
    key: string,
    value: ContentValue,
  ) => {
    if (!isNil(transform)) {
      if (key === "accountGroups") return transform(value as string[]);
    }
    return value as JSX.Element;
  };

  return (
    <AwsAccountDetails
      defaultOpen={defaultOpen}
      isAccordianOpen={isAccordianOpen}
    >
      {fields.map(({ key, renderComponent, transform, ...rest }, index) => {
        const classNames =
          index % 2 === 0
            ? ""
            : "items-start [&>div]:my-1.5 bg-gray-100 rounded-md !mt-2";
        return !isNil(renderComponent) ? (
          renderComponent(key, classNames)
        ) : (
          <Details key={key} classNames={classNames} {...rest}>
            {getContent(
              transform,
              key,
              props[key as keyof typeof props] as ContentValue,
            )}
          </Details>
        );
      })}
    </AwsAccountDetails>
  );
}
