import { useIntl } from "react-intl";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type RenderModalProps,
} from "ui";

import { type AccountGroup } from "../../../../../types";
import { AccountGroupsModalTable } from "./AccountGroupsModalTable";

export function AccountGroupsModal({
  accountGroups,
  isOpen,
  closeModal,
}: RenderModalProps<{
  accountGroups: AccountGroup[];
}>) {
  const intl = useIntl();

  return (
    <Modal onClose={closeModal} isOpen={isOpen} size="lg">
      <ModalHeader
        enableClose
        title={intl.formatMessage({
          defaultMessage: "Directly Linked Account Groups",
          id: "EMe8pc",
          description: "Directly Linked Account Groups",
        })}
      />
      <ModalBody>
        <AccountGroupsModalTable accountGroups={accountGroups} />
      </ModalBody>
      <ModalFooter>
        <Button appearance="primary" onClick={closeModal}>
          {intl.formatMessage({
            defaultMessage: "Done",
            id: "BIHI7Q",
            description: "Done Button Account Groups modal",
          })}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
