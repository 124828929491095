import { defineMessages } from "react-intl";

type MessageType = {
  defaultMessage: string;
  description: string;
};

export const alertStatusMessages: { [key: string]: MessageType } =
  defineMessages({
    open: {
      defaultMessage: "Open",
      id: "hZBkqO",
      description: "Alert Status",
    },
    pending_resolution: {
      defaultMessage: "Pending Resolution",
      id: "eo/PAC",
      description: "Alert Status",
    },
    snoozed: {
      defaultMessage: "Snoozed",
      id: "nPy0m9",
      description: "Alert Status",
    },
    dismissed: {
      defaultMessage: "Dismissed",
      id: "WSPOIk",
      description: "Alert Status",
    },
    resolved: {
      defaultMessage: "Resolved",
      id: "kAkzND",
      description: "Alert Status",
    },
  });
