import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { TruncateCell } from "table";
import { Body } from "ui";

import { ResourceGroupsModal } from "./ResourceGroupsModal";

type ResourceGroupsCellProps = {
  value: string[];
};

export function ResourceGroupsCell({ value }: ResourceGroupsCellProps) {
  const intl = useIntl();

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  const cellText = useMemo(() => {
    if (value.length === 1) {
      return <Body size="sm">{value[0]}</Body>;
    }

    const resourceGroupsCountText = intl.formatMessage(
      {
        defaultMessage:
          "{count, number} resource {count, plural, one {group} other {groups}}",
        id: "4zNx+A",
        description: "Resource Groups count",
      },
      {
        count: value.length,
      },
    );

    return (
      <Body as="button" appearance="link" size="sm" onClick={openModal}>
        {resourceGroupsCountText}
      </Body>
    );
  }, [value, intl]);

  if (!value.length) {
    return null;
  }

  return (
    <>
      <TruncateCell value={cellText} />
      {value.length > 1 && (
        <ResourceGroupsModal
          resourceGroups={value}
          isOpen={modalOpen}
          closeModal={closeModal}
        />
      )}
    </>
  );
}
