import { z } from "zod";

//This is a map that lists all the finding types that can be returned from a backend response.
export const findingTypesSchema = z.enum([
  "AZURE_SECURITY_CENTER_ALERTS",
  "HOST_VULNERABILITY_CVE",
  "SERVERLESS_VULNERABILITY",
  "PACKAGE_VULNERABILITY",
  "COMPLIANCE_CIS",
  "GUARD_DUTY_HOST",
  "GUARD_DUTY_IAM",
  "INSPECTOR_RBA",
  "INSPECTOR_SBP",
  "NETWORK_REACHABILITY",
  "UNCLASSIFIED",
  "COMMAND_AND_CONTROL",
  "CREDENTIAL_ACCESS",
  "CROSS_ACCOUNT_TRUST",
  "DATA_EXFILTRATION",
  "DEFENSE_EVASION",
  "DISCOVERY",
  "HIGH_PRIVILEGED_ROLE",
  "INITIAL_ACCESS",
  "INTERNET_EXPOSURE",
  "KEYS_AND_SECRETS",
  "LATERAL_MOVEMENT",
  "MALWARE",
  "MISCONFIGURATION",
  "NETWORK_ANOMALY",
  "PRIVILEGE_ESCALATION",
  "RECONNAISSANCE",
  "RESOURCE_HIJACKING",
  "SSH_BRUTE_FORCE",
  "UNAUTHORIZED_ACCESS",
  "UNENCRYPTED_DATA",
  "UNUSED_PRIVILEGES",
  "USER_ANOMALY",
  "WEAK_PASSWORD",
  "BOT_ACTIVITY",
  "WEB_ATTACK",
  "WEB_SCRAPING",
  "COMPUTE",
  "APPLICATION_SERVICES",
  "DEVELOPMENT",
  "VULNERABILITY",
  "MFA",
]);

export type FindingType = z.infer<typeof findingTypesSchema>;
