import {
  type CSSProperties,
  type DetailedHTMLProps,
  type HTMLAttributes,
  type ReactElement,
  type Ref,
} from "react";
import { useIntl } from "react-intl";
import { classNames } from "utils";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { type CellInstance, type Data, type RowInstance } from "../../types";
import { useTableInstance } from "../TableContext";
import { useTableLayoutEnabled, type TableLayoutProps } from "../TableLayout";
import { Cell, CellWrapper } from "./Cell";
import { DragHandle } from "./DragHandle";
import { StickyBorder } from "./StickyBorder";

export interface RowProps<D extends Data> {
  shouldHighlightRow?: (row: { row: RowInstance<D> }) => boolean;
  row: RowInstance<D>;
  dragHandleProps?: Record<string, unknown>;
  isDragging?: boolean;
  isHeld?: boolean;
  sortRef?: Ref<HTMLDivElement>;
  style?: CSSProperties;
}

type GroupedCells<D extends Data> = {
  sortRowCells: CellInstance<D>[];
  selectRowCells: CellInstance<D>[];
  groupedCells: CellInstance<D>[];
  generalCells: CellInstance<D>[];
  leftStickyCells: CellInstance<D>[];
  rightStickyCells: CellInstance<D>[];
};

function groupCells<D extends Data>(cells: CellInstance<D>[]): GroupedCells<D> {
  return cells.reduce(
    (accum: GroupedCells<D>, cell) => {
      if (cell.column.isSortRowColumn) {
        accum.sortRowCells.push(cell);
      } else if (cell.column.isSelectRowColumn) {
        accum.selectRowCells.push(cell);
      } else if (cell.isGrouped || cell.isPlaceholder) {
        accum.groupedCells.push(cell);
      } else if (cell.column.sticky) {
        if (cell.column.stickyPosition === "left") {
          accum.leftStickyCells.push(cell);
        } else {
          accum.rightStickyCells.push(cell);
        }
      } else {
        accum.generalCells.push(cell);
      }
      return accum;
    },
    {
      sortRowCells: [],
      selectRowCells: [],
      groupedCells: [],
      generalCells: [],
      leftStickyCells: [],
      rightStickyCells: [],
    },
  );
}

export function Row<D extends Data>({
  appearance = "primary",
  shouldHighlightRow,
  row,
  isDragging,
  isHeld,
  sortRef,
  dragHandleProps,
  style,
  ...rest
}: RowProps<D> & Pick<TableLayoutProps, "appearance">): ReactElement {
  const intl = useIntl();
  const {
    sortRowCells,
    selectRowCells,
    groupedCells,
    leftStickyCells,
    generalCells,
    rightStickyCells,
  } = groupCells(row.cells);
  const {
    state: { globalFilter = "" },
    disableRowSortHandle,
  } = useTableInstance();

  const shouldNotHighlight = !shouldHighlightRow?.({ row });
  const primaryClasses = classNames(
    "bg-white even:bg-gray-100 hover:bg-blue-100 dark:bg-blue-steel-950 dark:even:bg-blue-steel-970 dark:hover:bg-blue-steel-850",
  );

  const className = classNames(
    "group",
    appearance === "primary" && shouldNotHighlight && primaryClasses,
    appearance === "secondary" &&
      "border-b last:border-b-0 dark:border-blue-steel-850",
    appearance === "card" &&
      "m-2 rounded shadow-[0_0_5px_0_rgba(0,0,0,0.12)] dark:border-blue-steel-850 dark:shadow-[0_0_5px_0_rgba(0,0,0,1)]",
    appearance === "card" && shouldNotHighlight && primaryClasses,
    shouldHighlightRow?.({ row }) && "bg-blue-200 dark:bg-blue-steel-800",
    isDragging && "opacity-50",
    isHeld && "shadow-lg",
  );
  const tableLayoutEnabled = useTableLayoutEnabled();
  const { style: styleProps, ...rowProps } = row.getRowProps();
  const isStickyRow = row?.original?.isStickyRow || false;

  const combinedStyles = {
    ...(appearance !== "card" && { borderLeft: "1px solid transparent" }),
    minHeight: appearance !== "card" ? "2rem" : "2.5rem",
    ...style,
    ...styleProps,
  };
  if (tableLayoutEnabled) {
    combinedStyles.minWidth = combinedStyles.width;
    combinedStyles.width = undefined;
  }
  const dragDisabled = globalFilter || !!disableRowSortHandle || isStickyRow;

  return (
    <div
      aria-rowindex={row.index}
      {...rowProps}
      ref={sortRef}
      style={
        combinedStyles as DetailedHTMLProps<
          HTMLAttributes<HTMLDivElement>,
          HTMLDivElement
        >
      }
      className={className}
      {...rest}
    >
      {!!sortRowCells.length && (
        <div className="flex">
          {sortRowCells.map((cell) => (
            <CellWrapper
              cell={cell}
              key={cell.column.id}
              appearance={appearance}
            >
              <DragHandle
                {...(dragDisabled ? {} : dragHandleProps)}
                label={intl.formatMessage({
                  defaultMessage: "Move Row",
                  id: "o+pYwd",

                  description: "Drag icon user can click on to reorder rows",
                })}
                isHeld={isHeld}
                disabled={
                  dragDisabled &&
                  (disableRowSortHandle &&
                  typeof disableRowSortHandle === "string"
                    ? disableRowSortHandle
                    : isStickyRow && typeof isStickyRow === "string"
                      ? isStickyRow
                      : intl.formatMessage({
                          defaultMessage: "Unable to sort while filtering",
                          id: "GOKfIG",

                          description: "Drag icon disabled tooltip",
                        }))
                }
              />
            </CellWrapper>
          ))}
        </div>
      )}
      {!!selectRowCells.length && (
        <div className="flex">
          {selectRowCells.map((cell) => (
            <Cell
              cell={cell}
              key={cell.column.id}
              row={row}
              appearance={appearance}
            />
          ))}
        </div>
      )}
      {!!groupedCells.length && (
        <div className="flex">
          {groupedCells.map((cell) => (
            <Cell
              cell={cell}
              key={cell.column.id}
              row={row}
              appearance={appearance}
            />
          ))}
        </div>
      )}
      {!!leftStickyCells.length && (
        <div className="flex">
          {leftStickyCells.map((cell) => (
            <Cell
              cell={cell}
              key={cell.column.id}
              row={row}
              appearance={appearance}
            />
          ))}
        </div>
      )}
      <div className="flex grow">
        {generalCells.map((cell, index) => (
          <Cell
            cell={cell}
            key={cell.column.id}
            row={row}
            last={generalCells.length === index + 1}
            appearance={appearance}
          />
        ))}
      </div>
      {!!rightStickyCells.length && (
        <div
          style={{
            background: "inherit",
            position: "sticky",
            right: 0,
          }}
          className="flex"
        >
          <StickyBorder />
          {rightStickyCells.map((cell, index) => (
            <Cell
              cell={cell}
              key={cell.column.id}
              row={row}
              appearance={appearance}
              last={rightStickyCells.length === index + 1}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export function SortableRow<D extends Data>(
  props: RowProps<D> & Pick<TableLayoutProps, "appearance">,
): ReactElement {
  const {
    state: { globalFilter = "" },
  } = useTableInstance();
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: String(props.row.id),
    disabled: !!globalFilter,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Row
      style={style}
      {...props}
      sortRef={setNodeRef}
      isDragging={isDragging}
      dragHandleProps={{ ...attributes, ...listeners }}
    />
  );
}
