import { z } from "zod";
import { CloudTypesWithOtherEnumSchema } from "../../cloudType";

export const SecurityCapabilityFeatures = [
  "Cloud Visibility Compliance and Governance",
  "Identity Security",
  "Agentless Scanning",
  "Threat Detection",
  "Serverless Function Scanning",
  "Auto Protect",
  "Data Security",
  "Remediation",
  "AWS Guard Duty",
  "AWS Inspector",
  "Agentless API Discovery",
] as const;

export const SecurityCapabilityFeaturesEnumSchema = z.enum(
  SecurityCapabilityFeatures,
);

export type SecurityCapabilitiesType = z.infer<
  typeof SecurityCapabilityFeaturesEnumSchema
>;

export const SecurityCapabilityValuesEnumSchema = z.enum([
  "Agentless Scanning Default Member State",
  "Serverless Function Scanning Default Member State",
  "Agentless API Discovery Default Member State",
]);

export type SecurityCapabilityValuesType = z.infer<
  typeof SecurityCapabilityValuesEnumSchema
>;

export const AccountTypeSchema = z.union([
  z.literal("account"),
  z.literal("organization"),
  z.literal("activeDir"),
  z.literal("masterServiceAccount"),
  z.literal("tenant"),
  z.literal("compartment"),
  z.literal("workspace_domain"),
  z.literal("workspaceDomain"),
]);

export const Feature = z.object({
  featureName: z.string(),
  createdTs: z.number(),
  featureState: z.string(),
  displayName: SecurityCapabilityFeaturesEnumSchema,
  featureMode: z.union([z.string(), z.undefined(), z.null()]),
  defaultMemberState: z.union([z.string(), z.undefined(), z.null()]),
});

export type CloudAccountFeature = z.infer<typeof Feature>;

const accountGroupInfos = z.object({
  autoCreated: z.boolean(),
  groupId: z.string().optional(),
  groupName: z.string(),
  lastModifiedBy: z.string(),
});

export type AccountGroupInfosType = z.infer<typeof accountGroupInfos>;

export const CloudAccountSchema = z.object({
  accountId: z.string(),
  name: z.string(),
  cloudType: z.string(),
  enabled: z.boolean(),
  accountType: AccountTypeSchema,
  deploymentType: z.string(),
  lastModifiedEpochMillis: z.number(),
  lastModifiedBy: z.string(),
  owner: z.string().optional(),
  deploymentTypeDescription: z.string(),
  features: z.array(Feature).optional(),
  accountGroupInfos: z.array(accountGroupInfos).optional(),
});

export const CloudAccountResponseSchema = z.object({
  assumeRoleAccount: z.string().optional(),
  cloudAccount: CloudAccountSchema,
  memberSyncEnabled: z.boolean().optional(),
  groupIds: z.string().array().optional(),
  svcIdIamId: z.string().optional(),
  accountGroupInfos: z.array(accountGroupInfos).optional(),
});

export const AwsResponseSchema = z.object({
  assumeRoleAccount: z.string(),
  cloudAccount: CloudAccountSchema,
  roleArn: z.string(),
  externalId: z.string(),
  hasMemberRole: z.boolean(),
  templateUrl: z.string(),
  eventbridgeRuleNamePrefix: z.string().optional(),
  customerId: z.number(),
  groupIds: z.array(z.string()),
  storageScanConfig: z
    .object({
      scanOption: z.string(),
      snsTopicArn: z.string(),
    })
    .optional(),
  storageUUID: z.string().optional(),
  // Org props
  defaultAccountGroupId: z.string().optional(),
  defaultAccountGroupName: z.string().optional(),
  memberRoleName: z.string().optional(),
  memberExternalId: z.string().optional(),
  memberTemplateUrl: z.string().optional(),
  hierarchySelection: z
    .array(
      z
        .object({
          resourceId: z.string(),
          displayName: z.string(),
          nodeType: z.string(),
          selectionType: z.string(),
        })
        .optional(),
    )
    .optional(),
  //Advanced Settings Props
  customMemberRoleNameEnabled: z.boolean().optional(),
  skipOverrideMemberRoleName: z.boolean().optional(),
  useTenantExternalId: z.boolean().optional(),
  unifiedCftDisabled: z.boolean().optional(),
});

export const TempCloudAccountPayload = z.object({
  accountName: z.string(),
  accountType: z.string(),
  cloudType: z.string(),
  accountGroups: z.array(z.string()).optional(),
  defaultAccountGroupId: z.string().optional(),
  automatedFlow: z.boolean(),
});

export const SaveAwsTempAccountRoleArnPayload = z.object({
  name: z.string(),
  roleArn: z.string(),
});

export type TempCloudAccountPayloadSchema = z.infer<
  typeof TempCloudAccountPayload
>;

export const AzureResponseSchema = z.object({
  accountGroupMode: z.string().optional(),
  cloudAccount: CloudAccountSchema,
  tenantId: z.string(),
  servicePrincipalId: z.string(),
  clientId: z.string(),
  memberSyncEnabled: z.boolean(),
  templateUrl: z.string(),
  key: z.string(),
  customerId: z.number(),
  groupIds: z.array(z.string()),
  monitorFlowLogs: z.boolean(),
  environmentType: z.string(),

  //Tenant prop
  defaultAccountGroupId: z.string().optional(),
  defaultAccountGroupName: z.string().optional(),
  hierarchySelection: z
    .array(
      z.object({
        resourceId: z.string(),
        displayName: z.string(),
        nodeType: z.string(),
        selectionType: z.string(),
      }),
    )
    .optional(),
});

export const GcpResponseSchema = z.object({
  cloudAccount: CloudAccountSchema,
  customerId: z.number(),
  projectId: z.string(),
  serviceAccountEmail: z.string(),
  authenticationType: z.string(),
  flowLogStorageBucket: z.string(),
  compressionEnabled: z.boolean(),
  accountGroupCreationMode: z.string(),
  dataflowEnabledProject: z.string(),
  credentials: z
    .object({
      token_uri: z.string(),
      private_key_id: z.string(),
      client_x509_cert_url: z.string(),
      project_id: z.string(),
      auth_uri: z.string(),
      auth_provider_x509_cert_url: z.string(),
      client_email: z.string(),
      private_key: z.string(),
      type: z.string(),
      client_id: z.string(),
    })
    .or(
      z.object({
        subject_token_type: z.string(),
        audience: z.string(),
        service_account_impersonation_url: z.string(),
        type: z.string(),
        token_url: z.string(),
        credential_source: z.object({
          regional_cred_verification_url: z.string(),
          environment_id: z.string(),
          region_url: z.string(),
          url: z.string(),
        }),
      }),
    ),
  groupIds: z.array(z.string()),

  // Org Props
  organizationName: z.string().optional(),
  hierarchySelection: z.array(
    z.object({
      resourceId: z.string(),
      displayName: z.string(),
      nodeType: z.string(),
      selectionType: z.string(),
    }),
  ),

  // Org & Msa Props
  defaultAccountGroupId: z.string().optional(),
});

export const IbmResponseSchema = z.object({
  cloudAccount: CloudAccountSchema,
  customerId: z.number(),
  svcIdIamId: z.string(),
  groupIds: z.array(z.string()),
});

export const AzureDataSecurityResponseSchema = z.object({
  tenantId: z.string().optional(),
  prismaId: z.number().optional(),
  stack: z.string().optional(),
  accountId: z.string(),
  accountName: z.string(),
  redlockAccountId: z.number().optional(),
  accountType: z.string(),
  cloudType: CloudTypesWithOtherEnumSchema,
  activeStatus: z.boolean(),
  dssTenantId: z.string().optional(),
  storageUuid: z.string().optional(),
  frequency: z.string().optional(),
  scanOption: z.string().optional(),
  storageScanStatus: z.string(),
  cloudConfig: z
    .object({
      azTenantId: z.string().optional(),
      clientId: z.string().nullish(),
      clientSecret: z.string().nullish(),
    })
    .optional(),
  createdOn: z.number().optional(),
  updatedOn: z.number().optional(),
});

export const AzureDataSecurityStatusResponseSchema = z.object({
  message: z.string().optional(),
  status: z.string().optional(),
  section: z.array(z.string()),
});

export const AWSOrgDataSecurityResponseSchema = z.object({
  accountId: z.string().nullish(),
  masterExternalId: z.string(),
  masterRoleArn: z.string().nullish(),
  memberExternalId: z.string(),
  memberRoleName: z.string(),
  snsEndpointUrl: z.string(),
  masterCftUrl: z.string(),
  memberCftUrl: z.string(),
  memberCftTemplate: z.string(),
  masterCftTemplate: z.string(),
  masterCftLandingPage: z.string().optional(),
  snsTopicArn: z.string().nullish(),
  storageUUID: z.string(),
  scanOption: z.string(),
  storageScanStatus: z.string().nullish(),
});

export const AwsOrgUpdateConfigSchema = z.object({
  storageUUID: z.string(),
  accountId: z.string().nullish(),
  cloudType: CloudTypesWithOtherEnumSchema,
  accountType: z.string(),
  storageScanStatus: z.string(),
  dssTenantId: z.string(),
  cloudConfig: z.object({
    externalId: z.string(),
    memberExternalId: z.string(),
    memberRoleName: z.string(),
    roleArn: z.string(),
    snsTopicArn: z.string(),
  }),
  scanOption: z.string(),
});

export type AWSOrgDataSecurityResponseType = z.infer<
  typeof AWSOrgDataSecurityResponseSchema
>;
export type AwsOrgUpdateConfigType = z.infer<typeof AwsOrgUpdateConfigSchema>;

export const AWSAccountDataSecurityResponseSchema = z.object({
  storageUUID: z.string(),
  enabled: z.boolean(),
  configuration: z
    .object({
      scanOption: z.string().optional(),
      buckets: z
        .object({
          backward: z.array(z.string()).nullish(),
          forward: z.array(z.string()).nullish(),
        })
        .optional(),
      snsTopicArn: z.string().optional(),
    })
    .optional(),
  roleArn: z.string(),
  cftTemplateUrl: z.string(),
  callbackUrl: z.string(),
});

export const AWSTempAccountDetailsResponseSchema = z.object({
  cloudAccount: z.object({
    name: z.string(),
    accountType: z.string(),
    deploymentType: z.string(),
    cloudType: z.string(),
  }),
  groupIds: z.array(z.string()),
});
