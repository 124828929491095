import { type ReactNode } from "react";
import { noop } from "utils";

import {
  type UseComboboxStateChange,
  type UseSelectStateChange,
} from "downshift";
import { equals } from "remeda";
import { type SelectItem } from "./Select";
import { type SelectWithSearchProps } from "./SelectWithSearch";
import { type SelectWithoutSearchProps } from "./SelectWithoutSearch";

export const selectedItemLabel = (
  selectedItem?: SelectItem | SelectItem[] | null,
): ReactNode => {
  return Array.isArray(selectedItem)
    ? selectedItem
        .map((item) => item?.children || item?.value)
        .filter(Boolean)
        .join(", ")
    : selectedItem?.children ||
        (selectedItem?.value && String(selectedItem?.value)) ||
        "";
};

export const isDomElement = (type?: string): boolean => {
  return /button|div|span/.test(type ?? "");
};

type OnSelectedItemChangeProps = Pick<
  SelectWithoutSearchProps,
  "enableMultiSelect"
> & {
  changes:
    | UseComboboxStateChange<SelectItem>
    | UseSelectStateChange<SelectItem>;
  onChange:
    | SelectWithoutSearchProps["onChange"]
    | SelectWithSearchProps["onChange"];
  selectedValues: SelectItem["value"][];
  selectedItems: SelectItem[];
  allSelected: boolean;
  selectAllItem: SelectItem;
  itemsForSelectAll: SelectItem[];
};
export const onSelectedItemChange = ({
  changes,
  enableMultiSelect,
  selectedItems,
  selectedValues,
  onChange = noop,
  selectAllItem,
  allSelected,
  itemsForSelectAll,
}: OnSelectedItemChangeProps): void => {
  const { selectedItem } = changes;
  /* c8 ignore next */
  if (!selectedItem) {
    return;
  }
  if (enableMultiSelect) {
    const index = selectedValues.findIndex((v) =>
      equals(v, selectedItem.value),
    );
    const itemAlreadySelected = index >= 0;

    if (selectedItem.value === selectAllItem.value) {
      const selectAllValues = itemsForSelectAll.map((item) => item.value);
      onChange?.({
        selectedItem: selectAllItem,
        selectedItems: !allSelected
          ? [
              ...selectedItems,
              ...itemsForSelectAll.filter(
                (item) =>
                  !item.disabled &&
                  !selectedValues.some((v) => {
                    return equals(v, item.value);
                  }),
              ),
            ]
          : selectedItems.filter(
              (item) =>
                !selectAllValues.some((v) => {
                  return equals(v, item.value);
                }),
            ),
      });
    } else if (itemAlreadySelected) {
      // @ts-expect-error type in changes is incompatible, but irrelevant to us.
      onChange({
        ...changes,
        selectedItem: selectedItem,
        selectedItems: [
          ...selectedItems.slice(0, index),
          ...selectedItems.slice(index + 1),
        ].filter(Boolean),
      });
    } else {
      // @ts-expect-error type in changes is incompatible, but irrelevant to us.
      onChange({
        ...changes,
        selectedItem: selectedItem,
        selectedItems: [...selectedItems, selectedItem],
      });
    }
  } else {
    // @ts-expect-error type in changes is incompatible, but irrelevant to us.
    onChange({
      ...changes,
      selectedItem: selectedItem,
      selectedItems: [selectedItem],
    });
  }
};

export const sortBySelected =
  (selectedValues: SelectItem["value"][]) =>
  (itemA: SelectItem, itemB: SelectItem): number => {
    const itemASelected = selectedValues.some((v) => {
      return equals(v, itemA.value);
    });
    const itemBSelected = selectedValues.some((v) => {
      return equals(v, itemB.value);
    });

    return itemASelected === itemBSelected ? 0 : itemASelected ? -1 : 1;
  };

type getSelectAllArguments = {
  action: string;
  selectedValues: SelectItem["value"][];
  selectedItems: SelectItem[];
  items: SelectItem[];
  selectAllItem: SelectItem;
};
type getSelectAllReturn = {
  selectedItem: SelectItem;
  selectedItems: SelectItem[];
};
export const getSelectAll = ({
  action,
  selectAllItem,
  selectedItems,
  items,
  selectedValues,
}: getSelectAllArguments): getSelectAllReturn => {
  return {
    selectedItem: selectAllItem,
    selectedItems:
      action === "selectAll"
        ? [
            ...selectedItems,
            ...items.filter(
              (item) =>
                !item.disabled &&
                !selectedValues.some((v) => {
                  return equals(v, item.value);
                }),
            ),
          ]
        : selectedItems.filter(
            (item) =>
              !items.map((itemValue) => equals(itemValue.value, item.value)),
          ),
  };
};
