import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { TruncateCell } from "table";
import { Body } from "ui";

import { type AccountGroup } from "../../../../types";
import { AccountGroupsModal } from "./AccountGroupsModal/AccountGroupsModal";

type AccountGroupsCellProps = {
  value: AccountGroup[];
};

export function AccountGroupsCell({ value }: AccountGroupsCellProps) {
  const intl = useIntl();

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  const cellText = useMemo(() => {
    if (value.length === 1) {
      return <TruncateCell value={value[0].name} />;
    }

    const accountGroupsCountText = intl.formatMessage(
      {
        defaultMessage:
          "{count, number} account {count, plural, one {group} other {groups}}",
        id: "1Kjjk0",
        description: "Account Groups count",
      },
      {
        count: value.length,
      },
    );

    return (
      <Body
        as="button"
        appearance="link"
        size="sm"
        onClick={openModal}
        addClassName="w-full"
        truncate
      >
        {accountGroupsCountText}
      </Body>
    );
  }, [value, intl]);

  if (!value.length) {
    const allText = intl.formatMessage({
      defaultMessage: "All",
      id: "FzLSXF",
      description:
        "Value in table cell to indicate the data applies to all account groups.",
    });
    return <TruncateCell value={allText} />;
  }

  return (
    <>
      <TruncateCell value={cellText} />
      {value.length > 1 && (
        <AccountGroupsModal
          accountGroups={value}
          isOpen={modalOpen}
          closeModal={closeModal}
        />
      )}
    </>
  );
}
