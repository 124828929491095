import { useIntl } from "react-intl";
import {
  ConnectedPaginator,
  ConnectedTable,
  ConnectedTableFilter,
  TableProvider,
} from "table";

import { useResourceGroupsModalTableColumns } from "./useResourceGroupsModalTableColumns";

type ResourceGroupsModalTableProps = {
  resourceGroups: string[];
};

export function ResourceGroupsModalTable({
  resourceGroups,
}: ResourceGroupsModalTableProps) {
  const intl = useIntl();
  const columns = useResourceGroupsModalTableColumns();

  return (
    <TableProvider
      columns={columns}
      data={resourceGroups.map((name) => ({
        name,
      }))}
      initialState={{ sortBy: [{ id: "name" }] }}
      enableColumnSort
      enablePagination
    >
      <div className="flex flex-col space-y-4" style={{ maxHeight: "58vh" }}>
        <div className="flex flex-none justify-between">
          <span className="mr-2 pt-0.5 leading-loose">
            {intl.formatMessage({
              defaultMessage: "Total Results: ",
              id: "R6JUwC",
              description: "Total results",
            })}{" "}
            {Number(resourceGroups?.length ?? 0).toLocaleString()}
          </span>
          <ConnectedTableFilter />
        </div>
        <div className="overflow-x-auto">
          <ConnectedTable
            appearance="secondary"
            selector="roles-resourceGroups"
          />
        </div>
        <div className="flex justify-end space-x-2">
          <ConnectedPaginator />
        </div>
      </div>
    </TableProvider>
  );
}
