import { CloudTypeIcon } from "icons";
import { FormattedMessage } from "react-intl";
import { type CloudProvidersWithOther } from "requests";
import { Tooltip, TruncateText, isDarkMode } from "ui";
import { classNames } from "utils";
import { CloudProvidersTooltip } from "./CloudProvidersTooltip";

const CloudProvidersIcons = ({ icons }: { icons: CloudProvidersWithOther }) => {
  return (
    <>
      {icons.map((cProvider) => (
        <CloudTypeIcon
          size="lg"
          className="justify-center"
          key={cProvider}
          type={cProvider}
        />
      ))}
    </>
  );
};

export function CloudProvidersInfoItem({
  cloudProviders,
  maxShownIcons = 4,
}: {
  cloudProviders: CloudProvidersWithOther;
  maxShownIcons?: number;
}) {
  const plusTextClasses = classNames(
    isDarkMode() ? "dark:bg-blue-steel-830" : "bg-gray-100",
    "inline-flex h-6  w-6 cursor-pointer items-center justify-center rounded-md text-xs text-secondary",
    "dark:border-blue-steel-850 dark:text-dark-bg-secondary",
  );

  const shownIcons = cloudProviders?.slice(0, maxShownIcons);
  const shouldShowTooltip = cloudProviders.length > maxShownIcons;

  const contentToShow = (
    <div className="flex w-full items-center space-x-2">
      <CloudProvidersIcons icons={shownIcons} />
      {shouldShowTooltip && (
        <TruncateText addClassName={plusTextClasses}>
          <FormattedMessage
            defaultMessage="+{count}"
            id="wQiWS1"
            description="Tag text for more risk factors"
            values={{ count: cloudProviders.length - maxShownIcons }}
          />
        </TruncateText>
      )}
    </div>
  );

  return shouldShowTooltip ? (
    <Tooltip label={<CloudProvidersTooltip cloudProviders={cloudProviders} />}>
      {contentToShow}
    </Tooltip>
  ) : (
    contentToShow
  );
}
