import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const BitbucketIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Bitbucket",
    id: "miurVM",

    description: "provider icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 21 18" {...props}>
      <title>{alt}</title>
      <path
        d="M0.649629 5.36176e-05C0.55602 -0.00115683 0.463281 0.0181409 0.377925 0.0565915C0.292569 0.0950422 0.216665 0.151713 0.155545 0.222625C0.0944254 0.293537 0.0495709 0.37697 0.024132 0.467064C-0.00130688 0.557158 -0.00671333 0.651729 0.00829238 0.744136L2.73089 17.272C2.7648 17.4742 2.8689 17.6581 3.02489 17.7912C3.18089 17.9243 3.37881 17.9982 3.58389 17.9999H16.6452C16.7989 18.0019 16.9482 17.9486 17.0659 17.8497C17.1836 17.7508 17.2619 17.6129 17.2865 17.4612L20.0091 0.747342C20.0241 0.65494 20.0187 0.560379 19.9932 0.470299C19.9678 0.380218 19.9229 0.296802 19.8618 0.225907C19.8007 0.155012 19.7248 0.0983571 19.6394 0.0599206C19.5541 0.0214842 19.4613 0.00219785 19.3677 0.00341591L0.649629 5.36176e-05ZM12.1139 11.9454H7.94509L6.8163 6.04811H13.1241L12.1139 11.9454Z"
        fill="#2684FF"
      />
      <path
        d="M19.1267 6.04004H13.1124L12.103 11.9326H7.93752L3.01904 17.7708C3.17494 17.9055 3.37369 17.9805 3.57976 17.9822H16.6339C16.7875 17.9842 16.9367 17.9309 17.0543 17.8321C17.172 17.7333 17.2502 17.5955 17.2748 17.4439L19.1267 6.04004Z"
        fill="url(#paint0_linear_1771_21134)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1771_21134"
          x1="20.5172"
          y1="7.69021"
          x2="13.5698"
          y2="17.5548"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stopColor="#0052CC" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
