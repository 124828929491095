import { FormLayout } from "form";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, useModal } from "ui";
import {
  NextButton,
  WizardForm,
  type WizardStepState,
} from "../../../../../components/Wizard";
import { OnboardProvidersModal } from "../../../../../providers/ConnectProvider/components/OnboardProvidersModal";
import { buttonNames } from "../../../../messages/commonMessages";
import CFTDownloadButtonGroup from "../../components/CFTDownloadButtonGroup";
import ConnectToAwsLink from "../../components/ConnectToAwsLink";
import AddDetailsFormLayout from "./AddDetailsFormLayout";

type AddDetailsProps = {
  context: {
    closeModal: () => void;
    setCftDownloadedOrCreated: React.Dispatch<React.SetStateAction<boolean>>;
    isCftDownloadedOrCreated: boolean;
    customConfigurationStep: WizardStepState[];
    isCustomConfigEnabled: boolean;
    setCustomConfigEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  };
};

export default function AddDetails({
  context: {
    closeModal: closeOnboardingWizard,
    isCustomConfigEnabled,
    setCustomConfigEnabled,
    isCftDownloadedOrCreated,
    customConfigurationStep,
    setCftDownloadedOrCreated,
  },
}: AddDetailsProps) {
  const { openModal: openCloudSelectionModal } = useModal(
    OnboardProvidersModal,
  );
  const [disabled, setDisabled] = useState(false);

  const handleClick = useCallback(() => {
    closeOnboardingWizard();
    openCloudSelectionModal();
  }, [closeOnboardingWizard, openCloudSelectionModal]);

  return (
    <WizardForm
      activeStepTitle={
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-1">
            <div className="flex flex-col">
              <FormattedMessage
                defaultMessage="AWS Cloud Details"
                id="Ky58NP"
                description="Title for Add Details Step"
              />
            </div>
          </div>
          <ConnectToAwsLink />
        </div>
      }
      actions={
        <>
          <Button onClick={handleClick}>
            <FormattedMessage {...buttonNames.previous} />
          </Button>
          <CFTDownloadButtonGroup
            closeModal={closeOnboardingWizard}
            setCftDownloadedOrCreated={setCftDownloadedOrCreated}
            disabled={disabled}
          />
          <NextButton
            disabled={!isCftDownloadedOrCreated && !isCustomConfigEnabled}
          />
        </>
      }
    >
      <FormLayout>
        <AddDetailsFormLayout
          setDisabled={setDisabled}
          customConfigurationStep={customConfigurationStep}
          setCustomConfigEnabled={setCustomConfigEnabled}
        />
      </FormLayout>
    </WizardForm>
  );
}
