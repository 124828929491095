import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { RqlSearchTimeRangeComponentsSchema } from "requests";
import { z } from "zod";
import { investigateLayoutRoute } from "../investigateLayoutRoute";

export const SearchResults = lazy(() => import("./SearchResults"));

const rqlSearchSchema = z
  .object({
    searchId: z.optional(z.string().catch("")),
    query: z.optional(z.string().catch("")),
    alertId: z.optional(z.string().catch("")),
    policyId: z.optional(z.string().catch("")),
    alertPolicyName: z.optional(z.string().catch("")),
    assetId: z.optional(z.string().catch("")),
    viewBy: z.optional(z.enum(["asset", "cve"])),
  })
  .merge(RqlSearchTimeRangeComponentsSchema);

export type RqlSearchRouteSearch = z.infer<typeof rqlSearchSchema>;
export type searchSchemaType = z.infer<typeof rqlSearchSchema>;

export const searchRoute = new Route({
  getParentRoute: () => investigateLayoutRoute,
  path: "/search",
  component: SearchResults,
  validateSearch: rqlSearchSchema,
});
