import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { type Stage } from "requests";
import { TruncateText } from "ui";
import { StagedTitlesStyle } from "../../../../components/AssetDetails/StagedTitlesStyle";
import { type Stages } from "../types";

interface ImageStagesInfoToolTipProps {
  images: Stages;
}

export function ImageStagesInfoToolTip({
  images,
}: ImageStagesInfoToolTipProps) {
  const totalImagesCount = useMemo(() => {
    return Object.values(images ?? {}).reduce((sum, curr) => sum + curr, 0);
  }, [images]);

  return (
    <div className="px-2">
      <div data-testid="total-images" className="py-3 font-bold">
        <FormattedMessage
          defaultMessage="{count} Images"
          id="S+Ksv+"
          description="Images label"
          values={{
            count: totalImagesCount,
          }}
        />
      </div>
      {Object.entries(images).map(([stage, count]) => {
        const { icon, label } = StagedTitlesStyle[stage as Stage];

        // added truncate text for large number to prevent UI from breaking, but user cannot see the whole text.
        // todo: we should add text manipulation for large number of images
        return (
          <div
            key={stage}
            className="flex w-52 flex-row items-center justify-between py-1.5"
          >
            <div className="flex flex-row items-center">
              <div className="flex items-center gap-x-1">
                {icon}
                <span
                  data-testid={`stage-${stage}-label`}
                  className="font-bold"
                >
                  {label}
                </span>
              </div>
            </div>
            <span className="flex w-14 flex-col text-right font-bold">
              <TruncateText data-testid={`stage-${stage}-count`}>
                {count}
              </TruncateText>
            </span>
          </div>
        );
      })}
    </div>
  );
}
