import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { type UserRolesType } from "requests";
import { TruncateCell } from "table";
import { Body, Link, TruncateText } from "ui";
import { useRoleModalForm } from "../../../roles/Forms";
import { useCodeRolesModal } from "../CodeRolesModal/CodeRolesModal";

export default function Roles({
  context,
  value,
}: {
  context: { roles: UserRolesType };
  value: {
    id: string;
    name: string;
  }[];
}) {
  const { openModal } = useCodeRolesModal();
  const { openModal: openEditModal } = useRoleModalForm();

  const { roles } = context;

  const rolesListCount = useMemo(() => {
    const count = value.length;
    const currentRoleIds = value.map((r) => r.id);
    const translation = (
      <FormattedMessage
        values={{ count }}
        defaultMessage="{count} {count, plural, one {role} other {Roles}}"
        id="UmQhjr"
        description="Roles cell text"
      />
    );

    if (value.length > 1) {
      return (
        <Link
          size="sm"
          onClick={() =>
            openModal({
              data: roles?.filter((r) => currentRoleIds?.includes(r?.id)),
            })
          }
        >
          {translation}
        </Link>
      );
    }

    const isMsspSystemAdminRole = value[0].name === "MSSP System Admin";

    if (isMsspSystemAdminRole) {
      return (
        <TruncateText>
          <Body as="span" size="sm">
            {value[0].name}
          </Body>
        </TruncateText>
      );
    }

    return (
      <Link
        size="sm"
        onClick={() =>
          openEditModal({
            action: "edit",
            // @ts-expect-error match InitialValues type in roles with UserRolesType
            initialValues: roles?.find((r) => r.id === value[0].id),
          })
        }
      >
        {value[0].name}
      </Link>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, roles]);

  return <TruncateCell value={rolesListCount} />;
}
