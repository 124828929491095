import { DownloadIcon } from "icons";
import { type ReactElement } from "react";
import { Button, Tooltip } from "ui";

import { type Data, type TableInstance } from "../../types";
import { downloadCSV } from "../../utils/downloadTable";

export interface DownloadButtonProps<D extends Data> {
  fileName: string | (() => string);
  tableInstance: TableInstance<D>;
}

export function DownloadButton<D extends Data>({
  fileName,
  tableInstance,
}: DownloadButtonProps<D>): ReactElement {
  const disabled = tableInstance.rows.length === 0;
  return (
    <Tooltip label="Download the current table data">
      <Button
        aria-label="Download"
        disabled={disabled}
        appearance="tertiary"
        icon={<DownloadIcon />}
        onClick={() => {
          const fn = typeof fileName === "function" ? fileName() : fileName;
          downloadCSV(tableInstance, fn);
        }}
      />
    </Tooltip>
  );
}
