import { useMemo, useState } from "react";
import { TruncateCell, type RowInstance } from "table";
import { Body, TruncateText } from "ui";

import { useIntl } from "react-intl";
import { type CloudTypes } from "requests";
import { RouterLink } from "../../../../../../components/Navigation/RouterLink/RouterLink";
import { settingsCloudAccountsSidecarRoute } from "../../../../../settings/cloudAccounts/Sidecar/settingsCloudAccountsSidecarRoute";
import { type CloudAccount } from "../../../../types";
import { CloudAccountsModal } from "./CloudAccountsModal";

type CloudAccountsCellProps = {
  value: CloudAccount[];
  row: Pick<RowInstance<{ cloudType?: CloudTypes }>, "original">;
};

export function CloudAccountsCell({ value, row }: CloudAccountsCellProps) {
  const intl = useIntl();

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);
  const cloudType = row.original?.cloudType;

  const cellText = useMemo(() => {
    if (value.length === 1) {
      return (
        <TruncateText>
          <RouterLink
            to={settingsCloudAccountsSidecarRoute.id}
            params={{
              cloudAccountId: value[0].id,
              cloudType: value[0]?.cloudType ?? cloudType,
            }}
          >
            {value[0].name}
          </RouterLink>
        </TruncateText>
      );
    }

    const cloudAccountsCountText = intl.formatMessage(
      {
        defaultMessage:
          "{count, number} cloud {count, plural, one {account} other {accounts}}",
        id: "gCiDo1",
        description: "Cloud Accounts count",
      },
      {
        count: value.length,
      },
    );

    return (
      <Body
        as="button"
        appearance="link"
        size="sm"
        onClick={openModal}
        addClassName="w-full"
        truncate
      >
        {cloudAccountsCountText}
      </Body>
    );
  }, [value, cloudType, intl]);

  if (!value.length) {
    const allText = intl.formatMessage({
      defaultMessage: "All",
      id: "cT6CzU",
      description:
        "Value in table cell to indicate the data applies to all cloud accounts.",
    });
    return <TruncateCell value={allText} />;
  }

  return (
    <>
      <TruncateCell value={cellText} />
      {value.length > 1 && (
        <CloudAccountsModal
          cloudAccounts={value}
          isOpen={modalOpen}
          closeModal={closeModal}
          cloudType={cloudType}
        />
      )}
    </>
  );
}
