import { isChina, isGov } from "environment";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIsBusinessLicenseType } from "prisma";
import { useMemo } from "react";
import { useIsSystemAdmin } from "requests";

export const useIsAppDnaSupported = (): boolean => {
  const { appDna, appDnaTags } = useFlags();
  const sysAdmin = useIsSystemAdmin();
  const { isBusinessLicenseType } = useIsBusinessLicenseType();
  return useMemo(
    () =>
      (appDna || appDnaTags) &&
      sysAdmin &&
      !isBusinessLicenseType &&
      !isChina() &&
      !isGov(),
    [appDna, appDnaTags, isBusinessLicenseType, sysAdmin],
  );
};
