import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const AmazonElastiCacheIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Amazon Elasticache",
    id: "uKUO9Q",

    description: "Amazon Elasticache icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M50.0313 33.5298C39.5058 33.5298 28.8235 36.2749 28.8235 41.5298V77.2945C28.8235 82.5494 39.4588 85.2945 49.9843 85.2945C60.5098 85.2945 71.1764 82.5494 71.1764 77.2945V41.5298C71.1764 36.2749 60.5254 33.5298 50.0313 33.5298ZM50.0313 82.1572C38.3607 82.1572 31.9607 79.02 31.9607 77.2945V69.7494C35.8823 72.1337 42.9411 73.3729 49.9843 73.3729C57.0274 73.3729 64.1019 72.1337 67.9921 69.7651V77.2945C68.0392 79.02 61.6705 82.1572 50.0313 82.1572ZM50.0313 70.2357C38.3607 70.2357 32.0078 67.0984 32.0078 65.3729V57.8435C35.9294 60.2121 42.9882 61.4514 50.0313 61.4514C57.0745 61.4514 64.149 60.2121 68.0392 57.8435V65.3729C68.0392 67.02 61.6705 70.2357 50.0313 70.2357ZM50.0313 58.3141C38.3607 58.3141 32.0078 55.1768 32.0078 53.4514V45.9063C35.9294 48.2906 42.9882 49.5298 50.0313 49.5298C57.0745 49.5298 64.149 48.2906 68.0392 45.9063V53.4514C68.0392 55.0984 61.6705 58.3141 50.0313 58.3141ZM50.0313 46.3925C38.3607 46.3925 31.9607 43.1768 31.9607 41.5298C31.9607 39.8827 38.2353 36.667 49.9843 36.667C61.7333 36.667 68.0392 39.8827 68.0392 41.5298C68.0392 43.1768 61.6705 46.3925 50.0313 46.3925Z"
        className="fill-[url(#paint0_linear_6619_101606)] dark:fill-white"
      />
      <path
        d="M88.4314 21.2786C88.8474 21.2786 89.2464 21.1133 89.5406 20.8192C89.8347 20.525 90 20.126 90 19.71V16.2747C90 15.8587 89.8347 15.4597 89.5406 15.1655C89.2464 14.8713 88.8474 14.7061 88.4314 14.7061H11.5686C11.1526 14.7061 10.7536 14.8713 10.4594 15.1655C10.1653 15.4597 10 15.8587 10 16.2747V19.6943C10 20.1103 10.1653 20.5093 10.4594 20.8035C10.7536 21.0977 11.1526 21.2629 11.5686 21.2629C12.4311 21.3451 13.2321 21.7458 13.815 22.3868C14.3978 23.0279 14.7208 23.8632 14.7208 24.7296C14.7208 25.596 14.3978 26.4313 13.815 27.0723C13.2321 27.7134 12.4311 28.1141 11.5686 28.1963C11.1526 28.1963 10.7536 28.3615 10.4594 28.6557C10.1653 28.9499 10 29.3489 10 29.7649V57.059C10 57.475 10.1653 57.874 10.4594 58.1682C10.7536 58.4624 11.1526 58.6276 11.5686 58.6276H25.6863V55.4904H19.4118V53.9217H25.6863V50.7845H17.8431C17.4271 50.7845 17.0281 50.9498 16.7339 51.2439C16.4398 51.5381 16.2745 51.9371 16.2745 52.3531V55.4904H13.1373V31.1296C14.5072 30.7121 15.7068 29.8656 16.5592 28.7147C17.4116 27.5639 17.8717 26.1696 17.8717 24.7374C17.8717 23.3053 17.4116 21.911 16.5592 20.7601C15.7068 19.6093 14.5072 18.7627 13.1373 18.3453V17.8433H86.8627V18.361C85.5129 18.795 84.3356 19.6463 83.5004 20.7922C82.6652 21.9381 82.2152 23.3195 82.2152 24.7374C82.2152 26.1554 82.6652 27.5368 83.5004 28.6827C84.3356 29.8285 85.5129 30.6798 86.8627 31.1139V55.4904H85.2941V52.3531C85.2941 51.9371 85.1289 51.5381 84.8347 51.2439C84.5405 50.9498 84.1415 50.7845 83.7255 50.7845H74.3137V53.9217H82.1569V55.4904H74.3137V58.6276H88.4314C88.8474 58.6276 89.2464 58.4624 89.5406 58.1682C89.8347 57.874 90 57.475 90 57.059V29.7649C90 29.3489 89.8347 28.9499 89.5406 28.6557C89.2464 28.3615 88.8474 28.1963 88.4314 28.1963C87.5835 28.0972 86.8016 27.6902 86.2342 27.0525C85.6667 26.4149 85.3531 25.591 85.3531 24.7374C85.3531 23.8838 85.6667 23.06 86.2342 22.4223C86.8016 21.7847 87.5835 21.3777 88.4314 21.2786Z"
        className="fill-[url(#paint1_linear_6619_101606)] dark:fill-white"
      />
      <path
        d="M49.2158 30.3922V22.5491C49.2158 22.1331 49.0505 21.7341 48.7564 21.4399C48.4622 21.1457 48.0632 20.9805 47.6472 20.9805H36.6668C36.2507 20.9805 35.8518 21.1457 35.5576 21.4399C35.2634 21.7341 35.0981 22.1331 35.0981 22.5491V31.9609H38.2354V24.1177H46.0785V30.3922H49.2158Z"
        className="fill-[url(#paint2_linear_6619_101606)] dark:fill-white"
      />
      <path
        d="M64.9021 31.9609V22.5491C64.9021 22.1331 64.7368 21.7341 64.4426 21.4399C64.1485 21.1457 63.7495 20.9805 63.3334 20.9805H52.3531C51.937 20.9805 51.538 21.1457 51.2439 21.4399C50.9497 21.7341 50.7844 22.1331 50.7844 22.5491V30.3922H53.9217V24.1177H61.7648V31.9609H64.9021Z"
        className="fill-[url(#paint3_linear_6619_101606)] dark:fill-white"
      />
      <path
        d="M74.3138 44.5099V47.6471H79.0197C79.4357 47.6471 79.8347 47.4819 80.1289 47.1877C80.4231 46.8935 80.5884 46.4945 80.5884 46.0785V22.5491C80.5884 22.1331 80.4231 21.7341 80.1289 21.4399C79.8347 21.1457 79.4357 20.9805 79.0197 20.9805H68.0393C67.6233 20.9805 67.2243 21.1457 66.9301 21.4399C66.636 21.7341 66.4707 22.1331 66.4707 22.5491V33.5295H69.608V24.1177H77.4511V44.5099H74.3138Z"
        className="fill-[url(#paint4_linear_6619_101606)] dark:fill-white"
      />
      <path
        d="M22.5491 24.1177H30.3923V33.5295H33.5295V22.5491C33.5295 22.1331 33.3642 21.7341 33.0701 21.4399C32.7759 21.1457 32.3769 20.9805 31.9609 20.9805H20.9805C20.5645 20.9805 20.1655 21.1457 19.8713 21.4399C19.5771 21.7341 19.4119 22.1331 19.4119 22.5491V46.0785C19.4119 46.4945 19.5771 46.8935 19.8713 47.1877C20.1655 47.4819 20.5645 47.6471 20.9805 47.6471H25.6864V44.5099H22.5491V24.1177Z"
        className="fill-[url(#paint5_linear_6619_101606)] dark:fill-white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6619_101606"
          x1={28.8235}
          y1={33.5298}
          x2={79.5631}
          y2={75.0441}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6619_101606"
          x1={10}
          y1={14.7061}
          x2={47.0576}
          y2={82.2038}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6619_101606"
          x1={35.0981}
          y1={20.9805}
          x2={45.7407}
          y2={34.6637}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6619_101606"
          x1={50.7844}
          y1={20.9805}
          x2={61.427}
          y2={34.6637}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6619_101606"
          x1={66.4707}
          y1={20.9805}
          x2={88.5248}
          y2={32.6561}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6619_101606"
          x1={19.4119}
          y1={20.9805}
          x2={41.4659}
          y2={32.6561}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
