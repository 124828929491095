import { useMemo } from "react";
import {
  ADD_DETAILS,
  AGENTLESS_API_DISCOVERY,
  AGENTLESS_SCAN,
  ALIBABA_CLOUD,
  AWS,
  DATA_SECURITY,
  OCI,
  SERVERLESS_SCAN,
} from "../../../../constants";
import {
  useGetSupportedFeaturesList,
  useIdentitySubscription,
} from "../../../../hooks";

import {
  type AccountType,
  type AwsAddDetailsStepValues,
  type CloudType,
} from "../../../../types";

import { useQuery } from "@tanstack/react-query";
import { LoadingIcon } from "icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  getDlpStatus,
  tenantKeys,
  type SecurityCapabilitiesType,
} from "requests";
import { useDLPFeature } from "../../../../../../../utils/useDLPFeatures";
import { useWizardContext } from "../../../../../components/Wizard";
import { getWizardStepIndex } from "../../../Onboarding/utils";
import FeatureGroup from "../FeatureGroup";
import { type FeatureItem } from "../FeatureGroup/FeatureGroup";
import { getSecurityCapabilitiesItems } from "./items";

interface SecurityCapabilitiesPropTypes {
  cloudType: CloudType;
  payload: {
    accountType: AccountType;
    awsPartition?: string;
  };
}

export default function SecurityCapabilities({
  cloudType,
  payload,
}: SecurityCapabilitiesPropTypes) {
  const { agentlessApiDiscovery } = useFlags();
  const {
    state: { steps },
  } = useWizardContext();
  const getStepIndex = getWizardStepIndex(steps);
  const addDetailsStepIndex = getStepIndex(ADD_DETAILS);

  const { accountType } = steps[addDetailsStepIndex]
    ?.values as AwsAddDetailsStepValues;

  const { isLoading, supportedFeaturesList } = useGetSupportedFeaturesList({
    cloudType: AWS,
    payload,
    options: {
      enabled: ![ALIBABA_CLOUD, OCI].includes(cloudType),
    },
  });

  const dlpHasPermission = useDLPFeature(true);
  const { data: dlpData } = useQuery({
    queryKey: tenantKeys.dlpStatus(),
    queryFn: getDlpStatus,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: dlpHasPermission && cloudType === AWS,
  });
  const { isSubscribed } = useIdentitySubscription();

  const provisionStatus = dlpData?.status || "provisionNotStarted";
  const dlpReady = provisionStatus === "provisionSuccessful";

  const data = useMemo(() => {
    const securityCapabilitiesItems = getSecurityCapabilitiesItems();

    let features = securityCapabilitiesItems
      .filter((item: FeatureItem) =>
        supportedFeaturesList.includes(item.value as SecurityCapabilitiesType),
      )
      .map((item: FeatureItem) => {
        if (item.id === "identitySecurity") {
          return {
            ...item,
            tag: isSubscribed ? "subscribed" : "not_subscribed",
          };
        }
        return item;
      });

    if (cloudType === AWS && !agentlessApiDiscovery) {
      features = features.filter(
        ({ value }) => value !== AGENTLESS_API_DISCOVERY,
      );
    }

    const dsIndex = features.findIndex(
      (feature) => feature.value === DATA_SECURITY,
    );

    if (!dlpReady && dsIndex !== -1) {
      return [...features.slice(0, dsIndex), ...features.slice(dsIndex + 1)];
    }
    return features;
  }, [
    cloudType,
    agentlessApiDiscovery,
    dlpReady,
    supportedFeaturesList,
    isSubscribed,
  ]);

  const isComputeWorkloadSupported =
    supportedFeaturesList.includes(AGENTLESS_SCAN) ||
    supportedFeaturesList.includes(SERVERLESS_SCAN);

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <>
      {data.length ? (
        <section className="space-y-2">
          <FeatureGroup
            cloudType={cloudType}
            items={data}
            name="securityCapabilities"
            accountType={accountType}
            isComputeWorkloadSupported={isComputeWorkloadSupported}
          />
        </section>
      ) : null}
    </>
  );
}
