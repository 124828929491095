import { type ReactElement } from "react";
import { useController } from "react-hook-form";

import { type AccountType, type CloudType } from "../../../../types";
import FeaturesListView from "./FeaturesListView";

export type FeatureItem = {
  id: string;
  icon: ReactElement;
  value: string;
  showToggle?: boolean;
  orgScanToggleValue?: string;
  tag?: string;
};

interface FeatureGroupProps {
  name: string;
  cloudType?: CloudType;
  isComputeWorkloadSupported?: boolean;
  defaultValue?: object;
  items: FeatureItem[];
  accountType?: AccountType;
}

export default function FeatureGroup({
  name,
  cloudType,
  defaultValue = {},
  isComputeWorkloadSupported,
  items,
  accountType,
}: FeatureGroupProps) {
  const {
    field: { onChange, value: values },
  } = useController({
    name,
    defaultValue,
  });

  const {
    field: { onChange: onToggleChange, value: orgValues },
  } = useController({
    name: "orgSecurityCapabilities",
    defaultValue,
  });

  const featuresListProps = {
    values,
    orgValues,
    onChange,
    onToggleChange,
  };

  return (
    <FeaturesListView
      cloudType={cloudType}
      isComputeWorkloadSupported={isComputeWorkloadSupported}
      accountType={accountType}
      features={items}
      {...featuresListProps}
    />
  );
}
