import { orangeScale, visScaleA } from "colors";
import { type ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { type Stage } from "requests";
import { UCDIcon } from "../Navigation/UCDSelect/UCDIcon";

const DeployStageIcon = () => {
  const intl = useIntl();
  return (
    <UCDIcon
      mode="runtime"
      alt={intl.formatMessage({
        defaultMessage: "Deploy",
        id: "9ZihC6",
        description: "Deploy stage label",
      })}
    />
  );
};

const RunStageIcon = () => {
  const intl = useIntl();
  return (
    <UCDIcon
      mode="cloud"
      alt={intl.formatMessage({
        defaultMessage: "Run",
        id: "+SmtNz",
        description: "Run stage label",
      })}
    />
  );
};

const CodeAndBuildStageIcon = () => {
  const intl = useIntl();
  return (
    <UCDIcon
      mode="application"
      alt={intl.formatMessage({
        defaultMessage: "Code & Build",
        id: "kcSicK",
        description: "Code & Build stage label",
      })}
    />
  );
};

type StageTitleStyle = {
  label: ReactNode;
  icon: ReactNode;
  color?: string;
};

//TODO: change the colors to fit the icons and the Figma when the palette will update
export const StagedTitlesStyle: {
  [key in Stage]: StageTitleStyle;
} = {
  deploy: {
    label: (
      <FormattedMessage
        defaultMessage="Deploy"
        id="bGX7cc"
        description="Deploy message"
      />
    ),
    icon: <DeployStageIcon />,
    color: orangeScale.DEFAULT,
  },
  run: {
    label: (
      <FormattedMessage
        defaultMessage="Run"
        id="4QIsL9"
        description="Run message"
      />
    ),
    icon: <RunStageIcon />,
    color: visScaleA[400],
  },
  build: {
    label: (
      <FormattedMessage
        defaultMessage="Code & Build"
        id="t+uv2b"
        description="Code & Build message"
      />
    ),
    icon: <CodeAndBuildStageIcon />,
  },
};
