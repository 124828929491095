import { defineMessages } from "react-intl";

type MessageType = {
  defaultMessage: string;
  description: string;
};

export const resolutionReasonMessages: { [key: string]: MessageType } =
  defineMessages({
    RESOURCE_DELETED: {
      defaultMessage: "Resource Deleted",
      id: "H2Evs9",
      description: "Alert resolution reason label",
    },
    RESOURCE_UPDATED: {
      defaultMessage: "Resource Updated",
      id: "AUfAkM",
      description: "Alert resolution reason label",
    },
    POLICY_UPDATED: {
      defaultMessage: "Policy Updated",
      id: "zd84nF",
      description: "Alert resolution reason label",
    },
    POLICY_DISABLED: {
      defaultMessage: "Policy Disabled",
      id: "/drKKV",
      description: "Alert resolution reason label",
    },
    POLICY_DELETED: {
      defaultMessage: "Policy Deleted",
      id: "ilphTu",
      description: "Alert resolution reason label",
    },
    ALERT_RULE_UPDATED: {
      defaultMessage: "Alert Rule Updated",
      id: "H1Fr8m",
      description: "Alert resolution reason label",
    },
    ALERT_RULE_DISABLED: {
      defaultMessage: "Alert Rule Disabled",
      id: "AQVozh",
      description: "Alert resolution reason label",
    },
    ALERT_RULE_DELETED: {
      defaultMessage: "Alert Rule Deleted",
      id: "1elkaV",
      description: "Alert resolution reason label",
    },
    ACCOUNT_GROUP_UPDATED: {
      defaultMessage: "Account Group Updated",
      id: "kg6iNx",
      description: "Alert resolution reason label",
    },
    ACCOUNT_GROUP_DELETED: {
      defaultMessage: "Account Group Deleted",
      id: "JUUBUg",
      description: "Alert resolution reason label",
    },
    REMEDIATED: {
      defaultMessage: "Remediated",
      id: "tfrJp+",
      description: "Alert resolution reason label",
    },
    ACCOUNT_DISABLED: {
      defaultMessage: "Account Disabled",
      id: "oC+l+P",
      description: "Alert resolution reason label",
    },
    ACCOUNT_DELETED: {
      defaultMessage: "Account Deleted",
      id: "U+P0NW",
      description: "Alert resolution reason label",
    },
    ALERT_RULE_UPDATED_UPSCOPE: {
      defaultMessage: "Alert Rule Updated Upscope",
      id: "l7EMTg",
      description: "Alert resolution reason label",
    },
    SCHEDULED: {
      defaultMessage: "Scheduled",
      id: "AEr5yj",
      description: "Alert resolution reason label",
    },
    AUTO_REMEDIATED: {
      defaultMessage: "Auto Remediated",
      id: "CtOUVI",
      description: "Alert resolution reason label",
    },
    MANUALLY_REMEDIATED: {
      defaultMessage: "Manually Remediated",
      id: "fixl+r",
      description: "Alert resolution reason label",
    },
  });
