import { useIntl } from "react-intl";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type RenderModalProps,
} from "ui";

import { ResourceGroupsModalTable } from "./ResourceGroupsModalTable";

export function ResourceGroupsModal({
  resourceGroups,
  isOpen,
  closeModal,
}: RenderModalProps<{
  resourceGroups: string[];
}>) {
  const intl = useIntl();

  return (
    <Modal onClose={closeModal} isOpen={isOpen}>
      <ModalHeader
        enableClose
        title={intl.formatMessage({
          defaultMessage: "Resource Groups",
          id: "cT0lvi",
          description: "Label for resource groups",
        })}
      />
      <ModalBody>
        <ResourceGroupsModalTable resourceGroups={resourceGroups} />
      </ModalBody>
      <ModalFooter>
        <Button appearance="primary" onClick={closeModal}>
          {intl.formatMessage({
            defaultMessage: "Done",
            id: "qKDaSI",
            description: "Done Button Resource Groups Modal",
          })}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
