import { z } from "zod";
import * as schemas from "../../schemas";
import { findingTypesSchema } from "../findingTypesSchema";

export const actionPlanSortByEnum = [
  "criticality",
  "alertCount",
  "assetCount",
] as const;
export const ActionPlanSortByEnumSchema = z.enum(actionPlanSortByEnum);
export type ActionPlanSortByEnumType = z.infer<
  typeof ActionPlanSortByEnumSchema
>;

export const status = ["NEW", "IN_PROGRESS", "DONE"] as const;
export const StatusEnumSchema = z.enum(status);
export type StatusEnumType = z.infer<typeof StatusEnumSchema>;

export const visibility = ["hidden", "visible"] as const;
export const VisibilityEnumSchema = z.enum(visibility);

export const ActionPlanFilterSchema = z
  .object({
    id: z.array(z.string()),
    assignee: z.array(z.string()),
    status: z.array(StatusEnumSchema),
    "finding.type": z.array(z.string()),
  })
  .partial();

export type ActionPlanFilterType = z.infer<typeof ActionPlanFilterSchema>;

export const ActionPlanFiltersPostBodySchema = z.array(
  z.discriminatedUnion("name", [
    schemas.FilterSchema.extend({
      name: z.literal("status"),
      value: StatusEnumSchema,
    }),
    schemas.FilterSchema.extend({ name: z.literal("name") }),
    schemas.FilterSchema.extend({
      name: z.literal("visibility"),
      value: VisibilityEnumSchema,
    }),
    schemas.FilterSchema.extend({ name: z.literal("assigned") }),
    schemas.FilterSchema.extend({
      name: z.literal("findingTypes"),
      value: findingTypesSchema,
    }),
  ]),
);

export const ActionPlanRequestSchema = z.object({
  filters: ActionPlanFiltersPostBodySchema.optional(),
  sortBy: z
    .object({
      name: z.string(),
      order: z.string(),
    })
    .optional(),
  limit: z.number(),
  pageToken: z.string().optional(),
});

export const ActionPlanSchema = z.object({
  name: z.string(),
  id: z.string(),
  status: StatusEnumSchema,
  alertCount: z.number(),
  assetCount: z.number(),
  findingTypes: z.array(findingTypesSchema),
  impactfulAssetId: z.string(),
  impactfulAssetType: z.number(),
  thumbsUpDown: z.string(),
  thumbsDownReasons: z.array(z.string().optional()),
  thumbsDownDescription: z.string().nullish(),
  criticality: z.string(),
  hidden: z.boolean().optional(),
  summary: z.string(),
  assignee: z.string(),
  countByPolicyType: z.array(
    z.object({ policyType: z.string(), count: z.number() }),
  ),
  countByPolicyCategory: z.array(
    z.object({ policyCategory: z.string(), count: z.number() }),
  ),
  createdAtTs: z.number(),
  lastUpdatedTs: z.number(),
});

export type ActionPlanType = z.infer<typeof ActionPlanSchema>;

export const ActionPlanResponseSchema = z.object({
  lastRunTs: z.number(),
  totalCount: z.number(),
  value: z.array(ActionPlanSchema),
  nextPageToken: z.string().nullish(),
});

export type ActionPlanResponseType = z.infer<typeof ActionPlanResponseSchema>;

export const RelatedAlertsSchema = z.object({
  alertId: z.string(),
  alertTime: z.number(),
  alertStatus: z.string(),
  policy: z.object({
    policyName: z.string(),
    policyId: z.string(),
    policySeverity: z.string(),
    policyType: z.string(),
    findingTypes: z.array(z.string()),
  }),
  resource: z.object({
    accountId: z.string(),
    url: z.string(),
    name: z.string(),
    unifiedAssetId: z.string(),
    resourceDetailsAvailable: z.boolean(),
  }),
});

export type RelatedAlertsType = z.infer<typeof RelatedAlertsSchema>;

export const RelatedAlertsResponseSchema = z.object({
  lastRunTs: z.number(),
  id: z.string(),
  totalCount: z.number(),
  value: z.array(RelatedAlertsSchema),
  nextPageToken: z.string().nullish(),
});

export type RelatedAlertsResponseType = z.infer<
  typeof RelatedAlertsResponseSchema
>;

export const ImpactedAssetSchema = z.object({
  name: z.string(),
  unifiedAssetId: z.string(),
  accountId: z.string(),
  externalAssetId: z.string(),
  regionId: z.string(),
  assetType: z.string(),
  cloudType: z.string(),
  status: z.string(),
  resourceDetailsAvailable: z.boolean(),
});

export type ImpactedAssetType = z.infer<typeof ImpactedAssetSchema>;

export const ImpactedAssetsResponseSchema = z.object({
  lastRunTs: z.number(),
  id: z.string(),
  totalCount: z.number(),
  value: z.array(ImpactedAssetSchema),
  nextPageToken: z.string().nullish(),
});

export type ImpactedAssetsResponseType = z.infer<
  typeof ImpactedAssetsResponseSchema
>;

export const UpdateActionPlanRequestSchema = z
  .object({
    status: StatusEnumSchema,
    assignee: z.string(),
    hidden: z.boolean(),
    thumbsUpDown: z.string(),
    thumbsDownReasons: z.array(z.string().optional()),
    thumbsDownDescription: z.string().nullish(),
    actionPlanId: z.string(),
  })
  .partial();

export type UpdateActionPlanRequestType = z.infer<
  typeof UpdateActionPlanRequestSchema
>;

export const ActionPlanNameSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type ActionPlanNameType = z.infer<typeof ActionPlanNameSchema>;

export const ActionPlanNamesResponseSchema = z.object({
  lastRunTs: z.number(),
  totalCount: z.number(),
  value: z.array(ActionPlanNameSchema),
});

export const RecommendationSummaryResponseSchema = z.object({
  lastRunTs: z.number(),
  id: z.string(),
  recommendationSummary: z.string(),
});

export type RecommendationSummaryResponseType = z.infer<
  typeof RecommendationSummaryResponseSchema
>;

export const NotificationSchema = z.object({
  onDemandNotificationConfig: z.object({
    clientId: z.string(),
    generatorType: z.string(),
    integrationType: z.string(),
    templateId: z.string().optional(),
    recipients: z.array(z.string()),
  }),
  translation: z.object({
    body: z.string().optional(),
    details: z.object({
      actionPlanRelatedIssuesCallbackUrl: z.string(),
      actionPlanImpactedAssetsCallbackUrl: z.string(),
      actionPlanHowToFixCallbackUrl: z.string(),
    }),
  }),
});

export type NotificationType = z.infer<typeof NotificationSchema>;

export const NotificationResponseSchema = z.object({
  id: z.string().optional(),
  success: z.number(),
  failed: z.number(),
  successfulEvents: z
    .array(
      z
        .object({
          recipient: z.object({
            generatorType: z.string(),
            integrationType: z.string(),
            id: z.string(),
            refId: z.string().optional(),
          }),
          customerId: z.number(),
          clientId: z.string(),
          metaData: z.object({ jiraKey: z.string() }).optional(),
          notificationId: z.string(),
        })
        .optional(),
    )
    .optional(),
  failedEvents: z
    .array(
      z
        .object({
          recipient: z.object({
            generatorType: z.string(),
            integrationType: z.string(),
            id: z.string(),
            refId: z.string(),
          }),
          customerId: z.number(),
          errorMessage: z.string(),
          notificationId: z.string(),
        })
        .optional(),
    )
    .optional(),
});

export const ActionPlanJiraTicketStatus = z.array(
  z
    .array(
      z.object({
        findingKey: z.string().optional(),
        incidentIds: z.array(z.string()).optional(),
        incidentType: z.any().optional(),
        integrationId: z.string().optional(),
        metadata: z
          .array(
            z.object({
              issueId: z.string(),
              key: z.string(),
              status: z.string(),
              url: z.string(),
            }),
          )
          .optional(),
      }),
    )
    .optional(),
);
