import { useIsBusinessLicenseType } from "prisma";
import { Wizard, type WizardStepState } from "../../../../components/Wizard";
import {
  ACCOUNT_TYPE,
  ACTIVE_DIRECTORY,
  AGENTLESS_DEFAULT_MEMBER_STATE,
  AGENTLESS_SCAN,
  APPLICATION_ID,
  APPLICATION_KEY,
  AUTO_MAP,
  AUTO_PROTECT,
  AZURE,
  AZURE_ACTIVE_DIR_ONBOARD_ENABLED,
  AZURE_CHINA,
  AZURE_SELECTED_NODES_INITIAL,
  CONFIGURE_ACCOUNT,
  ENABLED,
  ENVIRONMENT_TYPE,
  ERRORS,
  GET_STARTED,
  IS_EDIT,
  MANUAL,
  MONITOR_FLOW_LOGS,
  NAME,
  REVIEW_STATUS,
  SERVERLESS_DEFAULT_MEMBER_STATE,
  SERVERLESS_SCAN,
  SERVICE_PRINCIPAL_ID,
  TENANT,
  TENANT_ID,
  VALUE,
  WIZARD_HEIGHT,
} from "../../../constants";
import {
  useGetAzureCloudAccountsCas,
  useGetDefaultAccountGroupId,
} from "../../../hooks";
import { stepTitle, wizardTitle } from "../../../messages/commonMessages";

import { isChina } from "environment";
import { fromJS } from "immutable";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { isEmpty } from "remeda";
import {
  type SecurityCapabilitiesType,
  type SecurityCapabilityValuesType,
} from "requests";
import LoadingModal from "../../../components/LoadingModal";
import { type OnboardingProps } from "../../../types";
import { ActiveStepTitle } from "../components";
import ConfigureAccount from "./ConfigureAccount";
import GetStarted from "./GetStarted";
import ReviewStatus from "./ReviewStatus";
import { AzureSelectMonitoredProjectContextProvider } from "./context/AzureSelectMonitoredProjectContext";
import { type AzureSelectMonitoredProjectInitialStateType } from "./context/AzureSelectMonitoredProjectContext/state/initialState";

const featureMap: Record<
  string,
  Exclude<
    SecurityCapabilitiesType,
    | "Cloud Visibility Compliance and Governance"
    | "Identity Security"
    | "Threat Detection"
    | "Remediation"
    | "Data Security"
  >
> = {
  "compute-agentless": AGENTLESS_SCAN,
  "compute-auto-protect": AUTO_PROTECT,
  "compute-serverless-scan": SERVERLESS_SCAN,
};

export function AzureOnboarding({
  accountId,
  closeModal,
  isOpen,
  isEdit = false,
  initialActiveIndex = 0,
  fromProvidersPage,
}: OnboardingProps) {
  const intl = useIntl();
  const { azureAutoMap } = useFlags();
  const { isBusinessLicenseType, isLoading: isLicenseTypeLoading } =
    useIsBusinessLicenseType();

  const { data: azureData, isLoading } = useGetAzureCloudAccountsCas({
    accountId,
    options: { enabled: isEdit },
  });

  const {
    clientId = "",
    cloudAccount,
    accountGroupMode = MANUAL,
    environmentType = !isChina() ? AZURE : AZURE_CHINA,
    key = "",
    memberSyncEnabled: rootSyncEnabled = true,
    monitorFlowLogs = true,
    servicePrincipalId = "",
    tenantId = "",
    groupIds = [],
    defaultAccountGroupId = "",
    hierarchySelection = [],
  } = azureData ?? {};

  const {
    accountType = TENANT,
    accountId: subscriptionId,
    enabled = true,
    name = "Azure Account",
    features = [],
  } = cloudAccount ?? {};

  const status = isEdit ? "valid" : "pending";

  const securityCapabilitiesValues = useMemo(
    () =>
      features.reduce(
        (
          values: Record<SecurityCapabilitiesType, boolean>,
          { featureName, featureState },
        ) => {
          /* c8 ignore next */
          if (featureMap[featureName])
            values[featureMap[featureName]] = featureState === "enabled";
          return values;
        },
        {} as Record<SecurityCapabilitiesType, boolean>,
      ),
    [features],
  );

  const orgSecurityCapabilitiesValues = useMemo(
    () =>
      features
        .filter(({ featureName }) => {
          const feature = featureMap[featureName];
          return feature === AGENTLESS_SCAN || feature === SERVERLESS_SCAN;
        })
        .reduce(
          (
            values: Record<SecurityCapabilityValuesType, boolean>,
            { featureName, defaultMemberState },
          ) => {
            featureMap[featureName] === AGENTLESS_SCAN
              ? (values[AGENTLESS_DEFAULT_MEMBER_STATE] = Boolean(
                  defaultMemberState === ENABLED ||
                    (!securityCapabilitiesValues[AGENTLESS_SCAN] &&
                      !isBusinessLicenseType),
                ))
              : (values[SERVERLESS_DEFAULT_MEMBER_STATE] = Boolean(
                  defaultMemberState === ENABLED ||
                    (!securityCapabilitiesValues[SERVERLESS_SCAN] &&
                      !isBusinessLicenseType),
                ));
            return values;
          },
          {} as Record<SecurityCapabilityValuesType, boolean>,
        ),
    [features, isBusinessLicenseType, securityCapabilitiesValues],
  );

  const remediation = useMemo(
    () =>
      features.filter(({ featureName }) => featureName === "remediation")[0]
        ?.featureState === "enabled",
    [features],
  );

  const {
    defaultAccountGroupId: newDefaultAccountGroupId = [],
    isLoading: isAccountGroupItemsLoading,
  } = useGetDefaultAccountGroupId();

  const accountGroupDefaultValue = useMemo(() => {
    let defaultAccountGroupIdValue: string | string[] = [];
    if (isEdit) {
      /* c8 ignore start */
      if (accountType === TENANT && rootSyncEnabled) {
        defaultAccountGroupIdValue =
          azureAutoMap && accountGroupMode === AUTO_MAP
            ? isEmpty(groupIds[0])
              ? newDefaultAccountGroupId[0]?.key
              : groupIds[0]
            : defaultAccountGroupId;
      } else {
        defaultAccountGroupIdValue = groupIds;
      }
      /* c8 ignore stop */
    } else {
      defaultAccountGroupIdValue = newDefaultAccountGroupId[0]?.key;
    }
    return defaultAccountGroupIdValue;
  }, [
    accountGroupMode,
    accountType,
    azureAutoMap,
    defaultAccountGroupId,
    groupIds,
    isEdit,
    newDefaultAccountGroupId,
    rootSyncEnabled,
  ]);

  const apiAzureActiveDir = useMemo(
    () => isEdit && accountType === TENANT && !rootSyncEnabled,
    [accountType, isEdit, rootSyncEnabled],
  );

  const selectProjectInitialData = useMemo(() => {
    if (isEdit && accountType !== TENANT)
      return fromJS({}) as AzureSelectMonitoredProjectInitialStateType;
    return fromJS({
      [SERVICE_PRINCIPAL_ID]: {
        [VALUE]: servicePrincipalId,
        [ERRORS]: [],
      },
      [TENANT_ID]: {
        [VALUE]: tenantId,
        [ERRORS]: [],
      },
      [APPLICATION_ID]: {
        [VALUE]: clientId,
        [ERRORS]: [],
      },
      [AZURE_ACTIVE_DIR_ONBOARD_ENABLED]: {
        [VALUE]: apiAzureActiveDir,
        [ERRORS]: [],
      },
      [ACCOUNT_TYPE]: {
        [VALUE]: accountType,
        [ERRORS]: [],
      },
      [NAME]: {
        [VALUE]: name,
        [ERRORS]: [],
      },
      [ENVIRONMENT_TYPE]: {
        [VALUE]: environmentType,
        [ERRORS]: [],
      },
      [APPLICATION_KEY]: {
        [VALUE]: key,
        [ERRORS]: [],
      },
      [MONITOR_FLOW_LOGS]: {
        [VALUE]: isBusinessLicenseType ? false : monitorFlowLogs,
        [ERRORS]: [],
      },
      [IS_EDIT]: { [VALUE]: isEdit },
      [AZURE_SELECTED_NODES_INITIAL]: {
        [VALUE]: hierarchySelection,
        [ERRORS]: [],
      },
    });
  }, [
    accountType,
    clientId,
    environmentType,
    hierarchySelection,
    isBusinessLicenseType,
    isEdit,
    key,
    monitorFlowLogs,
    name,
    servicePrincipalId,
    tenantId,
    apiAzureActiveDir,
  ]);

  const steps = useMemo((): WizardStepState[] => {
    const wizardSteps: WizardStepState[] = [
      {
        Component: GetStarted,
        disabled: false,
        key: GET_STARTED,
        label: intl.formatMessage(stepTitle[GET_STARTED]),
        hasBeenSubmitted: isEdit,
        status,
        values: {
          accountType: apiAzureActiveDir ? ACTIVE_DIRECTORY : accountType,
          environmentType,
          rootSyncEnabled: rootSyncEnabled,
          securityCapabilities: isEdit
            ? { ...securityCapabilitiesValues }
            : { [AGENTLESS_SCAN]: !isChina() && !isBusinessLicenseType },
          orgSecurityCapabilities: isEdit
            ? { ...orgSecurityCapabilitiesValues }
            : {
                [AGENTLESS_DEFAULT_MEMBER_STATE]:
                  !isChina() && !isBusinessLicenseType,
                [SERVERLESS_DEFAULT_MEMBER_STATE]:
                  !isChina() && !isBusinessLicenseType,
              },
        },
      },
      {
        Component: ConfigureAccount,
        disabled: !isEdit,
        key: CONFIGURE_ACCOUNT,
        label: intl.formatMessage(stepTitle[CONFIGURE_ACCOUNT]),
        hasBeenSubmitted: isEdit,
        status,
        values: {
          clientId,
          key,
          monitorFlowLogs: isBusinessLicenseType ? false : monitorFlowLogs,
          remediation,
          servicePrincipalId,
          subscriptionId,
          tenantId,
          groupIds: accountGroupDefaultValue,
          name,
          autoMap: accountGroupMode === AUTO_MAP,
        },
      },
      {
        Component: ReviewStatus,
        disabled: !isEdit,
        key: REVIEW_STATUS,
        label: intl.formatMessage(stepTitle[REVIEW_STATUS]),
        hasBeenSubmitted: isEdit,
        status,
      },
    ];
    return wizardSteps;
  }, [
    accountGroupDefaultValue,
    orgSecurityCapabilitiesValues,
    accountGroupMode,
    accountType,
    apiAzureActiveDir,
    clientId,
    environmentType,
    intl,
    isBusinessLicenseType,
    isEdit,
    key,
    monitorFlowLogs,
    name,
    remediation,
    securityCapabilitiesValues,
    servicePrincipalId,
    status,
    subscriptionId,
    tenantId,
    rootSyncEnabled,
  ]);

  const title = (
    <ActiveStepTitle
      label={intl.formatMessage(isEdit ? wizardTitle.edit : wizardTitle.add)}
      cloudType={AZURE}
      iconSize={"lg"}
    />
  );

  if (isLoading || isAccountGroupItemsLoading || isLicenseTypeLoading) {
    return <LoadingModal title={title} />;
  }

  return (
    <AzureSelectMonitoredProjectContextProvider
      initialState={selectProjectInitialData}
    >
      <Wizard
        context={{
          accountId,
          closeModal,
          enabled,
          isEdit,
          apiAzureActiveDir,
          fromProvidersPage,
        }}
        initialActiveIndex={initialActiveIndex}
        isOpen={isOpen}
        onClose={closeModal}
        size="xl"
        steps={steps}
        style={{ minHeight: WIZARD_HEIGHT }}
        title={title}
      />
    </AzureSelectMonitoredProjectContextProvider>
  );
}
