import { FilterObjectSchema, FilterStateSchema } from "../listItems/schemas";

import { z } from "zod";

export const TablePreferencesSchema = z
  .object({
    columnOrder: z.array(z.string()),
    hiddenColumns: z.array(z.string()),
    globalFilter: z.string(),
    groupBy: z.array(z.string()),
    sortBy: z.array(z.object({ id: z.string(), desc: z.boolean().optional() })),
    columnResizing: z
      .object({
        columnWidths: z.record(z.string(), z.number()),
        headerIdWidths: z.array(z.array(z.string().or(z.number()))),
        columnWidth: z.number(),
        isResizingColumn: z.string().nullable(),
      })
      .partial(),
  })
  .deepPartial();
export type TablePreferencesType = z.infer<typeof TablePreferencesSchema>;

export const GroupByPreferencesSchema = z.object({
  value: z.string().optional(),
});

export const ViewPreferencesSchema = z.object({
  view: z.enum(["table", "cards"]),
});
export type ViewPreferencesType = z.infer<typeof ViewPreferencesSchema>["view"];

export const SavedViewSchema = z.object({
  id: z.string(),
  name: z.string(),
  display: z.boolean().optional(),
  manuallyHidden: z.boolean().optional(),
  isEdited: z.boolean().optional(),
  settings: z.object({
    filterState: FilterStateSchema.optional(),
    tableState: TablePreferencesSchema.optional(),
  }),
});
export type SavedView = z.infer<typeof SavedViewSchema>;

export const SavedViewsSchema = z.record(z.string(), SavedViewSchema);

export const FilterBarPreferencesSchema = z
  .object({
    filters: FilterObjectSchema.optional(),
    displayedFilters: z.array(z.string()).optional(),
  })
  .optional();

export const defaultSavedViewsPreferencesSchema = z.object({
  views: SavedViewsSchema,
  order: z.array(z.string()),
});
export type DefaultSavedViewsPreferencesType = z.infer<
  typeof defaultSavedViewsPreferencesSchema
>;

export const ComplianceStandardsPreferencesSchema =
  defaultSavedViewsPreferencesSchema;
export const PoliciesPreferencesSchema = defaultSavedViewsPreferencesSchema;

export const AlertsOvervewPreferencesSchema = z.object({
  views: z.record(
    z.string(),
    SavedViewSchema.extend({
      settings: z.object({
        showMitre: z.boolean(),
        filterState: FilterStateSchema,
        tableState: TablePreferencesSchema,
        groupBy: z.string(),
        sortBy: z.string(),
        sortOrder: z.string(),
      }),
    }),
  ),
  order: z.array(z.string()),
});

export const ComplianceRequirementsPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const ComplianceSectionsPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const ComplianceReportPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const AlertsReportPreferencesSchema = z.object({
  table: TablePreferencesSchema,
});

export const CloudSecurityReportPreferencesSchema = z.object({
  table: TablePreferencesSchema,
});

export const PermissionGroupsPreferencesSchema = z.object({
  table: TablePreferencesSchema,
});

export const InvestigatePreferencesSchema = z
  .object({
    backgroundJobs: TablePreferencesSchema,
    investigateAuditEventsRql: TablePreferencesSchema,
    investigateAssetsRql: TablePreferencesSchema,
    investigateConfigRql: TablePreferencesSchema,
    investigatePermissionsRql: TablePreferencesSchema,
    investigatePermissionsSidecar: TablePreferencesSchema,
    savedSearchesV3: TablePreferencesSchema,
  })
  .partial();

export const InvestigateSavedSearchesPreferencesSchema = z
  .object({
    filterBarState: FilterBarPreferencesSchema,
  })
  .partial();

export const AssetExplorerPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const AuditLogsPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const UnmanagedAssetPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const UnmanagedAssetListPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const unmanagedAssetByCloudTypePreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const unmanagedAssetByResourceTypePreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const AssetsInventoryPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
  groupBy: GroupByPreferencesSchema,
  view: ViewPreferencesSchema,
});

export const AssetsInventoryCloudAccountPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const AssetsInventoryServiceNamePreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const AssetsInventoryCloudTypePreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const AssetsInventoryCloudTypeServiceNamePreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const AssetsInventoryCloudAccountServiceNamePreferencesSchema = z.object(
  {
    filterBarState: FilterBarPreferencesSchema,
    table: TablePreferencesSchema,
  },
);

export const ApiInventoryPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
  groupBy: GroupByPreferencesSchema,
  view: ViewPreferencesSchema,
});

export const AssetsInventoryRegionPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const ContainerImagesPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
});

export const HostsPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
});

export const LicensePreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  runtime: TablePreferencesSchema,
  buildtime: TablePreferencesSchema,
});

export const CloudAccountsPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const IntegrationPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const NotificationPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const AdoptionAdvisorPreferencesSchema = z.object({
  "hide-splash": z.boolean().optional(),
  "hidden-phases": z.any().optional(),
  "hide-new-badges-categories": z.any().optional(),
});

export const DataInventoryPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const DataInventoryResourcePreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const DataInventoryServicePreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});
export const ScanSettingsPreferencesSchema = z.object({
  table: TablePreferencesSchema,
});

export const DataProfilesPreferencesSchema = z.object({
  table: TablePreferencesSchema,
});

export const DataPatternsPreferencesSchema = z.object({
  table: TablePreferencesSchema,
});

export const DataInventoryObjectPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const AccountGroupsPreferencesSchema = z.object({
  table: TablePreferencesSchema,
});

export const ResourceListsPreferencesSchema = z.object({
  table: TablePreferencesSchema,
});

export const RolesPreferencesSchema = z.object({
  table: TablePreferencesSchema,
});

export const AccessKeysPreferencesSchema = z.object({
  table: TablePreferencesSchema,
});

export const UsersPreferencesSchema = z.object({
  table: TablePreferencesSchema,
});

export const CollectionsPreferencesSchema = z.object({
  table: TablePreferencesSchema,
});

export const CredentialsStorePreferencesSchema = z.object({
  table: TablePreferencesSchema,
});

export const RegistrySchema = z.object({
  table: TablePreferencesSchema,
  filterBarState: FilterBarPreferencesSchema,
});

export const CreditAllocationPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const ApplicationInventoryPreferencesSchema = z.object({
  filterBarState: FilterBarPreferencesSchema,
  table: TablePreferencesSchema,
});

export const UCDModeSchema = z.enum([
  "application",
  "runtime",
  "cloud",
  "data",
]);

export type UCDMode = z.infer<typeof UCDModeSchema>;

export const ColorThemeSchema = z.enum([
  "theme-base",
  "theme-lavender",
  "theme-scienceblue",
  "theme-pacificblue",
  "theme-robinblue",
  "theme-surfiegreen",
  "theme-bluechill",
  "theme-buddhagold",
  "theme-indochine",
  "theme-persianred",
]);

export type ColorTheme = z.infer<typeof ColorThemeSchema>;

export const PolicyFeedbackSchema = z.record(
  z.string(),
  z.object({
    status: z.enum(["like", "dislike"]),
    category: z.string().optional(),
    message: z.string().optional(),
  }),
);

export const PolicyPreferencesSchema = z.object({
  feedback: PolicyFeedbackSchema.optional(),
});

export const ftuExperiencePreferenceSchema = z.object({
  config: z
    .object({
      isEnabled: z.boolean().optional(),
      theme: z.object({
        background: z.string(),
        mode: z.string(),
        color: ColorThemeSchema,
        avatar: z.number(),
        role: UCDModeSchema,
        prevRole: UCDModeSchema.optional(),
      }),
    })
    .optional(),
  aiPoweredSearch: z.boolean().optional(),
  aiPoweredSearchTurnOffFeedback: z.boolean().optional(),
});

export const fttvExperiencePreferenceSchema = z.object({
  config: z
    .object({
      isEnabled: z.boolean().optional(),
    })
    .optional(),
});

export const PreferencesSchema = z
  .object({
    savedViews: z
      .object({
        complianceStandards: ComplianceStandardsPreferencesSchema.optional(),
        "alertsOverview-cloud": AlertsOvervewPreferencesSchema.optional(),
        "alertsOverview-runtime": AlertsOvervewPreferencesSchema.optional(),
        "alertsOverview-application": AlertsOvervewPreferencesSchema.optional(),
        "policies-cloud": PoliciesPreferencesSchema.optional(),
        "policies-runtime": PoliciesPreferencesSchema.optional(),
        "policies-application": PoliciesPreferencesSchema.optional(),
        "policies-data": PoliciesPreferencesSchema.optional(),
        "alertsOverview-data": AlertsOvervewPreferencesSchema.optional(),
      })
      .optional(),
    permissionGroups: PermissionGroupsPreferencesSchema.optional(),
    complianceRequirements: ComplianceRequirementsPreferencesSchema.optional(),
    complianceSections: ComplianceSectionsPreferencesSchema.optional(),
    complianceReport: ComplianceReportPreferencesSchema.optional(),
    alertsReport: AlertsReportPreferencesSchema.optional(),
    cloudSecurityReport: CloudSecurityReportPreferencesSchema.optional(),
    investigate: InvestigatePreferencesSchema.optional(),
    investigateSavedSearches:
      InvestigateSavedSearchesPreferencesSchema.optional(),
    integration: IntegrationPreferencesSchema.optional(),
    assetExplorerPage: AssetExplorerPreferencesSchema.optional(),
    auditLogs: AuditLogsPreferencesSchema.optional(),
    unmanagedAsset: UnmanagedAssetPreferencesSchema.optional(),
    unmanagedAssetList: UnmanagedAssetListPreferencesSchema.optional(),
    unmanagedAssetByCloudType:
      unmanagedAssetByCloudTypePreferencesSchema.optional(),
    unmanagedAssetByResourceType:
      unmanagedAssetByResourceTypePreferencesSchema.optional(),
    assetsInventory: AssetsInventoryPreferencesSchema.optional(),
    assetsInventoryCloudType:
      AssetsInventoryCloudTypePreferencesSchema.optional(),
    assetsInventoryCloudTypeServiceName:
      AssetsInventoryCloudTypeServiceNamePreferencesSchema.optional(),
    assetsInventoryServiceName:
      AssetsInventoryServiceNamePreferencesSchema.optional(),
    assetsInventoryCloudAccount:
      AssetsInventoryCloudAccountPreferencesSchema.optional(),
    assetsInventoryCloudAccountServiceName:
      AssetsInventoryCloudAccountServiceNamePreferencesSchema.optional(),
    apiInventory: ApiInventoryPreferencesSchema.optional(),
    assetsInventoryRegion: AssetsInventoryRegionPreferencesSchema.optional(),
    containerImages: ContainerImagesPreferencesSchema.optional(),
    hosts: HostsPreferencesSchema.optional(),
    licensing: LicensePreferencesSchema.optional(),
    cloudAccounts: CloudAccountsPreferencesSchema.optional(),
    adoption: AdoptionAdvisorPreferencesSchema.optional(),
    dataInventory: DataInventoryPreferencesSchema.optional(),
    dataInventoryResource: DataInventoryResourcePreferencesSchema.optional(),
    dataInventoryService: DataInventoryServicePreferencesSchema.optional(),
    dataInventoryObject: DataInventoryObjectPreferencesSchema.optional(),
    notificationTemplate: NotificationPreferencesSchema.optional(),
    scanSettings: ScanSettingsPreferencesSchema.optional(),
    fttvExperience: fttvExperiencePreferenceSchema.optional(),
    dataProfiles: DataProfilesPreferencesSchema.optional(),
    dataPatterns: DataPatternsPreferencesSchema.optional(),
    accountGroups: AccountGroupsPreferencesSchema.optional(),
    ftuExperience: ftuExperiencePreferenceSchema.optional(),
    resourceLists: ResourceListsPreferencesSchema.optional(),
    roles: RolesPreferencesSchema.optional(),
    accessKey: AccessKeysPreferencesSchema.optional(),
    users: UsersPreferencesSchema.optional(),
    collections: CollectionsPreferencesSchema.optional(),
    registries: RegistrySchema.optional(),
    creditAllocation: CreditAllocationPreferencesSchema.optional(),
    policy: PolicyPreferencesSchema.optional(),
    credentialsStore: CredentialsStorePreferencesSchema.optional(),
    inventoryApplications: ApplicationInventoryPreferencesSchema.optional(),
  })
  .deepPartial();
