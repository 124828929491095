import {
  CloudTypesEnumSchema,
  CloudTypesWithOtherEnumSchema,
} from "../cloudType";

import { z } from "zod";

export const CloudAccountNameSchema = z.object({
  name: z.string(),
  id: z.string(),
  cloudType: CloudTypesEnumSchema,
  parentAccountName: z.string().optional(),
});
export type CloudAccountName = z.infer<typeof CloudAccountNameSchema>;

export const CloudAccountNameResponseSchema = z.array(CloudAccountNameSchema);

export const CloudAccountNameWithOtherResponseSchema = z.array(
  z.object({
    name: z.string(),
    id: z.string(),
    cloudType: CloudTypesWithOtherEnumSchema,
    parentAccountName: z.string().optional(),
  }),
);
