import { isChina } from "environment";
import { useFormContext } from "form";
import { useEffect } from "react";
import { useWizardContext } from "../../../../../../components/Wizard";
import { ACCOUNT, AWS, ORGANIZATION } from "../../../../../constants";
import AccountType from "../../../components/AccountType";

export default function Scope({ isEdit }: { isEdit: boolean }) {
  const {
    state: { steps, activeIndex },
    valuesUpdate,
  } = useWizardContext();
  const { watch, setValue } = useFormContext();
  const [accountType, awsPartition = ""] = watch([
    "accountType",
    "partitionType",
  ]);

  const isChinaPartition = awsPartition.includes("cn") || isChina();

  useEffect(() => {
    if (isChinaPartition) {
      setValue("accountType", ACCOUNT);
    }
  }, [isChinaPartition, setValue]);

  const accountGroupsValue = steps[activeIndex + 1]?.values?.groupIds;

  useEffect(() => {
    if (!isEdit) {
      if (accountType === ACCOUNT && !Array.isArray(accountGroupsValue)) {
        valuesUpdate({
          index: activeIndex + 1,
          values: {
            ...steps[activeIndex + 1]?.values,
            groupIds: [accountGroupsValue],
          },
        });
      } else if (
        accountType === ORGANIZATION &&
        Array.isArray(accountGroupsValue)
      ) {
        valuesUpdate({
          index: activeIndex + 1,
          values: {
            ...steps[activeIndex + 1]?.values,
            groupIds: accountGroupsValue[0],
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  return (
    <section className="space-y-2">
      <div className="-ml-2">
        <AccountType
          cloudType={AWS}
          isDisabled={isEdit}
          name="accountType"
          isChinaPartition={isChinaPartition}
        />
      </div>
    </section>
  );
}
