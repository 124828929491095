import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { FormLayout } from "form";
import { HomeIcon, SettingsIcon } from "icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIsBusinessLicenseType, useUCDMode } from "prisma";
import { useEffect, useMemo, useState, type ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { isEmpty } from "remeda";
import {
  cloudAccountsKeys,
  getAwsAutomatedCloudAccountStatus,
  getAwsCloudAccountStatus,
  getCloudAccountsTableData,
  type ApiError,
  type AwsRequestPayloadType,
  type AwsStatusResponseType,
  type SecurityCapabilitiesFeatures,
  type SecurityCapabilitiesType,
  type SecurityCapabilityValuesType,
  type StatusSchema,
} from "requests";
import { useToastActions } from "stores";
import { Button } from "ui";
import { type z } from "zod";
import { WizardForm, useWizardContext } from "../../../../../components/Wizard";
import {
  ACCOUNT,
  ADD_DETAILS,
  AGENTLESS_API_DISCOVERY,
  AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE,
  AGENTLESS_DEFAULT_MEMBER_STATE,
  AGENTLESS_SCAN,
  AUTO_PROTECT,
  AWS,
  CUSTOM_CONFIGURATION,
  DISABLED,
  ENABLED,
  ORGANIZATION,
  REMEDIATION,
  SERVERLESS_DEFAULT_MEMBER_STATE,
  SERVERLESS_SCAN,
} from "../../../../constants";
import {
  useGetAwsCloudAccountsCas,
  useGetSupportedFeaturesList,
  useIdentitySubscription,
} from "../../../../hooks";
import {
  type AwsAddDetailsStepValues,
  type AwsCustomConfigurationStepValues,
} from "../../../../types";
import { parseI18nKeyFromResponse } from "../../../../utils";
import { featureMap } from "../../../Onboarding";
import {
  getAuthenticationData,
  getSecurityCapabilities,
  getWizardStepIndex,
} from "../../../Onboarding/utils";
import AccountDetailsStatus from "./AccountDetailsStatus";
import OnboardPending from "./OnboardPending";
import OnboardRepository from "./OnboardRepository";
import OnboardSuccess from "./OnboardSuccess";
import SecurityCapabilitiesPermissions from "./SecurityCapabilitiesPermissions";

type AutomatedPayloadProps = { accountName: string };
type RequestPayloadProps = Omit<
  AwsRequestPayloadType,
  "accountId" | "roleArn" | "features"
> & {
  features?: SecurityCapabilitiesFeatures[];
  roleArn?: string;
};
type orgSecurityCapabilitiesType = Record<
  SecurityCapabilityValuesType,
  boolean
>;
type SummaryProps = {
  context: {
    closeModal: () => void;
    isCustomConfigEnabled: boolean;
  };
};

export default function Summary({
  context: { closeModal, isCustomConfigEnabled },
}: SummaryProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const selectedUCDMode = useUCDMode();
  const { toast } = useToastActions();
  const { cloudScanMode, agentlessApiDiscovery } = useFlags();
  const {
    state: { steps },
  } = useWizardContext();

  const visitHomeNavLink =
    selectedUCDMode === "runtime"
      ? "/home/runtime"
      : selectedUCDMode === "application"
        ? "/home/appsec"
        : selectedUCDMode === "data"
          ? "/home/data/overview"
          : "/home/cloud/welcome";

  const getStepIndex = getWizardStepIndex(steps);
  const addDetailsStepIndex = getStepIndex(ADD_DETAILS);
  const customConfigStepIndex = getStepIndex(CUSTOM_CONFIGURATION);
  const [isApiPollingComplete, setIsApiPollingComplete] = useState(false);
  const [isAccountOnboarded, setIsAccountOnboarded] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [isRoleArnSaved, setIsRoleArnSaved] = useState(false);

  const {
    accountType,
    partitionType: awsPartition,
    name,
    onboardType,
    groupIds,
  } = steps[addDetailsStepIndex]?.values as AwsAddDetailsStepValues;

  const isAutomatedOnboarding = onboardType === "automated";
  const isAccountType = accountType === ACCOUNT;
  const isOrg = accountType === ORGANIZATION;

  let requestData: RequestPayloadProps | AutomatedPayloadProps = {
    accountName: name,
  };

  const { supportedFeaturesList, isLoading: isFeaturesLoading } =
    useGetSupportedFeaturesList({
      cloudType: AWS,
      payload: {
        accountType,
        awsPartition,
      },
    });

  const { isSubscribed, isLoading: indentitySecurityLoading } =
    useIdentitySubscription();
  const { isBusinessLicenseType } = useIsBusinessLicenseType();

  const { data: accountDetails } = useGetAwsCloudAccountsCas({
    accountId,
    options: { enabled: !isEmpty(accountId) },
  });

  const securityCapabilitiesValues = useMemo(() => {
    const values = {} as Record<SecurityCapabilitiesType, boolean>;
    accountDetails?.cloudAccount.features?.map(
      ({ featureName, featureState }) => {
        if (featureMap[featureName])
          values[featureMap[featureName]] = featureState === ENABLED;
      },
    );
    return values;
  }, [accountDetails]);

  const orgSecurityCapabilitiesValues = useMemo(
    () =>
      accountDetails?.cloudAccount.features
        ?.filter(({ featureName }) => {
          const feature = featureMap[featureName];
          return (
            feature === AGENTLESS_SCAN ||
            feature === SERVERLESS_SCAN ||
            (agentlessApiDiscovery && feature === AGENTLESS_API_DISCOVERY)
          );
        })
        .reduce(
          (
            values: orgSecurityCapabilitiesType,
            { featureName, defaultMemberState },
          ) => {
            featureMap[featureName] === AGENTLESS_SCAN
              ? (values[AGENTLESS_DEFAULT_MEMBER_STATE] = Boolean(
                  defaultMemberState === ENABLED ||
                    (!securityCapabilitiesValues[AGENTLESS_SCAN] &&
                      !isBusinessLicenseType),
                ))
              : agentlessApiDiscovery &&
                  featureMap[featureName] === AGENTLESS_API_DISCOVERY
                ? (values[AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE] =
                    Boolean(
                      defaultMemberState === ENABLED ||
                        (!securityCapabilitiesValues[AGENTLESS_API_DISCOVERY] &&
                          !isBusinessLicenseType),
                    ))
                : (values[SERVERLESS_DEFAULT_MEMBER_STATE] = Boolean(
                    defaultMemberState === ENABLED ||
                      (!securityCapabilitiesValues[SERVERLESS_SCAN] &&
                        !isBusinessLicenseType),
                  ));

            return values;
          },
          {} as orgSecurityCapabilitiesType,
        ) ?? ({} as orgSecurityCapabilitiesType),
    [
      accountDetails?.cloudAccount.features,
      agentlessApiDiscovery,
      isBusinessLicenseType,
      securityCapabilitiesValues,
    ],
  );

  const {
    customMemberRoleNameEnabled = false,
    skipOverrideMemberRoleName = false,
    useTenantExternalId = false,
    unifiedCftDisabled = false,
    roleArn = "",
    securityCapabilities,
    orgSecurityCapabilities,
    mode = "cloud-scan",
    hierarchySelection = [],
    memberRoleName = "",
  } = useMemo(
    () =>
      !isAutomatedOnboarding
        ? (steps[customConfigStepIndex]
            ?.values as AwsCustomConfigurationStepValues) ?? {}
        : !isEmpty(accountDetails ?? {})
          ? ({
              ...accountDetails,
              ...accountDetails?.cloudAccount,
              securityCapabilities: securityCapabilitiesValues,
              orgSecurityCapabilities: orgSecurityCapabilitiesValues,
            } as AwsCustomConfigurationStepValues)
          : ({} as AwsCustomConfigurationStepValues),
    [
      accountDetails,
      customConfigStepIndex,
      isAutomatedOnboarding,
      orgSecurityCapabilitiesValues,
      securityCapabilitiesValues,
      steps,
    ],
  );

  let features: SecurityCapabilitiesFeatures[] = [];

  if (
    (isCustomConfigEnabled && !isAutomatedOnboarding) ||
    (isAutomatedOnboarding && !isEmpty(accountDetails ?? {}))
  ) {
    features = [
      {
        name: AGENTLESS_SCAN,
        state: securityCapabilities[AGENTLESS_SCAN] ? ENABLED : DISABLED,
        ...(isOrg && {
          defaultMemberState:
            securityCapabilities[AGENTLESS_SCAN] &&
            orgSecurityCapabilities[AGENTLESS_DEFAULT_MEMBER_STATE]
              ? ENABLED
              : DISABLED,
        }),
        ...(cloudScanMode &&
          securityCapabilities[AGENTLESS_SCAN] && {
            mode,
          }),
      },
      {
        name: AUTO_PROTECT,
        state: securityCapabilities[AUTO_PROTECT] ? ENABLED : DISABLED,
      },
      {
        name: REMEDIATION,
        state: securityCapabilities[REMEDIATION] ? ENABLED : DISABLED,
      },
      {
        name: SERVERLESS_SCAN,
        state: securityCapabilities[SERVERLESS_SCAN] ? ENABLED : DISABLED,
        ...(isOrg && {
          defaultMemberState:
            securityCapabilities[SERVERLESS_SCAN] &&
            orgSecurityCapabilities[SERVERLESS_DEFAULT_MEMBER_STATE]
              ? ENABLED
              : DISABLED,
        }),
      },
      agentlessApiDiscovery && {
        name: AGENTLESS_API_DISCOVERY,
        state: securityCapabilities[AGENTLESS_API_DISCOVERY]
          ? ENABLED
          : DISABLED,
        ...(isOrg && {
          defaultMemberState:
            securityCapabilities[AGENTLESS_API_DISCOVERY] &&
            orgSecurityCapabilities[
              AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE
            ]
              ? ENABLED
              : DISABLED,
        }),
      },
    ]
      .filter(Boolean)
      .filter(({ name }) =>
        supportedFeaturesList.includes(name as SecurityCapabilitiesType),
      ) as SecurityCapabilitiesFeatures[];

    requestData = {
      accountType,
      enabled: true,
      features,
      ...(isAccountType
        ? { groupIds: groupIds as unknown as string[] }
        : { defaultAccountGroupId: groupIds as unknown as string }),
      ...(!isAccountType && { hierarchySelection }),
      name,
      roleArn,
      ...(!isAccountType && {
        customMemberRoleNameEnabled,
        ...(customMemberRoleNameEnabled && { memberRoleName }),
        skipOverrideMemberRoleName,
        unifiedCftDisabled,
      }),
      useTenantExternalId,
    } as RequestPayloadProps;
  }

  const {
    data: statusData,
    isError,
    error,
    isLoading: isCloudStatusLoading,
  } = useQuery({
    queryKey: cloudAccountsKeys.awsCloudAccountStatus({
      payload: requestData as unknown as AwsRequestPayloadType,
    }),
    queryFn: getAwsCloudAccountStatus,
    enabled: !isAutomatedOnboarding || !isEmpty(accountDetails ?? {}),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const {
    data: automatedAccountStatus = [] as AwsStatusResponseType,
    isError: automatedStatusError,
    error: automatedError,
    isLoading: isAutomatedCloudStatusLoading,
  } = useQuery({
    queryKey: cloudAccountsKeys.awsAutomatedCloudAccountStatus({
      payload: requestData as unknown as AutomatedPayloadProps,
    }),
    queryFn: getAwsAutomatedCloudAccountStatus,
    enabled: isAutomatedOnboarding && !isAccountOnboarded && !isRoleArnSaved,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: (query) => {
      if (isAccountOnboarded) return false;
      if (query.state.dataUpdateCount > 20) {
        setIsApiPollingComplete(true);
        return false;
      }
      return 15 * 1000; // polling the API every 15 seconds for 20 times
    },
  });

  const { data: cloudAccountsData = [] } = useQuery({
    queryKey: cloudAccountsKeys.cloudAccountsTable({
      excludeAccountGroupDetails: "true",
    }),
    queryFn: getCloudAccountsTableData,
    enabled: isAccountOnboarded || isRoleArnSaved,
  });

  useEffect(() => {
    const onBoardedAccount = cloudAccountsData.filter(
      (acc) => acc.name === name,
    );
    setAccountId(onBoardedAccount?.length ? onBoardedAccount[0].accountId : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudAccountsData]);

  useEffect(() => {
    if (
      (automatedError as unknown as ApiError)?.status === 400 ||
      (error as unknown as ApiError)?.status === 400
    ) {
      setIsAccountOnboarded(true);
    }
  }, [automatedError, error]);

  const accountStatus = isAutomatedOnboarding
    ? (isAccountOnboarded || isRoleArnSaved) &&
      accountDetails &&
      !isCloudStatusLoading
      ? statusData
      : automatedAccountStatus
    : statusData;

  useEffect(() => {
    if (
      (!automatedStatusError ||
        (automatedError as unknown as ApiError).status === 400) &&
      !isError
    )
      return;

    toast(
      parseI18nKeyFromResponse(
        intl,
        isAutomatedOnboarding &&
          (automatedError as unknown as ApiError)?.status !== 400
          ? automatedError
          : error,
      ),
      {
        appearance: "error",
      },
    );
    closeModal();
  }, [
    isError,
    error,
    intl,
    toast,
    closeModal,
    automatedStatusError,
    isAutomatedOnboarding,
    automatedError,
  ]);
  const authentication = useMemo(
    () => getAuthenticationData(accountStatus),
    [accountStatus],
  );

  const accountDetailsData = {
    accountGroups: Array.isArray(groupIds) ? groupIds : [groupIds as string],
    accountName: name,
    accountType,
    roleArn: roleArn,
    securityCapabilities: securityCapabilities
      ? getSecurityCapabilities(
          securityCapabilities,
          supportedFeaturesList,
          isSubscribed,
        ).filter((feature) =>
          agentlessApiDiscovery ? feature : feature !== AGENTLESS_API_DISCOVERY,
        )
      : [],
    authentication,
    deploymentType: awsPartition,
  };

  const isLoading =
    isAutomatedCloudStatusLoading ||
    isCloudStatusLoading ||
    isFeaturesLoading ||
    indentitySecurityLoading;

  const onBoardStatusCards: Record<
    Exclude<
      z.infer<typeof StatusSchema>,
      "error" | "warning" | "disabled" | "enabled"
    >,
    ReactElement
  > = {
    pending: <OnboardPending />,
    success: <OnboardSuccess />,
    ok: <OnboardSuccess />,
  };

  return (
    <WizardForm
      actions={
        <>
          <Button
            icon={<HomeIcon />}
            onClick={() => {
              navigate({ to: visitHomeNavLink });
              closeModal();
            }}
          >
            <FormattedMessage
              defaultMessage="Visit Home"
              id="rVnvWd"
              description="Visit Home button label"
            />
          </Button>
          <Button
            appearance="primary"
            icon={<SettingsIcon />}
            onClick={() => {
              navigate({ to: "/settings/providers/cloud-accounts" });
              closeModal();
            }}
          >
            <FormattedMessage
              defaultMessage="Visit Providers"
              id="P1X8uv"
              description="Visit Providers button label"
            />
          </Button>
        </>
      }
    >
      <FormLayout>
        {authentication &&
          onBoardStatusCards[
            authentication.status as Exclude<
              z.infer<typeof StatusSchema>,
              "error" | "warning" | "disabled" | "enabled"
            >
          ] &&
          onBoardStatusCards[
            authentication.status as Exclude<
              z.infer<typeof StatusSchema>,
              "error" | "warning" | "disabled" | "enabled"
            >
          ]}
        <SecurityCapabilitiesPermissions
          accountStatus={accountStatus ?? []}
          features={features}
          isSubscribed={isSubscribed}
          isLoading={isLoading}
        />
        <AccountDetailsStatus
          {...accountDetailsData}
          isLoading={isLoading}
          displayRoleArn={
            (isApiPollingComplete && !isAccountOnboarded) || !!roleArn.length
          }
          setIsRoleArnSaved={setIsRoleArnSaved}
          isAccordianOpen={isApiPollingComplete}
        />
        <OnboardRepository closeModal={closeModal} />
      </FormLayout>
    </WizardForm>
  );
}
