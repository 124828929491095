import { useFlags } from "launchdarkly-react-client-sdk";
import { type ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CHINA, GLOBAL_INT, GOV_CLOUD } from "../constants";
import { useGetAwsFeatureFlags } from "./useGetAwsFeatureFlags";

const sortedBy = { china: 2, global: 0, gov_cloud: 1 };
type SortedBy = keyof typeof sortedBy;

export function useGetAwsPartitionItems() {
  const intl = useIntl();
  const { simplifiedOnboardingExperience } = useFlags();
  const { awsPartitionsEnabled, isLoading, isError, error } =
    useGetAwsFeatureFlags();

  const awsPartitionItems: {
    key: string;
    title: string;
    value: string;
    description: ReactNode;
    children?: ReactNode;
  }[] = [];

  awsPartitionsEnabled.forEach((item: string) => {
    if (item.includes("cn")) {
      awsPartitionItems.push({
        key: CHINA,
        title: intl.formatMessage({
          defaultMessage: "China",
          id: "NlrvPE",
          description: "Option for cloud type for onboarding an AWS account",
        }),
        value: item,
        description: (
          <span className="text-xs">
            <FormattedMessage
              defaultMessage="Secure your China account used within the China region."
              id="9gN+Zr"
              description="Description for cloud type for onboarding an AWS account"
            />
          </span>
        ),
        ...(simplifiedOnboardingExperience
          ? {
              children: (
                <div>
                  {intl.formatMessage({
                    defaultMessage: "China",
                    id: "NlrvPE",
                    description:
                      "Option for cloud type for onboarding an AWS account",
                  })}
                </div>
              ),
            }
          : {}),
      });
    } else if (item.includes("gov")) {
      awsPartitionItems.push({
        key: GOV_CLOUD,
        title: intl.formatMessage({
          defaultMessage: "GovCloud (US)",
          id: "hpxt/Y",
          description: "Option for cloud type for onboarding an AWS account",
        }),
        value: item,
        description: (
          <span className="text-xs">
            <FormattedMessage
              defaultMessage="Secure your GovCloud account used by US government agencies or their partners."
              id="XwUjOd"
              description="Description for cloud type for onboarding an AWS account"
            />
          </span>
        ),
        ...(simplifiedOnboardingExperience
          ? {
              children: (
                <div>
                  {intl.formatMessage({
                    defaultMessage: "GovCloud (US)",
                    id: "hpxt/Y",
                    description:
                      "Option for cloud type for onboarding an AWS account",
                  })}
                </div>
              ),
            }
          : {}),
      });
    } else {
      awsPartitionItems.push({
        key: GLOBAL_INT,
        title: intl.formatMessage({
          defaultMessage: "Global",
          id: "cKF94A",
          description: "Option for cloud type for onboarding an AWS account",
        }),
        value: item,
        description: (
          <span className="text-xs">
            <FormattedMessage
              defaultMessage="Secure your Commercial account used by non-government businesses."
              id="stNkc7"
              description="Description for cloud type for onboarding an AWS account"
            />
          </span>
        ),
        ...(simplifiedOnboardingExperience
          ? {
              children: (
                <div>
                  {intl.formatMessage({
                    defaultMessage: "Global",
                    id: "cKF94A",
                    description:
                      "Option for cloud type for onboarding an AWS account",
                  })}
                </div>
              ),
            }
          : {}),
      });
    }
  });
  awsPartitionItems.sort(
    (a, b) => sortedBy[a.key as SortedBy] - sortedBy[b.key as SortedBy],
  );

  return { awsPartitionItems, isLoading, isError, error };
}
