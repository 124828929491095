import { SeverityIcon } from "icons";
import { type MouseEventHandler } from "react";
import { defineMessages, useIntl } from "react-intl";
import { type Severity } from "requests";
import { classNames } from "utils";
import { DynamicFormattedMessage } from "../Intl";
import { Body } from "../Typography";

type Props = {
  type: Exclude<React.ComponentProps<typeof SeverityIcon>["type"], undefined>;
  level: Severity;
  highlight?: boolean;
  stacked?: boolean;
  count?: number | string | JSX.Element;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * By default, if the count is not zero, the label will render
   * the number in blue color, and will use cursor-pointer on hover
   * indicating it's clickable.
   * Use this prop to force the label to be unclickable and
   * revert the text to the default color.
   *
   * Note: We cannot decide whether it's clickable by looking at the `onClick` prop,
   * since the click handler might be registered to a parent component,
   * eg. a wrapping `RouterLink`.
   */
  disabled?: boolean;
};

const severityMessages = defineMessages<Severity>({
  critical: {
    defaultMessage: "Critical",
    id: "tZ3zzZ",

    description: "Severity label",
  },
  high: {
    defaultMessage: "High",
    id: "b98uSz",

    description: "Severity label",
  },
  medium: {
    defaultMessage: "Medium",
    id: "UvadkP",

    description: "Severity label",
  },
  low: {
    defaultMessage: "Low",
    id: "af4NhU",

    description: "Severity label",
  },
  informational: {
    defaultMessage: "Informational",
    id: "y4+oC4",

    description: "Severity label",
  },
});

const severityBorderColors = {
  critical: "border-red-700 dark:border-[#FF3C4F]",
  high: "border-red-500 dark:border-red-650",
  medium: "border-[#D86800] dark:border-yellow-450",
  low: "border-[#CE8200] dark:border-[#FFE588]",
  informational: "border-blue-600 dark:border-blue-400",
} satisfies Record<Severity, string>;

export function SeverityLabel({
  level,
  type,
  count,
  highlight,
  stacked,
  onClick,
  disabled,
}: Props) {
  const intl = useIntl();

  return (
    <Body
      onClick={onClick}
      as="button"
      size="sm"
      disabled={!count || disabled}
      addClassName={classNames(
        "inline-flex gap-1 text-xs font-bold no-underline",
        !stacked && (type === "alert" ? "pl-2" : "pl-0.5"),
        stacked
          ? "flex-col items-center p-1"
          : "flex-row items-center rounded border py-0.5 pr-2",
        stacked && highlight && "rounded border",
        highlight
          ? severityBorderColors[level]
          : "border-gray-300 dark:border-blue-steel-850",
        count && !disabled ? "text-link dark:text-dark-bg-link" : "",
      )}
    >
      <SeverityIcon
        level={level}
        type={type}
        size="lg"
        showNativeTooltip={count !== undefined}
      />
      {typeof count === "number"
        ? intl.formatNumber(count || 0, { notation: "compact" })
        : count}
      <span className={classNames(count !== undefined && "sr-only")}>
        <DynamicFormattedMessage messageMap={severityMessages} token={level} />
      </span>
    </Body>
  );
}
