import { subDays } from "date-fns";
import { defineMessages } from "react-intl";
import { type AbsoluteTimeType } from "requests";
import { type MessageType } from "../types";
import { type Check } from "../useChecks/types";

export const adoptionProgressMessages: { [key: string]: MessageType } =
  defineMessages({
    CALCULATION_ERROR: {
      defaultMessage: "Calculation Error",
      id: "1RMzEY",
      description: "Adoption Advisor Adoption Progress Calculation Error Label",
    },
    check_again: {
      defaultMessage: "Please revisit later",
      id: "WOw2LM",
      description: "Adoption Advisor Adoption Progress check again Label",
    },
    IN_PROGRESS: {
      defaultMessage: "Calculating",
      id: "Mu2+7w",
      description: "Adoption Advisor Adoption Progress Calculating Label",
    },
    level_1: {
      defaultMessage: "Keep Going!",
      id: "wf7Qrr",
      description: "Adoption Advisor Adoption Progress Level 1 Label",
    },
    level_2: {
      defaultMessage: "Great Progress, You've Got This!",
      id: "udFcbE",
      description: "Adoption Advisor Adoption Progress Level 2 Label",
    },
    level_3: {
      defaultMessage: "You're Almost There!",
      id: "JsBmcn",
      description: "Adoption Advisor Adoption Progress Level 3 Label",
    },
    level_4: {
      defaultMessage: "Congratulations!",
      id: "nBvWdM",
      description: "Adoption Advisor Adoption Progress Level 4 Label",
    },
    SYSTEM_ERROR: {
      defaultMessage: "System Error",
      id: "XaIIy4",
      description: "Adoption Advisor Adoption Progress System Error Label",
    },
  });

export const scorelessStatus = [
  "SYSTEM_ERROR",
  "CALCULATION_ERROR",
  "IN_PROGRESS",
];

export const completionPercentage = (breakdowns: Check[]): number => {
  if (!breakdowns) return 0;

  const completed = breakdowns.reduce(
    (acc, { status }) => acc + (status === "COMPLETE" ? 1 : 0),
    0,
  );

  if (completed === breakdowns.length) return 100;

  return getPercentage(completed, breakdowns.length);
};

export const getPercentage = (
  numerator: number,
  denominator: number,
  options: { floor: boolean } = { floor: true },
): number => {
  const { floor } = options;
  const percent = (numerator / denominator) * 100;
  let result = floor ? Math.floor(percent) : Math.round(percent);

  if (result < 0 || isNaN(result)) {
    result = 0;
  } else if (result === Infinity) {
    result = 100;
  }

  return result;
};

// Risk burndown chart related functions
export const formatNumber = (value: number, compact = false): string => {
  return new Intl.NumberFormat(
    undefined,
    compact ? { notation: "compact", compactDisplay: "short" } : {},
  ).format(value);
};

export const getTimeRangeFromDaysBefore = (
  endTime: Date,
  daysBefore: number,
): AbsoluteTimeType => {
  return {
    type: "absolute",
    value: {
      endTime: parseInt(endTime.getTime().toString().slice(0, -5) + "00000"),
      startTime: parseInt(
        subDays(endTime, daysBefore).getTime().toString().slice(0, -5) +
          "00000",
      ),
    },
  };
};
