import { useMutation } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useMemo, useState } from "react";
import { get, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { isEmpty } from "remeda";
import { type SecurityCapabilitiesType } from "requests";
import { useToastActions } from "stores";
import { Bold } from "ui";
import { regexes } from "utils";
import { useWizardContext } from "../../../../../../components/Wizard";
import {
  ACCOUNT_ID,
  ACCOUNT_TYPE,
  AGENTLESS_SCAN,
  AWS,
  AWS_PARTITION,
  FEATURES,
  REMEDIATION,
} from "../../../../../constants";
import { useGetFeaturesList } from "../../../../../hooks";
import { type AwsGetStartedStepValuesType } from "../../../../../types";
import { parseErrorsFromResponse } from "../../../../../utils";
import InstructionsSidecar from "../../../components/InstructionsSidecar";
import ConfigHelpers from "./ConfigHelpers";
import { getInstructions } from "./instructions";
import { downloadManagementRoleCft, downloadMemberRoleCft } from "./utils";

type ManagementOrMemberIAMRoleCftProps = {
  closeModal: () => void;
  isEdit: boolean;
  setCftDownloadedOrCreated: (val: boolean) => void;
  initUnifiedCftDisabled: boolean;
};

type CallBackProps = {
  (payload: {
    accountId: string;
    accountType: "account" | "organization";
    awsPartition: string;
    features: string[];
    cftType: string;
    customMemberRoleNameEnabled: boolean;
    useTenantExternalId: boolean;
  }): Promise<Record<string, unknown>>;
};

export default function ManagementOrMemberIAMRoleCft({
  closeModal,
  isEdit,
  setCftDownloadedOrCreated,
  initUnifiedCftDisabled,
}: ManagementOrMemberIAMRoleCftProps) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const {
    state: { steps },
  } = useWizardContext();
  const { cloudScanMode } = useFlags();

  const { setValue, watch } = useFormContext();
  const {
    accountType = "account",
    partitionType: awsPartition,
    securityCapabilities,
    mode = "",
  } = steps[0]?.values as AwsGetStartedStepValuesType;

  const { supportedFeaturesList } = useGetFeaturesList({
    cloudType: AWS,
    payload: {
      accountType,
      awsPartition,
    },
  });

  const features = Object.keys(securityCapabilities)
    .filter((key) => securityCapabilities[key as SecurityCapabilitiesType])
    .filter((feature) =>
      supportedFeaturesList.some(
        (f) => f === (feature as SecurityCapabilitiesType),
      ),
    );

  const accountId = watch("accountId");
  const remediation = watch("remediation");
  const useTenantExternalId = watch("useTenantExternalId");
  const [isOpenConfigHelper, setIsOpenConfigHelper] = useState(false);
  const [showMemberIAMRoleSteps, setShowMemberIAMRoleSteps] = useState(false);
  const [isMemberCftDownloaded, setMemberCftDownloaded] = useState(false);
  const [isManagementCftDownloaded, setManagementCftDownloaded] =
    useState(false);

  const disabled = useMemo(
    () => (isEmpty(accountId) ? true : !regexes.awsAccountId.test(accountId)),
    [accountId],
  );

  const instructions = useMemo(
    () =>
      getInstructions({
        intl,
        isEdit,
        initUnifiedCftDisabled,
        showMemberIAMRoleSteps,
      }),
    [intl, isEdit, showMemberIAMRoleSteps, initUnifiedCftDisabled],
  );

  const getIAMRoleCft = async (callback: CallBackProps, cftType: string) => {
    try {
      const featuresList = remediation ? [...features, REMEDIATION] : features;

      const featureWithModes = featuresList.map((feature) => {
        if (feature === AGENTLESS_SCAN) {
          return {
            feature,
            mode,
          };
        }
        return { feature };
      });

      const response = await callback({
        [ACCOUNT_ID]: accountId,
        [ACCOUNT_TYPE]: accountType,
        [AWS_PARTITION]: awsPartition,
        [FEATURES]: featuresList,
        ...(cloudScanMode && { featureWithModes }),
        cftType,
        customMemberRoleNameEnabled: true,
        useTenantExternalId,
      });

      if (cftType === "org_member") {
        const externalId = get(response, "external-id", "");
        setValue("externalId", externalId);
        setMemberCftDownloaded(true);
      } else {
        setManagementCftDownloaded(true);
      }
    } catch (error) {
      toast(parseErrorsFromResponse(intl, error), {
        appearance: "error",
      });
      closeModal();
    }
  };

  useEffect(() => {
    if (isMemberCftDownloaded && isManagementCftDownloaded) {
      setCftDownloadedOrCreated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMemberCftDownloaded, isManagementCftDownloaded]);

  const {
    mutateAsync: downloadMemberIAMRoleCft,
    isPending: isDownloadingMemberCft,
  } = useMutation({
    mutationFn: async () => getIAMRoleCft(downloadMemberRoleCft, "org_member"),
  });

  const {
    mutateAsync: downloadManagementIAMRoleCft,
    isPending: isDownloadingManagementCft,
  } = useMutation({
    mutationFn: async () =>
      getIAMRoleCft(downloadManagementRoleCft, "org_management"),
  });

  const title = showMemberIAMRoleSteps
    ? intl.formatMessage({
        defaultMessage: "Download Member IAM Role CFT",
        id: "7YnOo0",
        description: "Sidecar instruction heading",
      })
    : intl.formatMessage({
        defaultMessage: "Download Management IAM Role CFT",
        id: "aGdY+s",
        description: "Sidecar instruction heading",
      });

  return (
    <section>
      <Bold addClassName="flex items-center">
        <FormattedMessage
          defaultMessage="Apply permissions for cloud account"
          id="Kf+11A"
          description="description for create and download stack link"
        />
      </Bold>

      <ConfigHelpers
        downloadMemberIAMRoleCft={downloadMemberIAMRoleCft}
        downloadManagementIAMRoleCft={downloadManagementIAMRoleCft}
        disabled={disabled}
        isDownloadingMemberCft={isDownloadingMemberCft}
        isDownloadingManagementCft={isDownloadingManagementCft}
        setIsOpenConfigHelper={setIsOpenConfigHelper}
        setShowMemberIAMRoleSteps={setShowMemberIAMRoleSteps}
        isMemberCftDownloaded={isMemberCftDownloaded}
        isManagementCftDownloaded={isManagementCftDownloaded}
      />
      <InstructionsSidecar
        instructions={instructions}
        isOpen={isOpenConfigHelper}
        setOpen={setIsOpenConfigHelper}
        title={title}
      />
    </section>
  );
}
