import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const AmazonRedshiftIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Amazon Redshift",
    id: "Ud9IUt",

    description: "Amazon Redshift icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M49.9998 90.0001C36.8158 90.0001 22.7998 86.1761 22.7998 79.0881V20.9121H25.9998V79.0881C25.9998 82.2881 35.1358 86.8001 49.9998 86.8001C64.8638 86.8001 73.9998 82.3201 73.9998 79.0881V20.9121H77.1998V79.0881C77.1998 86.1761 63.1838 90.0001 49.9998 90.0001Z"
        className="fill-[url(#paint0_linear_6619_101808)] dark:fill-white"
      />
      <path
        d="M49.9998 90.0001C36.8158 90.0001 22.7998 86.1761 22.7998 79.0881V20.9121H25.9998V79.0881C25.9998 82.2881 35.1358 86.8001 49.9998 86.8001C64.8638 86.8001 73.9998 82.3201 73.9998 79.0881V20.9121H77.1998V79.0881C77.1998 86.1761 63.1838 90.0001 49.9998 90.0001Z"
        className="fill-[url(#paint1_linear_6619_101808)] dark:fill-white"
      />
      <path
        d="M49.9998 31.808C36.8158 31.808 22.7998 27.984 22.7998 20.912C22.7998 13.84 36.8158 10 49.9998 10C63.1838 10 77.1998 13.824 77.1998 20.912C77.1998 28 63.1838 31.808 49.9998 31.808ZM49.9998 13.2C35.1358 13.2 25.9998 17.68 25.9998 20.912C25.9998 24.144 35.1358 28.608 49.9998 28.608C64.8638 28.608 73.9998 24.128 73.9998 20.912C73.9998 17.696 64.8638 13.2 49.9998 13.2Z"
        className="fill-[url(#paint2_linear_6619_101808)] dark:fill-white"
      />
      <path
        d="M45.4639 53.1321L44.7544 56.2524L54.4587 58.459L55.1682 55.3386L45.4639 53.1321Z"
        className="fill-[url(#paint3_linear_6619_101808)] dark:fill-white"
      />
      <path
        d="M62.6755 43.5732L58.9746 52.9492L61.9511 54.1241L65.652 44.7481L62.6755 43.5732Z"
        className="fill-[url(#paint4_linear_6619_101808)] dark:fill-white"
      />
      <path
        d="M38.1718 56.8882L34.9062 64.5405L37.8495 65.7965L41.115 58.1442L38.1718 56.8882Z"
        className="fill-[url(#paint5_linear_6619_101808)] dark:fill-white"
      />
      <path
        d="M34.7997 74.4319C33.6921 74.4319 32.6094 74.1035 31.6885 73.4882C30.7676 72.8728 30.0498 71.9982 29.626 70.975C29.2021 69.9517 29.0912 68.8257 29.3073 67.7394C29.5234 66.6531 30.0567 65.6553 30.8399 64.8721C31.6231 64.089 32.6209 63.5556 33.7072 63.3395C34.7935 63.1235 35.9195 63.2344 36.9427 63.6582C37.966 64.0821 38.8406 64.7998 39.4559 65.7207C40.0713 66.6417 40.3997 67.7244 40.3997 68.8319C40.3997 70.3172 39.8097 71.7415 38.7595 72.7917C37.7093 73.8419 36.2849 74.4319 34.7997 74.4319ZM34.7997 66.4319C34.325 66.4319 33.861 66.5727 33.4663 66.8364C33.0717 67.1001 32.7641 67.475 32.5824 67.9135C32.4007 68.352 32.3532 68.8346 32.4458 69.3002C32.5384 69.7657 32.767 70.1934 33.1027 70.529C33.4383 70.8646 33.8659 71.0932 34.3315 71.1858C34.797 71.2784 35.2796 71.2309 35.7181 71.0492C36.1567 70.8676 36.5315 70.56 36.7952 70.1653C37.059 69.7706 37.1997 69.3066 37.1997 68.8319C37.1997 68.1954 36.9469 67.585 36.4968 67.1349C36.0467 66.6848 35.4362 66.4319 34.7997 66.4319Z"
        className="fill-[url(#paint6_linear_6619_101808)] dark:fill-white"
      />
      <path
        d="M41.1996 59.4241C40.092 59.4241 39.0093 59.0957 38.0884 58.4804C37.1675 57.865 36.4497 56.9904 36.0259 55.9672C35.602 54.9439 35.4911 53.8179 35.7072 52.7316C35.9233 51.6453 36.4566 50.6475 37.2398 49.8643C38.023 49.0812 39.0208 48.5478 40.1071 48.3317C41.1934 48.1156 42.3194 48.2265 43.3426 48.6504C44.3659 49.0742 45.2405 49.792 45.8558 50.7129C46.4712 51.6338 46.7996 52.7165 46.7996 53.8241C46.7996 55.3093 46.2096 56.7337 45.1594 57.7839C44.1092 58.8341 42.6848 59.4241 41.1996 59.4241ZM41.1996 51.4241C40.7249 51.4241 40.2609 51.5649 39.8662 51.8286C39.4716 52.0923 39.164 52.4671 38.9823 52.9057C38.8007 53.3442 38.7531 53.8268 38.8457 54.2923C38.9383 54.7579 39.1669 55.1855 39.5026 55.5212C39.8382 55.8568 40.2658 56.0854 40.7314 56.178C41.197 56.2706 41.6795 56.2231 42.1181 56.0414C42.5566 55.8598 42.9314 55.5522 43.1951 55.1575C43.4589 54.7628 43.5996 54.2988 43.5996 53.8241C43.5996 53.1876 43.3468 52.5772 42.8967 52.1271C42.4466 51.677 41.8361 51.4241 41.1996 51.4241Z"
        className="fill-[url(#paint7_linear_6619_101808)] dark:fill-white"
      />
      <path
        d="M58.7997 62.8001C57.6921 62.8001 56.6094 62.4717 55.6885 61.8563C54.7676 61.241 54.0498 60.3664 53.626 59.3431C53.2021 58.3199 53.0912 57.1939 53.3073 56.1076C53.5234 55.0213 54.0567 54.0235 54.8399 53.2403C55.6231 52.4571 56.6209 51.9238 57.7072 51.7077C58.7935 51.4916 59.9195 51.6025 60.9427 52.0264C61.966 52.4502 62.8406 53.168 63.4559 54.0889C64.0713 55.0098 64.3997 56.0925 64.3997 57.2001C64.3997 58.6853 63.8097 60.1097 62.7595 61.1599C61.7093 62.2101 60.2849 62.8001 58.7997 62.8001ZM58.7997 54.8001C58.325 54.8001 57.861 54.9409 57.4663 55.2046C57.0717 55.4683 56.764 55.8431 56.5824 56.2817C56.4007 56.7202 56.3532 57.2028 56.4458 57.6683C56.5384 58.1339 56.767 58.5615 57.1026 58.8972C57.4383 59.2328 57.8659 59.4614 58.3315 59.554C58.797 59.6466 59.2796 59.5991 59.7181 59.4174C60.1567 59.2358 60.5315 58.9281 60.7952 58.5335C61.0589 58.1388 61.1997 57.6748 61.1997 57.2001C61.1997 56.5636 60.9469 55.9531 60.4968 55.503C60.0467 55.053 59.4362 54.8001 58.7997 54.8001Z"
        className="fill-[url(#paint8_linear_6619_101808)] dark:fill-white"
      />
      <path
        d="M65.1996 45.9998C64.092 45.9998 63.0093 45.6714 62.0884 45.056C61.1675 44.4407 60.4497 43.5661 60.0259 42.5428C59.602 41.5196 59.4911 40.3936 59.7072 39.3073C59.9233 38.221 60.4566 37.2232 61.2398 36.44C62.023 35.6568 63.0208 35.1235 64.1071 34.9074C65.1934 34.6913 66.3194 34.8022 67.3426 35.2261C68.3659 35.6499 69.2405 36.3677 69.8558 37.2886C70.4712 38.2095 70.7996 39.2922 70.7996 40.3998V40.4798C70.7745 41.9498 70.1739 43.3513 69.1269 44.3834C68.0798 45.4155 66.6698 45.9958 65.1996 45.9998ZM65.1996 37.9998C64.7284 38.0001 64.2677 38.139 63.8749 38.3994C63.4822 38.6597 63.1747 39.0299 62.991 39.4638C62.8072 39.8977 62.7552 40.3761 62.8415 40.8394C62.9278 41.3026 63.1486 41.7302 63.4763 42.0688C63.804 42.4074 64.2241 42.6421 64.6843 42.7435C65.1444 42.845 65.6243 42.8087 66.064 42.6393C66.5037 42.4698 66.8837 42.1747 67.1568 41.7907C67.4299 41.4067 67.5839 40.9508 67.5996 40.4798C67.6103 40.158 67.5562 39.8373 67.4404 39.5368C67.3246 39.2363 67.1495 38.9622 66.9256 38.7308C66.7016 38.4994 66.4334 38.3154 66.1369 38.1898C65.8404 38.0643 65.5216 37.9996 65.1996 37.9998Z"
        className="fill-[url(#paint9_linear_6619_101808)] dark:fill-white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6619_101808"
          x1={22.7998}
          y1={20.9121}
          x2={89.9602}
          y2={73.7943}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6619_101808"
          x1={22.7998}
          y1={20.9121}
          x2={89.9602}
          y2={73.7943}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6619_101808"
          x1={22.7998}
          y1={10}
          x2={37.8638}
          y2={47.5771}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6619_101808"
          x1={44.7544}
          y1={56.2524}
          x2={47.8591}
          y2={51.01}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6619_101808"
          x1={58.9746}
          y1={52.9492}
          x2={65.0603}
          y2={53.367}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6619_101808"
          x1={34.9062}
          y1={64.5405}
          x2={40.8758}
          y2={64.7565}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_6619_101808"
          x1={29.1997}
          y1={63.2319}
          x2={40.3997}
          y2={74.4319}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_6619_101808"
          x1={35.5996}
          y1={48.2241}
          x2={46.7996}
          y2={59.4241}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_6619_101808"
          x1={53.1997}
          y1={51.6001}
          x2={64.3997}
          y2={62.8001}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_6619_101808"
          x1={59.5996}
          y1={34.7998}
          x2={70.7996}
          y2={45.9998}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
