import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const GitlabIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Gitlab",
    id: "rD8ccj",

    description: "provider icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 21 19" {...props}>
      <title>{alt}</title>
      <path
        d="M10.3776 18.1074L13.9755 7.03418H6.77954L10.3775 18.1074H10.3776Z"
        fill="#E24329"
      />
      <path
        d="M10.3776 18.1074L6.77953 7.03418H1.73718L10.3776 18.1074Z"
        fill="#FC6D26"
      />
      <path
        d="M1.73723 7.03418L0.643887 10.3993C0.595273 10.5489 0.595267 10.7101 0.643868 10.8597C0.692469 11.0093 0.787183 11.1396 0.914436 11.2321L10.3776 18.1075L1.73716 7.03426L1.73723 7.03418Z"
        fill="#FCA326"
      />
      <path
        d="M1.73718 7.03417H6.77953L4.61262 0.364785C4.5011 0.021596 4.01561 0.0216724 3.90417 0.364785L1.73718 7.03417Z"
        fill="#E24329"
      />
      <path
        d="M10.3776 18.1074L13.9756 7.03418H19.018L10.3776 18.1074H10.3776Z"
        fill="#FC6D26"
      />
      <path
        d="M19.0179 7.03418L20.1113 10.3993C20.1599 10.5489 20.1599 10.7101 20.1113 10.8597C20.0627 11.0093 19.9679 11.1397 19.8407 11.2321L10.3776 18.1075L19.0179 7.03426V7.03418Z"
        fill="#FCA326"
      />
      <path
        d="M19.018 7.03417H13.9756L16.1426 0.364785C16.2541 0.021596 16.7396 0.0216724 16.851 0.364785L19.018 7.03417Z"
        fill="#E24329"
      />
    </SvgIcon>
  );
};
