import { lazy } from "react";
import { createRoute, privateRootRoute, redirect } from "router";

const AlertsLayout = lazy(() =>
  import("./AlertsLayout").then((res) => ({
    default: res.AlertsLayout,
  })),
);

export const alertsLayoutRoute = createRoute({
  getParentRoute: () => privateRootRoute,
  path: "alerts",
  component: AlertsLayout,
});

const PageNotFound = lazy(() =>
  import("../CatchAll/CatchAll").then((res) => ({ default: res.CatchAll })),
);

export const alertsIndexRoute = createRoute({
  getParentRoute: () => alertsLayoutRoute,
  path: "/",
  beforeLoad: ({ search }) => {
    throw redirect({ to: "/alerts/overview", search });
  },
});

export const alertsCatchAllRoute = createRoute({
  getParentRoute: () => alertsLayoutRoute,
  path: "$",
  component: PageNotFound,
  beforeLoad: ({ location: { pathname, search } }) => {
    if (pathname === "/alerts/reports") {
      throw redirect({ to: "/reports/alerts", replace: true, search });
    } else if (pathname === "/alerts/notification_templates") {
      throw redirect({
        to: "/settings/integrationAndNotification/notifications",
        replace: true,
        search,
      });
    }
  },
});
