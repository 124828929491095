import { z } from "zod";
import { SeverityEnumSchema } from "../policies";

const remediationActionsEnum = z.enum(["createTask", "createPr", "suppress"]);

export const TopVulnerabilitiesSchema = z.object({
  id: z.string(),
  severity: SeverityEnumSchema,
  cvssScore: z.number(),
  completeEpssScore: z.number(),
  epssScore: z.number(),
  epssScorePrevious: z.number(),
  riskFactors: z.array(z.string()),
  assetsImpacted: z.object({
    codeCount: z.number().optional(),
    buildCount: z.number().optional(),
    deployCount: z.number().optional(),
    runtimeCount: z.number().optional(),
  }),
  assetsAtRisk: z.number(),
  trends: z
    .array(
      z.object({ assetsWithCveCount: z.number(), reportedDate: z.number() }),
    )
    .optional(),
});

export type TopVulnerability = z.infer<typeof TopVulnerabilitiesSchema>;

const AssetsSummary = z.object({
  totalCount: z.number(),
  criticalCount: z.number(),
  highCount: z.number(),
  mediumCount: z.number(),
  lowCount: z.number(),
  percentageChange: z.number(),
});

export const OverviewSchema = z.object({
  overviewSummary: z.object({
    totalUniqueCves: AssetsSummary,
    totalVulnerabilities: AssetsSummary,
    totalRemediated: AssetsSummary,
  }),
  values: z.array(
    z.object({
      lastUpdatedDateTime: z.number(),
      totalVulnerableAsset: z.number(),
      totalRemediationCount: z.number(),
      totalVulnerabilityCount: z.number(),
    }),
  ),
});

export type OverviewVulnerability = z.infer<typeof OverviewSchema>;

const AssetsVulnSummary = z.object({
  totalCount: z.number(),
  criticalCount: z.number(),
  highCount: z.number(),
  mediumCount: z.number(),
  lowCount: z.number(),
});

const RuntimeAssetsVulnSummary = z.object({
  totalCount: z.number(),
  deployedImageCount: z.number(),
  serverlessFunctionCount: z.number(),
  hostCount: z.number(),
});

export const VulnerabilitiesOverviewSchema = z.object({
  overviewSummary: z.object({
    totalVulnerableRuntimeAssets: RuntimeAssetsVulnSummary,
    totalVulnerabilitiesinRuntime: AssetsVulnSummary,
    totalRemediatedinRuntime: AssetsVulnSummary,
  }),
  values: z.array(
    z.object({
      lastUpdatedDateTime: z.number(),
      totalVulnerableAsset: z.number(),
      totalRemediationCount: z.number(),
      totalVulnerabilityCount: z.number(),
    }),
  ),
});

export type VulnerabilitiesOverview = z.infer<
  typeof VulnerabilitiesOverviewSchema
>;

export const VulnerabilitiesBurndownSchema = z.array(
  z.object({
    epochTimestamp: z.number(),
    totalCount: z.number(),
    remediatedCount: z.number(),
  }),
);

export type VulnerabilitiesBurndownResponse = z.infer<
  typeof VulnerabilitiesBurndownSchema
>;

export const VulnerabilityAssetCount = z.object({
  vulnerability_count: z.number(),
  asset_count: z.number(),
});

export const PrioritizedVulnerabilitiesSchema = z.object({
  totalVulnerabilities: z.number(),
  urgent: VulnerabilityAssetCount,
  patchable: VulnerabilityAssetCount,
  exploitable: VulnerabilityAssetCount,
  internetExposed: VulnerabilityAssetCount,
  packageInUse: VulnerabilityAssetCount,
});

export const TopImpactingVulnerabilitiesResponseSchema = z.object({
  lastUpdatedDateTime: z.number(),
  cve: z.array(TopVulnerabilitiesSchema),
});

export type TopImpactingVulnerabilitiesResponse = z.infer<
  typeof TopImpactingVulnerabilitiesResponseSchema
>;

export const ImpactStageResponseSchema = z.object({
  value: z.object({
    code: z.object({
      iac: z.number().optional(),
      package: z.number().optional(),
    }),
    deploy: z.object({
      vmImage: z.number().optional(),
      registryImage: z.number().optional(),
    }),
    run: z.object({
      deployedImage: z.number().optional(),
      host: z.number().optional(),
      serverlessFunction: z.number().optional(),
    }),
  }),
});

export const ImpactStageRequestSchema = z.object({
  life_cycle: z.array(z.string()),
  asset_type: z.array(z.string()),
  severities: z.array(SeverityEnumSchema),
});

export const lifeCycleType = ["code", "build", "deploy", "run", "Run"] as const;
export const LifeCycleTypeEnumSchema = z.enum(lifeCycleType);
export const LifeCycleFilterTypeEnumSchema = z.array(LifeCycleTypeEnumSchema);

export const DistroDetailsSchema = z.object({
  affectedVersion: z.string(),
  cvss: z.number(),
  fixedTime: z.number(),
  packageName: z.string(),
  release: z.string(),
  severity: SeverityEnumSchema.nullish(),
  publishedDate: z.number().nullable(),
  modifiedDate: z.number().nullish(),
});

const distroObjectSchema = z.object({
  distro: z.string(),
  distroDetailsList: z.array(DistroDetailsSchema),
  impactCount: z.number(),
  highestCVSS: z.number(),
  highestSeverity: z.string(),
  firstPublishedDate: z.number().nullable(),
  lastModifiedDate: z.number().nullish(),
});

export const DetailsSchema = z.object({
  publishedDate: z.number().nullable(),
  lastModifiedDate: z.number().nullish(),
  attackVector: z.string(),
  privilegesRequired: z.string(),
  confidentiality: z.string(),
  attackComplexity: z.string(),
  userInteractionRequired: z.string(),
  integrity: z.string(),
  exploitable: z.boolean(),
  patchable: z.boolean(),
});

export const VulnerabilityOverviewResponseSchema = z.object({
  cveId: z.string(),
  cvss: z.number(),
  packageName: z.string().nullable(),
  lifeCycle: z.array(LifeCycleTypeEnumSchema).nullable(),
  riskFactors: z.array(z.string()).nullable(),
  severity: SeverityEnumSchema.nullish(),
  impactedAssetsCount: z.number().nullable(),
  impactedAssetsRuntimeCount: z.number().nullable(),
  description: z.string().nullable(),
  firstSeen: z.number(),
  lastSeen: z.number(),
  packageType: z.array(z.string()),
  impactedPackages: z.array(z.string()),
  cvssDetails: DetailsSchema,
  environmentFactors: z.object({
    internetExposed: z.boolean().optional(),
    packageInUse: z.boolean().optional(),
  }),
  exploitDetails: z.object({
    cisaLink: z.string(),
    cisaKind: z.string(),
  }),
  epssDetails: z.object({
    epss: z.number(),
    epss_previous: z.number(),
    probabilityScore: z.number().nullable(),
  }),
  additionalLinks: z.object({
    nvdlinks: z.array(z.string()).nullable(),
    vendorLinks: z.array(z.string()).nullable(),
  }),
  impactedDistrosList: z.array(distroObjectSchema).nullish(),
});

export const topType = ["5", "10"] as const;
export const TopTypeEnumSchema = z.enum(topType);

export type TopVulnerabilitiesType = z.infer<typeof TopVulnerabilitiesSchema>;
export type TopFilterType = z.infer<typeof TopTypeEnumSchema>;
export type LifeCycleFilterType = z.infer<typeof LifeCycleFilterTypeEnumSchema>;
export type ImpactStageResponseSchemaType = z.infer<
  typeof ImpactStageResponseSchema
>;
export type VulnerabilityOverviewResponse = z.infer<
  typeof VulnerabilityOverviewResponseSchema
>;

export type detailsType = z.infer<typeof DetailsSchema>;

// Vulnerabilities assets

export const assetCategoriesTypes = z.enum([
  "package",
  "iac",
  "vmImage",
  "registryImage",
  "deployedImage",
  "serverlessFunction",
  "host",
  "all",
]);

const RiskFactorsSchema = z.enum([
  "critical severity",
  "high severity",
  "medium severity",
  "has fix",
  "remote execution",
  "dos - low",
  "dos - high",
  "recent vulnerability",
  "exploit exists - in the wild",
  "exploit exists - poc",
  "attack complexity: low",
  "attack vector: network",
  "reachable from the internet",
  "listening ports",
  "container is running as root",
  "no mandatory security profile applied",
  "running as privileged container",
  "package in use",
  "sensitive information",
  "root mount",
  "runtime socket",
  "host access",
  "exploitable",
  "patchable",
  "urgent",
]);

export const VulnerabilityAssetsRequestSchema = z.object({
  cve_id: z.string(),
  group_by: z.string().optional(),
  sort_by: z.string().optional(),
  page_size: z.number().optional(),
  page_offset: z.number().optional(),
  asset_type: assetCategoriesTypes.optional(),
  risk_factors: z.array(RiskFactorsSchema).optional(),
  severity: z.array(SeverityEnumSchema).optional(),
  filter_suppressed: z.boolean().optional(),
});

const remediationAction = z.object({
  action: remediationActionsEnum,
  status: z.string(),
  message: z.string().nullable(),
  actionResult: z.string().nullable(),
});

export const asset = z.object({
  checkBoxRow: z.boolean().optional(),
  assetName: z.string().optional(),
  resourceName: z.string().optional(),
  packageName: z.string().optional(),
  packageVersion: z.string().optional(),
  fixVersion: z.string().optional(),
  severity: z.string().optional(),
  discoveredTime: z.number().optional(),
  age: z.number(),
  filePath: z.string().optional(),
  owner: z.string().optional(),
  selectTableItem: z.boolean().optional(),
  repository: z.string().optional(),
  remediationAvailable: z.array(remediationAction).optional(), // enum(["suppress", "create_pr"]
});
export const ImpactedAssetsSelected = z.object({
  all: z.boolean(),
  items: z.record(z.string(), z.boolean()),
});

export const countTypes = z.object({
  repositoryCount: z.number().optional(),
  cloudAccountCount: z.number().optional(),
  containerRegistryCount: z.number().optional(),
  containerCount: z.number().optional(),
  hostCount: z.number().optional(),
});

export const vulnerabilityAssetCategory = z.object({
  count: z.number(),
  repositorycount: z.number(),
  // nextPageToken: z.string(),
  fixImpact: z
    .object({
      percentageVulns: z.number(),
      across: z.number(),
      // percentage: z.number(),
      // repoCount: z.number(),
    })
    .merge(countTypes)
    .nullable(),
  remediationAvailable: z.array(remediationAction).optional(),
});

export const assetCategory = z
  .object({
    count: z.number(),
    fixImpact: z
      .object({
        percentage: z.number(),
      })
      .merge(countTypes)
      .nullable(),
    assets: z.array(asset).nullable(),
  })
  .merge(countTypes);

export const VulnerabilityAssetsSchema = z.object({
  value: z.object({
    cveId: z.string().optional(),
    cvssScore: z.number().optional(),
    score: z.number().nullable().optional(),
    lifeCycle: z.array(z.string()).optional(),
    risk_factors: z.array(z.string()).optional(),
    severity: z.array(z.string()).optional(),
    // nextToken: z.string().nullable(),
    package: vulnerabilityAssetCategory
      .extend({
        repositoryCount: countTypes.shape.repositoryCount,
      })
      .optional(),
    filter_suppressed: vulnerabilityAssetCategory
      .extend({
        repositoryCount: countTypes.shape.repositoryCount,
      })
      .optional(),
    iac: vulnerabilityAssetCategory
      .extend({
        repositoryCount: countTypes.shape.repositoryCount,
      })
      .optional(),
    vmImage: vulnerabilityAssetCategory
      .extend({
        cloudAccountCount: countTypes.shape.cloudAccountCount,
      })
      .optional(),
    registryImage: vulnerabilityAssetCategory
      .extend({
        containerRegistryCount: countTypes.shape.containerRegistryCount,
      })
      .optional(),
    deployedImage: vulnerabilityAssetCategory
      .extend({
        containerCount: countTypes.shape.containerCount,
      })
      .optional(),
    serverlessFunction: vulnerabilityAssetCategory
      .extend({
        containerCount: countTypes.shape.containerCount,
      })
      .optional(),
    host: vulnerabilityAssetCategory
      .extend({
        hostCount: countTypes.shape.hostCount,
      })
      .optional(),
    // when requesting with asset_type
    cve_id: z.string().optional(),
    assets: z.array(asset).optional(),
    page_offset: z.number().optional(),
    page_size: z.number().optional(),
    fixVersion: z.string().optional(),
  }),
});

const assetCategoryCountTypes = z.union([
  z.literal("repositoryCount"),
  z.literal("cloudAccountCount"),
  z.literal("containerRegistryCount"),
  z.literal("containerCount"),
  z.literal("hostCount"),
]);

const VulnerabilityAssetGroupByPropertySchema = z.enum([
  "asset_type",
  "application_lifecycle",
]);

const VulnerabilityAssetSortByPropertySchema = z.enum(["highest_severity"]);

const RiskFactorsSelectItemsSchema = z.array(RiskFactorsSchema);

export type AssetCategoriesType = z.infer<typeof assetCategoriesTypes>;
export type VulnerabilityAssetCategoryType = z.infer<
  typeof vulnerabilityAssetCategory
>;
export type VulnerabilityAssetType = z.infer<typeof asset>;
export type assetCategoryCountTypesType = z.infer<
  typeof assetCategoryCountTypes
>;
export type VulnerabilityAssetGroupByProperty = z.infer<
  typeof VulnerabilityAssetGroupByPropertySchema
>;
export type VulnerabilityAssetSortByProperty = z.infer<
  typeof VulnerabilityAssetSortByPropertySchema
>;
export type RiskFactorsType = z.infer<typeof RiskFactorsSelectItemsSchema>;
export type DistroDetailsListSchemaType = z.infer<typeof DistroDetailsSchema>;

export const VulnerabilitiesSearchRequestSchema = z.object({
  limit: z.number().optional(),
  query: z.string(),
});

export type VulnerabilitiesSearchRequestType = z.infer<
  typeof VulnerabilitiesSearchRequestSchema
>;

const EmptyStringEnum = z.enum([""]);

const SeverityEnum = z.enum([
  ...SeverityEnumSchema.options,
  ...EmptyStringEnum.options,
] as const);

export const VulnerabilitiesSearchItemSchema = z.object({
  cveId: z.string(),
  published: z.number().nullable().optional(),
  severity: SeverityEnum.nullable().optional(),
  cvssScore: z.number(),
  completeEpssScore: z.number(),
  epssScore: z.number(),
  epssScorePrevious: z.number(),
  cbdrStage: z.array(z.string()).optional(),
  patchable: z.boolean().optional(),
  exploitable: z.boolean().optional(),
  riskFactors: z.array(z.string()).optional(),
  code: z
    .object({
      assetsCount: z.number().nullable(),
      packages: z
        .object({
          packageCount: z.number().nullable(),
          repositoryCount: z.number().nullable(),
        })
        .nullable(),
      iacResources: z
        .object({
          iacResourcesCount: z.number().nullable(),
          repositoryCount: z.number().nullable(),
        })
        .nullable(),
    })
    .nullable(),
  deploy: z
    .object({
      assetsCount: z.number().nullable(),
      registryImages: z
        .object({
          registryImagesCount: z.number().nullable(),
          repositoryCount: z.number().nullable(),
        })
        .nullable(),
      hostVmImages: z
        .object({
          hostVmImagesCount: z.number().nullable(),
        })
        .nullable(),
    })
    .nullable(),
  run: z
    .object({
      assetsCount: z.number().nullable(),
      deployedImages: z
        .object({
          deployedImagesCount: z.number().nullable(),
          containerCount: z.number().nullable(),
        })
        .nullable(),
      functions: z
        .object({
          functionsCount: z.number().nullable(),
        })
        .nullable(),
      hosts: z
        .object({
          hostsCount: z.number().nullable(),
        })
        .nullable(),
    })
    .nullable(),
});

export const VulnerabilitiesAssetSearchItemSchema = z.object({
  unifiedAssetId: z.string(),
  assetType: z.string(),
  assetName: z.string().nullable(),
  cloudAccount: z.string().nullable(),
  c2cFixSource: z.string().nullable(),
  registryName: z.string().nullable(),
  internetExposed: z.boolean(),
  packageInUse: z.boolean(),
  application: z.string().nullable(),
  owner: z.string().nullable(),
  spId: z.string().nullish(),
  severityCount: z.object({
    lowSeverityCount: z.number(),
    mediumSeverityCount: z.number(),
    highSeverityCount: z.number(),
    criticalSeverityCount: z.number(),
  }),
});

export const VulnerabilitiesSearchDataResponseSchema = z.object({
  items: z.array(VulnerabilitiesSearchItemSchema),
  nextPageToken: z.string().nullish(),
  totalRows: z.number(),
  totalVulnerabilities: z.number(),
  totalAssets: z.number(),
});

export const VulnerabilitiesAssetSearchDataResponseSchema = z.object({
  items: z.array(VulnerabilitiesAssetSearchItemSchema),
  nextPageToken: z.string().nullish(),
  totalRows: z.number(),
  totalAssets: z.number(),
});

export const VulnerabilitiesSearchResponseSchema = z.object({
  data: VulnerabilitiesSearchDataResponseSchema.optional(),
  assetSearchResultData:
    VulnerabilitiesAssetSearchDataResponseSchema.optional(),
  id: z.string(),
});

export type VulnerabilitiesSearchItem = z.infer<
  typeof VulnerabilitiesSearchItemSchema
>;

export type VulnerabilitiesAssetsSearchItem = z.infer<
  typeof VulnerabilitiesAssetSearchItemSchema
>;

export const VulnerabilitiesAssetsSearchSchema = z.object({
  query: z.string().optional(),
  cveId: z.string(),
  assetLifecycle: z.string().nullable(),
  assetType: z.string(),
});

export type VulnerabilitiesAssetsSearchType = z.infer<
  typeof VulnerabilitiesAssetsSearchSchema
>;

export const VulnerabilitiesAssetsSearchResponseSchema = z.object({
  value: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        atRisk: z.boolean().optional(),
      }),
    )
    .optional(),
  nextPageToken: z.string().nullable().optional(),
});

export type VulnerabilitiesAssetsSearchResponseType = z.infer<
  typeof VulnerabilitiesAssetsSearchResponseSchema
>;

// Remediation

// status
export const RemediationStatusRequestSchema = z.object({
  cveId: z.string(),
  prismaId: z.string(),
  assetId: z.array(z.string()),
  assetType: z.string(),
});

export const RemediationStatusResponseObjectSchema = z.object({
  prismaId: z.string(),
  unifiedAssetId: z.string(),
  assetType: z.string().optional(),
  assetLifecycle: z.string(),
  cveId: z.string(),
  source: z.string(),
  remediationActions: z.array(remediationAction).optional(),
  lastUpdatedTimestamp: z.number().int(),
});

export const RemediationStatusResponseSchema = z.object({
  values: z.array(RemediationStatusResponseObjectSchema),
});

// action
export const RemediationActionsObjectSchema = z.object({
  cveId: z.string(),
  prismaId: z.string(),
  source: z.string().optional(),
  remediationAction: remediationActionsEnum,
  assetDetails: z
    .array(
      z.object({
        assetType: z.string(),
        assetId: z.string(),
      }),
    )
    .optional(),
  assetType: z.string().optional(),
  // Jira remediation specific params passed as headers
  "create-task-template-id": z.string().optional(),
});

export const RemediationActionCreatePRObjectSchema = z.object({
  cveId: z.string(),
  prismaId: z.string(),
  assetId: z.string().optional(),
  fixDetails: z
    .array(
      z.object({
        assetId: z.string(),
        fileId: z.string().optional(),
        cveUid: z.string().optional(),
        packageId: z.string().optional(),
        fixVersion: z.string().optional(),
      }),
    )
    .optional(),
});

export const RemediationActionsResponseSchema = z.object({
  message: z.string().optional(),
  // For Jira remediation action
  assetInfo: z
    .array(
      z.object({
        assetId: z.string(),
        assetType: z.string(),
      }),
    )
    .optional(),
  ticketId: z.string().optional(),
});

// remediation types
export type RemediationStatusRequestType = z.infer<
  typeof RemediationStatusRequestSchema
>;

export type RemediationStatusResponseObjectType = z.infer<
  typeof RemediationStatusResponseObjectSchema
>;

export type RemediationActionsRequestObjectType = z.infer<
  typeof RemediationActionsObjectSchema
>;

export type RemediationStatusResponseSchemaType = z.infer<
  typeof RemediationStatusResponseSchema
>;

export type RemediationActionCreatePRObject = z.infer<
  typeof RemediationActionCreatePRObjectSchema
>;

export type LifeCycleType = z.infer<typeof LifeCycleTypeEnumSchema>;

export const VulnerabilitiesCasAssetsResponseSchema = z.object({
  value: z.array(
    z.object({
      assetName: z.string(),
      filePath: z.string(),
      owner: z.string(),
      packageVersion: z.string(),
      repositoryName: z.string(),
      vulnerability: z.object({
        critical: z.number().nonnegative().optional(),
        high: z.number().nonnegative().optional(),
        low: z.number().nonnegative().optional(),
        medium: z.number().nonnegative().optional(),
      }),
    }),
  ),
});

export const VulnerabilitiesCasAssetsRequestSchema = z.object({
  cveId: z.string(),
  prismaId: z.string(),
  assetIds: z.array(z.string()),
});

export const C2CGraphRequestSchema = z.object({
  source: z.object({ unifiedAssetId: z.string(), stage: z.literal("RUN") }),
  traceStages: z.array(
    z.object({ name: z.literal("CODE"), cveId: z.string() }),
  ),
});

const AssetNodeSchema = z.object({
  label: z.string(),
  type: z.union([z.literal("PrimaryAsset"), z.literal("Asset")]),
  metadata: z
    .object({
      stage: z
        .union([
          z.literal("CODE"),
          z.literal("BUILD"),
          z.literal("DEPLOY"),
          z.literal("RUN"),
        ])
        .optional(),
      unifiedAssetId: z.string().optional(),
      assetName: z.string().optional(),
      attributes: z
        .object({
          pipelineName: z.string().optional(),
          pipelineType: z.string().optional(),
          jobInstanceName: z.array(z.string()).optional(),
          filePath: z.string().optional(),
          fileName: z.string().optional(),
          repoName: z.string().optional(),
          repoOwner: z.string().optional(),
          subType: z.string().optional(),
          fileId: z.string().optional(),
          cveUid: z.string().optional(),
          packageId: z.string().optional(),
          fixVersion: z.string().optional(),
        })
        .optional(),
    })
    .optional(),
});

const AssetGraphSchema = z.object({
  nodes: z.record(AssetNodeSchema),
  edges: z
    .array(
      z.object({
        id: z.string().optional(),
        relation: z.string().optional(),
        source: z.string(),
        target: z.string(),
        directed: z.boolean().optional(),
        relationshipTypeId: z.number().optional(),
        metadata: z.record(z.string()).optional(),
      }),
    )
    .optional(),
});

export const C2CGraphResponseSchema = z.object({ graph: AssetGraphSchema });
export type C2CGraphResponseType = z.infer<typeof C2CGraphResponseSchema>;
