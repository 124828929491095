import {
  type ComponentPropsWithoutRef,
  type ElementType,
  type ReactElement,
  type ReactNode,
} from "react";

import { classNames } from "utils";
import { TruncateText } from "../TruncateText";
import { Bold, type BoldProps } from "./Bold";

const sizeVariants = {
  sm: "text-xs leading-normal",
  lg: "text-sm",
};

export type OverlineProps<
  T = Omit<ComponentPropsWithoutRef<"span">, "className">,
> = BoldProps<T> & {
  /**
   * Add a class name to the element.
   */
  addClassName?: string;
  /**
   * The type of element to render.
   *
   * By default, `Overline` renders an `span` element, but any element type can be used.
   */
  as?: ElementType;
  children: ReactNode;
  size?: keyof typeof sizeVariants;
  /**
   * Truncate text when there is no room.
   *
   * This will add a flex class to the Overline, so it will no longer wrap.
   */
  truncate?: boolean;
};

/**
 * Renders its children in CAPS and semibold.
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Overline } from "ui";
 * ```
 */
export function Overline<
  T = Omit<ComponentPropsWithoutRef<"span">, "className">,
>({
  addClassName,
  as = "span",
  size = "sm",
  truncate = false,
  children,
  ...passThrough
}: OverlineProps<T>): ReactElement {
  const className = classNames(
    sizeVariants[size],
    truncate && "flex",
    "uppercase tracking-widest",
    addClassName,
  );

  return (
    <Bold
      addClassName={className}
      appearance="semibold"
      as={as}
      {...passThrough}
    >
      {truncate ? <TruncateText>{children}</TruncateText> : children}
    </Bold>
  );
}
