import { useIntl } from "react-intl";
import { isNil } from "remeda";
import { type PermissionsStatusComponent } from "requests";
import { type RowInstance } from "table";
import { Button } from "ui";
import { snakeCase } from "utils";
import { statusHeaderMap } from "../../../../../messages/statusHeaderMessages";

type NameProps = {
  row: Pick<RowInstance<PermissionsStatusComponent>, "original">;
  setBreadCrumbItemName: (value: string) => void;
  setTableData: (value: PermissionsStatusComponent[]) => void;
  setIsDrillDownTriggered: (value: boolean) => void;
};

type StatusNameColumn = keyof typeof statusHeaderMap;

export default function Name({
  row,
  setBreadCrumbItemName,
  setTableData,
  setIsDrillDownTriggered,
}: NameProps) {
  const intl = useIntl();
  const { displayName = "", components } = row.original;
  const statusNameKey = snakeCase(displayName) as StatusNameColumn;
  const formattedDisplayName = statusHeaderMap[statusNameKey]
    ? intl.formatMessage(statusHeaderMap[statusNameKey as StatusNameColumn])
    : displayName;

  if (!isNil(components))
    return (
      <div className="-ml-4">
        <Button
          appearance="clear"
          onClick={() => {
            setBreadCrumbItemName(displayName);
            setTableData(components);
            setIsDrillDownTriggered(true);
          }}
        >
          {formattedDisplayName}
        </Button>
      </div>
    );

  return <div className="break-words">{formattedDisplayName}</div>;
}
