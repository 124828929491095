import { useMemo } from "react";
import { useIntl } from "react-intl";
import { TruncateCell, type ColumnOptions } from "table";

export const useResourceGroupsModalTableColumns = () => {
  const intl = useIntl();

  return useMemo(() => {
    return [
      {
        accessor: "name",
        Cell: TruncateCell,
        Header: intl.formatMessage({
          defaultMessage: "Name",
          id: "Hik/zF",
          description: "Name header",
        }),
        width: 375,
      },
    ] as ColumnOptions<{ name: string }>[];
  }, [intl]);
};
