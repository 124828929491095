import { z } from "zod";
import { TimeRangeSchema } from "../../schemas";

const TranslationSchema = z.object({
  id: z.string(),
  defaultMessage: z.union([z.string(), z.array(z.any())]),
});

const GridNumberSchema = z.number().min(0.0).max(8);

export const ChartSchema = z.object({
  id: z.string(),
  nameDefinition: TranslationSchema,
  descriptionDefinition: TranslationSchema,
  hasPermission: z.function().returns(z.union([z.boolean(), z.undefined()])),
  Component: z.union([z.function(), z.object({}).passthrough()]),
  chartType: z.enum([
    "line",
    "bar",
    "pie",
    "table",
    "area",
    "gauge",
    "funnel",
    "geo",
    "metrics",
    "thermometer",
    "sankey",
  ]),
  tags: z.array(TranslationSchema).optional(),
  /**
   * Optionally define default options for a chart
   */
  defaultGrid: z
    .object({
      /**
       * The default height/width of a chart when added.
       */
      h: GridNumberSchema.optional(),
      w: GridNumberSchema.optional(),
      /**
       * Set the minimum/maximum height and width a given chart can be resized to.
       */
      minH: GridNumberSchema.optional(),
      minW: GridNumberSchema.optional(),
      maxH: GridNumberSchema.optional(),
      maxW: GridNumberSchema.optional(),
      isResizable: z.boolean().optional(),
    })
    .optional(),
});

export type ChartType = z.infer<typeof ChartSchema>;

export const ChartMapSchema = z.record(ChartSchema.shape.id, ChartSchema);

export type ChartMapType = z.infer<typeof ChartMapSchema>;

export const PreferencesChartSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  component: z.string(),
  settings: z.record(z.string(), z.unknown()).optional(),
  grid: z.object({
    x: z.number(),
    y: z.number(),
    h: GridNumberSchema,
    w: GridNumberSchema,
    minH: GridNumberSchema.optional(),
    minW: GridNumberSchema.optional(),
    maxH: GridNumberSchema.optional(),
    maxW: GridNumberSchema.optional(),
    i: z.string().optional(),
    isResizable: z.boolean().optional(),
  }),
});

export type PreferencesChartType = z.infer<typeof PreferencesChartSchema>;

export const DashboardGlobalFilterSchema = z.object({
  "account.group": z.array(z.string()).optional(),
  "cloud.account": z.array(z.string()).optional(),
  timeRange: TimeRangeSchema.optional(),
});

export type DashboardGlobalFilterType = z.infer<
  typeof DashboardGlobalFilterSchema
>;

export const DashboardSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  charts: z.record(z.string(), PreferencesChartSchema),
  filters: DashboardGlobalFilterSchema.optional(),
  isCuratedDashboard: z.boolean().optional(),
  display: z.boolean().optional(),
});

export type DashboardType = z.infer<typeof DashboardSchema>;

export const ResourcesByExposureRequestSchema = z.object({
  accountGroupIds: z.array(z.string()),
  accountGroupNames: z.array(z.string()).optional(),
  accountIds: z.array(z.string()),
  classifications: z.array(z.string()).optional(),
  limit: z.number().optional(),
  timeRange: TimeRangeSchema.optional(),
});

export type ResourcesByExposureFiltersType = z.infer<
  typeof ResourcesByExposureRequestSchema
>;

export const ResourcesByExposureResponseSchema = z.object({
  resources: z.object({
    exposure: z.array(
      z.object({
        count: z.number(),
        name: z.string(),
      }),
    ),
    total: z.number(),
  }),
});

export const ObjectsRequestSchema = ResourcesByExposureRequestSchema;

export type ClassificationData = z.infer<typeof ClassificationSchema>;
const ClassificationSchema = z.object({
  count: z.number(),
  name: z.string(),
});

export const ObjectsResponseSchema = z.object({
  objects: z.object({
    classification: z.array(ClassificationSchema),
    total: z.number(),
  }),
});

export const TopPubliclyExposedRequestSchema = ResourcesByExposureRequestSchema;

export const TopPubliclyExposedResponseSchema = z.array(
  z.object({
    count: z.number(),
    name: z.string(),
  }),
);

export type ObjectByRegionRequest = z.infer<typeof ObjectByRegionRequestSchema>;

export const ObjectByRegionRequestSchema = ResourcesByExposureRequestSchema;
export type ObjectByRegionData = z.infer<typeof ObjectByRegionDataSchema>;

export const ObjectByRegionDataSchema = z.array(
  z.object({
    classification: z.array(ClassificationSchema),
    cloudType: z.string().optional(),
    latitude: z.number(),
    longitude: z.number(),
    regionCode: z.string().optional(),
    regionName: z.string().optional(),
  }),
);
