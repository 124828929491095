import { useMemo, type ReactElement } from "react";
import { useController } from "react-hook-form";

import { type CloudType } from "../../../../types";
import FeaturesListView from "./FeaturesListView";

export type FeatureItemType = {
  id: string;
  icon: ReactElement;
  value: string;
  tag?: string;
  category: string;
  showCheckbox?: boolean;
  showToggle?: boolean;
  toggleValue?: string;
};

interface FeatureGroupPropTypes {
  name: string;
  cloudType?: CloudType;
  isComputeWorkloadSupported?: boolean;
  defaultValue?: object;
  items: FeatureItemType[];
  accountType?: string;
}

export default function FeatureGroup({
  name,
  cloudType,
  defaultValue = {},
  isComputeWorkloadSupported,
  items,
  accountType,
}: FeatureGroupPropTypes) {
  const {
    field: { onChange, value: values },
  } = useController({
    name,
    defaultValue: defaultValue,
  });

  const {
    field: { onChange: onToggleChange, value: orgValues },
  } = useController({
    name: "orgSecurityCapabilities",
    defaultValue: defaultValue,
  });

  const { foundationalFeatures, advancedFeatures } = useMemo(
    () => ({
      foundationalFeatures: items.filter(
        ({ category }) => category === "foundational",
      ),
      advancedFeatures: items.filter(({ category }) => category === "advanced"),
    }),
    [items],
  );

  const featuresListProps = {
    values,
    orgValues,
    onChange,
    onToggleChange,
  };

  return (
    <div>
      <FeaturesListView
        category="foundational"
        cloudType={cloudType}
        isComputeWorkloadSupported={isComputeWorkloadSupported}
        accountType={accountType}
        features={foundationalFeatures}
        {...featuresListProps}
      />

      {!!advancedFeatures.length && (
        <FeaturesListView
          category="advanced"
          cloudType={cloudType}
          isComputeWorkloadSupported={isComputeWorkloadSupported}
          accountType={accountType}
          features={advancedFeatures}
          {...featuresListProps}
        />
      )}
    </div>
  );
}
