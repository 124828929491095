import { TruncateCell, makeAbsoluteTimestampCell } from "table";

import { type AbsoluteTimestampProps } from "ui";

const MediumDateShortTimeAbsoluteTimestampCell = makeAbsoluteTimestampCell({
  formatOptions: {
    dateStyle: "medium",
    timeStyle: "short",
  },
});

type DataProfileTimeStampCellProps = {
  value: AbsoluteTimestampProps["value"];
};

export const DataProfileTimeStampCell = ({
  value,
}: DataProfileTimeStampCellProps) => (
  <TruncateCell
    value={<MediumDateShortTimeAbsoluteTimestampCell value={value} />}
  />
);
