import { type IntlShape } from "react-intl";
import { type BannerAppearance } from "ui";
import { AWS, AZURE, GCP } from "../../../../constants";
import {
  awsDescriptionMessages,
  azureDescriptionMessages,
  gcpDescriptionMessages,
} from "./messages";

function getDescriptionMsg(type: string, description: string) {
  switch (type) {
    case AZURE:
      return azureDescriptionMessages[
        description as keyof typeof azureDescriptionMessages
      ];
    case GCP:
      return gcpDescriptionMessages[
        description as keyof typeof gcpDescriptionMessages
      ];
    case AWS:
      return awsDescriptionMessages[
        description as keyof typeof awsDescriptionMessages
      ];
  }
  return "";
}

function getErrorType(errorMessage: string) {
  switch (errorMessage) {
    case "unauthorized_access":
      return "error";
    case "permission_error":
    case "no_automap_permissions":
    case "no_folder_view_permissions":
      return "warning";
    default:
      return "warning";
  }
}

export const getCredentialsErrorMessage = ({
  errorMessage,
  type,
  intl,
}: {
  errorMessage: string;
  type: string;
  intl: IntlShape;
}) => {
  if (errorMessage) {
    const errorType = getErrorType(errorMessage) as BannerAppearance;
    const descriptionMsg = getDescriptionMsg(type, errorMessage);
    return [
      {
        description: descriptionMsg
          ? intl.formatMessage(descriptionMsg)
          : "Undefined",
        errorType,
      },
    ];
  } else {
    return [];
  }
};
