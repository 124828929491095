// OpenAPI: https://prismacloud.pages.prismacloud.io/rql/search-service/openapi/#operation/searchWithGraphFormat

import { z } from "zod";
import { CloudTypesWithOtherEnumSchema } from "../../cloudType";
import { findingTypesSchema } from "../../findingTypesSchema";
import { CnsCloudGraphsPathsSchema } from "../cns";

const PermissionsResponseSchema = z.object({
  grantedByCloudType: z.string(),
  grantedByEntityType: z.string(),
  grantedByEntityTypeId: z.union([
    z.number().int().optional(),
    z.string().optional(),
  ]),
  grantedByEntityName: z.string(),
  grantedByEntityAssetId: z.string().optional(),
  destCloudType: z.string(),
  destCloudServiceName: z.string(),
  destCloudResourceName: z.string().optional(),
  destResourceTypeId: z.union([
    z.number().int().optional(),
    z.string().optional(),
  ]),
  destResourceAssetId: z.string().optional(),
  violatingAssetIds: z.array(z.string()).optional(),
});

export type PermissionsResponse = z.infer<typeof PermissionsResponseSchema>;

const CloudGraphSchema = z.object({
  nodes: z.record(
    z.object({
      nodeData: z.object({
        APIID: z.string().optional(),
        rrn: z.string().optional(),
        cloudType: z.string(),
        name: z.string().optional(),
        nativeID: z.string(),
        type: z.string(),
        unifiedAssetID: z.string().optional(),
        subType: z.string().optional(),
      }),
    }),
  ),
  paths: CnsCloudGraphsPathsSchema,
});

export type CloudGraph = z.infer<typeof CloudGraphSchema>;

const PrimaryAssetNodeSchema = z.object({
  label: z.string(),
  type: z.literal("PrimaryAsset"),
  metadata: z
    .object({
      externalAssetId: z.string(),
      // `assetType` and `assetCategory` can be derived from `apiId`
      assetType: z.string().nullish(),
      assetCategory: z.string().nullish(),
      // Alerts api doesn't contain this field
      apiId: z.number().optional(),
      assetApiId: z.number().optional(),
      accountId: z.string(),
      // Alerts api doesn't contain this field
      findingCount: z.number().int().optional(),
      // Alerts api doesn't contain this field
      matchingSecurityIssuesCount: z.number().int().optional(),
      lastModifiedAt: z.number().int().optional(),
    })
    .optional(),
});

export type PrimaryAssetNode = z.infer<typeof PrimaryAssetNodeSchema>;

// Asset related to the primary asset.
const AssetNodeSchema = z.object({
  label: z.string(),
  type: z.literal("Asset"),
  metadata: z
    .object({
      externalAssetId: z.string(),
      apiId: z.number().optional(),
      assetApiId: z.number().optional(),
      accountId: z.string(),
      lastModifiedAt: z.number().int().optional(),
      assetType: z.string().optional(),
    })
    .optional(),
});

export const FindingNodeSchema = z.object({
  label: z.string(),
  type: z.literal("Finding"),
  metadata: z.object({
    type: findingTypesSchema,
    policyType: z.string().optional(),
    severity: z.string(),
    description: z.string(),
    lastModifiedAt: z.number().int().optional(),
    permissionGraph: z
      .object({ items: z.array(PermissionsResponseSchema) })
      .optional(),
    networkGraph: z
      .object({ cloudGraphs: z.record(CloudGraphSchema) })
      .optional(),
    isRemediable: z.boolean().optional(),
    findingRemediationDetails: z
      .object({
        buildTimeMetadata: z
          .object({
            fix_pr_url: z.string().optional(),
            description: z.string().optional(),
            bc_resource_id: z.string().optional(),
            git_url: z.string().optional(),
            has_fix: z.boolean().optional(),
            alert_decoration_status: z.string().optional(),
          })
          .partial()
          .optional(),
        runtimeMetadata: z
          .object({ remediationTs: z.number().optional() })
          .partial()
          .optional(),
      })
      .optional(),
  }),
});

export type FindingNode = z.infer<typeof FindingNodeSchema>;

const VulnerabilityNodeSchema = z.object({
  label: z.string(),
  type: z.literal("Vulnerability"),
  metadata: z
    .object({
      severity: z.string(),
      score: z.number(),
      patchable: z.boolean().optional(),
      exploitable: z.boolean().optional(),
      published: z.number().optional(),
    })
    .optional(),
});

export type VulnerabilityNode = z.infer<typeof VulnerabilityNodeSchema>;

const SecretNodeMetadataSchema = z.object({
  capabilityType: z.literal("secret"),
  additionalCapabilityAttributes: z.object({
    id: z.string(),
    severity: z.enum(["critical", "high", "medium", "low"]),
    type: z.string(),
    path: z.string(),
    locationInFile: z.string().optional(),
    snippet: z.string().optional(),
    modifiedTime: z.number(),
  }),
});

// https://git.scm.prismacloud.io/prismacloud/rql/asset-capability-data-schema/-/tree/main/schema
const SecretNodeSchema = z.object({
  label: z.string(),
  type: z.literal("Capability"),
  metadata: SecretNodeMetadataSchema,
});

export type SecretNode = z.infer<typeof SecretNodeSchema>;

const AssetGraphSchema = z.object({
  nodes: z.record(
    z.union([
      PrimaryAssetNodeSchema,
      AssetNodeSchema,
      FindingNodeSchema,
      VulnerabilityNodeSchema,
      SecretNodeSchema,
    ]),
  ),
  edges: z
    .array(
      z.object({
        id: z.string().optional(),
        label: z.string().optional(),
        source: z.string(),
        target: z.string(),
        directed: z.boolean().optional(),
        relationshipTypeId: z.number().optional(),
        metadata: z.record(z.string()).optional(),
      }),
    )
    .optional(),
});

export const AssetGraphRequestSchema = z.object({
  query: z.string(),
  nextPageToken: z.string().optional(),
  savedSearchId: z.string().optional(),
});

export const AssetGraphResponseSchema = z.object({
  graphs: z.array(z.object({ graph: AssetGraphSchema })),
  nextPageToken: z.string().optional(),
  resultMetadata: z.object({
    searchId: z.string(),
    convertedQuery: z.string().optional(),
    cloudType: CloudTypesWithOtherEnumSchema,
  }),
});

export type AssetGraphResponse = z.infer<typeof AssetGraphResponseSchema>;
