import { FormattedMessage } from "react-intl";

import { RelativeTimestamp } from "../Timestamp";
import { type sizeVariants } from "../Typography";
type DataTimestampProps = {
  timestamp?: number;
  timestampSize?: keyof typeof sizeVariants;
};

export function DataTimestamp({
  timestamp,
  timestampSize = "lg",
}: DataTimestampProps) {
  if (typeof timestamp !== "number" || timestamp === 0)
    return <div>&nbsp;</div>;

  return (
    <div>
      <FormattedMessage
        defaultMessage="Data As Of: {timestamp}"
        id="SOUTkj"
        description="A label to indicate when the data was created."
        values={{
          timestamp: (
            <RelativeTimestamp value={timestamp} size={timestampSize} />
          ),
        }}
      />
    </div>
  );
}
