import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";
import { baseApi } from "../../apis/baseApi";
import {
  ConversationEditNameRequestSchema,
  CopilotCheckTosResponseSchema,
  CopilotFavoritesRequestSchema,
  CopilotFavoritesSchema,
  CopilotFeedbackRequestSchema,
  CopilotFeedbackResponseSchema,
  CopilotHistorySchema,
  CopilotPromptRequestSchema,
  CopilotPromptResponseSchema,
  CopilotRequestTypesSchema,
  CopilotSuggestionsResponseSchema,
  CopilotTosRequestSchema,
  CreateSupportCaseRequestSchema,
  CreateSupportCaseResponseSchema,
  type ConversationEditNameRequest,
  type CopilotFavoritesRequest,
  type CopilotFeedbackRequest,
  type CopilotOnboardingTemplateRequest,
  type CopilotPromptRequest,
  type CopilotRequestType,
  type CopilotSuggestionsRequest,
  type CopilotTosRequest,
} from "./schemas";

export type CreateSupportCaseRequest = z.infer<
  typeof CreateSupportCaseRequestSchema
>;

export const copilotKeys = {
  all: [{ scope: "copilot" }] as const,
  tos: () => [{ ...copilotKeys.all[0], entity: "tos" }] as const,
  welcome: () => [{ ...copilotKeys.all[0], entity: "welcome" }] as const,
  history: () => [{ ...copilotKeys.all[0], entity: "history" }] as const,
  favorites: () => [{ ...copilotKeys.all[0], entity: "favorites" }] as const,
  feedbackSuggestions: (requestType: CopilotRequestType) =>
    [
      { ...copilotKeys.all[0], entity: "feedbackSuggestions", requestType },
    ] as const,
  conversationDetails: (conversationId: string) =>
    [
      { ...copilotKeys.all[0], entity: "conversationDetails", conversationId },
    ] as const,
  requestTypes: () =>
    [{ ...copilotKeys.all[0], entity: "request-types" }] as const,
};

export const copilotPrompt = async (
  body: CopilotPromptRequest & {
    baseUrl?: string;
  },
  prismaCopilotV2Enabled?: boolean,
) => {
  return jsonApi({
    path: `/copilot/api/${prismaCopilotV2Enabled ? "v2" : "v1"}/prompt`,
    body,
    config: {
      method: "post",
    },
    requestSchema: CopilotPromptRequestSchema,
    responseSchema: CopilotPromptResponseSchema,
  });
};

export const copilotFeedback = async (body: CopilotFeedbackRequest) => {
  return jsonApi({
    path: "/copilot/api/v1/feedback",
    body,
    config: { method: "post" },
    requestSchema: CopilotFeedbackRequestSchema,
    responseSchema: CopilotFeedbackResponseSchema,
  });
};

export const copilotOnboardingTemplate = async (
  body: CopilotOnboardingTemplateRequest,
) => {
  return baseApi({
    path: "/copilot/api/v1/onboarding/template",
    config: {
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
      method: "post",
    },
  });
};

/** Returns a list of request types accepted by the fetch request */
export const listCopilotRequestTypes = async (
  _props: QueryFunctionContext<
    ReturnType<(typeof copilotKeys)["requestTypes"]>
  >,
) => {
  return jsonApi({
    path: "/copilot/api/v1/request-types",
    requestSchema: z.void(),
    responseSchema: CopilotRequestTypesSchema,
  });
};

/** Provides recent history of copilot conversations */
export const getCopilotHistory = async (
  _props: QueryFunctionContext<ReturnType<(typeof copilotKeys)["history"]>>,
) => {
  return jsonApi({
    path: `/copilot/api/v1/history`,
    requestSchema: z.void(),
    responseSchema: CopilotHistorySchema,
  });
};

/** Provides suggestions for copilot autosuggest feature */
export const getCopilotSuggestions = async ({
  request,
  ui_url,
}: CopilotSuggestionsRequest) => {
  return jsonApi({
    path: `/copilot/api/v1/suggest`,
    params: {
      request,
      ...(ui_url ? { ui_url } : {}),
    },
    requestSchema: z.void(),
    responseSchema: CopilotSuggestionsResponseSchema,
  });
};

/** Provides favorites of copilot conversations */
export const getCopilotFavorites = async (
  _props: QueryFunctionContext<ReturnType<(typeof copilotKeys)["favorites"]>>,
) => {
  return jsonApi({
    path: `/copilot/api/v1/favorite`,
    requestSchema: z.void(),
    responseSchema: CopilotFavoritesSchema,
  });
};

export const updateCopilotFavorites = async (body: CopilotFavoritesRequest) => {
  return jsonApi({
    path: "/copilot/api/v1/favorite",
    body,
    config: {
      method: "post",
    },
    requestSchema: CopilotFavoritesRequestSchema,
    responseSchema: z.boolean(),
  });
};

/** Provides details of a copilot conversation */
export const getConversationDetails = async ({
  queryKey: [{ conversationId }],
}: QueryFunctionContext<
  ReturnType<(typeof copilotKeys)["conversationDetails"]>
>) => {
  return jsonApi({
    path: `/copilot/api/v1/conversation/${conversationId}`,
    requestSchema: z.void(),
    responseSchema: z.array(CopilotPromptResponseSchema),
  });
};

export const editConversationName = async ({
  conversationId,
  name,
}: ConversationEditNameRequest & {
  conversationId: string;
}) => {
  return jsonApi({
    path: `/copilot/api/v1/conversation/${conversationId}`,
    body: {
      name,
    },
    config: {
      method: "PATCH",
    },
    requestSchema: ConversationEditNameRequestSchema,
    responseSchema: z.boolean(),
  });
};

export const deleteConversation = async ({
  conversationId,
}: {
  conversationId: string;
}) => {
  return jsonApi({
    path: `/copilot/api/v1/conversation/${conversationId}`,
    config: {
      method: "delete",
    },
    requestSchema: z.void(),
    responseSchema: z.boolean(),
  });
};

export const createSupportCase = async (body: CreateSupportCaseRequest) => {
  return jsonApi({
    path: `/copilot/api/v1/support-case-creation`,
    body,
    config: {
      method: "post",
    },
    requestSchema: CreateSupportCaseRequestSchema,
    responseSchema: CreateSupportCaseResponseSchema,
  });
};

export const getCopilotFeedbackSuggestions = async ({
  queryKey: [{ requestType }],
}: QueryFunctionContext<
  ReturnType<(typeof copilotKeys)["feedbackSuggestions"]>
>) => {
  return jsonApi({
    path: `/copilot/api/v1/feedback-suggestions`,
    params: { requestType },
    requestSchema: z.void(),
    responseSchema: z.array(z.string()),
  });
};

export const checkTos = async (
  _props: QueryFunctionContext<ReturnType<(typeof copilotKeys)["tos"]>>,
) => {
  return jsonApi({
    path: "/copilot/api/v1/tos-check",
    requestSchema: z.void(),
    responseSchema: CopilotCheckTosResponseSchema,
  });
};

export const acceptTos = async (body: CopilotTosRequest) => {
  return jsonApi({
    path: "/copilot/api/v1/tos",
    body,
    config: {
      method: "post",
    },
    requestSchema: CopilotTosRequestSchema,
    responseSchema: z.string(),
  });
};
