import { isChina, isGov } from "environment";
import { ModalWizardStepSummary } from "form";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { type PermissionGroupData } from "requests";
import {
  ActionPlanCard,
  AlarmCentreCard,
  AlertsCard,
  AssetInventoryCard,
  CodeSecurityCard,
  ComplianceCard,
  ComputeCard,
  DashboardCard,
  DataSecurityCard,
  DataSecurityPostureManagementCard,
  InvestigateCard,
  PoliciesCard,
  SettingsCard,
} from "../../../PermissionGroupsSidecar/Cards";
import { type PermissionGroupMap } from "../../../PermissionGroupsSidecar/PermissionGroupsSidecar";
import { type PermissionGroupStepNames } from "../../PermissionGroupWizard";

export const AssignPermissionsSummary = ({
  data,
}: {
  data: PermissionGroupData;
}) => {
  const intl = useIntl();
  const { actionPlans } = useFlags();

  const featureMap = useMemo(
    () =>
      data?.features
        ? data.features.reduce<PermissionGroupMap>(
            (acc, curr) => ({
              ...acc,
              [curr.featureName]: curr,
            }),
            {},
          )
        : {},
    [data?.features],
  );

  return (
    <ModalWizardStepSummary<PermissionGroupStepNames>
      name="assignPermissions"
      title={intl.formatMessage({
        defaultMessage: "Assigned Permissions",
        id: "tYNB2B",
        description: "Form Summary Header",
      })}
    >
      <DashboardCard featureMap={featureMap} />
      <AssetInventoryCard featureMap={featureMap} />
      <InvestigateCard featureMap={featureMap} />
      <PoliciesCard featureMap={featureMap} />
      <ComplianceCard featureMap={featureMap} />
      <CodeSecurityCard featureMap={featureMap} />
      <AlertsCard featureMap={featureMap} />
      <DataSecurityCard featureMap={featureMap} />
      {!isChina() && !isGov() && (
        <DataSecurityPostureManagementCard featureMap={featureMap} />
      )}
      <SettingsCard featureMap={featureMap} />
      <ComputeCard featureMap={featureMap} />
      <AlarmCentreCard featureMap={featureMap} />
      {actionPlans && <ActionPlanCard featureMap={featureMap} />}
    </ModalWizardStepSummary>
  );
};
