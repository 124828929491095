import {
  type CellInstance,
  type ColumnInstance,
  type Data,
  type RowInstance,
  type TableInstance,
} from "../types";

const CSV_INJECTION = /^[\\+=@-]+/;

function exportHeaders<D extends Data>({
  headers,
}: {
  headers: ColumnInstance<D>[];
}): string {
  return headers
    .filter((column) => !column.disableExport && column.isVisible)
    .map((column) => {
      if (column.exportHeader) {
        return column.exportHeader(column);
      }
      return column.render("Header");
    })
    .join(",");
}

function defaultExportCell<D extends Data>(
  cell: CellInstance<D>,
): string | number {
  if (Array.isArray(cell.value)) {
    return cell.value.join(",");
  }
  return cell.value;
}

function sanitizeString(value: string): number | string {
  return value.replace(CSV_INJECTION, "").replace(/"/g, '""');
}

function exportRows<D extends Data>({
  prepareRow,
  rows,
}: {
  prepareRow: (row: RowInstance<D>) => void;
  rows: RowInstance<D>[];
}): string {
  return rows
    .map((row) => {
      prepareRow(row);
      return row.cells
        .filter((cell) => !cell.column.disableExport)
        .map((cell) => {
          const value =
            cell.column.cellToString?.({ row, value: cell.value }) ??
            defaultExportCell(cell);

          if (typeof value === "string") {
            return `"${sanitizeString(value)}"`;
          }
          return value;
        })
        .join(",");
    })
    .join("\n");
}

export function prepareCSV<D extends Data>(
  tableInstance: TableInstance<D>,
): string {
  const { headers, prepareRow, preGroupedRows } = tableInstance;
  const exportedHeaders = exportHeaders({ headers });
  const exportedRows = exportRows({
    prepareRow,
    rows: preGroupedRows as RowInstance<D>[],
  });

  return `${exportedHeaders}\n${exportedRows}`;
}

export default function makeCSV<D extends Data>(
  tableInstance: TableInstance<D>,
): Blob {
  return new Blob([prepareCSV(tableInstance)], {
    type: "text/csv;charset=utf-8;",
  });
}
