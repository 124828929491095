import { z } from "zod";

const AuditLogsFeatureSchema = z.object({
  featureName: z.literal("settingsAuditLogs"),
  operations: z.object({ READ: z.boolean() }),
});
const ComputePolicyCloudFeatureSchema = z.object({
  featureName: z.literal("computePolicyCloud"),
  operations: z.object({ READ: z.boolean(), UPDATE: z.boolean() }),
});
const ComputeMonitorCNNFFeatureSchema = z.object({
  featureName: z.literal("computeMonitorCNNF"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const AlertsOverviewFeatureSchema = z.object({
  featureName: z.literal("alertsOverview"),
  operations: z.object({ READ: z.boolean() }),
});
const ComputeSandboxFeatureSchema = z.object({
  featureName: z.literal("computeSandbox"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeSystemLogsFeatureSchema = z.object({
  featureName: z.literal("computeSystemLogs"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const AlertsAlertRulesFeatureSchema = z.object({
  featureName: z.literal("alertsAlertRules"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ComputePolicyWAASFeatureSchema = z.object({
  featureName: z.literal("computePolicyWAAS"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
// TODO: should be removed once BE is done (RLP-128284)
const AssetInventoryFiltersFeatureSchema = z.object({
  featureName: z.literal("assetInventoryFilters"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ComputePolicyCustomRulesFeatureSchema = z.object({
  featureName: z.literal("computePolicyCustomRules"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const CodeSecuritySupplyChainFeatureSchema = z.object({
  featureName: z.literal("codeSecuritySupplyChain"),
  operations: z.object({ READ: z.boolean() }),
});
const InvestigateNetworkRqlFeatureSchema = z.object({
  featureName: z.literal("investigateNetworkRql"),
  operations: z.object({ READ: z.boolean() }),
});
const ComputeMonitorWAASFeatureSchema = z.object({
  featureName: z.literal("computeMonitorWAAS"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeMonitorCloudFeatureSchema = z.object({
  featureName: z.literal("computeMonitorCloud"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const SettingsCloudAccountsFeatureSchema = z.object({
  featureName: z.literal("settingsCloudAccounts"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ComputeRadarsCloudFeatureSchema = z.object({
  featureName: z.literal("computeRadarsCloud"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const CodeSecurityDashboardFeatureSchema = z.object({
  featureName: z.literal("codeSecurityDashboard"),
  operations: z.object({ READ: z.boolean() }),
});
const InvestigateEventRqlFeatureSchema = z.object({
  featureName: z.literal("investigateEventRql"),
  operations: z.object({ READ: z.boolean() }),
});
const SettingsResourceListFeatureSchema = z.object({
  featureName: z.literal("settingsResourceList"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ComputeMonitorRuntimeContainersFeatureSchema = z.object({
  featureName: z.literal("computeMonitorRuntimeContainers"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const DLPDataDashboardFeatureSchema = z.object({
  featureName: z.literal("dlpDataDashboard"),
  operations: z.object({ READ: z.boolean() }),
});
const CodeSecurityDevPipelinesCodeReviewsFeatureSchema = z.object({
  featureName: z.literal("codeSecurityDevPipelinesCodeReviews"),
  operations: z.object({ READ: z.boolean() }),
});
const SettingsAlertIpAddressesFeatureSchema = z.object({
  featureName: z.literal("settingsAlertIpAddresses"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const SettingsAnomalyTrustedListFeatureSchema = z.object({
  featureName: z.literal("settingsAnomalyTrustedList"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ComplianceReportsFeatureSchema = z.object({
  featureName: z.literal("complianceReports"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const SettingsSSOFeatureSchema = z.object({
  featureName: z.literal("settingsSSO"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const InvestigateConfigRqlFeatureSchema = z.object({
  featureName: z.literal("investigateConfigRql"),
  operations: z.object({ READ: z.boolean() }),
});
const SettingsAccountGroupFeatureSchema = z.object({
  featureName: z.literal("settingsAccountGroup"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const AlertsSnoozeDismissFeatureSchema = z.object({
  featureName: z.literal("alertsSnoozeDismiss"),
  operations: z.object({ UPDATE: z.boolean() }),
});
const CodeSecurityDevPipelinesProjectsFeatureSchema = z.object({
  featureName: z.literal("codeSecurityDevPipelinesProjects"),
  operations: z.object({ READ: z.boolean() }),
});
const CodeSecurityProjectsFeatureSchema = z.object({
  featureName: z.literal("codeSecurityProjects"),
  operations: z.object({ READ: z.boolean() }),
});
const SettingsAccessKeysFeatureSchema = z.object({
  featureName: z.literal("settingsAccessKeys"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
  }),
});
const ComputePolicyHostsFeatureSchema = z.object({
  featureName: z.literal("computePolicyHosts"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const SettingsAnomalyThresholdFeatureSchema = z.object({
  featureName: z.literal("settingsAnomalyThreshold"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComplianceFiltersFeatureSchema = z.object({
  featureName: z.literal("complianceFilters"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ComputeAuthFeatureSchema = z.object({
  featureName: z.literal("computeAuth"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeMonitorRuntimeServerlessFeatureSchema = z.object({
  featureName: z.literal("computeMonitorRuntimeServerless"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputePolicyCodeReposFeatureSchema = z.object({
  featureName: z.literal("computePolicyCodeRepos"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeCollectionsFeatureSchema = z.object({
  featureName: z.literal("computeCollections"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputePolicyRuntimeContainerFeatureSchema = z.object({
  featureName: z.literal("computePolicyRuntimeContainer"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const SettingsLoginIpAddressesFeatureSchema = z.object({
  featureName: z.literal("settingsLoginIpAddresses"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ComputeDownloadsFeatureSchema = z.object({
  featureName: z.literal("computeDownloads"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeMonitorAccessKubernetesFeatureSchema = z.object({
  featureName: z.literal("computeMonitorAccessKubernetes"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeMonitorImagesFeatureSchema = z.object({
  featureName: z.literal("computeMonitorImages"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const SettingsIntegrationsFeatureSchema = z.object({
  featureName: z.literal("settingsIntegrations"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ComputePolicyAccessKubernetesFeatureSchema = z.object({
  featureName: z.literal("computePolicyAccessKubernetes"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeUIEventSubscriberFeatureSchema = z.object({
  featureName: z.literal("computeUIEventSubscriber"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const DLPDataProfileFeatureSchema = z.object({
  featureName: z.literal("dlpDataProfile"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const DataSecurityPostureManagementFeatureSchema = z.object({
  featureName: z.literal("dataSecurityPostureManagement"),
  operations: z.object({ UPDATE: z.boolean() }),
});
const PoliciesFeatureSchema = z.object({
  featureName: z.literal("policies"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const PolicyComplianceMappingFeatureSchema = z.object({
  featureName: z.literal("policyComplianceMapping"),
  operations: z.object({
    UPDATE: z.boolean(),
  }),
});
const ComputeMonitorCIFeatureSchema = z.object({
  featureName: z.literal("computeMonitorCI"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputePolicyAccessDockerFeatureSchema = z.object({
  featureName: z.literal("computePolicyAccessDocker"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const SettingsUsersFeatureSchema = z.object({
  featureName: z.literal("settingsUsers"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ComputeMonitorVulnFeatureSchema = z.object({
  featureName: z.literal("computeMonitorVuln"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const AlertsRemediationFeatureSchema = z.object({
  featureName: z.literal("alertsRemediation"),
  operations: z.object({ UPDATE: z.boolean() }),
});
const ComputePolicyAccessSecretsFeatureSchema = z.object({
  featureName: z.literal("computePolicyAccessSecrets"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeSystemOperationsFeatureSchema = z.object({
  featureName: z.literal("computeSystemOperations"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const AlertsSavedFiltersFeatureSchema = z.object({
  featureName: z.literal("alertsSavedFilters"),
  operations: z.object({
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
    UPDATE: z.boolean(),
  }),
});
const AlertsReportFeatureSchema = z.object({
  featureName: z.literal("alertsReport"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ComputeMonitorRuntimeHostsFeatureSchema = z.object({
  featureName: z.literal("computeMonitorRuntimeHosts"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const SettingsRepositoriesFeatureSchema = z.object({
  featureName: z.literal("settingsRepositories"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const AlarmCentreSettingsFeatureSchema = z.object({
  featureName: z.literal("alarmCentreSettings"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ComputeMonitorServerlessFeatureSchema = z.object({
  featureName: z.literal("computeMonitorServerless"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputePolicyContainersFeatureSchema = z.object({
  featureName: z.literal("computePolicyContainers"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const AlertsNotificationTemplatesFeatureSchema = z.object({
  featureName: z.literal("alertsNotificationTemplates"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ComputeRadarsServerlessFeatureSchema = z.object({
  featureName: z.literal("computeRadarsServerless"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeMonitorComplianceFeatureSchema = z.object({
  featureName: z.literal("computeMonitorCompliance"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeMonitorAccessDockerFeatureSchema = z.object({
  featureName: z.literal("computeMonitorAccessDocker"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComplianceOverviewFeatureSchema = z.object({
  featureName: z.literal("complianceOverview"),
  operations: z.object({
    READ: z.boolean(),
  }),
});
const DLPResourceFeatureSchema = z.object({
  featureName: z.literal("dlpResource"),
  operations: z.object({
    UPDATE: z.boolean(),
    READ: z.boolean(),
  }),
});
const SavedSearchesFeatureSchema = z.object({
  featureName: z.literal("savedSearches"),
  operations: z.object({
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const SettingsEnterpriseFeatureSchema = z.object({
  featureName: z.literal("settingsEnterprise"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeMonitorHostsFeatureSchema = z.object({
  featureName: z.literal("computeMonitorHosts"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const DLPDataInventoryFeatureSchema = z.object({
  featureName: z.literal("dlpDataInventory"),
  operations: z.object({ READ: z.boolean() }),
});
const ComputeManageAlertsFeatureSchema = z.object({
  featureName: z.literal("computeManageAlerts"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputePolicyRuntimeServerlessFeatureSchema = z.object({
  featureName: z.literal("computePolicyRuntimeServerless"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const DLPDataPatternFeatureSchema = z.object({
  featureName: z.literal("dlpDataPattern"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const SettingsCodeSecurityFeatureSchema = z.object({
  featureName: z.literal("settingsCodeSecurity"),
  operations: z.object({ READ: z.boolean() }),
});
const ComputePolicyComplianceCustomRulesFeatureSchema = z.object({
  featureName: z.literal("computePolicyComplianceCustomRules"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeRadarsHostsFeatureSchema = z.object({
  featureName: z.literal("computeRadarsHosts"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputePolicyCNNFFeatureSchema = z.object({
  featureName: z.literal("computePolicyCNNF"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputePrivilegedOperationsFeatureSchema = z.object({
  featureName: z.literal("computePrivilegedOperations"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComplianceStandardsFeatureSchema = z.object({
  featureName: z.literal("complianceStandards"),
  operations: z.object({
    READ: z.boolean(),
    CREATE: z.boolean(),
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
  }),
});
const ComputeAccessUIFeatureSchema = z.object({
  featureName: z.literal("computeAccessUI"),
  operations: z.object({ READ: z.boolean() }),
});
const ComputePolicyRuntimeHostsFeatureSchema = z.object({
  featureName: z.literal("computePolicyRuntimeHosts"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeManageDefendersFeatureSchema = z.object({
  featureName: z.literal("computeManageDefenders"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputeMonitorRuntimeIncidentsFeatureSchema = z.object({
  featureName: z.literal("computeMonitorRuntimeIncidents"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const ComputePolicyServerlessFeatureSchema = z.object({
  featureName: z.literal("computePolicyServerless"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const SettingsLicensingFeatureSchema = z.object({
  featureName: z.literal("settingsLicensing"),
  operations: z.object({ READ: z.boolean() }),
});
const ComputeMonitorCodeReposFeatureSchema = z.object({
  featureName: z.literal("computeMonitorCodeRepos"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
// TODO: Remove once dashboardSecOps removed from backend
const DashboardSecOpsFeatureSchema = z.object({
  featureName: z.literal("dashboardSecOps"),
  operations: z.object({ READ: z.boolean() }),
});
const AlarmCentreFeatureSchema = z.object({
  featureName: z.literal("alarmCentre"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
  }),
});
const ComputeRadarsContainersFeatureSchema = z.object({
  featureName: z.literal("computeRadarsContainers"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const AssetInventoryOverviewFeatureSchema = z.object({
  featureName: z.literal("assetInventoryOverview"),
  operations: z.object({ READ: z.boolean() }),
});
const DLPSnippetFeatureSchema = z.object({
  featureName: z.literal("dlpSnippet"),
  operations: z.object({
    UPDATE: z.boolean(),
    READ: z.boolean(),
  }),
});
const SettingsUserRoleFeatureSchema = z.object({
  featureName: z.literal("settingsUserRole"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ComputeManageCredsFeatureSchema = z.object({
  featureName: z.literal("computeManageCreds"),
  operations: z.object({ UPDATE: z.boolean(), READ: z.boolean() }),
});
const AssetRqlFeatureSchema = z.object({
  featureName: z.literal("investigateAssetRql"),
  operations: z.object({ READ: z.boolean() }),
});

const VulnerabilityDashboardFeatureSchema = z.object({
  featureName: z.literal("vulnerabilityDashboard"),
  operations: z.object({ READ: z.boolean() }),
});
const InvestigateVulnerabilityRqlFeatureSchema = z.object({
  featureName: z.literal("investigateVulnerabilityRql"),
  operations: z.object({ READ: z.boolean() }),
});
const VulnerabilityRemediationFeatureSchema = z.object({
  featureName: z.literal("vulnerabilityRemediation"),
  operations: z.object({ CREATE: z.boolean() }),
});
const ActionPlanOverviewFeatureSchema = z.object({
  featureName: z.literal("actionPlanOverview"),
  operations: z.object({
    READ: z.boolean(),
  }),
});
const ActionPlanNotificationTemplatesSchema = z.object({
  featureName: z.literal("actionPlanNotificationTemplates"),
  operations: z.object({
    UPDATE: z.boolean(),
    DELETE: z.boolean(),
    READ: z.boolean(),
    CREATE: z.boolean(),
  }),
});
const ActionPlanStatusAndAssignmentSchema = z.object({
  featureName: z.literal("actionPlanStatusAndAssignment"),
  operations: z.object({
    UPDATE: z.boolean(),
  }),
});
const ActionPlanRemediationSchema = z.object({
  featureName: z.literal("actionPlanRemediation"),
  operations: z.object({
    UPDATE: z.boolean(),
  }),
});

export const FeatureSchema = z.discriminatedUnion("featureName", [
  AuditLogsFeatureSchema,
  ComputePolicyCloudFeatureSchema,
  ComputeMonitorCNNFFeatureSchema,
  AlertsOverviewFeatureSchema,
  ComputeSandboxFeatureSchema,
  ComputeSystemLogsFeatureSchema,
  AlertsAlertRulesFeatureSchema,
  ComputePolicyWAASFeatureSchema,
  AssetInventoryFiltersFeatureSchema,
  ComputePolicyCustomRulesFeatureSchema,
  CodeSecuritySupplyChainFeatureSchema,
  InvestigateNetworkRqlFeatureSchema,
  ComputeMonitorWAASFeatureSchema,
  ComputeMonitorCloudFeatureSchema,
  SettingsCloudAccountsFeatureSchema,
  ComputeRadarsCloudFeatureSchema,
  CodeSecurityDashboardFeatureSchema,
  InvestigateEventRqlFeatureSchema,
  SettingsResourceListFeatureSchema,
  ComputeMonitorRuntimeContainersFeatureSchema,
  DLPDataDashboardFeatureSchema,
  CodeSecurityDevPipelinesCodeReviewsFeatureSchema,
  SettingsAlertIpAddressesFeatureSchema,
  SettingsAnomalyTrustedListFeatureSchema,
  ComplianceReportsFeatureSchema,
  SettingsSSOFeatureSchema,
  InvestigateConfigRqlFeatureSchema,
  SettingsAccountGroupFeatureSchema,
  AlertsSnoozeDismissFeatureSchema,
  CodeSecurityDevPipelinesProjectsFeatureSchema,
  CodeSecurityProjectsFeatureSchema,
  SettingsAccessKeysFeatureSchema,
  ComputePolicyHostsFeatureSchema,
  SettingsAnomalyThresholdFeatureSchema,
  ComplianceFiltersFeatureSchema,
  ComputeAuthFeatureSchema,
  ComputeMonitorRuntimeServerlessFeatureSchema,
  ComputePolicyCodeReposFeatureSchema,
  ComputeCollectionsFeatureSchema,
  ComputePolicyRuntimeContainerFeatureSchema,
  SettingsLoginIpAddressesFeatureSchema,
  ComputeDownloadsFeatureSchema,
  ComputeMonitorAccessKubernetesFeatureSchema,
  ComputeMonitorImagesFeatureSchema,
  SettingsIntegrationsFeatureSchema,
  ComputePolicyAccessKubernetesFeatureSchema,
  ComputeUIEventSubscriberFeatureSchema,
  DLPDataProfileFeatureSchema,
  PoliciesFeatureSchema,
  PolicyComplianceMappingFeatureSchema,
  ComputeMonitorCIFeatureSchema,
  ComputePolicyAccessDockerFeatureSchema,
  SettingsUsersFeatureSchema,
  ComputeMonitorVulnFeatureSchema,
  AlertsRemediationFeatureSchema,
  ComputePolicyAccessSecretsFeatureSchema,
  ComputeSystemOperationsFeatureSchema,
  AlertsSavedFiltersFeatureSchema,
  AlertsReportFeatureSchema,
  ComputeMonitorRuntimeHostsFeatureSchema,
  SettingsRepositoriesFeatureSchema,
  AlarmCentreSettingsFeatureSchema,
  ComputeMonitorServerlessFeatureSchema,
  ComputePolicyContainersFeatureSchema,
  AlertsNotificationTemplatesFeatureSchema,
  ComputeRadarsServerlessFeatureSchema,
  ComputeMonitorComplianceFeatureSchema,
  ComputeMonitorAccessDockerFeatureSchema,
  ComplianceOverviewFeatureSchema,
  DLPResourceFeatureSchema,
  SavedSearchesFeatureSchema,
  SettingsEnterpriseFeatureSchema,
  ComputeMonitorHostsFeatureSchema,
  DLPDataInventoryFeatureSchema,
  ComputeManageAlertsFeatureSchema,
  ComputePolicyRuntimeServerlessFeatureSchema,
  DLPDataPatternFeatureSchema,
  SettingsCodeSecurityFeatureSchema,
  ComputePolicyComplianceCustomRulesFeatureSchema,
  ComputeRadarsHostsFeatureSchema,
  ComputePolicyCNNFFeatureSchema,
  ComputePrivilegedOperationsFeatureSchema,
  ComplianceStandardsFeatureSchema,
  ComputeAccessUIFeatureSchema,
  ComputePolicyRuntimeHostsFeatureSchema,
  ComputeManageDefendersFeatureSchema,
  ComputeMonitorRuntimeIncidentsFeatureSchema,
  ComputePolicyServerlessFeatureSchema,
  SettingsLicensingFeatureSchema,
  DashboardSecOpsFeatureSchema,
  ComputeMonitorCodeReposFeatureSchema,
  AlarmCentreFeatureSchema,
  ComputeRadarsContainersFeatureSchema,
  AssetInventoryOverviewFeatureSchema,
  DLPSnippetFeatureSchema,
  SettingsUserRoleFeatureSchema,
  ComputeManageCredsFeatureSchema,
  AssetRqlFeatureSchema,
  VulnerabilityDashboardFeatureSchema,
  InvestigateVulnerabilityRqlFeatureSchema,
  VulnerabilityRemediationFeatureSchema,
  ActionPlanOverviewFeatureSchema,
  ActionPlanNotificationTemplatesSchema,
  ActionPlanStatusAndAssignmentSchema,
  ActionPlanRemediationSchema,
  DataSecurityPostureManagementFeatureSchema,
]);

// TODO: Make FeatureNames a dynamic array based on FeatureSchemas
// const arr2 = arr.map((obj) => {
//   return obj.shape.featureName.value;
// });
// z.enum(arr2);

const FeatureNames = [
  "dataSecurityPostureManagement",
  "settingsAuditLogs",
  "computePolicyCloud",
  "computeMonitorCNNF",
  "alertsOverview",
  "computeSandbox",
  "alertsAlertRules",
  "computeSystemLogs",
  "computePolicyWAAS",
  "assetInventoryFilters",
  "computePolicyCustomRules",
  "codeSecuritySupplyChain",
  "investigateNetworkRql",
  "computeMonitorWAAS",
  "settingsCloudAccounts",
  "computeMonitorCloud",
  "computeRadarsCloud",
  "codeSecurityDashboard",
  "investigateEventRql",
  "settingsResourceList",
  "computeMonitorRuntimeContainers",
  "dlpDataDashboard",
  "codeSecurityDevPipelinesCodeReviews",
  "settingsAlertIpAddresses",
  "settingsAnomalyTrustedList",
  "complianceReports",
  "settingsSSO",
  "investigateConfigRql",
  "settingsAccountGroup",
  "alertsSnoozeDismiss",
  "codeSecurityDevPipelinesProjects",
  "codeSecurityProjects",
  "settingsAccessKeys",
  "computePolicyHosts",
  "complianceFilters",
  "settingsAnomalyThreshold",
  "computeAuth",
  "computePolicyCodeRepos",
  "computeMonitorRuntimeServerless",
  "computeCollections",
  "settingsLoginIpAddresses",
  "computePolicyRuntimeContainer",
  "computeDownloads",
  "computeMonitorAccessKubernetes",
  "computeMonitorImages",
  "settingsIntegrations",
  "computePolicyAccessKubernetes",
  "computeUIEventSubscriber",
  "dlpDataProfile",
  "policies",
  "policyComplianceMapping",
  "computeMonitorCI",
  "computePolicyAccessDocker",
  "settingsUsers",
  "computeMonitorVuln",
  "alertsRemediation",
  "computePolicyAccessSecrets",
  "computeSystemOperations",
  "alertsSavedFilters",
  "alertsReport",
  "computeMonitorRuntimeHosts",
  "settingsRepositories",
  "alarmCentreSettings",
  "computeMonitorServerless",
  "computePolicyContainers",
  "alertsNotificationTemplates",
  "computeRadarsServerless",
  "computeMonitorCompliance",
  "computeMonitorAccessDocker",
  "complianceOverview",
  "dlpResource",
  "savedSearches",
  "computeMonitorHosts",
  "settingsEnterprise",
  "dlpDataInventory",
  "computeManageAlerts",
  "dlpDataPattern",
  "computePolicyRuntimeServerless",
  "settingsCodeSecurity",
  "computePolicyComplianceCustomRules",
  "computeRadarsHosts",
  "computePolicyCNNF",
  "computePrivilegedOperations",
  "complianceStandards",
  "computePolicyRuntimeHosts",
  "computeManageDefenders",
  "computeAccessUI",
  "computeMonitorRuntimeIncidents",
  "computePolicyServerless",
  "computeMonitorCodeRepos",
  "settingsLicensing",
  // TODO: Remove once dashboardSecOps removed from backend
  "dashboardSecOps",
  "alarmCentre",
  "computeRadarsContainers",
  "assetInventoryOverview",
  "dlpSnippet",
  "settingsUserRole",
  "computeManageCreds",
  "investigateAssetRql",
  "vulnerabilityDashboard",
  "investigateVulnerabilityRql",
  "vulnerabilityRemediation",
  "actionPlanOverview",
  "actionPlanNotificationTemplates",
  "actionPlanStatusAndAssignment",
  "actionPlanRemediation",
] as const;

export const FeatureNamesSchema = z.array(z.enum(FeatureNames));
