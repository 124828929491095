import { useMutation } from "@tanstack/react-query";
import { Set } from "immutable";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { isEmpty, partition } from "remeda";
import {
  getAzureSelectMonitoredProjectAncestors,
  getAzureSelectMonitoredProjectChildrens,
} from "requests";
import { useToastActions } from "stores";
import { usePrevious } from "ui";
import { regexes, snakeCase } from "utils";
import { useWizardContext } from "../../../../../../../components/Wizard";
import {
  ACCOUNT_ID,
  ACCOUNT_TYPE,
  ALL,
  CLIENT_ID,
  CLOUD_ACCOUNT,
  ENABLED,
  ENVIRONMENT_TYPE,
  KEY,
  MONITOR_FLOW_LOGS,
  NAME,
  ROOT_SYNC_ENABLED,
  SERVICE_PRINCIPAL_ID,
  TENANT,
  TENANT_ID,
} from "../../../../../../constants";
import { useIsRenderedFirstTime } from "../../../../../../hooks";
import { type AzureGetStartedStepValuesType } from "../../../../../../types";
import { parseErrorsFromResponse } from "../../../../../../utils";
import { useAzureSelectMonitoredProjectContext } from "../../../context/AzureSelectMonitoredProjectContext";
import {
  initAzureTreeMap,
  setAzureFoldersCredentialError,
  setAzureLoadedNodes,
  setAzureOUSelectProjectsIsLoading,
  setAzurePreviouslySelectedNodesNotFound,
  setAzureProjectSelectMode,
  setAzureSelectProjectsInitialized,
  setAzureSelectedNodes,
  setEnvironmentType,
  updateAzureTreeData,
} from "../../../context/AzureSelectMonitoredProjectContext/state/actions";
import {
  getAccountName,
  getApplicationId,
  getApplicationKey,
  getAzureFoldersCredentialError,
  getAzureRootId,
  getAzureSelectProjectsInitialized,
  getAzureSelectedNodesInitial,
  getCloudAccountEnable,
  getEnvironmentType,
  getMonitorFlowLogs,
  getServicePrincipalId,
  getTenantId,
} from "../../../context/AzureSelectMonitoredProjectContext/state/getters";
import AzureSelectProjects from "./AzureSelectProjects";

type ConnectedAzureSelectProjectsProps = {
  closeModal: () => void;
};

export default function ConnectedAzureSelectProjects({
  closeModal,
}: ConnectedAzureSelectProjectsProps) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const {
    state: { steps },
  } = useWizardContext();
  const { environmentType } = (steps[0]?.values ??
    {}) as AzureGetStartedStepValuesType;

  const { state, dispatch } = useAzureSelectMonitoredProjectContext();

  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isRenderedFirstTime = useIsRenderedFirstTime();

  const clientId = getApplicationId(state);
  const tenantId = getTenantId(state);
  const applicationKey = getApplicationKey(state);
  const servicePrincipalId = getServicePrincipalId(state);
  const prevEnvironmentType = getEnvironmentType(state);
  const isEnvironmentTypeChanged = environmentType !== prevEnvironmentType;

  const isClientIdChanged = usePrevious(clientId) !== clientId;
  const isTenantIdChanged = usePrevious(tenantId) !== tenantId;
  const isServicePrincipalId =
    usePrevious(servicePrincipalId) !== servicePrincipalId;
  const isApplicationKeyChanged =
    usePrevious(applicationKey) !== applicationKey;

  // Invalid tenantID or clientID is inserted
  const isInvalidGuid =
    !regexes.guid.test(tenantId) || !regexes.guid.test(clientId);

  const isAzureSelectProjectsInitialized =
    getAzureSelectProjectsInitialized(state);
  const credentialError = getAzureFoldersCredentialError(state);

  // Children Api
  const { mutateAsync: loadAzureNodeData } = useMutation({
    mutationFn: async ({
      childrenType,
      loadType,
      parentId,
    }: {
      childrenType: string;
      loadType: string;
      parentId: string;
    }) => {
      try {
        // 1st  call is through tenant id
        const id =
          parentId !== "organizations/" ? parentId : getTenantId(state);
        const requestPayload = {
          [CLIENT_ID]: getApplicationId(state),
          [CLOUD_ACCOUNT]: {
            [ACCOUNT_ID]: getTenantId(state),
            [ACCOUNT_TYPE]: TENANT,
            [ENABLED]: getCloudAccountEnable(state),
            [NAME]: getAccountName(state),
          },
          [ENVIRONMENT_TYPE]: environmentType,
          [KEY]: getApplicationKey(state),
          [MONITOR_FLOW_LOGS]: getMonitorFlowLogs(state),
          [ROOT_SYNC_ENABLED]: true,
          [SERVICE_PRINCIPAL_ID]: getServicePrincipalId(state),
          [TENANT_ID]: getTenantId(state),
        };

        const data = await getAzureSelectMonitoredProjectChildrens({
          id,
          body: requestPayload,
        });

        dispatch(
          updateAzureTreeData({ childrenType, data, loadType, parentId }),
        );
        dispatch(setAzureLoadedNodes(parentId));
      } catch (error) {
        const errorMessages = [
          "no_folder_view_permissions",
          "unauthorized_access",
        ];
        const errorMessage = snakeCase(
          parseErrorsFromResponse(intl, error)[0] ?? "",
        );

        if (errorMessages.includes(errorMessage)) {
          dispatch(setAzureFoldersCredentialError(errorMessage));
          setAzureProjectSelectMode(ALL, dispatch);
        } else {
          toast(parseErrorsFromResponse(intl, error), {
            appearance: "error",
          });
          closeModal();
        }
      }
    },
  });

  // Ancestor Api
  const { mutateAsync: loadAzureNodeAncestorData } = useMutation({
    mutationFn: async ({
      orgName,
      rootId,
      id,
      selectedNodes,
    }: {
      orgName: string;
      rootId: string;
      id: string;
      selectedNodes: string[];
    }) => {
      try {
        const data = await getAzureSelectMonitoredProjectAncestors({
          id,
          body: {
            resourceIds: selectedNodes,
          },
        });

        const [ancestorsOfSelected, notFound] = partition(
          data,
          (el) => !!el.ancestors,
        );
        dispatch(setAzurePreviouslySelectedNodesNotFound(notFound));
        dispatch(initAzureTreeMap({ ancestorsOfSelected, orgName, rootId }));
        return false;
      } catch (error) {
        return true;
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const flag =
        isEmpty(clientId) ||
        isEmpty(tenantId) ||
        isEmpty(applicationKey) ||
        isEmpty(servicePrincipalId) ||
        isInvalidGuid ||
        (!isClientIdChanged &&
          !isTenantIdChanged &&
          !isApplicationKeyChanged &&
          !isServicePrincipalId &&
          !isEnvironmentTypeChanged &&
          !isRenderedFirstTime) ||
        (!credentialError &&
          isRenderedFirstTime &&
          isAzureSelectProjectsInitialized);
      // Don't make new api call or return
      if (flag) {
        return;
      }

      if (isEnvironmentTypeChanged) {
        dispatch(setEnvironmentType(environmentType));
      }

      dispatch(setAzureOUSelectProjectsIsLoading(true));
      dispatch(setAzureSelectProjectsInitialized(false));

      const rootId = getAzureRootId(state);
      const orgName = getTenantId(state);
      const id = getTenantId(state);
      const nodeInitial = getAzureSelectedNodesInitial(state);
      const hierarchySelection =
        nodeInitial && "size" in nodeInitial ? nodeInitial.toJS() : nodeInitial;
      const selectionMode =
        (Array.isArray(hierarchySelection) &&
          hierarchySelection.length &&
          hierarchySelection[0]?.selectionType) ||
        ALL;

      const selectedNodes: string[] = hierarchySelection?.map(
        (node: { resourceId: string }) => node.resourceId,
      );

      setAzureProjectSelectMode(selectionMode, dispatch);
      setAzureSelectedNodes(Set(selectedNodes), dispatch);
      dispatch(setAzureFoldersCredentialError(""));

      let isAncestorApiFailed = false;
      if (hierarchySelection?.length && selectionMode !== ALL) {
        isAncestorApiFailed = await loadAzureNodeAncestorData({
          orgName,
          rootId,
          id,
          selectedNodes,
        });
      } else {
        dispatch(initAzureTreeMap({ orgName, rootId }));
      }

      await loadAzureNodeData({
        childrenType: "all",
        loadType: "initial",
        parentId: rootId,
      });

      dispatch(setAzureOUSelectProjectsIsLoading(false));
      if (!isAncestorApiFailed) {
        dispatch(setAzureSelectProjectsInitialized(true));
      }
    };

    try {
      fetchData();
    } catch (error) {
      dispatch(setAzureOUSelectProjectsIsLoading(false));
      dispatch(setAzureSelectProjectsInitialized(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicationKey,
    clientId,
    credentialError,
    isApplicationKeyChanged,
    isAzureSelectProjectsInitialized,
    isClientIdChanged,
    isEnvironmentTypeChanged,
    isInvalidGuid,
    isTenantIdChanged,
    isServicePrincipalId,
    servicePrincipalId,
    tenantId,
  ]);

  return <AzureSelectProjects loadAzureNodeData={loadAzureNodeData} />;
}
