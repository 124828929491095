import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "@tanstack/react-router";
import { FormLayout } from "form";
import { HomeIcon, LoadingIcon, SettingsIcon } from "icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIsBusinessLicenseType, useUCDMode } from "prisma";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { isEmpty } from "remeda";
import {
  cloudAccountsKeys,
  getAwsCloudAccountStatus,
  getAwsTempAccountDetails,
  getCloudAccountsTableData,
  type AwsRequestPayloadType,
  type SecurityCapabilitiesFeatures,
  type SecurityCapabilitiesType,
  type SecurityCapabilityValuesType,
} from "requests";
import { useToastActions } from "stores";
import { Banner, Button } from "ui";
import { WizardForm } from "../../../../components/Wizard";
import {
  ACCOUNT,
  AGENTLESS_API_DISCOVERY,
  AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE,
  AGENTLESS_DEFAULT_MEMBER_STATE,
  AGENTLESS_SCAN,
  AUTO_PROTECT,
  AWS,
  DISABLED,
  ENABLED,
  ORGANIZATION,
  REMEDIATION,
  SERVERLESS_DEFAULT_MEMBER_STATE,
  SERVERLESS_SCAN,
} from "../../../constants";
import {
  useGetAwsCloudAccountsCas,
  useGetAwsPartitionItems,
  useGetSupportedFeaturesList,
  useIdentitySubscription,
} from "../../../hooks";
import {
  type AccountType,
  type AwsAddDetailsStepValues,
  type AwsCustomConfigurationStepValues,
} from "../../../types";
import { parseI18nKeyFromResponse } from "../../../utils";
import { featureMap } from "../../Onboarding";
import { settingsCloudAccountsEditModalRoute } from "../../Onboarding/settingsCloudAccountsEditModalRoute";
import { getAuthenticationData } from "../../Onboarding/utils";
import AccountDetailsStatus from "../AWSOnboarding/Summary/AccountDetailsStatus";
import IssueWithRoleArn from "../AWSOnboarding/Summary/IssueWithRoleArn";
import OnboardSuccess from "../AWSOnboarding/Summary/OnboardSuccess";
import SecurityCapabilitiesPermissions from "../AWSOnboarding/Summary/SecurityCapabilitiesPermissions";

type RequestPayloadProps = Omit<
  AwsRequestPayloadType,
  "accountId" | "roleArn" | "features"
> & {
  features?: SecurityCapabilitiesFeatures[];
  roleArn?: string;
};

export default function Summary({
  context: { closeModal },
}: {
  context: { closeModal: () => void };
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const selectedUCDMode = useUCDMode();
  const { cloudScanMode, agentlessApiDiscovery } = useFlags();
  const { toast } = useToastActions();
  const [isRoleArnSaved, setIsRoleArnSaved] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [isDuplicateAccount, setIsDuplicateAccount] = useState(false);

  const { cloudAccountId: accountName } = useParams({
    from: settingsCloudAccountsEditModalRoute.id,
  });

  const { data, isLoading: isAwsTempAccountDetailsLoading } = useQuery({
    queryKey: cloudAccountsKeys.awsTempcloudAccountDetails({ accountName }),
    queryFn: getAwsTempAccountDetails,
    retry: false,
  });

  const { data: cloudAccountsData = [] } = useQuery({
    queryKey: cloudAccountsKeys.cloudAccountsTable({
      excludeAccountGroupDetails: "true",
    }),
    queryFn: getCloudAccountsTableData,
    enabled: isAwsTempAccountDetailsLoading || isRoleArnSaved,
  });

  const { isSubscribed, isLoading: indentitySecurityLoading } =
    useIdentitySubscription();

  const { data: accountDetails } = useGetAwsCloudAccountsCas({
    accountId,
    options: { enabled: !isEmpty(accountId) },
  });

  const { isBusinessLicenseType } = useIsBusinessLicenseType();

  useEffect(() => {
    const onBoardedAccount = cloudAccountsData.filter(
      (acc) => acc.name === accountName,
    );
    setAccountId(onBoardedAccount?.length ? onBoardedAccount[0].accountId : "");
    setIsDuplicateAccount(!isRoleArnSaved && !!onBoardedAccount?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudAccountsData]);

  const { awsPartitionItems } = useGetAwsPartitionItems();

  const { accountType, deploymentType } = data?.cloudAccount ?? {};
  const { groupIds: accountGroups = [] as string[] } = data ?? {};

  const isAccountType = accountType === ACCOUNT;
  const isOrg = accountType === ORGANIZATION;

  const { supportedFeaturesList, isLoading: isFeaturesLoading } =
    useGetSupportedFeaturesList({
      cloudType: AWS,
      payload: {
        accountType: accountType as AccountType,
        awsPartition: awsPartitionItems[0]?.value,
      },
    });

  const securityCapabilities = useMemo(
    () =>
      accountDetails?.cloudAccount.features?.reduce(
        (
          values: Record<SecurityCapabilitiesType, boolean>,
          { featureName, featureState },
        ) => {
          if (featureMap[featureName])
            values[featureMap[featureName]] = featureState === ENABLED;
          return values;
        },
        {} as Record<SecurityCapabilitiesType, boolean>,
      ) ?? ({} as Record<SecurityCapabilitiesType, boolean>),
    [accountDetails],
  );

  const orgSecurityCapabilities = useMemo(
    () =>
      accountDetails?.cloudAccount.features
        ?.filter(({ featureName }) => {
          const feature = featureMap[featureName];
          return (
            feature === AGENTLESS_SCAN ||
            feature === SERVERLESS_SCAN ||
            (agentlessApiDiscovery && feature === AGENTLESS_API_DISCOVERY)
          );
        })
        .reduce(
          (
            values: Record<SecurityCapabilityValuesType, boolean>,
            { featureName, defaultMemberState },
          ) => {
            featureMap[featureName] === AGENTLESS_SCAN
              ? (values[AGENTLESS_DEFAULT_MEMBER_STATE] = Boolean(
                  defaultMemberState === ENABLED ||
                    (!securityCapabilities[AGENTLESS_SCAN] &&
                      !isBusinessLicenseType),
                ))
              : agentlessApiDiscovery &&
                  featureMap[featureName] === AGENTLESS_API_DISCOVERY
                ? (values[AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE] =
                    Boolean(
                      defaultMemberState === ENABLED ||
                        (!securityCapabilities[AGENTLESS_API_DISCOVERY] &&
                          !isBusinessLicenseType),
                    ))
                : (values[SERVERLESS_DEFAULT_MEMBER_STATE] = Boolean(
                    defaultMemberState === ENABLED ||
                      (!securityCapabilities[SERVERLESS_SCAN] &&
                        !isBusinessLicenseType),
                  ));

            return values;
          },
          {} as Record<SecurityCapabilityValuesType, boolean>,
        ) ?? ({} as Record<SecurityCapabilityValuesType, boolean>),
    [
      accountDetails?.cloudAccount.features,
      agentlessApiDiscovery,
      isBusinessLicenseType,
      securityCapabilities,
    ],
  );

  let features: SecurityCapabilitiesFeatures[] = [];

  let requestData: RequestPayloadProps = {} as RequestPayloadProps;

  if (!isEmpty(accountDetails ?? {})) {
    const {
      customMemberRoleNameEnabled = false,
      skipOverrideMemberRoleName = false,
      useTenantExternalId = false,
      unifiedCftDisabled = false,
      roleArn = "",
      mode = "cloud-scan",
      hierarchySelection = [],
      memberRoleName = "",
    } = { ...accountDetails, ...accountDetails?.cloudAccount };

    features = [
      {
        name: AGENTLESS_SCAN,
        state: securityCapabilities[AGENTLESS_SCAN] ? ENABLED : DISABLED,
        ...(isOrg && {
          defaultMemberState:
            securityCapabilities[AGENTLESS_SCAN] &&
            orgSecurityCapabilities[AGENTLESS_DEFAULT_MEMBER_STATE]
              ? ENABLED
              : DISABLED,
        }),
        ...(cloudScanMode &&
          securityCapabilities[AGENTLESS_SCAN] && {
            mode,
          }),
      },
      {
        name: AUTO_PROTECT,
        state: securityCapabilities[AUTO_PROTECT] ? ENABLED : DISABLED,
      },
      {
        name: REMEDIATION,
        state: securityCapabilities[REMEDIATION] ? ENABLED : DISABLED,
      },
      {
        name: SERVERLESS_SCAN,
        state: securityCapabilities[SERVERLESS_SCAN] ? ENABLED : DISABLED,
        ...(isOrg && {
          defaultMemberState:
            securityCapabilities[SERVERLESS_SCAN] &&
            orgSecurityCapabilities[SERVERLESS_DEFAULT_MEMBER_STATE]
              ? ENABLED
              : DISABLED,
        }),
      },
      agentlessApiDiscovery && {
        name: AGENTLESS_API_DISCOVERY,
        state: securityCapabilities[AGENTLESS_API_DISCOVERY]
          ? ENABLED
          : DISABLED,
        ...(isOrg && {
          defaultMemberState:
            securityCapabilities[AGENTLESS_API_DISCOVERY] &&
            orgSecurityCapabilities[
              AGENTLESS_API_DISCOVERY_DEFAULT_MEMBER_STATE
            ]
              ? ENABLED
              : DISABLED,
        }),
      },
    ]
      .filter(Boolean)
      .filter(({ name }) =>
        supportedFeaturesList.includes(name as SecurityCapabilitiesType),
      ) as SecurityCapabilitiesFeatures[];

    requestData = {
      accountType,
      enabled: true,
      features,
      ...(isAccountType
        ? { groupIds: accountGroups as unknown as string[] }
        : { defaultAccountGroupId: accountGroups[0] as unknown as string }),
      ...(!isAccountType && { hierarchySelection }),
      name: accountName,
      roleArn,
      ...(!isAccountType && {
        customMemberRoleNameEnabled,
        ...(customMemberRoleNameEnabled && { memberRoleName }),
        skipOverrideMemberRoleName,
        unifiedCftDisabled,
      }),
      useTenantExternalId,
    } as RequestPayloadProps;
  }

  const {
    data: statusData,
    isError,
    error,
    isLoading: isCloudStatusLoading,
  } = useQuery({
    queryKey: cloudAccountsKeys.awsCloudAccountStatus({
      payload: requestData as unknown as AwsRequestPayloadType,
    }),
    queryFn: getAwsCloudAccountStatus,
    enabled: !isEmpty(accountDetails ?? {}),
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (!isError) return;
    toast(parseI18nKeyFromResponse(intl, error), {
      appearance: "error",
    });
    closeModal();
  }, [closeModal, error, intl, isError, toast]);

  const authentication = useMemo(
    () => getAuthenticationData(statusData),
    [statusData],
  );

  const accountDetailsData = {
    ...(isRoleArnSaved && authentication && { authentication }),
    accountType: accountType as AccountType,
    accountName,
    accountGroups,
    deploymentType,
    awsPartition: awsPartitionItems[0]?.value,
  };

  const isLoading =
    isAwsTempAccountDetailsLoading ||
    isCloudStatusLoading ||
    isBusinessLicenseType ||
    indentitySecurityLoading ||
    isFeaturesLoading;

  const visitHomeNavLink =
    selectedUCDMode === "runtime"
      ? "/home/runtime"
      : selectedUCDMode === "application"
        ? "/home/appsec"
        : selectedUCDMode === "data"
          ? "/home/data/overview"
          : "/home/cloud/welcome";

  return (
    <WizardForm
      actions={
        <>
          <Button
            icon={<HomeIcon />}
            onClick={() => navigate({ to: visitHomeNavLink })}
          >
            <FormattedMessage
              defaultMessage="Visit Home"
              id="rVnvWd"
              description="Visit Home button label"
            />
          </Button>
          <Button
            appearance="primary"
            icon={<SettingsIcon />}
            onClick={() =>
              navigate({ to: "/settings/providers/cloud-accounts" })
            }
          >
            <FormattedMessage
              defaultMessage="Visit Providers"
              id="P1X8uv"
              description="Visit Providers button label"
            />
          </Button>
        </>
      }
    >
      {isAwsTempAccountDetailsLoading ? (
        <LoadingIcon />
      ) : (
        <FormLayout>
          {!isDuplicateAccount && (
            <>
              {!isRoleArnSaved ? (
                <IssueWithRoleArn />
              ) : (
                authentication?.status === "ok" && <OnboardSuccess />
              )}
            </>
          )}

          {isDuplicateAccount && (
            <Banner appearance="error">
              <FormattedMessage
                defaultMessage="This account Name is already onboarded. Please delete this and onboard with a different account name."
                id="mRhJlb"
                description="Label for account name already exists banner"
              />
            </Banner>
          )}

          {isRoleArnSaved && (
            <SecurityCapabilitiesPermissions
              isTempEdit={true}
              accountDetails={
                {
                  accountType,
                  partitionType: awsPartitionItems[0].value,
                  onboardType: "automated",
                  securityCapabilities,
                  orgSecurityCapabilities,
                  mode: "cloud-scan",
                } as AwsAddDetailsStepValues & AwsCustomConfigurationStepValues
              }
              accountStatus={statusData ?? []}
              features={features}
              isSubscribed={isSubscribed}
              isLoading={isLoading}
            />
          )}

          <AccountDetailsStatus
            {...accountDetailsData}
            isLoading={isAwsTempAccountDetailsLoading}
            displayRoleArn={!isDuplicateAccount}
            isTempEdit={true}
            setIsRoleArnSaved={setIsRoleArnSaved}
            defaultOpen={!isAwsTempAccountDetailsLoading}
          />
        </FormLayout>
      )}
    </WizardForm>
  );
}
