import { Route } from "@tanstack/react-router";
import { lazy } from "react";
import { z } from "zod";
import { dashboardRoute } from "../../../dashboardRoute";

export const VulnerabilitiesSidecar = lazy(
  () => import("./VulnerabilitiesSidecar"),
);

export const VulnerabilitiesSearchSchema = z.object({
  cveId: z.string().optional(),
});

export const vulnerabilitiesSidecarRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/$cveId",
  component: VulnerabilitiesSidecar,
  validateSearch: VulnerabilitiesSearchSchema,
});
