import { isWithinInterval, parseISO } from "date-fns";
import { type FilterValue, type Value } from "filters/src/types";
import { equals } from "remeda";
import {
  type AccountGroupNameSchema,
  type AlertsReportSchema,
  type CloudAccountNameSchema,
  type CloudRegionWithOther,
  type ResourceList,
} from "requests";
import { RRule } from "rrule";
import { type z } from "zod";
import { convertIdsToNames } from "../../../../utils/convertIdsToNames/convertIdsToNames";
export type Report = z.infer<typeof AlertsReportSchema>;

export type CloudAccount = z.infer<typeof CloudAccountNameSchema>;

export type AccountGroup = z.infer<typeof AccountGroupNameSchema>;

const excludedCloudTypes = ["oci", "alibaba_cloud"];

export function anyMatch(target: Value, value?: FilterValue): boolean {
  if (Array.isArray(value)) {
    return value.includes(target);
  }

  if (typeof target === "boolean" || typeof value === "boolean")
    return target?.toString() === value?.toString();

  return target === value;
}

export function matchSubType(targetSubType: string, subType: string[]) {
  // The reasoning for this logic is that the policies API
  // has subtype values as "run" and "build" for subtype but the suggestions
  // API has a "run_and_build" filter option, which represents a policy with subtype value ["run", "build"].
  if (targetSubType === "run_and_build") {
    return subType.includes("run") && subType.includes("build");
  }
  return anyMatch(targetSubType, subType);
}

type TimeRange = {
  value: {
    endTime: number;
    startTime: number;
  };
};

export const isTimeWithinRange = (
  { value: { endTime, startTime } }: TimeRange,
  selectedTime: string,
): boolean => {
  const currentDate = parseISO(selectedTime);
  return isWithinInterval(currentDate, {
    start: startTime,
    end: endTime,
  });
};

export function matchRuleType(
  targetRule: string,
  rules: { type: string }[] = [],
) {
  return rules.map((rule) => rule.type).includes(targetRule);
}

export function matchCloudType(
  targetCloudType: string,
  cloudType: string | string[],
): boolean {
  //excluding all for alibaba & oci
  if (cloudType === "all" && !excludedCloudTypes.includes(targetCloudType))
    return true;

  return equals(targetCloudType, cloudType);
}

export function matchCloudAccount(cloudAccountObjects: CloudAccount[] = []) {
  return (targetCloudAccountName: string, cloudAccountIds: string[]) => {
    if (!targetCloudAccountName?.length) return true;
    const CloudAccountNames =
      convertIdsToNames(cloudAccountObjects)(cloudAccountIds) ?? [];
    return anyMatch(targetCloudAccountName, CloudAccountNames);
  };
}

export function matchAccountGroup(accountGroupObjects: AccountGroup[] = []) {
  return (targetAccountGroupName: string, accountGroupIds: string[]) => {
    if (!targetAccountGroupName?.length) return true;
    const accountGroupNames =
      convertIdsToNames(accountGroupObjects)(accountGroupIds) ?? [];
    return anyMatch(targetAccountGroupName, accountGroupNames);
  };
}

export function matchRegion(regionObjects: CloudRegionWithOther[] = []) {
  return (targetRegionName: string, regionIds: string[]) => {
    if (!targetRegionName?.length) return true;
    const regionNames = convertIdsToNames(regionObjects)(regionIds) ?? [];
    return anyMatch(targetRegionName, regionNames);
  };
}

export function matchResourceList(resourceListObjects: ResourceList[] = []) {
  return (targetResourceListName: string, resourceListIds: string[]) => {
    if (!targetResourceListName?.length) return true;
    const resourceListNames =
      convertIdsToNames(resourceListObjects)(resourceListIds) ?? [];
    return anyMatch(targetResourceListName, resourceListNames);
  };
}

export function matchSchedule(targetSchedule: string, schedule: string) {
  if (!schedule?.length) return false;
  const rule = RRule.fromString(schedule);
  switch (rule.options.freq) {
    case RRule.DAILY:
      return targetSchedule === "daily";
    case RRule.MONTHLY:
      return targetSchedule === "monthly";
    case RRule.WEEKLY:
      return targetSchedule === "weekly";
    case RRule.YEARLY:
      return targetSchedule === "yearly";
    default:
      return false;
  }
}
