import { useIntl } from "react-intl";
import {
  ConnectedPaginator,
  ConnectedTable,
  ConnectedTableFilter,
  TableProvider,
} from "table";

import { type AccountGroup } from "../../../../../types";
import { useAccountGroupsModalTableColumns } from "./useAccountGroupsModalTableColumns";

type AccountGroupsModalTableProps = {
  accountGroups: AccountGroup[];
};

export function AccountGroupsModalTable({
  accountGroups,
}: AccountGroupsModalTableProps) {
  const intl = useIntl();
  const columns = useAccountGroupsModalTableColumns();

  return (
    <TableProvider
      columns={columns}
      data={accountGroups}
      initialState={{ sortBy: [{ id: "name" }] }}
      enableColumnSort
      enablePagination
    >
      <div className="flex flex-col space-y-4" style={{ maxHeight: "58vh" }}>
        <div className="flex flex-none justify-between">
          <span className="mr-2 pt-0.5 leading-loose">
            {intl.formatMessage({
              defaultMessage: "Total Results: ",
              id: "R6JUwC",
              description: "Total results",
            })}{" "}
            {Number(accountGroups?.length ?? 0).toLocaleString()}
          </span>
          <ConnectedTableFilter />
        </div>
        <div className="overflow-x-auto">
          <ConnectedTable
            appearance="secondary"
            selector="alert-reports-accountGroups"
          />
        </div>
        <div className="flex justify-end space-x-2">
          <ConnectedPaginator />
        </div>
      </div>
    </TableProvider>
  );
}
