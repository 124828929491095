import { useIntl } from "react-intl";

import { SvgIcon, type SvgIconProps } from "../../components/SvgIcon";

export const AmazonKinesisDataFirehoseIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "Amazon Kinesis Data Firehose",
    id: "k5R2eh",

    description: "Amazon Kinesis Data Firehose icon alt text",
  });

  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <title>{alt}</title>
      <path
        d="M50 33.9487H42.0064C41.5824 33.9487 41.1758 34.1172 40.876 34.417C40.5761 34.7168 40.4077 35.1234 40.4077 35.5475V64.3244C40.4077 64.7484 40.5761 65.1551 40.876 65.4549C41.1758 65.7547 41.5824 65.9232 42.0064 65.9232H50C50.424 65.9232 50.8307 65.7547 51.1305 65.4549C51.4303 65.1551 51.5988 64.7484 51.5988 64.3244V35.5475C51.5988 35.1234 51.4303 34.7168 51.1305 34.417C50.8307 34.1172 50.424 33.9487 50 33.9487ZM48.4013 62.7257H43.6052V37.1462H48.4013V62.7257Z"
        className="fill-[url(#paint0_linear_6619_101670)] dark:fill-white"
      />
      <path
        d="M69.5366 41.9745L51.599 38.0257L50.352 37.7699C50.1172 37.7148 49.873 37.7134 49.6377 37.766C49.4024 37.8186 49.182 37.9238 48.993 38.0736C48.8096 38.2224 48.6615 38.41 48.5592 38.6228C48.4569 38.8356 48.4031 39.0685 48.4015 39.3047V60.5996C48.3983 60.8411 48.4498 61.0803 48.5523 61.299C48.6547 61.5178 48.8054 61.7105 48.993 61.8626C49.2852 62.0775 49.6376 62.195 50.0002 62.1983H50.352L51.599 61.9106L69.5366 57.9298C69.8972 57.8485 70.2186 57.6449 70.446 57.3534C70.6733 57.0619 70.7926 56.7006 70.7836 56.331V43.5413C70.7852 43.1771 70.6625 42.8234 70.4358 42.5385C70.209 42.2536 69.8918 42.0546 69.5366 41.9745ZM67.5862 55.068L51.599 58.6172V41.2231L67.5862 44.7883V55.068Z"
        className="fill-[url(#paint1_linear_6619_101670)] dark:fill-white"
      />
      <path
        d="M89.9679 41.2231V44.5805C84.7672 46.0498 79.3849 46.7764 73.9807 46.7387V43.5413C79.3964 43.5826 84.787 42.8009 89.9679 41.2231Z"
        className="fill-[url(#paint2_linear_6619_101670)] dark:fill-white"
      />
      <path
        d="M89.9679 47.458V50.6555C84.6616 51.2691 79.3222 51.5521 73.9807 51.5028V48.3053C79.3224 48.3664 84.6626 48.0833 89.9679 47.458Z"
        className="fill-[url(#paint3_linear_6619_101670)] dark:fill-white"
      />
      <path
        d="M89.9679 49.4724V52.6699C84.6753 51.8852 79.3311 51.5005 73.9807 51.5188V48.3214C79.3311 48.3039 84.6752 48.6887 89.9679 49.4724Z"
        className="fill-[url(#paint4_linear_6619_101670)] dark:fill-white"
      />
      <path
        d="M89.9679 55.3714V58.8087C84.8013 57.1548 79.4055 56.3292 73.9807 56.3627V53.1652C79.3888 53.1179 84.7748 53.8611 89.9679 55.3714Z"
        className="fill-[url(#paint5_linear_6619_101670)] dark:fill-white"
      />
      <path
        d="M37.2102 41.9426V45.1401C27.6863 44.6376 18.3974 41.9985 10.032 37.4183V33.6772C17.0823 38.2816 26.4668 41.0474 37.2102 41.9426Z"
        className="fill-[url(#paint6_linear_6619_101670)] dark:fill-white"
      />
      <path
        d="M37.2102 48.3054V51.5028C32.5739 51.295 27.2982 50.8633 21.9904 50.096C17.9517 49.5477 13.956 48.7197 10.032 47.6179V44.3086C18.8996 46.7199 28.0239 48.0617 37.2102 48.3054Z"
        className="fill-[url(#paint7_linear_6619_101670)] dark:fill-white"
      />
      <path
        d="M37.2102 48.7051V51.9025C28.0245 52.1541 18.9012 53.4957 10.032 55.8993V52.59C13.965 51.4844 17.972 50.6616 22.0224 50.1279C27.2982 49.3446 32.5739 48.9289 37.2102 48.7051Z"
        className="fill-[url(#paint8_linear_6619_101670)] dark:fill-white"
      />
      <path
        d="M37.2099 36.6827V39.8801C15.1635 37.3221 10.1755 26.0192 10.1755 16.3949C10.1755 16.0272 10.1755 10 10.1755 10H13.373C13.373 10.3517 13.373 16.1071 13.373 16.4748C13.373 24.6922 17.7854 34.3006 37.2099 36.6827Z"
        className="fill-[url(#paint9_linear_6619_101670)] dark:fill-white"
      />
      <path
        d="M37.2102 54.9561V58.1535C26.4828 59.0488 17.0983 61.8945 10.032 66.4668V62.7418C18.3971 58.1514 27.6834 55.4912 37.2102 54.9561Z"
        className="fill-[url(#paint10_linear_6619_101670)] dark:fill-white"
      />
      <path
        d="M37.2104 60.2798V63.4772C24.8044 64.996 13.3735 70.048 13.3735 83.4612C13.3735 83.5572 13.3735 84.0528 13.3735 84.7082C13.3735 86.0991 13.3735 89.3445 13.3735 89.7442L12.0626 89.8561L10.1921 90C10.1837 89.9524 10.1837 89.9037 10.1921 89.8561C10.1921 89.4724 10.1921 88.4333 10.1921 84.6763C10.1921 84.0048 10.1921 83.5572 10.1921 83.4612C10.1761 73.9648 15.1641 62.8218 37.2104 60.2798Z"
        className="fill-[url(#paint11_linear_6619_101670)] dark:fill-white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6619_101670"
          x1={40.4077}
          y1={33.9487}
          x2={60.3472}
          y2={40.9276}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6619_101670"
          x1={48.4014}
          y1={37.7275}
          x2={72.7752}
          y2={60.0215}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6619_101670"
          x1={73.9807}
          y1={41.2231}
          x2={77.3832}
          y2={51.083}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6619_101670"
          x1={73.9807}
          y1={47.458}
          x2={75.9092}
          y2={55.07}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6619_101670"
          x1={73.9807}
          y1={48.3208}
          x2={76.1839}
          y2={56.4196}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6619_101670"
          x1={73.9807}
          y1={53.1631}
          x2={77.5259}
          y2={63.2024}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_6619_101670"
          x1={10.032}
          y1={33.6772}
          x2={18.241}
          y2={53.1407}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_6619_101670"
          x1={10.032}
          y1={44.3086}
          x2={13.5913}
          y2={57.7549}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_6619_101670"
          x1={10.032}
          y1={48.7051}
          x2={13.5913}
          y2={62.1514}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_6619_101670"
          x1={10.1755}
          y1={10}
          x2={39.9066}
          y2={36.8995}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_6619_101670"
          x1={10.032}
          y1={54.9561}
          x2={18.2993}
          y2={74.4762}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_6619_101670"
          x1={10.1858}
          y1={60.2798}
          x2={39.7722}
          y2={87.1828}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset={1} stopColor="#CC861C" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
