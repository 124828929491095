import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Form,
  FormLayout,
  Input,
  Select,
  SubmitButton,
  Textarea,
  useCreateRecord,
  useUpdateRecord,
} from "form";
import {
  awsFeatureKeys,
  createUserRole,
  getAWSFeature,
  getBCRepositories,
  getPermissionGroups,
  permissionGroupsKeys,
  rolesKeys,
  updateUserRole,
  type ApiError,
  type BCRepositories,
  type CloudGroupAllName,
  type PermissionGroupData,
  type UserRoleItemType,
} from "requests";
import { useToastActions } from "stores";
import {
  Button,
  Link,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type RenderModalProps,
} from "ui";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { LoadingIcon } from "icons";
import { isEmpty, isNil } from "remeda";
import { useCodeRolesModal } from "../../users/Table/CodeRolesModal";
import { type FormValues, type InitialValues } from "../roleTypes";
import { DynamicFields } from "./DynamicFields";
import { errorMessagesMap } from "./messages";
import {
  BUILD_AND_DEPLOY_SECURITY,
  NETSECOPS_ROLES,
  ONLY_ALLOW_COMPUTE_ACCESS_ROLES,
  SYSTEM_ADMIN,
  hideRepositories,
} from "./utils";

const DOCUMENTATION_PAGE =
  "https://docs.prismacloud.io/en/enterprise-edition/content-collections/administration/prisma-cloud-admin-permissions";

type RoleRequest = {
  data: UserRoleItemType;
  httpMethod: string;
  id: string;
  name?: string;
};

export function RolesForm({
  action,
  initialValues,
  closeModal,
  isOpen,
  dataRoles,
}: RenderModalProps<{
  action: string;
  initialValues?: InitialValues;
  dataRoles?: CloudGroupAllName;
}>) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const { pcBridgecrewEnabled } = useFlags();
  const queryClient = useQueryClient();

  const isCloneAction = action === "clone";
  const isEditAction = action === "edit";

  const { data: roleTypesData = [], isLoading: isLoadingRoleTypes } = useQuery({
    queryKey: permissionGroupsKeys.permissionGroups({
      includeFeatures: "true",
    }),
    queryFn: getPermissionGroups,
  });

  const { openModal } = useCodeRolesModal();
  const { data: featureStatus } = useQuery({
    queryKey: awsFeatureKeys.awsFeature(),
    queryFn: getAWSFeature,
    staleTime: 6 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
  });

  const isBCEnabled = !!featureStatus?.is_bc_service_enabled;

  const enableRepositories = pcBridgecrewEnabled && isBCEnabled;

  const {
    data: repositoriesListFromApi = [],
    isLoading: isLoadingRepositoriesList,
  } = useQuery({
    queryKey: rolesKeys.bcRepositories(),
    queryFn: getBCRepositories,
    enabled: enableRepositories,
    staleTime: 60000,
  });

  const roleTypes = useMemo(() => {
    if (!roleTypesData) return [];

    return roleTypesData
      .sort((a: PermissionGroupData, b: PermissionGroupData) =>
        a.type === "Default" && b.type === "Custom"
          ? -1
          : a.type === "Custom" && b.type === "Default"
            ? 1
            : a.type === "Default" && b.type === "Default"
              ? Number(a?.id?.replace("-", "")) -
                Number(b?.id?.replace("-", ""))
              : (b?.lastModifiedTs ?? 0) - (a?.lastModifiedTs ?? 0),
      )
      .map((role: PermissionGroupData) => ({
        value: role.name,
        context: {
          acceptAccountGroups: role.acceptAccountGroups,
          acceptCodeRepositories: role.acceptCodeRepositories,
          acceptResourceLists: role.acceptResourceLists,
          features: role.features,
        },
      }));
  }, [roleTypesData]);

  const titlePrefix =
    !isEditAction && !isCloneAction
      ? "Add"
      : (isEditAction && "Edit") || (isCloneAction && "Clone");

  const updateRecord = useUpdateRecord<RoleRequest>();
  const createRecord = useCreateRecord<RoleRequest>();

  const hasRoleType = !isNil(roleTypes) && !isEmpty(roleTypes);

  const { mutateAsync: userRoleMutation, isPending: isMutateLoading } =
    useMutation({
      mutationFn: async ({ data, httpMethod, id }: RoleRequest) => {
        if (httpMethod === "PUT") {
          await updateRecord(() => updateUserRole(id, data), {
            id,
            record: data,
            dataCacheKey: rolesKeys.userRole(),
            successMessage: intl.formatMessage({
              defaultMessage: "Role saved successfully.",
              id: "TdKB/z",
              description: "Success message when role is saved successfully",
            }),
            knownErrorMessages: errorMessagesMap,
            onSuccess: () => {
              closeModal();
              queryClient.invalidateQueries({
                queryKey: permissionGroupsKeys.permissionGroups({
                  includeFeatures: "true",
                }),
              });
            },
            onError: (error) => {
              const { headers } = error as ApiError;
              let messageKey;
              try {
                messageKey = headers?.["x-redlock-status"][0].i18nKey;
              } catch (e) {
                messageKey = "unknown_error";
              }
              const subject = headers?.["x-redlock-status"][0]?.subject ?? 0;

              toast(
                intl.formatMessage(
                  errorMessagesMap[
                    messageKey as keyof typeof errorMessagesMap
                  ] ?? errorMessagesMap["unknown_error"],
                  {
                    action: isEditAction ? "update" : "create",
                    roleName: data?.name,
                    subject,
                  },
                ),
                { appearance: "error" },
              );
              return false;
            },
          });
        } else {
          await createRecord(() => createUserRole(data), {
            record: data,
            dataCacheKey: rolesKeys.userRole(),
            successMessage: intl.formatMessage({
              defaultMessage: "Role saved successfully.",
              id: "TdKB/z",
              description: "Success message when role is saved successfully",
            }),
            knownErrorMessages: errorMessagesMap,
            onSuccess: () => {
              closeModal();
              queryClient.invalidateQueries({
                queryKey: permissionGroupsKeys.permissionGroups({
                  includeFeatures: "true",
                }),
              });
            },
            onError: (error) => {
              const { headers } = error as ApiError;
              let messageKey;
              try {
                messageKey = headers?.["x-redlock-status"][0].i18nKey;
              } catch (e) {
                messageKey = "unknown_error";
              }
              const subject = headers?.["x-redlock-status"][0]?.subject ?? 0;

              toast(
                intl.formatMessage(
                  errorMessagesMap[
                    messageKey as keyof typeof errorMessagesMap
                  ] ?? errorMessagesMap["unknown_error"],
                  {
                    action: isEditAction ? "update" : "create",
                    roleName: data?.name,
                    subject,
                  },
                ),
                { appearance: "error" },
              );
              return false;
            },
          });
        }
      },
    });

  const handleFormSubmit = ({
    formData,
    initialValues,
  }: {
    formData: FormValues;
    initialValues?: InitialValues;
  }) => {
    const id = initialValues?.id || "";
    const updateModel = isEditAction && id;
    const httpMethod = updateModel ? "PUT" : "POST";
    // const endpoint = updateModel ? `user/role/${id}` : "user/role";

    const {
      description = "",
      name = "",
      accountGroups = [],
      resourceLists = [],
      repositories = [],
      additionalAttributes,
      roleType,
      selectAllRepositories,
    } = formData;

    const {
      hasDefenderPermissions = false,
      onlyAllowReadAccess = false,
      onlyAllowCIAccess = false,
      onlyAllowComputeAccess = false,
      restrictDismissalAccess = false,
    } = additionalAttributes || {};

    const isSystemAdmin = roleType?.value === SYSTEM_ADMIN;

    const repositoryListIds = {
      codeRepositoryIds: selectAllRepositories
        ? repositoriesListFromApi?.map(
            (repository: BCRepositories[0]) => repository.id,
          )
        : repositories?.map((a: { context: { id: string } }) => a.context.id),
    };

    const data = {
      additionalAttributes: {
        hasDefenderPermissions,
        ...(roleType?.value === BUILD_AND_DEPLOY_SECURITY && {
          onlyAllowCIAccess,
        }),
        ...(ONLY_ALLOW_COMPUTE_ACCESS_ROLES.includes(roleType?.value) && {
          onlyAllowComputeAccess,
        }),
        ...(NETSECOPS_ROLES.includes(roleType?.value) && {
          onlyAllowReadAccess,
        }),
      },
      // NOTE - Because System Admins have access to all accounts, we pass an empty array here
      accountGroupIds:
        isSystemAdmin || accountGroups === null
          ? []
          : accountGroups?.map((a: { value: string }) => a.value),
      description,
      name,
      resourceListIds: isSystemAdmin
        ? []
        : resourceLists?.map((a: { value: string }) => a.value),
      ...(pcBridgecrewEnabled &&
        !hideRepositories(roleType?.value, additionalAttributes) &&
        repositoryListIds),
      restrictDismissalAccess,
      roleType: roleType.value,
    };

    return userRoleMutation({ data, httpMethod, id });
  };

  return (
    <Form
      modalProps={{ onClose: closeModal, isOpen }}
      onSubmit={async (formData: FormValues) => {
        await handleFormSubmit({
          formData,
          initialValues,
        });
      }}
    >
      <ModalHeader
        title={intl.formatMessage(
          {
            defaultMessage: "{titlePrefix} Role",
            id: "147Mke",
            description: "Modal title",
          },
          { titlePrefix: titlePrefix },
        )}
      />
      <ModalBody overflow>
        <FormLayout>
          <Input
            data-autofocus
            name="name"
            label={intl.formatMessage({
              defaultMessage: "Name",
              id: "aTL0Px",
              description: "Label for role name field",
            })}
            defaultValue={`${isCloneAction ? "Copy of " : ""}${
              initialValues?.name || ""
            }`}
          />
          <Textarea
            name="description"
            label={intl.formatMessage({
              defaultMessage: "Description",
              id: "2gCqF1",
              description: "Label for Description field",
            })}
            defaultValue={initialValues?.description}
            register={{ required: false }}
          />
          <div className="relative space-y-1">
            <div className="absolute right-0 text-xs">
              <Link href={DOCUMENTATION_PAGE} target="_blank">
                <FormattedMessage
                  defaultMessage="View Permissions"
                  id="mnF9MW"
                  description="View permissions hyperlink text"
                />
              </Link>
            </div>
            <Select
              label={intl.formatMessage({
                defaultMessage: "Permission Group",
                id: "aT+IlV",
                description: "Label for Permission Group field",
              })}
              name="roleType"
              enableSearch
              defaultValue={
                initialValues?.roleType
                  ? {
                      value: initialValues?.roleType,
                      context: {},
                    }
                  : null
              }
              items={roleTypes}
              disabled={!hasRoleType || isLoadingRoleTypes}
              menuStyle={{ minWidth: "34rem" }}
            />
          </div>
          {initialValues?.roleType && isLoadingRoleTypes ? (
            <div className="text-center">
              <LoadingIcon />
            </div>
          ) : (
            <DynamicFields
              initialValues={initialValues}
              roleTypes={roleTypes}
            />
          )}
        </FormLayout>
      </ModalBody>
      <ModalFooter>
        {dataRoles && (
          <Button
            disabled={isMutateLoading}
            onClick={() => {
              closeModal();
              openModal({ data: dataRoles });
            }}
          >
            <FormattedMessage
              defaultMessage="Previous"
              id="4bbyuV"
              description="Label for Previous button"
            />
          </Button>
        )}
        <SubmitButton
          disabled={
            isMutateLoading || (enableRepositories && isLoadingRepositoriesList)
          }
        >
          {intl.formatMessage({
            defaultMessage: "Save",
            id: "MS8rJn",
            description: "Add/update role save button label",
          })}
        </SubmitButton>
      </ModalFooter>
    </Form>
  );
}
