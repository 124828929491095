import { useEffect } from "react";
import { useIntl, type MessageDescriptor } from "react-intl";
import { type ApiError } from "requests";
import { useToastActions } from "stores";

/**
 * Takes an error object from react-query, looks for an x-redlock-status header, and gets the first i18nKey from it.
 * If the i18nKey is found within the provided errorMessageMap, this hook will display an error toast with the
 * respective message, otherwise it will do nothing.
 */
export function useApiErrorToast<i18nKey extends string = string>({
  error,
  errorMessageMap,
}: {
  error: Error | null;
  errorMessageMap: Record<i18nKey, MessageDescriptor>;
}) {
  const intl = useIntl();
  const { toast } = useToastActions();

  useEffect(() => {
    if (error) {
      const { headers } = error as unknown as ApiError<i18nKey>;
      const statusHeader = headers?.["x-redlock-status"]?.[0];

      if (errorMessageMap[statusHeader?.i18nKey]) {
        toast(
          intl.formatMessage(errorMessageMap[statusHeader?.i18nKey], {
            subject: statusHeader?.subject,
          }),
          { appearance: "error" },
        );
      }
    }
  }, [error, errorMessageMap, intl, toast]);
}
