import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type RenderModalProps,
} from "ui";

import { useIntl } from "react-intl";
import { type CloudTypes } from "requests";
import { type CloudAccount } from "../../../../../types";
import { CloudAccountsModalTable } from "./CloudAccountsModalTable";

export function CloudAccountsModal({
  cloudAccounts,
  cloudType,
  isOpen,
  closeModal,
}: RenderModalProps<{
  cloudAccounts: CloudAccount[];
  cloudType?: CloudTypes;
}>) {
  const intl = useIntl();

  return (
    <Modal onClose={closeModal} isOpen={isOpen}>
      <ModalHeader
        enableClose
        title={intl.formatMessage({
          defaultMessage: "Cloud Accounts",
          id: "baLX46",
          description: "Label for cloud accounts",
        })}
      />
      <ModalBody>
        <CloudAccountsModalTable
          cloudAccounts={cloudAccounts}
          cloudType={cloudType}
        />
      </ModalBody>
      <ModalFooter>
        <Button appearance="primary" onClick={closeModal}>
          {intl.formatMessage({
            defaultMessage: "Done",
            id: "keWodn",
            description: "Done Button Cloud Accounts Modal",
          })}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
