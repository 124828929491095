import { type z } from "zod";
import { type CloudTypesEnumSchema } from "../cloudType";
import {
  type AlibabaRequestPayloadSchema,
  type AwsRequestPayloadType,
  type AzureRequestPayloadType,
  type GcpRequestPayloadType,
  type IbmRequestPayloadSchema,
  type OciRequestPayloadSchema,
} from "./cloudAccountStatus";
import {
  type AccountTypeSchema,
  type TempCloudAccountPayloadSchema,
} from "./cloudAccountsCas";
import { type CloudAccountsRestParamsSchema } from "./cloudAccountsRest/schemas";
import { type CloudAccountsTableParamsSchema } from "./cloudAccountsTable/schemas";

export const cloudAccountsKeys = {
  all: [{ scope: "cloudAccounts" }] as const,
  cloudAccountsTable: ({
    excludeAccountGroupDetails,
    includePendingAccounts,
  }: z.infer<typeof CloudAccountsTableParamsSchema>) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "cloudAccountsTable",
        excludeAccountGroupDetails,
        includePendingAccounts,
      },
    ] as const,
  cloudAccountsGroups: ({
    accountId,
    cloudType,
  }: {
    accountId: string;
    cloudType: z.infer<typeof CloudTypesEnumSchema>;
  }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "cloudAccountGroups",
        accountId,
        cloudType,
        includeGroupInfo: "true",
      },
    ] as const,
  projects: ({
    accountId,
    cloudType,
  }: {
    accountId: string;
    cloudType: z.infer<typeof CloudTypesEnumSchema>;
  }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "projects",
        accountId,
        cloudType,
      },
    ] as const,
  cloudAccountsCas: ({
    accountId,
    cloudType,
    includeGroupInfo = false,
  }: {
    accountId: string;
    cloudType: z.infer<typeof CloudTypesEnumSchema>;
    includeGroupInfo?: boolean;
  }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "cloudAccountsCas",
        accountId,
        cloudType,
        includeGroupInfo,
      },
    ] as const,
  accountNameExists: ({ name }: { name: string }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "accountNameExists",
        name,
      },
    ] as const,
  awsCloudAccountsCas: ({ accountId }: { accountId: string }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "awsCloudAccountsCas",
        accountId,
      },
    ] as const,
  awsTempCloudAccount: ({
    payload,
  }: {
    payload: TempCloudAccountPayloadSchema;
  }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "awsTempCloudAccount",
        payload,
      },
    ] as const,
  awsTempcloudAccountDetails: ({ accountName }: { accountName: string }) => [
    {
      ...cloudAccountsKeys.all[0],
      entity: "awsTempCloudAccountDetails",
      accountName,
    },
  ],
  azureCloudAccountsCas: ({ accountId }: { accountId: string }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "azureCloudAccountsCas",
        accountId,
      },
    ] as const,
  gcpCloudAccountsCas: ({ accountId }: { accountId: string }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "gcpCloudAccountsCas",
        accountId,
      },
    ] as const,
  ibmCloudAccountsCas: ({ accountId }: { accountId: string }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "ibmCloudAccountsCas",
        accountId,
      },
    ] as const,
  permissionsStatus: ({ accountId }: { accountId: string }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "permissionsStatus",
        accountId,
      },
    ] as const,

  cloudAccountsRest: ({
    accountId,
    cloudType,
    includeGroupInfo,
  }: {
    accountId: string;
    cloudType: z.infer<typeof CloudTypesEnumSchema>;
  } & z.infer<typeof CloudAccountsRestParamsSchema>) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "cloudAccountsRest",
        accountId,
        cloudType,
        includeGroupInfo,
      },
    ] as const,
  cloudAccountStatus: ({ accountId }: { accountId: string }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "cloudAccountStatus",
        accountId,
      },
    ] as const,
  computeLimit: ({ accountId }: { accountId?: string }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "computeLimit",
        accountId,
      },
    ] as const,
  supportedFeatures: ({
    cloudType,
    payload,
  }: {
    cloudType: z.infer<typeof CloudTypesEnumSchema>;
    payload: {
      accountType: z.infer<typeof AccountTypeSchema>;
      awsPartition?: string;
    };
  }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "supportedFeatures",
        cloudType,
        payload,
      },
    ] as const,
  cloudAccountOwner: ({ accountId }: { accountId: string }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "cloudAccountOwner",
        accountId,
      },
    ] as const,
  awsRegions: ({ deploymentType }: { deploymentType: string }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "awsRegions",
        deploymentType,
      },
    ] as const,
  ibmCloudAccountStatus: ({
    accountId,
    payload,
  }: {
    accountId: string;
    payload: IbmRequestPayloadSchema;
  }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "ibmCloudAccountStatus",
        accountId,
        payload,
      },
    ] as const,
  awsCloudAccountStatus: ({ payload }: { payload: AwsRequestPayloadType }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "awsCloudAccountStatus",
        payload,
      },
    ] as const,
  awsAutomatedCloudAccountStatus: ({
    payload,
  }: {
    payload: { accountName: string };
  }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "awsAutomatedCloudAccountStatus",
        payload,
      },
    ] as const,
  azureCloudAccountStatus: ({
    payload,
  }: {
    payload: AzureRequestPayloadType;
  }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "azureCloudAccountStatus",
        payload,
      },
    ] as const,
  alibabaCloudAccountStatus: ({
    payload,
  }: {
    payload: AlibabaRequestPayloadSchema;
  }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "alibabaCloudAccountStatus",
        payload,
      },
    ] as const,
  ociCloudAccountStatus: ({ payload }: { payload: OciRequestPayloadSchema }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "ociCloudAccountStatus",
        payload,
      },
    ] as const,
  gcpCloudAccountStatus: ({ payload }: { payload: GcpRequestPayloadType }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "gcpCloudAccountStatus",
        payload,
      },
    ] as const,

  azureAccountStatus: ({
    accountId,
    initialize,
  }: {
    accountId: string;
    initialize?: boolean;
  }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "azureAccountStatus",
        accountId,
        initialize,
      },
    ] as const,

  azureAccountConfig: ({
    accountId,
    initialize,
  }: {
    accountId: string;
    initialize?: boolean;
  }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "azureAccountConfig",
        accountId,
        initialize,
      },
    ] as const,

  getAzureAccountDataSecurityDetails: ({ accountId }: { accountId: string }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "getAzureAccountDataSecurityDetails",
        accountId,
      },
    ] as const,
  getAzureAccountDataSecurityStatus: ({ accountId }: { accountId: string }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "getAzureAccountDataSecurityStatus",
        accountId,
      },
    ] as const,
  awsAccountDataSecurity: ({ accountId }: { accountId: string }) =>
    [
      {
        ...cloudAccountsKeys.all[0],
        entity: "awsAccountDataSecurity",
        accountId,
      },
    ] as const,
  awsOrgDataSecurity: ({ accountId }: { accountId: string }) => [
    {
      ...cloudAccountsKeys.all[0],
      entity: "awsOrgDataSecurity",
      accountId,
    },
  ],

  awsOrgDataSecurityStatus: ({ accountId }: { accountId: string }) => [
    {
      ...cloudAccountsKeys.all[0],
      entity: "awsOrgDataSecurityStatus",
      accountId,
    },
  ],
} as const;
