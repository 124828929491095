import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { isEmpty } from "remeda";
import {
  appDna,
  getAppDnaApplicationDefinition,
  reviewAppDnaApplicationDefinition,
  type ReviewApplicationsDefinitionMetadataType,
} from "requests";
import { useToastActions } from "stores";
import {
  Button,
  ChipInput,
  Field,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type RenderModalProps,
} from "ui";
import { parseI18nKeyFromResponse } from "../../../cloudAccounts/utils";
import { getValue } from "../../utils";

export default function ApplicationDefinitionModal({
  closeModal,
  isOpen,
  id,
  isEdit = false,
}: { id: string; isEdit?: boolean } & RenderModalProps) {
  const intl = useIntl();
  const { toast } = useToastActions();
  const queryClient = useQueryClient();
  const [displayName, setDisplayName] = useState("");
  const [owner, setOwner] = useState("");
  const [clusterAssetId, setClusterAssetId] = useState("");
  const [businessCriticality, setBusinessCriticality] = useState("");
  const [environment, setEnvironment] = useState("");

  const {
    data,
    isLoading: isAppDefLoading,
    isFetching: isAppDefFetching,
  } = useQuery({
    queryKey: appDna.applicationDefinition({ id }),
    queryFn: getAppDnaApplicationDefinition,
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!(isAppDefFetching && isAppDefLoading) && data && data.metadata) {
      setDisplayName(getValue(data.metadata.displayName));
      setOwner(getValue(data.metadata.owner));
      setClusterAssetId(getValue(data.metadata.clusterAssetId) ?? "Undefined");
      setBusinessCriticality(getValue(data.metadata.businessCriticality));
      setEnvironment(getValue(data.metadata.environment));
    }
  }, [data, isAppDefFetching, isAppDefLoading]);

  const criteria = useMemo(() => {
    let values: string[] = [];
    data?.definition?.operands?.forEach((op) => {
      if (op.key === "namespace") {
        values = values.concat(`k8s namespace (${op.values})`);
      }
    });
    return values?.map((value) => ({ value }));
  }, [data]);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async () => {
      try {
        const metadata = {} as ReviewApplicationsDefinitionMetadataType;
        if (displayName !== getValue(data?.metadata.displayName)) {
          metadata.displayName = displayName ? [displayName] : displayName;
        }
        if (owner !== getValue(data?.metadata.owner)) {
          metadata.owner = owner ? [owner] : owner;
        }
        if (
          businessCriticality !== getValue(data?.metadata.businessCriticality)
        ) {
          metadata.businessCriticality = businessCriticality
            ? [businessCriticality]
            : businessCriticality;
        }
        if (environment !== getValue(data?.metadata.environment)) {
          metadata.environment = environment ? [environment] : environment;
        }
        if (!isEmpty(metadata)) {
          await reviewAppDnaApplicationDefinition({
            ids: [id],
            metadata,
          });
          queryClient.invalidateQueries({ queryKey: appDna.all });
        }
        toast(
          isEdit
            ? intl.formatMessage({
                defaultMessage: "Application updated successfully.",
                id: "DM/YHz",
                description: "Application updated successfully.",
              })
            : intl.formatMessage({
                defaultMessage: "Application reviewed successfully.",
                id: "+Hyt5Z",
                description: "Application reviewed successfully.",
              }),
          { appearance: "success" },
        );

        closeModal();
      } catch (error) {
        toast(parseI18nKeyFromResponse(intl, error), { appearance: "warning" });
      }
    },
  });

  const showLoading = isPending || (isAppDefFetching && isAppDefLoading);

  return (
    <Modal onClose={closeModal} isOpen={isOpen} style={{ minHeight: 450 }}>
      <ModalHeader
        title={
          isEdit
            ? intl.formatMessage({
                defaultMessage: "Edit Application",
                id: "V0cHPy",
                description: "AppDna Edit Application",
              })
            : intl.formatMessage({
                defaultMessage: "Review Application",
                id: "ueSaOj",
                description: "AppDna Review Application",
              })
        }
      />
      <ModalBody isLoading={showLoading}>
        <div className="space-y-2 p-4">
          <Field
            label={intl.formatMessage({
              defaultMessage: "Application Name",
              id: "tOj94H",
              description: "AppDna Application Name",
            })}
            control={
              <Input
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            }
          />
          <Field
            label={intl.formatMessage({
              defaultMessage: "K8s Cluster ID",
              id: "ATGr3v",
              description: "AppDna K8s Cluster ID",
            })}
            control={<Input value={clusterAssetId} readOnly />}
          />
          <Field
            label={intl.formatMessage({
              defaultMessage: "Owner",
              id: "eJqL5A",
              description: "AppDna Owner",
            })}
            control={
              <Input value={owner} onChange={(e) => setOwner(e.target.value)} />
            }
          />
          <Field
            label={intl.formatMessage({
              defaultMessage: "Business Criticality",
              id: "KoF0cE",
              description: "AppDna Business Criticality",
            })}
            control={
              <Input
                value={businessCriticality}
                onChange={(e) => setBusinessCriticality(e.target.value)}
              />
            }
          />
          <Field
            label={intl.formatMessage({
              defaultMessage: "Criteria",
              id: "5c+c+a",
              description: "AppDna Criteria",
            })}
            control={
              <ChipInput
                disabled
                selectedItems={criteria}
                onSelectedItemsChange={undefined}
                allowArbitraryItems={false}
              />
            }
          />
          <Field
            label={intl.formatMessage({
              defaultMessage: "Environment",
              id: "1tDm2r",
              description: "AppDna Environment",
            })}
            control={
              <Input
                value={environment}
                onChange={(e) => setEnvironment(e.target.value)}
              />
            }
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={showLoading}
          onClick={() => mutateAsync()}
          appearance="primary"
        >
          <FormattedMessage
            defaultMessage="Save"
            id="0CvRZW"
            description="Save"
          />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
