import { CloseIcon } from "icons";
import {
  type ButtonHTMLAttributes,
  type ReactElement,
  type ReactNode,
} from "react";
import { useIntl } from "react-intl";
import { classNames } from "utils";
import isIconOnly from "../Button/isIconOnly";
import { TruncateText } from "../TruncateText";
import validateProps from "./validateProps";

export const closeAppearance = {
  standard: "hover:bg-blue-200",
  simple: "hover:bg-blue-200",
  blue: "hover:bg-blue-400",
  green: "hover:bg-green-400",
  yellow: "hover:bg-yellow-400",
  orange: "hover:bg-orange-400",
  red: "hover:bg-red-400",
  gray: "hover:bg-gray-400",
  teal: "hover:bg-vis-d-400",
  configuration: "",
  identity: "",
  network: "",
  vulnerability: "",
  vulnerability_malware: "",
  data: "",
  other: "",
  compute: "",
  applicationServices: "",
  development: "",
};

const sizes = {
  xs: "4rem",
  sm: "8rem",
  md: "16rem",
  lg: "24rem",
};

export type TagAppearances = keyof typeof closeAppearance;

export type TagProps = {
  /**
   * Apply a different style to the tag
   */
  appearance?: TagAppearances;
  /**
   * The content of the Tag
   */
  children?: ReactNode;
  /**
   * Add a close button on the right of the tag
   *
   * Cannot be used with `onClick`.
   */
  closeButtonProps?: Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className">;
  /**
   * A selector for testing.
   */
  ["data-testid"]?: string;
  /**
   * An icon to display on the left side of the tag
   */
  icon?: ReactNode;
  /**
   * Style the tag as it is selected
   */
  isSelected?: boolean;
  /**
   * Make the entire tag clickable.
   *
   * Cannot be used with `closeButtonProps`.
   */
  onClick?: () => void;
  /**
   * Style the tag as readonly
   */
  readOnly?: boolean;
  /**
   * The maximum size for the tag. Text will be truncated if beyond this size.
   */
  size?: keyof typeof sizes;
  suppressTooltip?: boolean;
};

export const Tag = (props: TagProps): ReactElement => {
  const {
    children,
    appearance = "standard",
    closeButtonProps,
    ["data-testid"]: dataSelector,
    icon,
    isSelected = false,
    onClick,
    readOnly = false,
    size = "sm",
    suppressTooltip,
  } = props;

  validateProps(props);

  const intl = useIntl();

  const iconOnly = isIconOnly(props);

  const typeClassName: Record<TagAppearances, string> = {
    standard: classNames(
      "border text-secondary dark:border-blue-steel-850 dark:text-dark-bg-secondary",
      onClick && "hover:bg-blue-100",
      readOnly
        ? "bg-gray-200 dark:bg-gray-900 dark:text-dark-bg-disabled"
        : isSelected
          ? "border-blue-600 bg-blue-200 dark:border-blue-300 dark:bg-blue-200"
          : "border-gray-300 bg-white dark:bg-blue-steel-950",
    ),
    configuration: classNames(
      "border text-secondary dark:text-dark-bg-secondary",
      onClick &&
        "hover:border-domain-configuration-colorBorder dark:hover:border-domain-configuration-colorDarkBorder",
      `border-domain-configuration-color bg-domain-configuration-color`,
      `dark:border-domain-configuration-colorDark dark:bg-domain-configuration-colorDark`,
    ),
    identity: classNames(
      "border text-secondary dark:text-dark-bg-secondary",
      onClick &&
        "hover:border-domain-identity-colorBorder dark:hover:border-domain-identity-colorDarkBorder",
      `border-domain-identity-color bg-domain-identity-color`,
      `dark:border-domain-identity-colorDark dark:bg-domain-identity-colorDark`,
    ),
    network: classNames(
      "border text-secondary dark:text-dark-bg-secondary",
      onClick &&
        "hover:border-domain-network-colorBorder dark:hover:border-domain-network-colorDarkBorder",
      `border-domain-network-color bg-domain-network-color`,
      `dark:border-domain-network-colorDark dark:bg-domain-network-colorDark`,
    ),
    vulnerability: classNames(
      "border text-secondary dark:text-dark-bg-secondary",
      onClick &&
        "hover:border-domain-vulnerability_malware-colorBorder dark:hover:border-domain-vulnerability_malware-colorDarkBorder",
      `border-domain-vulnerability_malware-color bg-domain-vulnerability_malware-color`,
      `dark:border-domain-vulnerability_malware-colorDark dark:bg-domain-vulnerability_malware-colorDark`,
    ),
    vulnerability_malware: classNames(
      "border text-secondary dark:text-dark-bg-secondary",
      onClick &&
        "hover:border-domain-vulnerability_malware-colorBorder dark:hover:border-domain-vulnerability_malware-colorDarkBorder",
      `border-domain-vulnerability_malware-color bg-domain-vulnerability_malware-color`,
      `dark:border-domain-vulnerability_malware-colorDark dark:bg-domain-vulnerability_malware-colorDark`,
    ),
    data: classNames(
      "border text-secondary dark:text-dark-bg-secondary",
      onClick &&
        "hover:border-domain-data-colorBorder dark:hover:border-domain-data-colorDarkBorder",
      `border-domain-data-color bg-domain-data-color`,
      `dark:border-domain-data-colorDark dark:bg-domain-data-colorDark`,
    ),
    other: classNames(
      "border text-secondary dark:text-dark-bg-secondary",
      onClick &&
        "hover:border-domain-other-colorBorder dark:hover:border-domain-other-colorDarkBorder",
      `border-domain-other-color bg-domain-other-color`,
      `dark:border-domain-other-colorDark dark:bg-domain-other-colorDark`,
    ),
    applicationServices: classNames(
      "border text-secondary dark:text-dark-bg-secondary",
      onClick &&
        "hover:border-domain-applicationServices-colorBorder dark:hover:border-domain-applicationServices-colorDarkBorder",
      `border-domain-applicationServices-color bg-domain-applicationServices-color`,
      `dark:border-domain-applicationServices-colorDark dark:bg-domain-applicationServices-colorDark`,
    ),
    development: classNames(
      "border text-secondary dark:text-dark-bg-secondary",
      onClick &&
        "hover:border-domain-development-colorBorder dark:hover:border-domain-development-colorDarkBorder",
      `border-domain-development-color bg-domain-development-color`,
      `dark:border-domain-development-colorDark dark:bg-domain-development-colorDark`,
    ),
    compute: classNames(
      "border text-secondary dark:text-dark-bg-secondary",
      onClick &&
        "hover:border-domain-compute-colorBorder dark:hover:border-domain-compute-colorDarkBorder",
      `border-domain-compute-color bg-domain-compute-color`,
      `dark:border-domain-compute-colorDark dark:bg-domain-compute-colorDark`,
    ),
    simple: classNames(
      "text-secondary dark:text-dark-bg-secondary",
      onClick && "hover:bg-blue-100",
      readOnly
        ? "bg-gray-200 dark:bg-gray-900"
        : isSelected
          ? "bg-blue-200"
          : "bg-white dark:bg-blue-steel-950",
    ),
    blue: classNames(
      onClick && "hover:bg-blue-400 dark:hover:bg-blue-700",
      isSelected
        ? "bg-blue-400 ring ring-blue-200 dark:bg-blue-800"
        : "bg-blue-300 dark:bg-blue-600",
    ),
    green: classNames(
      onClick && "hover:bg-green-400",
      isSelected ? "bg-green-400 ring ring-blue-200" : "bg-green-300",
    ),
    yellow: classNames(
      onClick && "hover:bg-yellow-400",
      isSelected ? "bg-yellow-400 ring ring-blue-200" : "bg-yellow-300",
    ),
    orange: classNames(
      onClick && "hover:bg-orange-400",
      isSelected ? "bg-orange-400 ring ring-blue-200" : "bg-orange-300",
    ),
    red: classNames(
      onClick && "hover:bg-red-400",
      isSelected ? "bg-red-400 ring ring-blue-200" : "bg-red-300",
    ),
    gray: classNames(
      onClick && "hover:bg-gray-400",
      isSelected
        ? "bg-gray-400 text-default ring ring-blue-200"
        : "bg-gray-300 text-default",
    ),
    teal: classNames(
      onClick && "hover:bg-vis-d-400",
      isSelected ? "bg-vis-d-400 ring ring-blue-200" : "bg-vis-d-600",
    ),
  };

  const classes = classNames(
    "h-6 rounded-full px-2 py-1 text-xs",
    "inline-flex items-center align-middle",
    typeClassName[appearance],
    readOnly && "cursor-text",
    onClick && "cursor-pointer focus:outline-none focus-visible:ring",
  );

  const closeClassname = classNames(
    "-mr-1 ml-1 flex",
    "rounded-full focus:outline-none",
    readOnly ? "cursor-default" : "focus-visible:ring",
    !readOnly && closeAppearance[appearance],
  );

  const closeButton = (
    <button
      type="button"
      {...closeButtonProps}
      aria-label={intl.formatMessage({
        defaultMessage: "Remove",
        id: "P1wNW7",

        description: "Remove tag",
      })}
      className={closeClassname}
      style={{ padding: "1px" }}
    >
      <CloseIcon size="sm" />
    </button>
  );

  const Element = onClick ? "button" : "div";

  const iconClass = classNames(!iconOnly && "mr-1");

  return (
    <Element
      className={classes}
      data-testid={dataSelector}
      onClick={onClick}
      style={{
        maxWidth: sizes[size],
      }}
      type={Element === "button" ? "button" : undefined}
    >
      {icon && <span className={iconClass}>{icon}</span>}
      <TruncateText suppressTooltip={suppressTooltip}>{children}</TruncateText>
      {closeButtonProps && closeButton}
    </Element>
  );
};
