import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import {
  AlibabaRequestPayload,
  AwsRequestPayload,
  AzureRequestPayload,
  CloudAccountStatusResponseSchema,
  GcpRequestPayload,
  IbmRequestPayload,
  IbmStatusResponse,
  OciRequestPayload,
  OnboardingStatusResponse,
} from "./schemas";

import { jsonApi } from "../../../apis";
import { type cloudAccountsKeys } from "../cloudAccountsKeys";

export const getCloudAccountStatus = async ({
  queryKey: [{ accountId }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["cloudAccountStatus"]>
>) => {
  return jsonApi({
    path: `account/${accountId}/config/status`,
    requestSchema: z.void(),
    responseSchema: CloudAccountStatusResponseSchema,
  });
};

export const getIbmCloudAccountStatus = async ({
  queryKey: [{ accountId, payload }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["ibmCloudAccountStatus"]>
>) => {
  return jsonApi({
    path: `cas/v1/cloud-type/ibm/account/${accountId}/status`,
    body: { ...payload },
    config: { method: "post" },
    requestSchema: IbmRequestPayload,
    responseSchema: IbmStatusResponse,
  });
};

export const getAwsCloudAccountStatus = async ({
  queryKey: [{ payload }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["awsCloudAccountStatus"]>
>) => {
  return jsonApi({
    path: "cas/v1/cloud_account/status/aws",
    body: { ...payload },
    config: { method: "post" },
    requestSchema: AwsRequestPayload,
    responseSchema: OnboardingStatusResponse,
  });
};

export const getAwsAutomatedCloudAccountStatus = async ({
  queryKey: [{ payload }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["awsAutomatedCloudAccountStatus"]>
>) => {
  return jsonApi({
    path: "cas/v1/temp-cloud-account/status/aws",
    body: { ...payload },
    config: { method: "post" },
    requestSchema: z.object({
      accountName: z.string(),
    }),
    responseSchema: OnboardingStatusResponse,
  });
};

export const getAzureCloudAccountStatus = async ({
  queryKey: [{ payload }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["azureCloudAccountStatus"]>
>) => {
  return jsonApi({
    path: "cas/v1/cloud_account/status/azure",
    body: { ...payload },
    config: { method: "post" },
    requestSchema: AzureRequestPayload,
    responseSchema: OnboardingStatusResponse,
  });
};

export const getAlibabaCloudAccountStatus = async ({
  queryKey: [{ payload }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["alibabaCloudAccountStatus"]>
>) => {
  return jsonApi({
    path: "cloud/status/alibaba_cloud",
    body: { ...payload },
    config: { method: "post" },
    requestSchema: AlibabaRequestPayload,
    responseSchema: OnboardingStatusResponse,
  });
};

export const getOciCloudAccountStatus = async ({
  queryKey: [{ payload }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["ociCloudAccountStatus"]>
>) => {
  return jsonApi({
    path: "cloud/status/oci",
    body: { ...payload },
    config: { method: "post" },
    requestSchema: OciRequestPayload,
    responseSchema: OnboardingStatusResponse,
  });
};

export const getGcpCloudAccountStatus = async ({
  queryKey: [{ payload }],
}: QueryFunctionContext<
  ReturnType<(typeof cloudAccountsKeys)["gcpCloudAccountStatus"]>
>) => {
  return jsonApi({
    path: "cas/v1/cloud_account/status/gcp",
    body: { ...payload },
    config: { method: "post" },
    requestSchema: GcpRequestPayload,
    responseSchema: OnboardingStatusResponse,
  });
};
