import { z } from "zod";
import { UCDModeSchema } from "../preferences";

export const collections = [
  "alerts",
  "policies",
  "complianceStandards",
  "dashboards",
  "actionPlans",
] as const;
export type Collections = (typeof collections)[number];

const savedViewTypes = [
  "curated",
  "default",
  "custom",
  "shared_global",
] as const;
const savedViewTypesEnum = z.enum(savedViewTypes);

export const SavedViewBaseSchema = z.object({
  id: z.string(),
  name: z.string(),
  category: savedViewTypesEnum,
  createdBy: z
    .object({
      displayName: z.string(),
      username: z.string(),
      firstName: z.string().optional(),
      lastName: z.string().optional(),
    })
    .or(z.string()),
  visible: z.record(UCDModeSchema, z.boolean()),
});
export type SavedViewBase = z.infer<typeof SavedViewBaseSchema>;
export type SavedViewBaseWithSettings = z.infer<typeof SavedViewBaseSchema> & {
  settings: Record<string, unknown>;
};

export type CreatableSavedView<View extends SavedViewBaseWithSettings> = Pick<
  View,
  "name" | "visible" | "settings"
>;

export const creatableCustomViewFields = {
  name: true,
  visible: true,
  settings: true,
} as const;
export const editableCustomViewFields = {
  name: true,
  visible: true,
  category: true,
  settings: true,
} as const;
export const editableCuratedViewFields = {
  visible: true,
} as const;
export const editableDefaultViewFields = {
  visible: true,
  settings: true,
} as const;
export type EditableSavedView<
  SavedView extends SavedViewBase & { settings: Record<string, unknown> },
> = Partial<Pick<SavedView, "name" | "visible" | "category" | "settings">>;

export const GetSavedViewsResponseSchema = z.object({
  views: z.array(SavedViewBaseSchema),
  order: z.record(UCDModeSchema, z.array(z.string())),
});
export type GetSavedViewsResponse = z.infer<typeof GetSavedViewsResponseSchema>;

export const orderRequestSchema = z.object({
  order: z.string().array(),
  persona: UCDModeSchema,
});
