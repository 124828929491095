import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Card, CardHeader } from "ui";
import { PermissionGroupCardBody } from "../PermissionGroupCardBody";
import { type PermissionGroupMap } from "../PermissionGroupsSidecar";

type InvestigateCardProps = {
  featureMap: PermissionGroupMap;
};

export const investigateFeatureKeys = [
  "investigateAssetRql",
  "investigateConfigRql",
  "investigateEventRql",
  "investigateNetworkRql",
  "investigateVulnerabilityRql",
  "savedSearches",
] as const;

export function InvestigateCard({ featureMap }: InvestigateCardProps) {
  const intl = useIntl();

  const features = useMemo(
    () =>
      investigateFeatureKeys
        .filter((key) => !!featureMap[key])
        .map((key) => featureMap[key]),
    [featureMap],
  );

  if (!features.length) return null;

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          defaultMessage: "Investigate",
          id: "/HRntH",
          description: "Permission Group sidecar card header title",
        })}
      />
      <PermissionGroupCardBody features={features} />
    </Card>
  );
}
