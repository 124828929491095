import { useIntl } from "react-intl";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useEffect, useMemo } from "react";
import {
  cloudAccountsKeys,
  getAzureCloudAccountStatus,
  saveAzureCloudAccount,
  updateAzureCloudAccount,
  type AzureRequestPayloadType,
  type AzureStatusResponseType,
  type SecurityCapabilitiesFeatures,
  type SecurityCapabilitiesType,
} from "requests";
import { useToastActions } from "stores";
import { useModal } from "ui";
import { useCloudAccountPendoGuide } from "../../../../../../../utils/useCloudAccountPendoGuide";
import {
  PrevButton,
  WizardForm,
  useWizardContext,
} from "../../../../../components/Wizard";
import {
  ACCOUNT,
  ACCOUNT_GROUP_MODE,
  ACCOUNT_ID,
  ACCOUNT_TYPE,
  ACTIVE_DIRECTORY,
  AGENTLESS_DEFAULT_MEMBER_STATE,
  AGENTLESS_SCAN,
  AUTO_MAP,
  AUTO_PROTECT,
  AZURE,
  AZURE_HIERARCHY_SELECTION,
  CLIENT_ID,
  CLOUD_ACCOUNT,
  CONFIGURE_ACCOUNT,
  DEFAULT_ACCOUNT_GROUP_ID,
  DISABLED,
  ENABLED,
  ENVIRONMENT_TYPE,
  FEATURES,
  GET_STARTED,
  GROUP_IDS,
  IDENTITY_SECURITY,
  KEY,
  MANUAL,
  MONITOR_FLOW_LOGS,
  NAME,
  REMEDIATION,
  ROOT_SYNC_ENABLED,
  SERVERLESS_DEFAULT_MEMBER_STATE,
  SERVERLESS_SCAN,
  SERVICE_PRINCIPAL_ID,
  SUBSCRIPTION,
  TENANT,
  TENANT_ID,
  THREAT_DETECTION,
} from "../../../../constants";
import {
  useGetSupportedFeaturesList,
  useIdentitySubscription,
  useIsRenderedFirstTime,
} from "../../../../hooks";
import {
  type AccountType,
  type AzureConfigureAccountStepValuesType,
  type AzureGetStartedStepValuesType,
} from "../../../../types";
import { parseI18nKeyFromResponse } from "../../../../utils";
import CloudSelection from "../../../CloudSelection";
import { ProductDocumentationLink } from "../../components";
import SaveAndCloseButton from "../../components/SaveAndCloseButton";
import SaveAndOnboardButton from "../../components/SaveAndOnboardButton";
import {
  getAuthenticationData,
  getSecurityCapabilities,
  getWizardStepIndex,
  handleSuccess,
} from "../../utils";
import AccountDetailsStatus from "./AccountDetailsStatus";
import SecurityCapabilitiesPermissions from "./SecurityCapabilitiesPermissions";

type ReviewStatusProps = {
  context: {
    accountId: string;
    closeModal: () => void;
    enabled: boolean;
    isEdit: boolean;
  };
};

export default function ReviewStatus({
  context: { accountId, closeModal, enabled, isEdit },
}: ReviewStatusProps) {
  const { azureAutoMap } = useFlags();
  const intl = useIntl();
  const { showGuide } = useCloudAccountPendoGuide();
  const queryClient = useQueryClient();
  const { toast } = useToastActions();
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isRenderedForFirstTime = useIsRenderedFirstTime();

  const {
    state: { steps },
  } = useWizardContext();
  const { openModal } = useModal(CloudSelection);
  const getStepIndex = getWizardStepIndex(steps);
  const getStartedStepIndex = getStepIndex(GET_STARTED);
  const configureAccountIndex = getStepIndex(CONFIGURE_ACCOUNT);

  const {
    accountType,
    environmentType: deploymentType,
    securityCapabilities,
    orgSecurityCapabilities,
  } = steps[getStartedStepIndex]?.values as AzureGetStartedStepValuesType;

  const {
    autoMap,
    name,
    clientId,
    key,
    monitorFlowLogs,
    servicePrincipalId,
    hierarchySelection = [],
    subscriptionId,
    groupIds,
    remediation,
    tenantId,
  } = steps[configureAccountIndex]
    ?.values as AzureConfigureAccountStepValuesType;

  const isActiveDirectory = accountType === ACTIVE_DIRECTORY;
  const isAccountType = accountType === ACCOUNT;
  const isTenant = accountType === TENANT;

  const { supportedFeaturesList, isLoading: isFeaturesLoading } =
    useGetSupportedFeaturesList({
      cloudType: AZURE,
      payload: {
        ...(isActiveDirectory ? { accountType: TENANT } : { accountType }),
        deploymentType,
        ...(accountType === TENANT && { rootSyncEnabled: true }),
      },
    });

  const { isSubscribed, isLoading: identitySecurityLoading } =
    useIdentitySubscription();

  const features = [
    {
      name: AGENTLESS_SCAN,
      state: securityCapabilities[AGENTLESS_SCAN] ? ENABLED : DISABLED,
      ...(isTenant && {
        defaultMemberState:
          securityCapabilities[AGENTLESS_SCAN] &&
          orgSecurityCapabilities[AGENTLESS_DEFAULT_MEMBER_STATE]
            ? ENABLED
            : DISABLED,
      }),
    },
    {
      name: AUTO_PROTECT,
      state: securityCapabilities[AUTO_PROTECT] ? ENABLED : DISABLED,
    },
    {
      name: REMEDIATION,
      state: remediation ? ENABLED : DISABLED,
    },
    {
      name: SERVERLESS_SCAN,
      state: securityCapabilities[SERVERLESS_SCAN] ? ENABLED : DISABLED,
      ...(isTenant && {
        defaultMemberState:
          securityCapabilities[SERVERLESS_SCAN] &&
          orgSecurityCapabilities[SERVERLESS_DEFAULT_MEMBER_STATE]
            ? ENABLED
            : DISABLED,
      }),
    },
  ].filter(({ name }) =>
    supportedFeaturesList.includes(name as SecurityCapabilitiesType),
  ) as SecurityCapabilitiesFeatures[];

  const requestData: AzureRequestPayloadType = useMemo(() => {
    return {
      [CLIENT_ID]: clientId,
      [CLOUD_ACCOUNT]: {
        [ACCOUNT_ID]: subscriptionId,
        [ACCOUNT_TYPE]: isActiveDirectory ? TENANT : accountType,
        [ENABLED]: enabled,
        [NAME]: name,
        ...(!isTenant && { [GROUP_IDS]: groupIds }),
      },
      [ENVIRONMENT_TYPE]: deploymentType,
      [KEY]: key,
      [MONITOR_FLOW_LOGS]: monitorFlowLogs,
      [SERVICE_PRINCIPAL_ID]: servicePrincipalId,
      [TENANT_ID]: tenantId,
      [FEATURES]: features,
      ...(isTenant && {
        [DEFAULT_ACCOUNT_GROUP_ID]: groupIds,
        [ROOT_SYNC_ENABLED]: isTenant,
        [AZURE_HIERARCHY_SELECTION]: hierarchySelection,
        ...(azureAutoMap && {
          [ACCOUNT_GROUP_MODE]: autoMap ? AUTO_MAP : MANUAL,
        }),
      }),
    };
  }, [
    accountType,
    autoMap,
    azureAutoMap,
    clientId,
    deploymentType,
    enabled,
    features,
    groupIds,
    hierarchySelection,
    isActiveDirectory,
    isTenant,
    key,
    monitorFlowLogs,
    name,
    servicePrincipalId,
    subscriptionId,
    tenantId,
  ]);

  if (azureAutoMap && isTenant && autoMap) {
    delete requestData[DEFAULT_ACCOUNT_GROUP_ID];
  }

  const {
    data: accountStatus = [] as AzureStatusResponseType,
    isFetching: isCloudStatusLoading,
    isError,
    error,
  } = useQuery({
    queryKey: cloudAccountsKeys.azureCloudAccountStatus({
      payload: requestData,
    }),
    queryFn: getAzureCloudAccountStatus,
    enabled: !isRenderedForFirstTime && !isFeaturesLoading,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (!isError) return;

    toast(parseI18nKeyFromResponse(intl, error), {
      appearance: "error",
    });
    closeModal();
  }, [isError, error, intl, toast, closeModal]);

  const { mutateAsync: createConfig, isPending: isAccountCreating } =
    useMutation({
      mutationFn: () => saveAzureCloudAccount({ payload: requestData }),
    });

  const { mutateAsync: updateConfig, isPending: isAccountUpdating } =
    useMutation({
      mutationFn: () =>
        updateAzureCloudAccount({ accountId, payload: requestData }),
    });

  const onSubmit = useCallback(
    async ({ openNewOnboarding = false }) => {
      try {
        if (isEdit) {
          await updateConfig();
        } else {
          await createConfig();
        }
        queryClient.invalidateQueries({
          queryKey: cloudAccountsKeys.cloudAccountsTable({
            excludeAccountGroupDetails: "true",
          }),
        });
        /* c8 ignore next */
        if (isEdit) {
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.cloudAccountsCas({
              accountId,
              cloudType: AZURE,
              includeGroupInfo: false,
            }),
          });
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.azureCloudAccountsCas({ accountId }),
          });
          queryClient.invalidateQueries({
            queryKey: cloudAccountsKeys.permissionsStatus({ accountId }),
          });
        }
        closeModal();
        openNewOnboarding && openModal();
        if (!isEdit) showGuide();
        toast(handleSuccess(isEdit, intl), { appearance: "success" });
      } catch (error) {
        toast(parseI18nKeyFromResponse(intl, error), {
          appearance: "error",
        });
      }
    },
    [
      isEdit,
      queryClient,
      accountId,
      closeModal,
      openModal,
      showGuide,
      toast,
      intl,
      updateConfig,
      createConfig,
    ],
  );

  const securityCapabilitiesFeatures = getSecurityCapabilities(
    securityCapabilities,
    supportedFeaturesList,
    isSubscribed,
  )?.filter(
    (feature) =>
      !(
        isActiveDirectory &&
        [THREAT_DETECTION, IDENTITY_SECURITY].includes(feature)
      ),
  );

  const accountDetailsData = {
    accountGroups: Array.isArray(groupIds) ? groupIds : [groupIds],
    accountName: name,
    accountType: (accountType === ACCOUNT
      ? SUBSCRIPTION
      : accountType) as AccountType,
    authentication: getAuthenticationData(accountStatus),
    autoMap,
    clientId,
    isAccountType,
    servicePrincipalId,
    securityCapabilities: securityCapabilitiesFeatures,
    subscriptionId,
    tenantId,
    deploymentType,
  };

  const isLoading =
    isCloudStatusLoading || isFeaturesLoading || identitySecurityLoading;
  const isSubmitting = isAccountCreating || isAccountUpdating;
  const disableBtn = isLoading || isSubmitting;

  return (
    <WizardForm
      actions={
        <>
          <PrevButton data-selector="prev-button" disabled={disableBtn} />
          {!isEdit && (
            <SaveAndOnboardButton disabled={disableBtn} onSubmit={onSubmit} />
          )}
          <SaveAndCloseButton disabled={disableBtn} onSubmit={onSubmit} />
        </>
      }
      secondaryFooterContent={<ProductDocumentationLink />}
    >
      <AccountDetailsStatus {...accountDetailsData} isLoading={isLoading} />
      <SecurityCapabilitiesPermissions
        accountStatus={accountStatus}
        features={features}
        isSubscribed={isSubscribed}
        isActiveDirectory={isActiveDirectory}
        isLoading={isLoading}
      />
    </WizardForm>
  );
}
