import { defineMessages } from "react-intl";

export const filtersErrorMessageMap = defineMessages({
  resource_tags_v2_count_exceeded_threshold: {
    defaultMessage: "Number of asset tags cannot exceed {subject}.",
    id: "FiSIpc",
    description: "Error message when maximum number of resource tags exceeded",
  },
  resource_tags_v2_size_exceeded_threshold: {
    defaultMessage:
      "Keys and values cannot exceed {subject} characters in length.",
    id: "NKcBug",
    description:
      "Error message when maximum length for keys or values exceeded",
  },
  resource_list_count_exceeded_threshold: {
    defaultMessage: "Resource list filter count cannot exceed {subject}.",
    id: "n4poIT",
    description:
      "Error message when maximum number of resource list filters exceeded",
  },
});
