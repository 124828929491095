import { severities, type Severity } from "requests";

export const startCase = (str: string) =>
  str
    .replace(/([a-z\d])([A-Z])/g, "$1 $2") // Convert camelCase
    .replace(/[_-]+/g, " ") // Convert snake_case and kebab-case
    .toLowerCase() // Lowercase everything
    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()); // Uppercase first character of each word

export const camelToKebab = (s: string) =>
  [...s]
    .map((c, i) =>
      c === c.toUpperCase() && i !== 0
        ? "-" + c.toLowerCase()
        : c.toLowerCase(),
    )
    .join("");

export const getSeverity = (level: string): Severity => {
  const levelNumber = parseInt(level, 10);
  if (
    isNaN(levelNumber) ||
    levelNumber < 0 ||
    levelNumber >= severities.length
  ) {
    throw new Error("Invalid severity value");
  }
  return severities[levelNumber];
};

export type HttpError = Error & {
  status: number;
};

export function toCamelCase(str: string) {
  return str
    .replace(/[.-_]/g, " ")
    .replace(/[ ](.)/g, (char: string) => char.toUpperCase())
    .replace(" ", "");
}

export function getQueryClausesFromQuery(query: string) {
  const clauses = query
    .toLowerCase()
    .replace(/(.*)where/g, "")
    .trim()
    .split(/and/i);

  const clausesObject = clauses.map((clause) => {
    const c = clause.trim().split(/>=|<=|=|>|<|in \(|contains all/);
    if (c.length <= 1) return {};
    return {
      [toCamelCase(c[0].trim())]: c[1]
        .replace(/['()]/g, "")
        .trim()
        .toLowerCase()
        .split(", "),
    };
  });
  return clausesObject;
}
