import { defineMessages, useIntl, type IntlShape } from "react-intl";

import { type ReactElement } from "react";
import { TruncateWithExpandCell } from "table";

export type RegionsCellProps = {
  value: string[];
};

const regionsCellMessages = defineMessages({
  all: {
    defaultMessage: "All",
    id: "7pWOgY",
    description:
      "Value in table cell to indicate the data applies to all cloud regions.",
  },
});

export function getRegionsCellText({
  value,
  intl,
}: {
  value: RegionsCellProps["value"];
  intl: IntlShape;
}) {
  return value.length
    ? value.join(", ")
    : intl.formatMessage(regionsCellMessages["all"]);
}

export function RegionsCell({ value = [] }: RegionsCellProps): ReactElement {
  const intl = useIntl();

  return <TruncateWithExpandCell value={getRegionsCellText({ value, intl })} />;
}
