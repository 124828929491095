import { CloudTypeIcon } from "icons";
import { type CloudProvidersWithOther } from "requests";
import { cloudTypeMessages } from "table";
import { DynamicFormattedMessage } from "ui";

export function CloudProvidersTooltip({
  cloudProviders,
}: {
  cloudProviders: CloudProvidersWithOther;
}) {
  return (
    <div data-testid="cloud-providers-tooltip" className="px-1">
      {cloudProviders.map((cProvider) => {
        return (
          <div key={cProvider} className="flex items-center py-1 text-xs">
            <CloudTypeIcon
              size="lg"
              showNativeTooltip={false}
              className="mr-2 flex justify-center"
              type={cProvider}
            />
            <DynamicFormattedMessage
              messageMap={cloudTypeMessages}
              token={cProvider}
            />
          </div>
        );
      })}
    </div>
  );
}
