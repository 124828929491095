import { createRoute } from "@tanstack/react-router";
import { lazy } from "react";
import { ActionPlanFilterSchema } from "requests";
import { actionPlansSavedViews } from "saved-views-next";
import { z } from "zod";
import { actionPlansLayoutRoute } from "./actionPlansLayoutRoute";

export const ActionPlansOverview = lazy(() => import("./ActionPlansOverview"));

export const ActionPlansSearchSchema = z.object({
  filters: ActionPlanFilterSchema.catch({}).optional(),
  viewId: z.string().catch("default").optional(),
  alertId: z.string().optional(),
  assetId: z.string().optional(),
  actionPlanTab: z.string().optional(),
});

export type ActionPlansSearchType = z.infer<typeof ActionPlansSearchSchema>;

export const actionPlansOverviewRoute = createRoute({
  getParentRoute: () => actionPlansLayoutRoute,
  path: "overview",
  component: ActionPlansOverview,
  validateSearch: ActionPlansSearchSchema,
  loaderDeps: ({ search: { viewId } }) => ({ viewId }),
  loader: ({ context, deps: { viewId } }) => {
    context?.queryClient.prefetchQuery(
      actionPlansSavedViews.getViewQueryOptions(viewId ?? "default"),
    );
  },
});
