import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../../apis";
import { type appDna } from "../appDna";
import {
  ApplicationsDefinitionRequestSchema,
  ApplicationsDefinitionResponseSchema,
  ApplicationsDefinitionWithMetadataResponseSchema,
  ReviewApplicationsDefinitionRequestSchema,
} from "./schemas";

export const getAppDnaApplicationsDefinition = async ({
  pageParam,
  queryKey: [{ pageSize, reviewed }],
}: QueryFunctionContext<
  ReturnType<(typeof appDna)["applicationsDefinition"]>,
  string
>) => {
  return jsonApi({
    path: "appid/api/v1/app/definition",
    config: {
      method: "POST",
    },
    body: {
      ...(reviewed && { reviewed }),
    },
    params: {
      page_size: pageSize,
      ...(pageParam && { next_page_token: pageParam }),
    },
    requestSchema: ApplicationsDefinitionRequestSchema,
    responseSchema: ApplicationsDefinitionResponseSchema,
  });
};

export const reviewAppDnaApplicationDefinition = async (
  requestBody: z.infer<typeof ReviewApplicationsDefinitionRequestSchema>,
) => {
  return jsonApi({
    path: `appid/api/v1/app/definition`,
    config: {
      method: "PATCH",
    },
    body: requestBody,
    requestSchema: ReviewApplicationsDefinitionRequestSchema,
    responseSchema: z.any(),
  });
};

export const getAppDnaApplicationDefinition = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<
  ReturnType<(typeof appDna)["applicationDefinition"]>
>) => {
  return jsonApi({
    path: `appid/api/v1/app/definition/${id}`,
    requestSchema: z.any(),
    responseSchema: ApplicationsDefinitionWithMetadataResponseSchema,
  });
};
