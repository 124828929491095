/* c8 ignore start */
import { usePerformanceTracker, useRole } from "prisma";
import { PermissionDenied } from "ui";
import { PermissionGroupsTable } from "./PermissionGroupsTable/PermissionGroupsTable";

export function PermissionGroups() {
  const { syntheticRole } = useRole();
  usePerformanceTracker(
    "AccessControlPermissionGroupSettings",
    "AccessControlPermissionGroupSettings",
  );
  return syntheticRole === "System Admin" ? (
    <PermissionGroupsTable />
  ) : (
    <PermissionDenied />
  );
}
/* c8 ignore stop */
