import { defineMessages } from "react-intl";

import CheckboxFeatureCard from "../CheckboxFeatureCard";
import { type FeatureItemType } from "./FeatureGroup";

import { isChina } from "environment";
import { Body, Bold, DynamicFormattedMessage } from "ui";
import { type CloudType } from "../../../../types";

const messages = defineMessages({
  foundational: {
    defaultMessage: "<b>Foundational</b> <sub>(Recommended)</sub>",
    id: "ds9Lvz",
    description: "Category heading for features list",
  },
  advanced: {
    defaultMessage: "<b>Advanced</b> <sub>(Additional Capabilities)</sub>",
    id: "2wx6P+",
    description: "Category heading for features list",
  },
});

type FeaturesListViewPropTypes = {
  category: string;
  cloudType?: CloudType;
  isComputeWorkloadSupported?: boolean;
  features: FeatureItemType[];
  values: Record<string, boolean>;
  onChange: (...event: unknown[]) => void;
  orgValues?: Record<string, boolean>;
  onToggleChange?: (...event: unknown[]) => void;
  accountType?: string;
};

export default function FeaturesListView({
  category,
  cloudType,
  isComputeWorkloadSupported,
  features,
  values,
  onChange,
  orgValues,
  onToggleChange,
  accountType,
}: FeaturesListViewPropTypes) {
  return (
    <>
      <Body>
        <DynamicFormattedMessage
          messageMap={messages}
          token={category}
          values={{
            b: (chunks) => <Bold>{chunks}</Bold>,
            sub: (chunks) => (
              <Body as="span" appearance="secondary" size="sm">
                {chunks}
              </Body>
            ),
          }}
        />
      </Body>
      <div className="my-2 rounded border border-gray-300 bg-white dark:border-blue-steel-500 dark:bg-blue-steel-950">
        {features.map(({ value, toggleValue, ...rest }) => {
          if (!(isChina() && value === "Identity Security")) {
            return (
              <CheckboxFeatureCard
                key={value}
                {...rest}
                cloudType={cloudType}
                isComputeWorkloadSupported={isComputeWorkloadSupported}
                accountType={accountType}
                checked={!!values[value]}
                onChange={() => {
                  const newValues = { ...values };
                  newValues[value] = !newValues[value];
                  onChange(newValues);
                }}
                toggleChecked={Boolean(
                  toggleValue && orgValues && !!orgValues[toggleValue],
                )}
                onToggle={() => {
                  if (!toggleValue || !onToggleChange) {
                    return;
                  }
                  const newValues = { ...orgValues };
                  newValues[toggleValue] = !newValues[toggleValue];
                  onToggleChange(newValues);
                }}
              />
            );
          }
          return null;
        })}
      </div>
    </>
  );
}
