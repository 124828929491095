import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { isEmpty } from "remeda";
import { cloudAccountsKeys, saveAwsTempAccountRoleARN } from "requests";
import { useToastActions } from "stores";
import { Button, Input } from "ui";
import { Details } from "../../../../../../../components/ReviewStatus/AccountDetails/components/Details";
import { parseI18nKeyFromResponse } from "../../../../../../../utils";

export function RoleARN({
  accountName,
  setIsRoleArnSaved,
  roleArn,
}: {
  accountName: string;
  setIsRoleArnSaved?: (val: boolean) => void;
  roleArn?: string;
}) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [iamRoleArn, setIamRoleArn] = useState(roleArn ?? "");
  const { toast } = useToastActions();
  const [isRoleArnChanged, setIsRoleArnchanged] = useState(
    !isEmpty(roleArn) ? true : false,
  );
  const [isSubmitApiLoading, setIssubmitApiLoading] = useState(false);

  const { mutateAsync: onSubmit } = useMutation({
    mutationFn: () => {
      setIssubmitApiLoading(true);
      return saveAwsTempAccountRoleARN({
        payload: { name: accountName, roleArn: iamRoleArn },
      });
    },
    onSuccess: () => {
      setIsRoleArnchanged(true);
      toast(
        intl.formatMessage({
          defaultMessage: "RoleARN Saved Successfully!",
          id: "Dj1GoS",
          description: "success toast",
        }),
        { appearance: "success" },
      );
      queryClient.invalidateQueries({
        queryKey: cloudAccountsKeys.cloudAccountsTable({
          excludeAccountGroupDetails: "true",
          includePendingAccounts: "true",
        }),
      });
      setIsRoleArnSaved && setIsRoleArnSaved(true);
    },
    onError: (error) => {
      setIssubmitApiLoading(false);
      toast(parseI18nKeyFromResponse(intl, error), { appearance: "error" });
    },
  });
  return (
    <Details
      label={
        <FormattedMessage
          defaultMessage="IAM Role ARN"
          id="GgrDHz"
          description="Description for Role ARN on review status"
        />
      }
    >
      {isRoleArnChanged ? (
        <div>
          <FormattedMessage
            defaultMessage="{iamRoleArn}"
            id="svX06F"
            description="Description for Role ARN on review status"
            values={{
              iamRoleArn,
            }}
          />
        </div>
      ) : (
        <div className="flex">
          <div className="w-[31rem]">
            <Input
              placeholder={intl.formatMessage({
                defaultMessage: "Enter Role ARN",
                id: "sEKL2C",
                description: "Placeholder text for IAM Role ARN",
              })}
              onChange={(event: { target: { value: string } }) => {
                setIamRoleArn(event.target.value);
              }}
            />
          </div>
          <div className="w-24">
            <Button
              addClassName="ml-4"
              appearance="primary"
              onClick={() => onSubmit()}
              isLoading={isSubmitApiLoading}
            >
              <FormattedMessage
                defaultMessage="Submit"
                id="xJsK9Z"
                description="submit button label"
              />
            </Button>
          </div>
        </div>
      )}
    </Details>
  );
}
