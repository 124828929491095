import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../../apis";
import {
  ActionPlanSuggestionRequestSchema,
  AlertSuggestionRequestSchema,
  AssetExplorerSuggestionRequestSchema,
  ComplianceReportSuggestionRequestSchema,
  ComplianceStandardSuggestionRequestSchema,
  DataInventorySuggestionRequestSchema,
  GetActionPlanSuggestionsResponseSchema,
  GetAlertSuggestionsResponseSchema,
  GetAssetExplorerSuggestionsResponseSchema,
  GetComplianceReportSuggestionsResponseSchema,
  GetComplianceStandardSuggestionsResponseSchema,
  GetDataInventorySuggestionsResponseSchema,
  GetInventorySuggestionsResponseSchema,
  GetPolicySuggestionsResponseSchema,
  InventorySuggestionRequestSchema,
  PolicySuggestionRequestSchema,
  PostSuggestionsResponseSchema,
} from "./schemas";

const suggestionsAPIMap = {
  alertSuggestion: {
    path: "filter/alert/suggest",
    requestSchema: AlertSuggestionRequestSchema,
    responseSchema: GetAlertSuggestionsResponseSchema,
  },
  complianceSuggestion: {
    path: "filter/compliance/posture/suggest",
    requestSchema: ComplianceStandardSuggestionRequestSchema,
    responseSchema: GetComplianceStandardSuggestionsResponseSchema,
  },
  policySuggestion: {
    path: "filter/policy/suggest",
    requestSchema: PolicySuggestionRequestSchema,
    responseSchema: GetPolicySuggestionsResponseSchema,
  },
  reportSuggestion: {
    path: "filter/report/suggest",
    requestSchema: ComplianceReportSuggestionRequestSchema,
    responseSchema: GetComplianceReportSuggestionsResponseSchema,
  },
  assetExplorerSuggestion: {
    path: "filter/resource/scan_info/suggest",
    requestSchema: AssetExplorerSuggestionRequestSchema,
    responseSchema: GetAssetExplorerSuggestionsResponseSchema,
  },
  inventorySuggestion: {
    path: "filter/v2/inventory/suggest",
    requestSchema: InventorySuggestionRequestSchema,
    responseSchema: GetInventorySuggestionsResponseSchema,
  },
  dataInventorySuggestion: {
    path: "dlp/api/v1/inventory/filter/suggest",
    requestSchema: DataInventorySuggestionRequestSchema,
    responseSchema: GetDataInventorySuggestionsResponseSchema,
  },
  actionPlanSuggestion: {
    path: "apm/api/v1/filter/action-plan/suggest",
    requestSchema: ActionPlanSuggestionRequestSchema,
    responseSchema: GetActionPlanSuggestionsResponseSchema,
  },
};

export type SuggestionEntities = keyof typeof suggestionsAPIMap;

export type SuggestionKeyFunctionForPost = (props: {
  appliedFilters?: { filterName?: string; query?: string }[];
  filterName: string;
  query: string;
}) => {
  scope: "suggestions";
  entity: SuggestionEntities;
  appliedFilters?: { filterName?: string; query?: string }[];
  filterName: string;
  query: string;
}[];

type SuggestionKeyFunctionForGet = (
  entity: SuggestionEntities,
) => { scope: "suggestions"; entity: SuggestionEntities }[];

export const suggestionKeys = {
  all: [{ scope: "suggestions" }] as const,
  getItemSuggestions: (entity: SuggestionEntities) => [
    { ...suggestionKeys.all[0], entity },
  ],
  getItemSuggestionsFromPost: ({
    entity,
    appliedFilters = [],
    filterName,
    query,
  }: {
    entity: SuggestionEntities;
    appliedFilters: { filterName?: string; query?: string }[];
    filterName: string;
    query: string;
  }) => [
    {
      ...suggestionKeys.all[0],
      entity,
      appliedFilters,
      filterName,
      query,
    },
  ],
} as const;

export const getItemSuggestions = async ({
  queryKey: [{ entity }],
}: QueryFunctionContext<ReturnType<SuggestionKeyFunctionForGet>>) => {
  const { path, responseSchema } = suggestionsAPIMap[entity];
  return jsonApi({
    path,
    requestSchema: z.void(),
    // @ts-expect-error Dynamic values cause TS pain.
    responseSchema: responseSchema,
  });
};

export const itemSuggestionsFromPost = async ({
  queryKey: [{ appliedFilters, filterName, query, entity }],
}: QueryFunctionContext<ReturnType<SuggestionKeyFunctionForPost>>) => {
  const { path, requestSchema } = suggestionsAPIMap[entity];

  return jsonApi({
    path,
    body: { appliedFilters, filterName, query },
    config: {
      method: "post",
    },
    requestSchema: requestSchema,
    responseSchema: PostSuggestionsResponseSchema,
  });
};
