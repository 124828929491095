import {
  type AppDnaTagsFilters,
  type ApplicationSidecarFilters,
} from "./applications";
import {
  type ApplicationBusinessCriticality,
  type ApplicationEnvironment,
} from "./applicationsDefinition";
import {
  type ApplicationGraphRisksSearchRequestType,
  type ApplicationGraphServiceDetailsSearchRequestType,
  type ApplicationGraphServicesSearchRequestType,
  type ApplicationsGraphSearchRequestType,
} from "./graph";

export const appDna = {
  all: [{ scope: "appDna" }] as const,
  applicationsDefinition: ({
    pageSize,
    reviewed,
  }: {
    pageSize: string;
    reviewed: "true" | "false";
  }) =>
    [
      {
        pageSize,
        reviewed,
        ...appDna.all[0],
        entity: "applicationsDefinition",
      },
    ] as const,
  applicationDefinition: ({ id }: { id: string }) =>
    [
      {
        ...appDna.all[0],
        id,
        entity: "applicationDefinition",
      },
    ] as const,
  applications: ({
    pageSize,
    businessCriticality,
    environment,
  }: {
    pageSize: string;
    businessCriticality?: ApplicationBusinessCriticality;
    environment?: ApplicationEnvironment;
  }) =>
    [
      {
        pageSize,
        businessCriticality,
        environment,
        ...appDna.all[0],
        entity: "applications",
      },
    ] as const,
  applicationGroupedAssets: ({ id }: { id: string }) =>
    [
      {
        id,
        ...appDna.all[0],
        entity: "applicationAssets",
      },
    ] as const,
  applicationAssets: ({ id, assetType }: { id: string; assetType: string }) =>
    [
      {
        id,
        assetType,
        ...appDna.all[0],
        entity: "applicationAssets",
      },
    ] as const,
  applicationDetails: ({ id }: { id: string }) =>
    [{ id, ...appDna.all[0], entity: "applicationDetails" }] as const,
  applicationsGraphSearchResults: ({
    query,
    id,
  }: ApplicationsGraphSearchRequestType) =>
    [
      { query, id, ...appDna.all[0], entity: "applicationsGraphSearchResults" },
    ] as const,
  applicationGraphRisksSearch: ({
    query,
    applicationId,
  }: ApplicationGraphRisksSearchRequestType) =>
    [
      {
        query,
        applicationId,
        ...appDna.all[0],
        entity: "applicationGraphRisksSearch",
      },
    ] as const,
  applicationGraphServicesSearch: ({
    query,
    applicationId,
  }: ApplicationGraphServicesSearchRequestType) =>
    [
      {
        query,
        applicationId,
        ...appDna.all[0],
        entity: "applicationGraphServicesSearch",
      },
    ] as const,
  applicationGraphServiceDetailsSearch: ({
    query,
    applicationId,
    service,
  }: ApplicationGraphServiceDetailsSearchRequestType & { service: string }) =>
    [
      {
        query,
        applicationId,
        service,
        ...appDna.all[0],
        entity: "applicationGraphServiceDetailsSearch",
      },
    ] as const,
  applicationNudgeStatus: () =>
    [
      {
        ...appDna.all[0],
        entity: "applicationNudgeStatus",
      },
    ] as const,
  applicationCriteria: () =>
    [
      {
        ...appDna.all[0],
        entity: "applicationCriteria",
      },
    ] as const,
  applicationCriterion: ({ id }: { id: string }) =>
    [
      {
        ...appDna.all[0],
        id,
        entity: "applicationCriterion",
      },
    ] as const,
  applicationSummary: () =>
    [
      {
        ...appDna.all[0],
        entity: "applicationSummary",
      },
    ] as const,
  applicationsTags: ({
    appDefFilters,
  }: {
    appDefFilters?: AppDnaTagsFilters;
  }) =>
    [
      {
        ...appDna.all[0],
        ...appDefFilters,
        entity: "applicationsTags",
      },
    ] as const,
  applicationTagsDetails: ({ id }: { id: string }) =>
    [
      {
        ...appDna.all[0],
        id,
        entity: "applicationTagsDetails",
      },
    ] as const,
  applicationTags: () =>
    [
      {
        ...appDna.all[0],
        entity: "applicationTags",
      },
    ] as const,
  applicationSidecarAssets: ({
    payload,
    applicationId,
  }: {
    payload: ApplicationSidecarFilters;
    applicationId: string;
  }) =>
    [
      {
        ...appDna.all[0],
        payload,
        applicationId,
        entity: "applicationSidecarAssets",
      },
    ] as const,
  applicationSidecarAlerts: ({
    payload,
    applicationId,
  }: {
    payload: ApplicationSidecarFilters;
    applicationId: string;
  }) =>
    [
      {
        ...appDna.all[0],
        payload,
        applicationId,
        entity: "applicationSidecarAlerts",
      },
    ] as const,
  applicationSidecarVulnerabilities: ({
    payload,
    applicationId,
  }: {
    payload: ApplicationSidecarFilters;
    applicationId: string;
  }) =>
    [
      {
        ...appDna.all[0],
        payload,
        applicationId,
        entity: "applicationSidecarVulnerabilities",
      },
    ] as const,
  applicationAssetsByAssetClass: ({ id }: { id: string }) =>
    [
      {
        ...appDna.all[0],
        id,
        entity: "applicationAssetsByAssetClass",
      },
    ] as const,
  applicationVulnsByAssetClassWithCve: ({ id }: { id: string }) =>
    [
      {
        ...appDna.all[0],
        id,
        entity: "applicationVulnsByAssetClassWithCve",
      },
    ] as const,
  applicationVulnsByAssetClassWithAsset: ({ id }: { id: string }) =>
    [
      {
        ...appDna.all[0],
        id,
        entity: "applicationVulnsByAssetClassWithAsset",
      },
    ] as const,
  applicationVulnsByAssetClassWithCveForAssetCount: ({
    id,
    payload,
  }: {
    id: string;
    payload: ApplicationSidecarFilters;
  }) =>
    [
      {
        ...appDna.all[0],
        payload,
        id,
        entity: "applicationVulnsByAssetClassWithCveForAssetCount",
      },
    ] as const,
} as const;
