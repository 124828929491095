import { useQuery } from "@tanstack/react-query";
import { grayScale } from "colors";
import { UserAtIcon } from "icons";
import { AccountGroupSelect } from "prisma";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "remeda";
import { accountNameExists, cloudAccountsKeys } from "requests";
import { Body, Bold, Card, CardBody } from "ui";
import { type WizardStepState } from "../../../../../components/Wizard";
import { AWS, ORGANIZATION } from "../../../../constants";
import {
  useGetAwsPartitionItems,
  useIsRenderedFirstTime,
} from "../../../../hooks";
import { useAWSSelectMemberAccountsContext } from "../../../Onboarding/AwsOnboarding/context/AwsSelectMemberAccountsContext";
import { setAccountName } from "../../../Onboarding/AwsOnboarding/context/AwsSelectMemberAccountsContext/state/actions";

import ComputeLimitBanner from "../../../Onboarding/components/ComputeLimitBanner";
import { accountGroupsMultiSelect } from "../../../Onboarding/utils";
import AccountName from "../../components/AccountName";
import AWSConsoleSteps from "./AWSConsoleSteps";
import DeploymentType from "./DeploymentType";
import OnboardType from "./OnboardType";
import Scope from "./Scope";

type AddDetailsFormLayoutProps = {
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  customConfigurationStep: WizardStepState[];
  setCustomConfigEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AddDetailsFormLayout({
  setDisabled,
  customConfigurationStep,
  setCustomConfigEnabled,
}: AddDetailsFormLayoutProps) {
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const isFirstRender = useIsRenderedFirstTime();
  const { watch, setError, setValue, clearErrors, resetField, getFieldState } =
    useFormContext();
  const [name, onboardType, accountGroupDefaultValue, accountType] = watch([
    "name",
    "onboardType",
    "groupIds",
    "accountType",
  ]);

  const isAutomatedOnboardType = onboardType === "automated";

  const { data, refetch, isFetching } = useQuery({
    queryKey: cloudAccountsKeys.accountNameExists({ name }),
    queryFn: accountNameExists,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const { awsPartitionItems } = useGetAwsPartitionItems();
  const showPartitionSection = awsPartitionItems?.length > 1;

  const { dispatch: selectMemberAccountsDispatch } =
    useAWSSelectMemberAccountsContext();

  const onAccountNameBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    const value = event.target?.value;
    if (isAutomatedOnboardType) {
      setDisabled(true);
      if (!isFirstRender) {
        refetch();
      }
    } else {
      setDisabled(false);
      selectMemberAccountsDispatch(setAccountName(value ?? ""));
    }
  };

  useEffect(() => {
    if (isAutomatedOnboardType) {
      if (isFetching) {
        setDisabled(true);
      } else {
        const fieldState = getFieldState("name");
        if (fieldState?.error) return;

        if (!isEmpty(data) && data.exists) {
          setError("name", {
            message: "Cloud Account Name Already exists",
          });
        } else {
          setDisabled(false);
          clearErrors("name");
          selectMemberAccountsDispatch(setAccountName(name ?? ""));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const accountGroupsMultiSelectProps = {
    accountType,
    selectedCloud: AWS,
  };

  const enableMultiSelect = accountGroupsMultiSelect({
    ...accountGroupsMultiSelectProps,
  });

  const defaultValue = useMemo(() => {
    if (enableMultiSelect) {
      if (!Array.isArray(accountGroupDefaultValue)) {
        resetField("groupIds", { defaultValue: [accountGroupDefaultValue] });
        setValue("groupIds", [accountGroupDefaultValue]);
        return [accountGroupDefaultValue];
      }
    } else {
      if (Array.isArray(accountGroupDefaultValue)) {
        resetField("groupIds", { defaultValue: accountGroupDefaultValue[0] });
        setValue("groupIds", accountGroupDefaultValue[0]);
        return accountGroupDefaultValue[0];
      }
    }
    return accountGroupDefaultValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableMultiSelect]);

  return (
    <>
      <OnboardType
        customConfigurationStep={customConfigurationStep}
        setCustomConfigEnabled={setCustomConfigEnabled}
      />
      <ComputeLimitBanner />
      <Card>
        <CardBody addClassName="space-y-4">
          {showPartitionSection && !isAutomatedOnboardType && (
            <DeploymentType />
          )}
          <Scope />
          <AccountName onBlur={onAccountNameBlur} isFetching={isFetching} />

          <section className="space-y-2">
            <Body
              size="sm"
              appearance="secondary"
              addClassName="mb-1 font-bold"
            >
              <FormattedMessage
                defaultMessage="Account Group"
                id="5Vw0qb"
                description="Label for account group select"
              />
            </Body>
            <AccountGroupSelect
              defaultValue={defaultValue}
              enableMultiSelect={enableMultiSelect}
              itemValue="id"
              name="groupIds"
              showLabel={false}
            />
          </section>
          {isAutomatedOnboardType && accountType === ORGANIZATION && (
            <section className="space-y-2">
              <Card>
                <CardBody>
                  <span className="flex">
                    <UserAtIcon
                      size="sm"
                      color={grayScale[600]}
                      className="mr-2"
                    />
                    <Bold>
                      <FormattedMessage
                        defaultMessage="All members accounts are selected by default as per Prisma Cloud Recommendation"
                        id="nCHYO9"
                        description="Message to be displayed for Generate template onboarding type"
                      />
                    </Bold>
                  </span>

                  <Body>
                    <FormattedMessage
                      defaultMessage="To customize choose the generate template onboard type"
                      id="OPSQ3G"
                      description="Message to be displayed for Generate template onboarding type"
                    />
                  </Body>
                </CardBody>
              </Card>
            </section>
          )}
        </CardBody>
      </Card>
      <AWSConsoleSteps />
    </>
  );
}
