import { cloneElement, type ReactElement, type ReactNode } from "react";
import { classNames } from "utils";
import { Breadcrumbs } from "../Breadcrumbs";
import { Tooltip } from "../Tooltip";
import { Title } from "../Typography";
export interface PageHeaderProps {
  /**
   * Page-level actions.
   */
  actions?: ReactNode;
  /**
   * An array of links to ancestor pages.
   */
  breadcrumbs?: ReactNode[];
  /**
   * An icon displayed next to the title.
   */
  icon?: ReactNode;
  /**
   * A subordinate title that provides additional information about the content on the page.
   */
  subtitle?: ReactNode;
  /**
   * The primary title of the page.
   */
  title: ReactNode;
  /**
   * This is generally the TabList component.
   *
   * If you have a tabList, you likely should not pass the filterBar prop.
   * Instead, use the TabPanelLayout and pass it's filterBar prop.
   */
  tabList?: ReactElement;
  /**
   * Enable horizontal scrolling.
   */
  enableScrolling?: boolean;
}

/**
 * Page header displays the top level page content, including:
 *
 * * Title
 * * Subtitle
 * * Actions
 * * Breadcrumbs
 *
 * ### Import Guide
 *
 * ```jsx
 * import { Button, PageHeader } from "ui";
 *
 * // Import breadcrumb links from your local Link component
 * // that is connected to your router.
 * ```
 */
export function PageHeader({
  actions,
  breadcrumbs,
  icon,
  title,
  tabList,
  subtitle,
  enableScrolling = true,
}: PageHeaderProps): ReactElement {
  const clonedTabList = tabList
    ? cloneElement(tabList, { isHeader: true })
    : null;
  return (
    <div
      className={classNames(
        "flex items-center justify-between p-6",
        enableScrolling && !!tabList && "overflow-x-auto",
      )}
    >
      <div
        className={classNames("flex flex-row", !enableScrolling && "w-[99%]")}
      >
        <div
          className={classNames(
            "flex items-center space-x-2",
            !enableScrolling && "w-5/6",
          )}
        >
          {icon && <div className="mr-2">{icon}</div>}
          {subtitle ? (
            <Tooltip label={subtitle}>
              <div>
                <Title
                  level={1}
                  addClassName="text-xs font-normal uppercase leading-6 tracking-[0.24rem]"
                >
                  {title}
                </Title>
              </div>
            </Tooltip>
          ) : (
            <Title
              level={1}
              addClassName="text-xs font-normal uppercase leading-6 tracking-[0.24rem]"
            >
              {title}
            </Title>
          )}

          {clonedTabList && (
            <div className="after:border after:dark:border-gray-900" />
          )}
          {breadcrumbs && breadcrumbs.length > 0 && (
            <div className="after:border after:dark:border-gray-900" />
          )}

          {breadcrumbs && breadcrumbs.length > 0 ? (
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          ) : (
            clonedTabList && (
              <div
                className={classNames(
                  "flex bg-white dark:bg-blue-steel-990",
                  !enableScrolling && "w-full",
                )}
              >
                {clonedTabList}
              </div>
            )
          )}
        </div>
      </div>
      {actions && <div className="flex space-x-1">{actions}</div>}
    </div>
  );
}
