import { useIntl } from "react-intl";
import { SvgIcon, type SvgIconProps } from "../components/SvgIcon";

export const ViewModeIcon = (props: SvgIconProps) => {
  const intl = useIntl();
  const alt = intl.formatMessage({
    defaultMessage: "View Mode",
    id: "to6R0k",
    description: "View Mode Icon alt text",
  });
  const className = "fill-[#006FCC] dark:fill-[#38A5FF]";

  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <title>{alt}</title>
      <path
        d="M21 7C21 4.79086 19.2091 3 17 3C14.7909 3 13 4.79086 13 7C13 9.20914 14.7909 11 17 11C19.2091 11 21 9.20914 21 7ZM19.5 7C19.5 8.38071 18.3807 9.5 17 9.5C15.6193 9.5 14.5 8.38071 14.5 7C14.5 5.61929 15.6193 4.5 17 4.5C18.3807 4.5 19.5 5.61929 19.5 7ZM7 21C4.79086 21 3 19.2091 3 17C3 14.7909 4.79086 13 7 13C9.20914 13 11 14.7909 11 17C11 19.2091 9.20914 21 7 21ZM7 19.5C8.38071 19.5 9.5 18.3807 9.5 17C9.5 15.6193 8.38071 14.5 7 14.5C5.61929 14.5 4.5 15.6193 4.5 17C4.5 18.3807 5.61929 19.5 7 19.5Z"
        fill={props?.fill}
        className={`${props?.fill ? "" : className}`}
      />
      <path
        d="M21 15.25C21 14.0074 19.9926 13 18.75 13H15.25C14.0074 13 13 14.0074 13 15.25V18.75C13 19.9926 14.0074 21 15.25 21H18.75C19.9926 21 21 19.9926 21 18.75V15.25Z"
        fill={props?.fill}
        className={`${props?.fill ? "" : className}`}
      />
      <path
        d="M11 5.25C11 4.00736 9.99264 3 8.75 3H5.25C4.00736 3 3 4.00736 3 5.25V8.75C3 9.99264 4.00736 11 5.25 11H8.75C9.99264 11 11 9.99264 11 8.75V5.25Z"
        fill={props?.fill}
        className={`${props?.fill ? "" : className}`}
      />
    </SvgIcon>
  );
};
