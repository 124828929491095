import { isChina, isGov } from "environment";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useIsBusinessLicenseType } from "prisma";
import { useMemo } from "react";
import { useIsSystemAdmin } from "requests";

export const useIsK8sSatelliteSupported = (): boolean => {
  const { k8SSatellite } = useFlags();
  const sysAdmin = useIsSystemAdmin();
  const { isBusinessLicenseType } = useIsBusinessLicenseType();
  return useMemo(
    () =>
      k8SSatellite &&
      sysAdmin &&
      !isBusinessLicenseType &&
      !isChina() &&
      !isGov(),
    [isBusinessLicenseType, k8SSatellite, sysAdmin],
  );
};
