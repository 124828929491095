import * as schemas from "../../schemas";
import {
  ApplicationBusinessCriticalityEnumSchema,
  ApplicationEnvironmentEnumSchema,
} from "../appDna";
import {
  CloudTypesEnumSchema,
  CloudTypesWithOtherEnumSchema,
  cloudTypes,
} from "../cloudType";
import {
  PolicyCategoryEnumSchema,
  PolicySubTypesEnumSchema,
  PolicyTypesEnumSchema,
  SeverityEnumSchema,
  type PolicySchema,
} from "../policies";
import { AssetGraphResponseSchema, type FindingNodeSchema } from "../rql";

import { z } from "zod";
import { TimeRangeSchema } from "../../schemas";
import { AlertRulesSchema } from "../alertRule/schemas";
import { copilotRequestTypes } from "../copilot";

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof literalSchema>;
type Json = Literal | { [key: string]: Json } | Json[];
const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]),
);

export const groupByList = [
  "policy.label",
  "policy.name",
  "policy.type",
  "policy.severity",
  "compliance.standard",
] as const;

export const AlertsGroupBySchema = z.enum(groupByList);

export const policyAlertFields = [
  "resource.id",
  "policy.name",
  "resource.name",
  "policy.type",
  "policy.subType",
  "timeRange.type",
] as const;
export const PolicyAlertFieldsEnumSchema = z.enum(policyAlertFields);

export const alertStatus = [
  "open",
  "dismissed",
  "snoozed",
  "resolved",
  "pending_resolution",
] as const;
export const AlertStatusEnumSchema = z.enum(alertStatus);

export const alertSummaryCategories = [
  "incidents",
  "attack-path",
  "misconfigurations",
  "exposures",
  "iam",
  "data-security",
] as const;
export const AlertSummaryCategoriesSchema = z.enum(alertSummaryCategories);

export const policyCloudTypes = [...cloudTypes] as const;
export const PolicyCloudTypesEnumSchema = z.enum(policyCloudTypes);

export const timeRangeTypes = [
  "ALERT_OPENED",
  "ALERT_UPDATED",
  "ALERT_STATUS_UPDATED",
] as const;
export const TimeRangeTypeEnumSchema = z.enum(timeRangeTypes);
export type TimeRangeType = z.infer<typeof TimeRangeTypeEnumSchema>;

export const objectExposure = ["private", "public", "conditional"] as const;
export const ObjectExposureEnumSchema = z.enum(objectExposure);

export const cloudService = [
  "amazon s3",
  "azure blob storage",
  "amazon rds",
] as const;
export const CloudServiceEnumSchema = z.enum(cloudService);

export const gitProviders = [
  "github",
  "gitlab",
  "bitbucket",
  "perforce",
] as const;
export const GitProvidersEnumSchema = z.enum(gitProviders);

export const iacFrameworks = ["Terraform", "CloudFormation"] as const;
export const IACFrameworksEnumSchema = z.enum(iacFrameworks);

const ApplicationMetadata = z
  .array(
    z.object({
      id: z.string(),
      name: z.string(),
      environment: ApplicationEnvironmentEnumSchema,
      businessCriticality: ApplicationBusinessCriticalityEnumSchema,
      owner: z.string(),
    }),
  )
  .optional();

const auditType = [
  "Capability",
  "Docker",
  "Filesystem",
  "Firewall",
  "Kubernetes",
  "Network",
  "Processes",
  "Swarm",
  "Syscalls",
] as const;
export const AuditTypeEnumSchema = z.enum(auditType);

const AnomalyType = [
  "login_anomaly",
  "uba_anomaly",
  "autofocus_network_anomaly",
  "https_data_exfil_network_anomaly",
  "tor_network_anomaly",
  "brute_force_anomaly",
  "impossible_time_travel_desc",
  "unusual_user_fingerprint_desc",
  "crypto_jacking_anomaly",
  "event_itt_anomaly",
  "event_tor_anomaly",
  "suspicious_activity_anomaly",
  "azure_td_anomaly",
  "cred_compromise_activity_anomaly",
  "recon_activity_anomaly",
  "portscan_network_anomaly",
  "portsweep_network_anomaly",
  "spambot_network_anomaly",
  "unusual_port_network_anomaly",
  "unusual_proto_network_anomaly",
  "dns_crypto_anomaly",
  "dns_dga_anomaly",
  "dns_rebind_anomaly",
] as const;

export const AlertsAnomalyTypeSchema = z.enum(AnomalyType);

export const AlertsOverviewFilterSchema = z
  .object({
    "account.group": z.array(z.string()),
    "alert.id": z.array(z.string()),
    "alert.status": z.array(AlertStatusEnumSchema),
    "alertRule.name": z.array(z.string()),
    "asset.class": z.array(z.string()),
    "buildtime.resourceName": z.array(z.string()),
    "cloud.account": z.array(z.string()),
    "cloud.accountId": z.array(z.string()),
    "cloud.region": z.array(z.string()),
    "cloud.service": z.array(z.string()),
    "cloud.type": z.array(CloudTypesWithOtherEnumSchema),
    "git.filename": z.array(z.string()),
    "git.provider": z.array(GitProvidersEnumSchema),
    "git.repository": z.array(z.string()),
    "iac.framework": z.array(IACFrameworksEnumSchema),
    malware: z.array(z.literal("true")),
    "object.classification": z.array(z.string()),
    "object.exposure": z.array(ObjectExposureEnumSchema),
    "object.identifier": z.array(z.string()),
    "policy.category": z.array(PolicyCategoryEnumSchema),
    "policy.complianceStandard": z.array(z.string()),
    "policy.complianceRequirement": z.array(z.string()),
    "policy.complianceSection": z.array(z.string()),
    "policy.label": z.array(z.string()),
    "policy.name": z.array(z.string()),
    "policy.remediable": z.enum(["true", "false"]),
    "policy.severity": z.array(SeverityEnumSchema),
    "policy.subtype": z.array(PolicySubTypesEnumSchema),
    "policy.type": z.array(PolicyTypesEnumSchema),
    "resource.group": z.array(z.string()),
    "resource.id": z.array(z.string()),
    "resource.name": z.array(z.string()),
    "resource.type": z.array(z.string()),
    "timeRange.type": TimeRangeTypeEnumSchema,
    "vulnerability.severity": z.array(SeverityEnumSchema),
    "alert.resolvedInCloud": z.array(z.literal("true")),
    "resource.tagv2": z.array(z.string()),
    "resourceList.tag": z.array(z.string()).optional(), // TODO: remove optional after resourceListExplicitFilter flag is fully enabled
    timeRange: TimeRangeSchema,
  })
  .partial();

export const AlertsFiltersPostBodySchema = z.array(
  z.discriminatedUnion("name", [
    schemas.FilterSchema.extend({ name: z.literal("policy.id") }),
    schemas.FilterSchema.extend({ name: z.literal("account.group") }),
    schemas.FilterSchema.extend({ name: z.literal("account.groupId") }),
    schemas.FilterSchema.extend({ name: z.literal("alert.id") }),
    schemas.FilterSchema.extend({
      name: z.literal("alert.status"),
      value: AlertStatusEnumSchema,
    }),
    schemas.FilterSchema.extend({ name: z.literal("alert.resolutionReason") }),
    schemas.FilterSchema.extend({ name: z.literal("alertRule.name") }),
    schemas.FilterSchema.extend({ name: z.literal("asset.class") }),
    schemas.FilterSchema.extend({ name: z.literal("buildtime.resourceName") }),
    schemas.FilterSchema.extend({ name: z.literal("cloud.account") }),
    schemas.FilterSchema.extend({ name: z.literal("cloud.accountId") }),
    schemas.FilterSchema.extend({ name: z.literal("cloud.region") }),
    schemas.FilterSchema.extend({
      name: z.literal("cloud.service"),
    }),
    schemas.FilterSchema.extend({
      name: z.literal("cloud.type"),
      value: CloudTypesWithOtherEnumSchema,
    }),
    schemas.FilterSchema.extend({ name: z.literal("git.filename") }),
    schemas.FilterSchema.extend({
      name: z.literal("git.provider"),
      value: GitProvidersEnumSchema,
    }),
    schemas.FilterSchema.extend({ name: z.literal("git.repository") }),
    schemas.FilterSchema.extend({
      name: z.literal("iac.framework"),
      value: IACFrameworksEnumSchema,
    }),
    schemas.FilterSchema.extend({
      name: z.literal("malware"),
      value: z.literal("true"),
    }),
    schemas.FilterSchema.extend({ name: z.literal("object.classification") }),
    schemas.FilterSchema.extend({
      name: z.literal("object.exposure"),
      value: ObjectExposureEnumSchema,
    }),
    schemas.FilterSchema.extend({ name: z.literal("object.identifier") }),
    schemas.FilterSchema.extend({
      name: z.literal("policy.complianceStandard"),
    }),
    schemas.FilterSchema.extend({
      name: z.literal("policy.complianceStandardId"),
    }),
    schemas.FilterSchema.extend({
      name: z.literal("policy.complianceRequirement"),
    }),
    schemas.FilterSchema.extend({
      name: z.literal("policy.complianceSection"),
    }),
    schemas.FilterSchema.extend({ name: z.literal("policy.label") }),
    schemas.FilterSchema.extend({ name: z.literal("policy.name") }),
    schemas.FilterSchema.extend({
      name: z.literal("policy.remediable"),
      value: z.enum(["true", "false"]),
    }),
    schemas.FilterSchema.extend({
      name: z.literal("policy.severity"),
      value: SeverityEnumSchema,
    }),
    schemas.FilterSchema.extend({
      name: z.literal("policy.category"),
      value: PolicyCategoryEnumSchema,
    }),
    schemas.FilterSchema.extend({
      name: z.literal("policy.subtype"),
      value: PolicySubTypesEnumSchema,
    }),
    schemas.FilterSchema.extend({
      name: z.literal("policy.type"),
      value: PolicyTypesEnumSchema,
    }),
    schemas.FilterSchema.extend({ name: z.literal("resource.group") }),
    schemas.FilterSchema.extend({ name: z.literal("resource.id") }),
    schemas.FilterSchema.extend({ name: z.literal("resource.name") }),
    schemas.FilterSchema.extend({ name: z.literal("resource.type") }),
    schemas.FilterSchema.extend({
      name: z.literal("timeRange.type"),
      value: TimeRangeTypeEnumSchema,
    }),
    schemas.FilterSchema.extend({
      name: z.literal("vulnerability.severity"),
      value: SeverityEnumSchema,
    }),
    schemas.FilterSchema.extend({
      name: z.literal("alert.resolvedInCloud"),
      value: z.literal("true"),
    }),
    schemas.FilterSchema.extend({ name: z.literal("resource.tagv2") }),
    schemas.FilterSchema.extend({ name: z.literal("resourceList.tag") }),
  ]),
);

export type AlertsFiltersPostBodyType = z.infer<
  typeof AlertsFiltersPostBodySchema
>;

export const GetAlertsRequest = z.object({
  detailed: z.boolean(),
  webClient: z.boolean(),
  filters: AlertsFiltersPostBodySchema,
  timeRange: schemas.TimeRangeSchema,
});

export const AlertV2Schema = z.object({
  policyId: z.string(),
  saveSearchId: z.string().optional(),
  policy: z.object({
    policyId: z.string(),
    name: z.string(),
    policyType: PolicyTypesEnumSchema,
    policySubTypes: z.array(PolicySubTypesEnumSchema),
    systemDefault: z.boolean(),
    description: z.string().optional(),
    severity: SeverityEnumSchema,
    cloudType: CloudTypesWithOtherEnumSchema,
    complianceMetadata: z.array(
      z.object({
        standardName: z.string().optional(),
        standardDescription: z.string().optional(),
        requirementId: z.string().optional(),
        requirementName: z.string().optional(),
        sectionId: z.string().optional(),
        sectionDescription: z.string().optional(),
        policyId: z.string().optional(),
        complianceId: z.string().optional(),
        sectionViewOrder: z.number().optional(),
        requirementViewOrder: z.number().optional(),
        systemDefault: z.boolean().optional(),
        customAssigned: z.boolean().optional(),
      }),
    ),
    labels: z.array(z.string()),
    enabled: z.boolean(),
    createdOn: schemas.TimeStampSchema,
    createdBy: z.string(),
    lastModifiedOn: schemas.TimeStampSchema,
    lastModifiedBy: z.string(),
    ruleLastModifiedOn: schemas.TimeStampSchema,
    deleted: z.boolean(),
    remediable: z.boolean(),
  }),
  // alertRules: z.array(),
  alertCount: z.number().nonnegative(),
});
export type AlertV2 = z.infer<typeof AlertV2Schema>;

// https://pan.dev/prisma-cloud/api/cspm/alerts#operation/get-alerts-v2
export const GetAlertsResponse = z.array(AlertV2Schema);
export type AlertsV2Response = z.infer<typeof GetAlertsResponse>;

export const GetPolicyAlertsRequest = z.object({
  detailed: z.boolean(),
  filters: AlertsFiltersPostBodySchema.nonempty(),
  timeRange: schemas.TimeRangeSchema,
  limit: z.number().positive(),
  webClient: z.boolean(),
  pageToken: z.string().optional(),
});

export const AlertResourceSchema = z.object({
  policyScanConfigId: z.string().optional(),
  rrn: z.string().optional(),
  id: z.string().optional(),
  name: z.string().optional(),
  account: z.string().optional(),
  accountId: z.string().optional(),
  region: z.string().optional(),
  regionId: z.string().optional(),
  resourceType: z.string().optional(),
  resourceApiName: z.string().optional(),
  cloudServiceName: z.string().optional(),
  url: z.string().optional(),
  cloudType: CloudTypesWithOtherEnumSchema,
  resourceTs: schemas.TimeStampSchema,
  unifiedAssetId: z.string().optional(),
  resourceConfigJsonAvailable: z.boolean(),
  resourceDetailsAvailable: z.boolean(),
  data: jsonSchema.nullish(),
  internalResourceId: z.string().optional(),
  cloudAccountAncestors: z.array(z.string()).nullish(),
});

export const MetaDataSchema = z.object({
  saveSearchId: z.string().nullish(),
  subject: z.string().nullish(),
  bucketName: z.string().nullish(),
  bucketRrn: z.string().nullish(),
  owner: z.string().nullish(),
  classification: z.array(z.unknown()).nullish(),
  dataIdentifiers: z.array(z.unknown()).nullish(),
  malwarev2: z.string().nullish(),
  objectExposure: z.string().nullish(),
  objectTags: z.array(z.unknown()).nullish(),
  cveCritical: z.number().nullish(),
  cveHigh: z.number().nullish(),
  cveLow: z.number().nullish(),
  cveMedium: z.number().nullish(),
  remediable: z.boolean().nullish(),
});

export const AlertsMetadataForWorkloadVulnerabilitiesSchema = z.object({
  cveCritical: z.number().nullish(),
  cveHigh: z.number().nullish(),
  cveLow: z.number().nullish(),
  cveMedium: z.number().nullish(),
});

export const PaginatedItemResponse = z.object({
  id: z.string(),
  status: AlertStatusEnumSchema,
  reason: z.string(),
  firstSeen: schemas.TimeStampSchema,
  lastSeen: schemas.TimeStampSchema,
  alertTime: schemas.TimeStampSchema,
  lastUpdated: schemas.TimeStampSchema,
  policyId: z.string(),
  metadata: MetaDataSchema.nullish(),
  policy: z.object({
    policyId: z.string(),
    policyType: PolicyTypesEnumSchema,
    systemDefault: z.boolean(),
    remediable: z.boolean(),
  }),
  // alertRules: [],
  // history: [],
  resource: AlertResourceSchema,
  investigateOptions: z
    .object({
      alertId: z.string().optional(),
      endTs: z.number().optional(),
      searchId: z.string().optional(),
      startTs: z.number().optional(),
      hasSearchExecutionSupport: z.boolean().optional(),
    })
    .optional(),
  saveSearchId: z.string().optional(),
  anomalyDetail: z
    .object({
      investigateSupport: z.boolean().optional(),
    })
    .optional(),
});

export const PolicyAlertAllFilterItemResponse = z.object({
  id: z.string(),
  alertRules: z.array(z.string()).optional(),
  policy: z.object({
    findingTypes: z.array(z.string()).optional(),
    name: z.string(),
    policyType: PolicyTypesEnumSchema,
    remediable: z.boolean().optional(),
    severity: SeverityEnumSchema.optional(),
  }),
  resource: z.object({
    id: z.string(),
    name: z.string(),
    resourceConfigJsonAvailable: z.boolean(),
    resourceDetailsAvailable: z.boolean(),
    resourceTs: schemas.TimeStampSchema,
    cloudType: PolicyCloudTypesEnumSchema.optional(),
    cloudServiceName: z.string().optional(),
    service: z.string().optional(),
    account: z.string().optional(),
  }),
});

// https://pan.dev/prisma-cloud/api/cspm/alerts#operation/post-alerts-grouped
export const GetPolicyAlertsResponse = z.object({
  totalRows: z.number().nonnegative(),
  nextPageToken: z.string().optional(),
  sortAllowedColumns: z.array(z.string()),
  items: z.array(PaginatedItemResponse),
});

export const GetPolicyAlertAllFilterResponse = z.object({
  totalRows: z.number().nonnegative(),
  sortAllowedColumns: z.array(z.string()),
  items: z.array(PolicyAlertAllFilterItemResponse),
});

export const AlertHistorySchema = z.object({
  modifiedBy: z.string(),
  modifiedOn: z.number(),
  reason: z.string(),
  status: z.string(),
});

const AlertGrantersSchema = z.object({
  grantedByEntityType: z.string().optional(), // Only for source entity
  grantedByEntityName: z.string().optional(), // Only for source entity
  grantedByEntityAccount: z.string().optional(),
  grantedByEntityUai: z.string().optional(), // Only for source entity
  grantedByPolicyType: z.string(),
  grantedByPolicyName: z.string(),
  grantedByPolicyUai: z.string().optional(),
});
export type AlertGrantersType = z.infer<typeof AlertGrantersSchema>;

export const AlertMetadataSchema = z
  .object({
    remediable: z.boolean().optional(),
    iamData: z.object({
      account: z.string(),
      service: z.string().optional(), // Only for source entity
      resourceType: z.string().optional(), // Only for source entity
      triggeredByType: z.enum(["source", "granter"]),
      entities: z.array(AlertGrantersSchema),
    }),
  })
  .optional();

// https://pan.dev/prisma-cloud/api/cspm/alerts#operation/get-alert
export const GetAlertsDetailsResponse = z.object({
  id: z.string(),
  status: AlertStatusEnumSchema,
  reason: z.string(),
  firstSeen: schemas.TimeStampSchema,
  lastSeen: schemas.TimeStampSchema,
  alertTime: schemas.TimeStampSchema,
  policy: z.object({
    policyId: z.string(),
    policyType: PolicyTypesEnumSchema,
    systemDefault: z.boolean(),
    remediable: z.boolean(),
    recommendation: z.string().nullish(),
  }),
  alertRules: AlertRulesSchema,
  history: z.array(AlertHistorySchema).optional(),
  resource: AlertResourceSchema,
  investigateOptions: z
    .object({
      alertId: z.string(),
      endTs: z.number().optional(),
      searchId: z.string().optional(),
      startTs: z.number().optional(),
      hasSearchExecutionSupport: z.boolean().optional(),
    })
    .optional(),
  networkAnomaly: z.boolean(),
  actionLog: z.string().optional(),
  alertAttribution: z
    .object({
      attributionEventList: z
        .array(
          z.object({
            event: jsonSchema.optional(),
          }),
        )
        .optional(),
      resourceCreatedBy: z.string().optional(),
      resourceCreatedOn: z.number().optional(),
    })
    .optional(),
  dismissedBy: z.string().optional(),
  dismissalNote: z.string().optional(),
  recommendation: z.string().nullish(),
  appMetadata: ApplicationMetadata,
  metadata: AlertMetadataSchema,
});

export const GetAlertByIdForConfigResponse = z.object({
  id: z.string(),
  status: AlertStatusEnumSchema,
  reason: z.string(),
  firstSeen: schemas.TimeStampSchema,
  lastSeen: schemas.TimeStampSchema,
  alertTime: schemas.TimeStampSchema,
  saveSearchId: z.string(),
  metadata: z.object({
    saveSearchId: z.string(),
    code_security_url: z.string().optional(),
    buildtime_resource_name: z.string().optional(),
    framework: z.string().optional(),
    git_org: z.string().optional(),
    git_repository: z.string().optional(),
    git_provider: z.string().optional(),
    git_url: z.string().optional(),
    git_directory: z.string().optional(),
    git_filename: z.string().optional(),
    git_last_modified_by: z.string().optional(),
    git_modifiers: z.array(z.string()).optional(),
    git_last_modified_time: schemas.TimeStampSchema.nullish(),
    yor_trace: z.string().optional(),
  }),
  history: z.array(AlertHistorySchema).optional(),
  policy: z.object({
    policyId: z.string(),
    policyType: PolicyTypesEnumSchema,
    systemDefault: z.boolean(),
    remediable: z.boolean(),
    severity: SeverityEnumSchema,
    remediation: z
      .object({
        description: z.string(),
        impact: z.string().optional(),
        cliScriptTemplate: z.string(),
      })
      .nullish(),
    findingTypes: z.array(z.string()).optional(),
    recommendation: z.string().nullish(),
  }),
  alertRules: AlertRulesSchema,
  resource: AlertResourceSchema,
  networkAnomaly: z.boolean(),
  alertAttribution: z
    .object({
      resourceCreatedOn: schemas.TimeStampSchema,
      resourceCreatedBy: z.string(),
      attributionEventList: z
        .array(
          z.object({
            event: jsonSchema.optional(),
          }),
        )
        .optional(),
    })
    .nullish(),
  dismissedBy: z.string().optional(),
  dismissalNote: z.string().optional(),
  actionLog: z.string().optional(),
  recommendation: z.string().nullish(),
  appMetadata: ApplicationMetadata,
});

export const GetAlertByIdForWorkloadVulnerabilityResponse = z.object({
  id: z.string(),
  status: AlertStatusEnumSchema,
  reason: z.string(),
  firstSeen: schemas.TimeStampSchema,
  lastSeen: schemas.TimeStampSchema,
  alertTime: schemas.TimeStampSchema,
  eventOccurred: z.number().nullish(),
  saveSearchId: z.string().nullish(),
  metadata: AlertsMetadataForWorkloadVulnerabilitiesSchema.merge(
    z.object({
      source: z.string().nullish(),
    }),
  ),
  policy: z.object({
    policyId: z.string(),
    policyType: PolicyTypesEnumSchema,
    systemDefault: z.boolean(),
    severity: z.string(),
    remediable: z.boolean(),
    recommendation: z.string().nullish(),
  }),
  alertRules: AlertRulesSchema,
  history: z.array(AlertHistorySchema).nullish(),
  resource: AlertResourceSchema.merge(
    z.object({
      additionalInfo: z.object({
        hosts: z.array(z.string()).nullish(),
        labels: z.array(z.string()).nullish(),
      }),
    }),
  ),
  networkAnomaly: z.boolean(),
  recommendation: z.string().nullish(),
  appMetadata: ApplicationMetadata,
});

export const WorkloadIncidentMetadataSchema = z.object({
  incidentCategory: z.string().nullish(),
  incidentCount: z.number().nullish(),
  firstIncidentTime: schemas.TimeStampSchema,
  lastIncidentTime: schemas.TimeStampSchema,
  auditCount: z.number().nullish(),
  auditType: AuditTypeEnumSchema.nullish(),
  auditMessage: z.string().nullish(),
  auditTime: schemas.TimeStampSchema,
  auditAttackTechniques: z.array(z.string()).nullish(),
  auditRuleName: z.string().nullish(),
  incidentCustomRuleName: z.string().nullish(),
  auditUser: z.string().nullish(),
});

export const GetAlertByIdForWorkloadIncidentsResponse = z.object({
  id: z.string(),
  status: AlertStatusEnumSchema,
  reason: z.string(),
  firstSeen: schemas.TimeStampSchema,
  lastSeen: schemas.TimeStampSchema,
  alertTime: schemas.TimeStampSchema,
  eventOccurred: z.number().nullish(),
  saveSearchId: z.string().nullish(),
  metadata: WorkloadIncidentMetadataSchema.nullish(),
  policy: z.object({
    policyId: z.string(),
    policyType: PolicyTypesEnumSchema,
    systemDefault: z.boolean(),
    severity: z.string(),
    remediable: z.boolean(),
    recommendation: z.string().nullish(),
  }),
  alertRules: AlertRulesSchema,
  history: z.array(AlertHistorySchema),
  resource: AlertResourceSchema.merge(
    z.object({
      additionalInfo: z
        .object({
          hosts: z.array(z.string()),
          labels: z.array(z.string()).nullish(),
        })
        .nullish(),
    }),
  ),
  networkAnomaly: z.boolean(),
  recommendation: z.string().nullish(),
  appMetadata: ApplicationMetadata,
});

export const ConnectionDetailsSchema = z.object({
  timestamp: schemas.TimeStampSchema,
  accountName: z.string(),
  srcIp: z.string(),
  destIp: z.string(),
  srcIsp: z.string(),
  destIsp: z.string(),
  feedSource: z.string(),
  destPort: z.number(),
  classification: z.string(),
  trafficVolume: z.number(),
  inboundTrafficVolume: z.number(),
  outboundTrafficVolume: z.number(),
  packets: z.number(),
  inboundPackets: z.number(),
  outboundPackets: z.number(),
  accepted: z.string(),
  markedThreatTs: schemas.TimeStampSchema.optional(),
  threatDescription: z.string(),
});

export const GetAlertByIdForNetworkResponse = z.object({
  id: z.string(),
  status: AlertStatusEnumSchema,
  reason: z.string(),
  firstSeen: schemas.TimeStampSchema,
  lastSeen: schemas.TimeStampSchema,
  alertTime: schemas.TimeStampSchema,
  eventOccurred: z.number().nullish(),
  saveSearchId: z.string().nullish(),
  metadata: z
    .object({ saveSearchId: z.string(), subject: z.string() })
    .nullish(),
  policy: z.object({
    policyId: z.string(),
    policyType: PolicyTypesEnumSchema,
    systemDefault: z.boolean(),
    severity: z.string(),
    remediable: z.boolean(),
    recommendation: z.string().nullish(),
  }),
  alertRules: AlertRulesSchema,
  history: z.array(AlertHistorySchema).nullish(),
  resource: AlertResourceSchema.merge(
    z.object({
      cloudAccountAncestors: z.array(z.string()).nullish(),
      additionalInfo: z
        .object({
          eventOccurred: z.string(),
          triggeredBy: z.string(),
        })
        .nullish(),
      data: z
        .object({
          id: z.string(),
          kind: z.string(),
          name: z.string(),
          tags: z.object({
            items: z.array(z.string()),
            fingerprint: z.string(),
          }),
          zone: z.string(),
          disks: z.array(
            z.object({
              boot: z.boolean(),
              kind: z.string(),
              mode: z.string(),
              type: z.string(),
              index: z.number(),
              source: z.string(),
              licenses: z.array(z.string()),
              interface: z.string(),
              autoDelete: z.boolean(),
              deviceName: z.string(),
              diskSizeGb: z.string(),
              architecture: z.string(),
              guestOsFeatures: z.array(z.object({ type: z.string() })),
            }),
          ),
          labels: z.object({
            dnd_365: z.string(),
            purpose: z.string(),
            created_by: z.string(),
            dnd_automation: z.string(),
          }),
          status: z.string(),
          metadata: z.object({
            kind: z.string(),
            items: z.array(z.object({ key: z.string(), value: z.string() })),
            fingerprint: z.string(),
          }),
          selfLink: z.string(),
          scheduling: z.object({
            preemptible: z.boolean(),
            automaticRestart: z.boolean(),
            onHostMaintenance: z.string(),
            provisioningModel: z.string(),
          }),
          cpuPlatform: z.string(),
          description: z.string(),
          fingerprint: z.string(),
          machineType: z.string(),
          canIpForward: z.boolean(),
          startRestricted: z.boolean(),
          labelFingerprint: z.string(),
          creationTimestamp: z.string(),
          networkInterfaces: z.array(
            z.object({
              kind: z.string(),
              name: z.string(),
              network: z.string(),
              networkIP: z.string(),
              stackType: z.string(),
              subnetwork: z.string(),
              fingerprint: z.string(),
              accessConfigs: z.array(
                z.object({
                  kind: z.string(),
                  name: z.string(),
                  type: z.string(),
                  natIP: z.string(),
                  networkTier: z.string(),
                }),
              ),
            }),
          ),
          deletionProtection: z.boolean(),
          lastStartTimestamp: z.string(),
          shieldedInstanceConfig: z.object({
            enableVtpm: z.boolean(),
            enableSecureBoot: z.boolean(),
            enableIntegrityMonitoring: z.boolean(),
          }),
          shieldedInstanceIntegrityPolicy: z.object({
            updateAutoLearnPolicy: z.boolean(),
          }),
        })
        .nullish(),
    }),
  ),
  triggeredBy: z.string().nullish(),
  investigateOptions: z.object({
    alertId: z.string(),
    endTs: z.number().nullish(),
    searchId: z.string().nullish(),
    startTs: z.number().nullish(),
  }),
  networkAnomaly: z.boolean(),
  actionLog: z.string().nullish(),
  alertAttribution: z
    .object({
      attributionEventList: z
        .array(
          z.object({
            event: jsonSchema.nullish(),
          }),
        )
        .nullish(),
      resourceCreatedBy: z.string().nullish(),
      resourceCreatedOn: z.number().nullish(),
    })
    .optional(),
  dismissedBy: z.string().nullish(),
  dismissalNote: z.string().nullish(),
  recommendation: z.string().nullish(),
  appMetadata: ApplicationMetadata,
  connectionDetails: z.array(ConnectionDetailsSchema).nullish(),
});

export const GetAlertByIdForPolicyTypeDataResponse = z.object({
  id: z.string(),
  status: AlertStatusEnumSchema,
  reason: z.string(),
  firstSeen: schemas.TimeStampSchema,
  lastSeen: schemas.TimeStampSchema,
  alertTime: schemas.TimeStampSchema,
  eventOccurred: z.number(),
  saveSearchId: z.string(),
  metadata: z.object({
    saveSearchId: z.string(),
    subject: z.string(),
    bucketRrn: z.string().nullish(),
    bucketName: z.string().nullish(),
  }),
  policy: z.object({
    policyId: z.string(),
    policyType: PolicyTypesEnumSchema,
    systemDefault: z.boolean(),
    severity: z.string(),
    remediable: z.boolean(),
    recommendation: z.string().nullish(),
  }),
  alertRules: AlertRulesSchema,
  history: z.array(AlertHistorySchema).nullish(),
  resource: AlertResourceSchema.merge(
    z.object({
      cloudAccountAncestors: z.array(z.string()).nullish(),
      additionalInfo: z.object({
        eventOccurred: z.string(),
        triggeredBy: z.string(),
      }),
    }),
  ),
  triggeredBy: z.string(),
  investigateOptions: z.object({
    alertId: z.string(),
    endTs: z.number().nullish(),
    searchId: z.string().nullish(),
    startTs: z.number().nullish(),
  }),
  networkAnomaly: z.boolean(),
  actionLog: z.string().nullish(),
  alertAttribution: z
    .object({
      attributionEventList: z
        .array(
          z.object({
            event: jsonSchema.nullish(),
          }),
        )
        .nullish(),
      resourceCreatedBy: z.string().nullish(),
      resourceCreatedOn: z.number().nullish(),
    })
    .optional(),
  dismissedBy: z.string().nullish(),
  dismissalNote: z.string().nullish(),
  recommendation: z.string().nullish(),
  appMetadata: ApplicationMetadata,
});

const csvDownloadStatus = [
  "IN_PROGRESS",
  "READY_TO_DOWNLOAD",
  "FAILED",
] as const;
export const CSVDownloadStatusEnum = z.enum(csvDownloadStatus);

export const ValueSchema = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.record(z.unknown()),
  z.undefined(),
]);

export const AggregatedValueSchema = z.union([
  ValueSchema,
  z.array(ValueSchema),
  z.record(z.union([ValueSchema, z.array(ValueSchema)])),
]);

export const FilterTableTypeResponse = z.array(
  z.object({
    createdBy: z.string(),
    createdOn: z.number(),
    customerId: z.number(),
    description: z.union([z.string(), z.undefined()]).optional(),
    filterId: z.string(),
    filterTarget: z.string(),
    lastModifiedBy: z.string(),
    name: z.string(),
    filters: z.array(
      z.object({
        name: z.string(),
        operator: z.literal("="),
        value: AggregatedValueSchema,
      }),
    ),
  }),
);

export const GetAlertByIdForAuditEventResponse = z.object({
  id: z.string(),
  status: AlertStatusEnumSchema,
  reason: z.string(),
  firstSeen: schemas.TimeStampSchema,
  lastSeen: schemas.TimeStampSchema,
  alertTime: schemas.TimeStampSchema,
  eventOccurred: z.number(),
  saveSearchId: z.string(),
  metadata: z.object({ saveSearchId: z.string(), subject: z.string() }),
  policy: z.object({
    policyId: z.string(),
    policyType: PolicyTypesEnumSchema,
    systemDefault: z.boolean(),
    severity: z.string(),
    remediable: z.boolean(),
    recommendation: z.string().nullish(),
  }),
  alertRules: AlertRulesSchema,
  history: z.array(AlertHistorySchema).nullish(),
  resource: AlertResourceSchema.merge(
    z.object({
      additionalInfo: z.object({
        eventOccurred: z.string(),
        triggeredBy: z.string(),
      }),
    }),
  ),
  triggeredBy: z.string(),
  investigateOptions: z.object({
    alertId: z.string(),
    endTs: z.number().nullish(),
    searchId: z.string().nullish(),
    startTs: z.number().nullish(),
  }),
  networkAnomaly: z.boolean(),
  actionLog: z.string().nullish(),
  alertAttribution: z
    .object({
      attributionEventList: z
        .array(
          z.object({
            event: jsonSchema.nullish(),
          }),
        )
        .nullish(),
      resourceCreatedBy: z.string().nullish(),
      resourceCreatedOn: z.number().nullish(),
    })
    .optional(),
  dismissedBy: z.string().nullish(),
  dismissalNote: z.string().nullish(),
  recommendation: z.string().nullish(),
});

export const AnomalyDetailSchema = z.object({
  customerId: z.number(),
  type: z.union([AlertsAnomalyTypeSchema, z.string()]).nullish(),
  title: z.string(),
  id: z.string().nullish(),
  description: z.string().nullish(),
  eventId: z.number().nullish(),
  accountName: z.string().nullish(),
  region: z.string().nullish(),
  subject: z.string().nullish(),
  action: z.string().nullish(),
  resource: z.string().nullish(),
  time: schemas.TimeStampSchema,
  groupedAnomalyCount: z.number().nullish(),
  status: z.string().nullish(),
  dismissedUntil: schemas.TimeStampSchema.nullish(),
  dismissedBy: z.string().nullish(),
  investigateSupport: z.boolean().nullish(),
  int_ext: z.string().nullish(),
  srcHost: z
    .object({
      redlockResourceDetails: z
        .object({
          rrn: z.string().optional(),
          resourceName: z.string().optional(),
        })
        .optional(),
      ip: z.string().optional(),
      geoState: z.string().optional(),
      geoCountry: z.string().optional(),
      autoFocusLink: z.string().nullish(),
    })
    .nullish(),
  targetHost: z
    .object({
      geoState: z.string().optional(),
      geoCountry: z.string().optional(),
      details: z
        .array(
          z.object({
            rrn: z.string().nullish(),
            host: z.string().nullish(),
          }),
        )
        .nullish(),
      redlockResourceDetails: z
        .object({
          rrn: z.string().optional(),
          resourceName: z.string().optional(),
          resourceId: z.number().nullish(),
        })
        .optional(),
      ip: z.string().nullish(),
      port: z.number().nullish(),
      totalHosts: z.number().nullish(),
      autoFocusLink: z.string().nullish(),
    })
    .nullish(),
  severity: SeverityEnumSchema.nullish(),
});

export const ReconNodesSchema = z.object({
  sourceIp: z.string(),
  sourceLocation: z.string(),
  role: z.string(),
  accessDenied: z.string(),
  sampleProviderIds: z.union([z.array(z.string()), z.string()]),
  sampleApis: z.union([z.array(z.string()), z.string()]),
  sampleServices: z.union([z.array(z.string()), z.string()]),
});

export const PortScannedDetailsSchema = z.object({
  ports: z.array(z.string()).optional(),
  description: z.string().optional(),
});

export const HostScannedDetailSchema = z.object({
  host: z.string().nullish(),
  rrn: z.string().nullish(),
  unifiedAssetId: z.string().nullish(),
});

export const UsualPortsDetailedSchema = z.object({
  protocol: z.string().optional(),
  ports: z.union([z.array(z.string()).optional(), z.string().optional()]),
});

export const DetailNodesSchema = z.object({
  node: z.string().optional(),
  torCount: z.number().optional(),
  location: z.string().optional(),
  successCount: z.number().optional(),
  failureCount: z.number().optional(),
  eventIds: z.array(z.string()).optional(),
  eventNames: z.array(z.string()).optional(),
});

export const ResolvedQuerySchema = z.object({
  ips: z.array(z.string()).optional(),
  status: z.string().optional(),
  query: z.string().optional(),
});

export const UnusualDetailsSchema = z.object({
  details: z.array(
    z.object({
      node: z.string().optional(),
      torCount: z.number().optional(),
    }),
  ),
});

export const AnomalyDetailFeatureSchema = z.object({
  additionalThreatGroups: z.string().nullish(),
  id: z.number().optional(),
  name: z.string().nullish(),
  context: z.string().nullish(),
  highlight_context: z.boolean().nullish(),
  usual: z.array(z.string()).nullish(),
  usualProtocols: z.union([
    z.array(z.string()).optional(),
    z.string().optional(),
  ]),
  unusual: z.union([z.string().nullish(), UnusualDetailsSchema]).nullish(),
  anomaly: z.boolean().nullish(),
  portsScanned: z
    .object({
      totalPorts: z.number().nullish(),
      details: z.array(PortScannedDetailsSchema).nullish(),
    })
    .optional(),
  hostsScanned: z
    .object({
      totalHosts: z.number().nullish(),
      details: z.array(HostScannedDetailSchema).nullish(),
    })
    .optional(),
  usualPortsDetailed: z
    .object({
      usualPortCount: z.number(),
      details: z.array(UsualPortsDetailedSchema).optional(),
    })
    .optional(),
  details: z
    .object({
      usageCount: z.number().optional(),
      torCount: z.number().optional(),
      reconNodes: z.array(ReconNodesSchema).optional(),
      nodes: z.array(DetailNodesSchema).optional(),
    })
    .optional(),
  resolvedFqdns: z
    .object({
      queryCount: z.number().nullish(),
      details: z.array(ResolvedQuerySchema).nullish(),
    })
    .nullish(),
  query: z
    .object({
      queryCount: z.number().optional(),
      details: z.array(ResolvedQuerySchema).optional(),
    })
    .optional(),
  nonResolvedFqdns: z
    .object({
      queryCount: z.number().optional(),
      details: z.array(ResolvedQuerySchema).optional(),
    })
    .nullish(),
  location: z.string().optional(),
  time: schemas.TimeStampSchema.nullish(),
  failCount: z.number().optional(),
  contextTranslated: z.boolean().optional(),
  torIpAddresses: z.array(z.string()).optional(),
  subType: z.number().optional(),
  targetHost: z
    .object({
      geoState: z.string().optional(),
      geoCountry: z.string().optional(),
      details: z
        .array(
          z.object({
            rrn: z.string().nullish(),
            host: z.string().nullish(),
            unifiedAssetId: z.string().nullish(),
          }),
        )
        .nullish(),
      redlockResourceDetails: z
        .object({
          rrn: z.string().optional(),
          resourceName: z.string().optional(),
          resourceId: z.number().nullish(),
          unifiedAssetId: z.string().nullish(),
        })
        .optional(),
      ip: z.string().optional(),
      port: z.number().nullish(),
      totalHosts: z.number().nullish(),
    })
    .optional(),
  sourceHost: z
    .object({
      rrn: z.string().nullish(),
      host: z.string().nullish(),
      ip: z.string().nullish(),
      unifiedAssetId: z.string().nullish(),
    })
    .nullish(),
  sourceLocation: z.string().optional(),
  targetPort: z
    .object({
      port: z.number().nullish(),
    })
    .nullish(),
  clientLocation: z.string().optional(),
  serverLevel: z
    .object({
      rrn: z.string().optional(),
      host: z.string().optional(),
      unifiedAssetId: z.string().nullish(),
    })
    .optional(),
  unusualPort: z.string().optional(),
  clientLevel: z
    .object({
      rrn: z.string().optional(),
      host: z.string().optional(),
      unifiedAssetId: z.string().nullish(),
    })
    .optional(),
  serverLocation: z.string().optional(),
  unusualProtocol: z.string().optional(),
  int_ext: z.string().optional(),
  torType: z.string().optional(),
  srcHost: z
    .object({
      redlockResourceDetails: z
        .object({
          rrn: z.string().optional(),
          resourceName: z.string().optional(),
          unifiedAssetId: z.string().nullish(),
        })
        .optional(),
      ip: z.string().optional(),
      geoState: z.string().optional(),
      geoCountry: z.string().optional(),
      autoFocusLink: z.string().nullish(),
    })
    .optional(),
  exfilVolume: z
    .object({
      volume: z.string().optional(),
    })
    .optional(),
  dstHost: z
    .object({
      ip: z.string().optional(),
      port: z.number().nullish(),
      geoState: z.string().optional(),
      geoCountry: z.string().optional(),
      geoIsp: z.string().optional(),
    })
    .optional(),
  usualVolume: z.string().optional(),
  additionalContext: z
    .object({
      usualTraffic: z.string().optional(),
    })
    .optional(),
  isAnomaly: z.boolean().optional(),
  internalIps: z.string().optional(),
  publicIps: z.string().optional(),
  metadata: z.any(),
  threatFeedSource: z.string().optional(),
  description: z.string().nullish(),
  apiName: z.string().nullish(),
  role: z.string().nullish(),
  initialIP: z.string().nullish(),
});

export const AnomalyMetadataSchema = z.object({
  anomalyDetail: AnomalyDetailSchema.merge(
    z.object({
      features: z.array(AnomalyDetailFeatureSchema).nullish(),
    }),
  ),
  anomalyId: z.string().optional(),
});

export const AnomalDetailSchema = AnomalyDetailSchema.merge(
  z.object({
    features: z.array(AnomalyDetailFeatureSchema).optional(),
    descriptionMap: z
      .object({
        duration: schemas.TimeStampSchema.optional(),
        distance: z.string().optional(),
        speed: z.string().optional(),
      })
      .optional(),
    int_ext: z.string().optional(),
    srcHost: z
      .object({
        autoFocusLink: z.string().optional(),
        ip: z.string().optional(),
        geoState: z.string().optional(),
        geoCountry: z.string().optional(),
      })
      .optional(),
    targetHost: z
      .object({
        autoFocusLink: z.string().optional(),
        ip: z.string().optional(),
        redlockResourceDetails: z
          .object({
            rrn: z.string().optional(),
            resourceName: z.string().optional(),
            unifiedAssetId: z.string().nullish(),
          })
          .optional(),
        port: z.number().nullish(),
      })
      .optional(),
    additionalThreatGroups: z.string().nullish(),
    dstHost: z
      .object({
        ip: z.string().nullish(),
        port: z.number().nullish(),
        geoState: z.string().nullish(),
        geoCountry: z.string().nullish(),
        geoIsp: z.string().nullish(),
      })
      .nullish(),
  }),
);

export const GetAlertByIdForAnomalyResponse = z.object({
  id: z.string(),
  status: AlertStatusEnumSchema,
  reason: z.string(),
  firstSeen: schemas.TimeStampSchema,
  lastSeen: schemas.TimeStampSchema,
  alertTime: schemas.TimeStampSchema,
  metadata: AnomalyMetadataSchema,
  policy: z.object({
    policyId: z.string(),
    policyType: PolicyTypesEnumSchema,
    systemDefault: z.boolean(),
    remediable: z.boolean(),
    recommendation: z.string().nullish(),
  }),
  alertRules: AlertRulesSchema.nullish(),
  resource: AlertResourceSchema,
  anomalyDetail: AnomalDetailSchema,
  investigateOptions: z
    .object({
      alertId: z.string(),
      endTs: z.number().optional(),
      searchId: z.string().optional(),
      startTs: z.number().optional(),
    })
    .optional(),
  alertAttribution: z
    .object({
      attributionEventList: z
        .array(
          z.object({
            event: jsonSchema.optional(),
          }),
        )
        .optional(),
      resourceCreatedBy: z.string().optional(),
      resourceCreatedOn: z.number().optional(),
    })
    .optional(),
  history: z.array(AlertHistorySchema).optional(),
  actionLog: z.string().optional(),
  dismissedBy: z.string().optional(),
  dismissalNote: z.string().optional(),
  recommendation: z.string().nullish(),
  appMetadata: ApplicationMetadata,
});

export const SaveFilterTypeRequest = z.object({
  createdBy: z.string().optional(),
  createdOn: z.number().optional(),
  customerId: z.number().optional(),
  description: z.string().optional(),
  filterId: z.string().optional(),
  filterTarget: z.string(),
  filters: z.array(
    z.object({
      name: z.string(),
      operator: z.literal("="),
      value: z.string(),
    }),
  ),
  lastModifiedBy: z.string().optional(),
  name: z.string(),
  timeRange: TimeRangeSchema,
});

export const AlertCSVTypeRequest = z.object({
  filters: AlertsFiltersPostBodySchema,
  timeRange: TimeRangeSchema,
});

export const AlertCSVTypeResponse = z.object({
  status: CSVDownloadStatusEnum,
  statusUri: z.string(),
});

export const CSVTypeResponse = z.object({
  status: CSVDownloadStatusEnum,
  statusUri: z.string(),
  downloadUri: z.string().optional(),
});

export const AlertDismissTypeRequest = z.object({
  alerts: z.array(z.string().nullable()).nullish(),
  dismissalNote: z.string(),
  dismissalTimeRange: z
    .object({
      type: z.string(),
      value: z.object({
        endTime: z.number().optional(),
        amount: z.string().optional(),
        unit: z.string().optional(),
      }),
    })
    .optional(),
  filter: z.object({
    filters: AlertsFiltersPostBodySchema.nullable(),
    timeRange: TimeRangeSchema.nullable(),
  }),
  policies: z.array(z.string().nullable()).nullish(),
});

export const AlertReopenTypeRequest = z.object({
  alerts: z.array(z.string().nullable()).nullish(),
  filter: z.object({
    filters: AlertsFiltersPostBodySchema.nullable(),
    timeRange: TimeRangeSchema.nullable(),
  }),
  policies: z.array(z.string().nullable()).nullish(),
});

export const AlertRemediationInfoTypeRequest = z.object({
  alerts: z.array(z.string()),
  findingId: z.string().nullish().optional(),
  filter: z.object({
    timeRange: TimeRangeSchema,
  }),
});

export const AlertRemediationInfoResponse = z.object({
  cliDescription: z.string(),
  alertIdVsCliScript: z.record(z.string(), z.string()),
  scriptImpact: z.string().optional(),
});

export const AlertRemediationCli = z.object({
  paramName: z.string(),
  cloudType: z.string(),
  customSupported: z.boolean(),
  descriptionI18N: z.string(),
});

export const AlertRemediationCliResponse = z.array(AlertRemediationCli);

export const AlertsPolicyV3Schema = z.object({
  alertCount: z.number(),
  policyId: z.string(),
  policyName: z.string(),
  severity: SeverityEnumSchema.nullish(),
  policyLabels: z.array(z.string()).nullish(),
  complianceMetadata: z
    .array(
      z.object({
        standardName: z.string().nullish(),
        standardDescription: z.string().nullish(),
        requirementId: z.string().nullish(),
        requirementName: z.string().nullish(),
        sectionId: z.string().nullish(),
        sectionDescription: z.string().nullish(),
        policyId: z.string().nullish(),
        complianceId: z.string().nullish(),
        sectionViewOrder: z.number().nullish(),
        requirementViewOrder: z.number().nullish(),
        systemDefault: z.boolean().nullish(),
        customAssigned: z.boolean().nullish(),
      }),
    )
    .nullish(),
  remediable: z.boolean().nullish(),
  cloudType: CloudTypesWithOtherEnumSchema.nullish(),
  findingTypes: z.array(z.string().nullish()).nullish(),
  restrictAlertDismissal: z.boolean().nullish(),
  policyType: PolicyTypesEnumSchema,
  saveSearchId: z.string().nullish(),
  resourceType: z.string().nullish(),
  mittreAttacks: z.array(z.string()).nullish(),
  description: z.string().nullish(),
});

export const GetAlertsAggregateRequest = z.object({
  filters: AlertsFiltersPostBodySchema,
  timeRange: schemas.TimeRangeSchema,
  size: z.number().positive(),
  nextPageToken: z.string().optional(),
  groupBy: AlertsGroupBySchema,
  sortBy: z.array(z.string()),
});

export const GetAlertsAggregateRequestWithoutPaging = z.object({
  filters: AlertsFiltersPostBodySchema,
  timeRange: schemas.TimeRangeSchema,
  groupBy: AlertsGroupBySchema,
  sortBy: z.array(z.string()),
});

export const AlertGroupSchema = z.object({
  criticalAlertCount: z.number().nullish(),
  highAlertCount: z.number().nullish(),
  informationalAlertCount: z.number().nullish(),
  runPolicyCount: z.number().nullish(),
  buildPolicyCount: z.number().nullish(),
  lowAlertCount: z.number().nullish(),
  mediumAlertCount: z.number().nullish(),
  group: PolicyTypesEnumSchema.or(SeverityEnumSchema).nullish(),
  standardDescription: z.string().nullish(),
  totalAlerts: z.number().nullish(),
  totalPolicies: z.number().nullish(),
  findingTypes: z.array(z.string().nullish()).default([]),
  policyIds: z.array(z.string()).nullish(),
  cloudTypes: z.array(CloudTypesEnumSchema).nullish(),
});

export const GetAlertsAggregateResponse = z.object({
  totalRows: z.number().nonnegative().nullish(),
  nextPageToken: z.string().optional(),
  sortAllowedColumns: z.array(z.string()).nullish(),
  groupBy: z.string(),
  groups: z.array(AlertGroupSchema),
  countDetails: z.object({
    totalAlerts: z.number(),
    accurate: z.boolean().optional(),
    totalPolicies: z.number(),
  }),
});

export const GetAlertsAggregateResponseWithoutPaging = z.object({
  sortAllowedColumns: z.array(z.string()).nullish(),
  groupBy: z.string(),
  groups: z.array(AlertGroupSchema),
});

export const GetAlertsV3PolicyRequest = z.object({
  filters: AlertsFiltersPostBodySchema,
  timeRange: schemas.TimeRangeSchema,
  size: z.number().positive(),
  nextPageToken: z.string().optional(),
  sortBy: z.array(z.string()),
});

export const GetAlertsV3PolicyResponse = z.object({
  totalRows: z.number().nullish(),
  nextPageToken: z.string().optional(),
  sortAllowedColumns: z.array(z.string()).nullish(),
  policies: z.array(AlertsPolicyV3Schema),
  countDetails: z.object({
    totalAlerts: z.number(),
    accurate: z.boolean().optional(),
    totalPolicies: z.number(),
  }),
});

const VulnerabilityCountSchema = z.object({
  totalCriticalVulnerabilities: z.number(),
  totalHighVulnerabilities: z.number(),
});

export const VulnerabilitiesResponseSchema = z.object({
  value: z.object({
    images: VulnerabilityCountSchema,
    hosts: VulnerabilityCountSchema,
    totalBySeverity: VulnerabilityCountSchema,
  }),
  date: z.string(),
  ts: z.number(),
  lastScanTs: z.number().optional(),
  totalCriticalAlerts: z.number().optional(),
  totalHighAlerts: z.number().optional(),
});

export const TopVulnerabilitiesResponseSchema = z.object({
  value: z.array(
    z.object({
      numCriticalVulnerabilities: z.number(),
      numHighVulnerabilities: z.number(),
      resourceName: z.string(),
      totalVulnerabilities: z.number(),
      unifiedAssetId: z.string(),
    }),
  ),
  date: z.string(),
  ts: z.number(),
  lastScanTs: z.number(),
});

export type TopVulnerabilitiesResponseType = z.infer<
  typeof TopVulnerabilitiesResponseSchema
>;

export const GetVulnerabilitiesRequest = z.object({
  filters: AlertsFiltersPostBodySchema,
  timeRange: schemas.TimeRangeSchema,
});

export const topVulnerableAssets = z.enum(["IMAGES", "HOSTS"]);
export type TopVulnerableAssetsType = z.infer<typeof topVulnerableAssets>;

export const GetTopVulnerabilitiesRequest = z.object({
  filters: AlertsFiltersPostBodySchema,
  timeRange: schemas.TimeRangeSchema,
});

export const NodeSchema = z.object({
  nodeType: z.string(),
  label: z.string().nullish(),
  severity: SeverityEnumSchema.nullish(),
  findingPolicyType: z.string().nullish(),
  findingPolicySubTypes: z.array(z.string()).nullish(),
  findingType: z.string().nullish(),
  assetId: z.string().nullish(),
  assetName: z.string().nullish(),
  name: z.string().nullish(),
  assetApiId: z.number().nullish(),
  findingId: z.string().nullish().optional(),
  findingRemediationDetails: z
    .object({
      buildTimeMetadata: z
        .object({
          fix_pr_url: z.string().optional(),
          description: z.string().optional(),
          bc_resource_id: z.string().optional(),
          git_url: z.string().optional(),
        })
        .partial()
        .optional(),
      runtimeMetadata: z
        .object({ remediationTs: z.number().optional() })
        .partial()
        .optional(),
    })
    .optional(),
  riskFactors: z.array(z.string()).optional(),
  cvssScore: z.number().nullish(),
});

export const SecretNodeSchema = z.object({
  nodeType: z.string(),
  type: z.string(),
  id: z.string(),
  severity: z.string(),
  path: z.string(),
  modifiedTime: z.number(),
  snippet: z.string(),
  locationInFile: z.string(),
});

export const NodesSchema = z.record(
  z.string(),
  z.union([NodeSchema, SecretNodeSchema]),
);

export const EdgesSchema = z.array(
  z.object({ source: z.string(), target: z.string() }),
);

export const GraphSchema = z.object({
  directed: z.boolean(),
  nodes: NodesSchema.nullish(),
  edges: EdgesSchema.nullish(),
});

export const AttackPathDetailsSchema = z.object({
  graph: GraphSchema.nullish(),
});

export const GetAlertByIdForAttackPathResponse = z.object({
  id: z.string(),
  status: AlertStatusEnumSchema,
  reason: z.string(),
  firstSeen: schemas.TimeStampSchema,
  lastSeen: schemas.TimeStampSchema,
  alertTime: schemas.TimeStampSchema,
  saveSearchId: z.string().nullish(),
  investigateOptions: z
    .object({
      hasSearchExecutionSupport: z.boolean().optional(),
    })
    .optional(),
  metadata: z
    .object({
      attackPathDetails: AttackPathDetailsSchema.nullish(),
      hasSearchExecutionSupport: z.boolean().optional(),
    })
    .nullish(),
  policy: z.object({
    policyId: z.string(),
    policyType: z.string(),
    systemDefault: z.boolean(),
    severity: SeverityEnumSchema,
    findingTypes: z.array(z.string()).nullish(),
    remediable: z.boolean(),
    recommendation: z.string().nullish(),
  }),
  alertRules: AlertRulesSchema,
  history: z.array(AlertHistorySchema).nullish(),
  actionLog: z.string().optional(),
  resource: AlertResourceSchema,
  networkAnomaly: z.boolean().optional(),
  alertAttribution: z
    .object({
      attributionEventList: z
        .array(
          z.object({
            event: jsonSchema.optional(),
          }),
        )
        .optional(),
      resourceCreatedBy: z.string().optional(),
      resourceCreatedOn: z.number().optional(),
    })
    .optional(),
  dismissedBy: z.string().optional(),
  dismissalNote: z.string().optional(),
  recommendation: z.string().nullish(),
  appMetadata: ApplicationMetadata,
});

export const AssetByAssetIdRequest = z.object({
  type: z.string(),
  assetId: z.string(),
});

export const AssetByAssetIdResponse = z.object({
  id: z.string(),
  name: z.string(),
  assetType: z.string(),
  alertsCount: z.number(),
});

export const CNSDataRequest = z.object({
  ModelVersion: z.number(),
  addressMatchCriteria: z.string(),
  alertOn: z.string(),
  sourceIP: z.string(),
  sourceSelector: z.object({
    ModelVersion: z.number(),
    resourceType: z.string(),
  }),
  effectiveAction: z.string(),
  name: z.string(),
  rawRQL: z.string(),
  destinationSelector: z.object({
    ModelVersion: z.number(),
    cloudTypes: z.array(z.string()),
    objectIDs: z.array(z.string()),
    resourceType: z.string(),
  }),
  type: z.string(),
});

export const CNSNodeDataSchema = z.object({
  APIID: z.number().nullish(),
  ID: z.string().nullish(),
  RRN: z.string().nullish(),
  VPCID: z.string().nullish(),
  accountId: z.string().nullish(),
  attachments: z.array(z.string()).nullish(),
  cloudType: CloudTypesWithOtherEnumSchema,
  createTime: z.string().nullish(),
  name: z.string().nullish(),
  namespace: z.string().nullish(),
  nativeID: z.string().nullish(),
  normalizedTags: z.null().nullish(),
  parameters: z
    .object({
      ModelVersion: z.number().nullish(),
      addresses: z
        .array(
          z.object({
            ipversion: z.string().nullish(),
            primary: z.boolean().nullish(),
            privatednsname: z.string().nullish(),
            privateip: z.string().nullish(),
            privatenetwork: z
              .object({ ip: z.string().nullish(), mask: z.string().nullish() })
              .nullish(),
            publicdnsname: z.string().nullish(),
            publicip: z.string().nullish(),
            publicipref: z.string().nullish(),
            publicnetwork: z
              .object({ ip: z.string().nullish(), mask: z.string().nullish() })
              .nullish(),
          }),
        )
        .nullish(),
      attachmentType: z.string().nullish(),
      availabilityZone: z.string().nullish(),
      hasPublicIP: z.boolean().nullish(),
      relatedObjectID: z.string().nullish(),
      resourceStatus: z.string().nullish(),
      securityTags: z.array(z.string().nullish()).nullish(),
      subnets: z.array(z.string().nullish()).nullish(),
    })
    .nullish(),
  protected: z.boolean().nullish(),
  regionName: z.string().nullish(),
  relatedObjectID: z.string().nullish(),
  subType: z.string().nullish(),
  type: z.string().nullish(),
  unifiedAssetID: z.string().nullish(),
});

export const CNSNodesSchema = z.object({
  nodeData: CNSNodeDataSchema.nullish(),
  type: z.string(),
});

export const CNSDataResponse = z.object({
  paths: z
    .array(
      z
        .object({
          nativeID: z.string(),
          policy: z.object({ action: z.string(), policyID: z.string() }),
          routeTables: z.object({}),
        })
        .nullish(),
    )
    .nullish(),
  nodes: z.record(z.string(), CNSNodesSchema).nullish(),
});

export const IAMDataRequest = z.object({
  query: z.string(),
});

export const IAMDataResponse = z.array(
  z
    .object({
      grantedByEntityType: z.string().nullish(),
      grantedByEntityAssetId: z.string().nullish(),
      grantedByEntityName: z.string().nullish(),
      destResourceAssetId: z.string().nullish(),
      destCloudServiceName: z.string().nullish(),
    })
    .nullish(),
);

export const GetPolicyAlertAllFilterRequestSchema = z.object({
  detailed: z.boolean(),
  filters: AlertsFiltersPostBodySchema.nonempty(),
  timeRange: schemas.TimeRangeSchema,
  fields: z.array(PolicyAlertFieldsEnumSchema).optional(),
  groupBy: z.array(z.literal("policy.id")),
  sortBy: z.array(z.literal("alertCount:desc")),
});

export const AlertGraph = z.object({
  graphs: AssetGraphResponseSchema.shape.graphs,
  nextPageToken: z.string().optional(),
});

export const AlertSummaryResponseSchema = z.object({
  value: z.object({
    totalCriticalAlerts: z.number(),
    totalHighAlerts: z.number(),
  }),
  startTime: z.number(),
  endTime: z.number(),
  lastScanTs: z.number(),
  swimlaneType: AlertSummaryCategoriesSchema,
});

export const TopPoliciesResponse = z.object({
  policyId: z.string(),
  policyName: z.string(),
  policyType: PolicyTypesEnumSchema,
  severity: SeverityEnumSchema,
  alertCount: z.number(),
  policyDescription: z.string().optional(),
});

export const TopAlertPoliciesResponseSchema = z.object({
  value: z.array(TopPoliciesResponse),
  startTime: z.number(),
  endTime: z.number(),
  lastScanTs: z.number(),
  swimlaneType: AlertSummaryCategoriesSchema,
});
export type TopAlertPoliciesResponseType = z.infer<
  typeof TopAlertPoliciesResponseSchema
>;

export const TopAssetResponse = z.object({
  externalResourceId: z.string(),
  resourceName: z.string().nullable(),
  cloudName: z.string(),
  serviceName: z.string().nullable(),
  accountName: z.string().nullable(),
  alertCount: z.number(),
  severity: z.string().optional(),
  criticalAlertCount: z.number().optional(),
  highAlertCount: z.number().optional(),
  vulnType: z.string().optional(),
});

export const TopAlertAssetsResponseSchema = z.object({
  value: z.array(TopAssetResponse),
  startTime: z.number(),
  endTime: z.number(),
  lastScanTs: z.number(),
  swimlaneType: AlertSummaryCategoriesSchema,
});
export type TopAlertAssetsResponseType = z.infer<
  typeof TopAlertAssetsResponseSchema
>;

export const MeanResolutionTimeResponseSchema = z.object({
  value: z.array(
    z.object({
      severity: z.string(),
      numberOfDays: z.number(),
    }),
  ),
  startTime: z.number(),
  endTime: z.number(),
  lastScanTs: z.number(),
  dataAvailableFrom: z.number(),
});
export type MeanResolutionTimeResponseType = z.infer<
  typeof MeanResolutionTimeResponseSchema
>;

export const ResolutionReasonResponseSchema = z.object({
  value: z.array(
    z.object({
      resolutionReason: z.string(),
      count: z.number(),
    }),
  ),
  startTime: z.number(),
  endTime: z.number(),
  lastScanTs: z.number(),
  dataAvailableFrom: z.number(),
});
export type ResolutionReasonResponseType = z.infer<
  typeof ResolutionReasonResponseSchema
>;

export const StatusResponseSchema = z.object({
  value: z.array(
    z.object({
      alertStatus: z.string(),
      count: z.number(),
    }),
  ),
});
export type StatusResponseType = z.infer<typeof StatusResponseSchema>;

export const CreatePRSchema = z.object({
  resourceId: z.string(),
  accountId: z.string(),
  prismaId: z.string(),
  policyId: z.string(),
});

export const CreatedPRSchema = z.object({
  fixPrUrl: z.string(),
});

// This is not strict schema, as different services (iam, network, etc..)
// might return different set of fields
export const SearchAlertResponseSchema = z.object({
  timeRange: TimeRangeSchema.optional(),
  data: z.any().optional(),
  query: z.string(),
});

export const AlertsCopilotPromptRequestSchema = z.object({
  requestText: z.string(),
  requestType: z.enum(copilotRequestTypes),
  context: z
    .object({
      currentContext: z.string(),
      requestType: z.enum(copilotRequestTypes),
    })
    .optional(),
});
export type AlertsCopilotPromptRequest = z.infer<
  typeof AlertsCopilotPromptRequestSchema
>;

export const AlertsCopilotPromptResponseSchema = z.object({
  requestType: z.enum(copilotRequestTypes),
  format: z.string(),
  text: z.string(),
});
export type AlertsCopilotPromptResponse = z.infer<
  typeof AlertsCopilotPromptResponseSchema
>;

export type AlertsFilterType = z.infer<typeof AlertsFiltersPostBodySchema>;
export type AlertsPaginatedItemType = z.infer<typeof PaginatedItemResponse>;
export type AlertsTimeRangeType = z.infer<typeof TimeRangeSchema>;
export type AlertsResourceType = z.infer<typeof AlertResourceSchema>;
export type AlertsDismissType = z.infer<typeof AlertDismissTypeRequest>;
export type AlertReopenType = z.infer<typeof AlertReopenTypeRequest>;
export type AlertsPolicyType = z.infer<typeof PolicyTypesEnumSchema>;
export type AlertsPolicySubtype = z.infer<typeof PolicySubTypesEnumSchema>;
export type PolicyAlertsResponseType = z.infer<typeof GetPolicyAlertsResponse>;
export type AlertsDetailsType = z.infer<typeof GetAlertsDetailsResponse>;
export type AlertByIdForConfigType = z.infer<
  typeof GetAlertByIdForConfigResponse
>;
export type AlertByIdForAuditEventType = z.infer<
  typeof GetAlertByIdForAuditEventResponse
>;
export type AlertByIdDataType = z.infer<
  typeof GetAlertByIdForPolicyTypeDataResponse
>;

export type AlertHistoryType = z.infer<typeof AlertHistorySchema>;
export type AlertsMetadataForWorkloadVulnerabilitiesType = z.infer<
  typeof AlertsMetadataForWorkloadVulnerabilitiesSchema
>;
export type AlertByIdForWorkloadIncidentsType = z.infer<
  typeof GetAlertByIdForWorkloadIncidentsResponse
>;
export type AlertByIdForNetworkType = z.infer<
  typeof GetAlertByIdForNetworkResponse
>;
export type WorkloadIncidentMetadataType = z.infer<
  typeof WorkloadIncidentMetadataSchema
>;
export type AuditTypeEnumType = z.infer<typeof AuditTypeEnumSchema>;

export type AlertByIdForAnomalyType = z.infer<
  typeof GetAlertByIdForAnomalyResponse
>;
export type AnomalyDetailFeatureType = z.infer<
  typeof AnomalyDetailFeatureSchema
>;
export type AnomalyMetadataType = z.infer<typeof AnomalyMetadataSchema>;
export type ReconNodesType = z.infer<typeof ReconNodesSchema>;
export type AlertsAnomalyType = z.infer<typeof AlertsAnomalyTypeSchema>;
export type PortScannedDetailsType = z.infer<typeof PortScannedDetailsSchema>;
export type HostScannedDetailType = z.infer<typeof HostScannedDetailSchema>;
export type UsualPortsDetailedType = z.infer<typeof UsualPortsDetailedSchema>;
export type DetailNodesType = z.infer<typeof DetailNodesSchema>;
export type ResolvedQueryType = z.infer<typeof ResolvedQuerySchema>;
export type AnomalDetailType = z.infer<typeof AnomalDetailSchema>;
export type AlertStatusType = z.infer<typeof AlertStatusEnumSchema>;
export type AnomalyDetailType = z.infer<typeof AnomalyDetailSchema>;
export type UnusualDetailsType = z.infer<typeof UnusualDetailsSchema>;
export type AlertSummaryCategoriesType = z.infer<
  typeof AlertSummaryCategoriesSchema
>;

export type AlertByIdForWorkloadVulnerabilityType = z.infer<
  typeof GetAlertByIdForWorkloadVulnerabilityResponse
>;

export type AlertsGroupByType = z.infer<typeof AlertsGroupBySchema>;

export type AlertByIdForAttackPathType = z.infer<
  typeof GetAlertByIdForAttackPathResponse
>;

export type NodesType = z.infer<typeof NodesSchema>;
export type EdgesType = z.infer<typeof EdgesSchema>;
export type PolicyInfoType = z.infer<typeof PolicySchema>;
export type GraphType = z.infer<typeof GraphSchema>;
export type SeverityType = z.infer<typeof SeverityEnumSchema>;
export type AttackPathDetailsType = z.infer<typeof AttackPathDetailsSchema>;
export type SingleFindingNodeType = z.infer<typeof FindingNodeSchema>;

export type CloudTypesWithOtherEnumType = z.infer<
  typeof CloudTypesWithOtherEnumSchema
>;

export type PolicyAlertDataCacheZodType = {
  policyId: string;
  filters: z.infer<typeof AlertsFiltersPostBodySchema>;
  timeRange: z.infer<typeof TimeRangeSchema>;
};

export type AlertsAggregateDataCacheType = {
  filters: z.infer<typeof AlertsFiltersPostBodySchema>;
  timeRange: z.infer<typeof TimeRangeSchema>;
  groupBy: AlertsGroupByType;
  sortBy: Array<string>;
};

export type AlertsPolicyV3Type = z.infer<typeof AlertsPolicyV3Schema>;
export type AlertsV3PolicyResponseType = z.infer<
  typeof GetAlertsV3PolicyResponse
>;
export type AlertGroupType = z.infer<typeof AlertGroupSchema>;
export type AlertsAggregateResponseType = z.infer<
  typeof GetAlertsAggregateResponse
>;
export type AlertsAggregateResponseWithoutPagingType = z.infer<
  typeof GetAlertsAggregateResponseWithoutPaging
>;
export type EvidenceNodeType = z.infer<typeof NodeSchema>;
export type AlertsOverviewFilterType = z.infer<
  typeof AlertsOverviewFilterSchema
>;
export type AlertGraphType = z.infer<typeof AlertGraph>;
export type ConnectionDetailsType = z.infer<typeof ConnectionDetailsSchema>;
export type SecretNodeType = z.infer<typeof SecretNodeSchema>;
