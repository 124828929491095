import { createRoute } from "@tanstack/react-router";
import { lazy } from "react";
import { AlertsOverviewFilterSchema } from "requests";
import { alertsSavedViews } from "saved-views-next";
import { z } from "zod";
import { alertsLayoutRoute } from "./alertsLayoutRoute";

export const Overview = lazy(() => import("./AlertsOverview"));

export const AlertsOverviewSearchSchema = z.object({
  filters: AlertsOverviewFilterSchema.catch({}).optional(),
  // TODO: Maybe when the router is upgraded this will work better
  // if I use .default("default") it works well with useSearch, but
  // then links to the page think the field is required
  // this fixes links, but useSearch thinks it is undefined
  viewId: z.string().catch("default").optional(),
  groupBy: z.string().optional(),
  sortBy: z.string().optional(),
  sortOrder: z.string().optional(),
  openAlertRules: z.optional(z.boolean()),
  openAlertId: z.optional(z.string()),
  alarmIds: z.number().optional(),
});

export type AlertsOverviewSearchType = z.infer<
  typeof AlertsOverviewSearchSchema
>;

export const alertsOverviewRoute = createRoute({
  getParentRoute: () => alertsLayoutRoute,
  path: "overview",
  component: Overview,
  validateSearch: AlertsOverviewSearchSchema,
  loaderDeps: ({ search: { viewId } }) => ({ viewId }),
  loader: ({ context, deps: { viewId } }) => {
    context?.queryClient.prefetchQuery(
      alertsSavedViews.getViewQueryOptions(viewId ?? "default"),
    );
  },
});
